import React from 'react';
import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import SubHeadline from '../components/SubHeadline';
import styled from '@emotion/styled';
import SecondSubHeadline from '../components/SecondSubHeadline';
import {grey5, grey70} from '../colors';
import Page from '../components/Page';
import {withTranslation} from "react-i18next";

export const Container = styled.div`
                        padding: 56px 370px 0 70px;
                        `;

export const Text = styled.p`
                        color: ${grey70};
                        font-size: 18px;
                        text-align: left;
                    `;

class AGB extends React.PureComponent {
  render() {
    const {t} = this.props;
    return (
      <Page background={grey5}>
        <PageContent>

          <Headline>{t('agb1', "Allgemeine Geschäftsbedingungen")} {t('agb18', "der deepsight GmbH")} </Headline>
          <Container>

            <SubHeadline>1. {t('agb2', "Allgemeines; Geltungsbereich")}</SubHeadline>
            <SecondSubHeadline>1.1.</SecondSubHeadline>
            <Text>
              {t('agb3', "Mit der Auftragsannahme wird den AGB der deepsight GmbH und den inhaltlichen Leistungspunkten zugestimmt. Dazu gehört auch der DataDesk (https:// datadesk.deepsight.de). Diese sind Bestandteil aller Verträge, die deepsight mit ihren Vertragspartnern (nachfolgend auch „Auftraggeber“ genannt) über die von ihr angebotenen Leistungen schließt. Sie gelten auch für alle zukünftigen Leistungen und Angebote an den Auftraggebern, selbst wenn sie nicht nochmals gesondert vereinbart werden. Abweichende, entgegenstehende oder ergänzende allgemeine Geschäftsbedingungen werden selbst bei Kenntnis nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird ausdrücklich schriftlich zugestimmt.")}
            </Text>

            <Text>
              {t('agb300', "Die folgenden allgemeinen Geschäftsbedingungen gelten für alle gegenwärtigen und zukünftigen Geschäftsbeziehungen zwischen der deepsight GmbH und anderen Unternehmern. Unternehmer im Sinne dieser Geschäftsbedingungen sind natürliche oder juristische Personen oder rechtsfähige Personengesellschaften, die in Ausübung einer gewerblichen oder selbständigen beruflichen Tätigkeit handeln.")}
            </Text>

            <SecondSubHeadline>1.2.</SecondSubHeadline>
            <Text>
              {t('agb4', "Von diesen Geschäftsbedingungen insgesamt oder teilweise abweichende Geschäftsbedingungen des Auftraggebers erkennt die deepsight GmbH nicht an, es sei denn, den Geschäftsbedingungen des Auftraggebers wird ausdrücklich schriftlich zugestimmt. Die vorliegenden Geschäftsbedingungen gelten auch dann ausschließlich, wenn die deepsight GmbH in Kenntnis entgegenstehender Geschäftsbedingungen des Auftraggebers Leistungen vorbehaltlos erbringt.")}
            </Text>

            <SecondSubHeadline>1.3.</SecondSubHeadline>
            <Text>
              {t('agb5', "Im Einzelfall getroffene, individuelle Vereinbarungen mit den Auftraggebern (einschließlich Nebenabreden und Vertragsänderungen) haben in jedem Fall Vorrang vor diesen Geschäftsbedingungen. Für den Inhalt derartiger Vereinbarungen ist ein schriftlicher Vertrag bzw. eine schriftliche Bestätigung der deepsight GmbH maßgebend.")}
            </Text>


            <SubHeadline>2. {t('agb6', "Gegenstand des Vertrages; Vertragsschluss")}</SubHeadline>
            <SecondSubHeadline>2.1.</SecondSubHeadline>
            <Text>
              {t('agb7', "Vertragsgegenstand ist die Bereinigung, Auswertung und/oder Visualisierung von Texten des Auftraggebers mit regelbasierten und KI-Lösungen durch die deepsight GmbH.")}
            </Text>

            <SecondSubHeadline>2.2.</SecondSubHeadline>
            <Text>
              {t('agb8', "Die deepsight GmbH bietet zudem ein weites Leistungsspektrum im Bereich der Datenanalyse. Hierunter fallen unter anderem automatisierte Analysen wie Data Cleaning (z.B. Erkennung der Sinnhaftigkeit von Texten, Rechtschreibprüfung, Stammformbildung), Pseudo-/Anonymisierung (Ersetzen/Entfernen von sensiblen Informationen, z.B. Personennamen, Adressen, Telefonnummern), Sentimentanalyse (Identifizierung der Stimmung), vortrainierte KI-Modelle zur Themen-Einordnung, unsupervised Topic Modeling (Identifizierung und Gruppierung von Themen aus Texten ohne vordefinierte Schubladen) sowie generelles Data Mining (Gewinnung neuer Informationen aus strukturierten und unstrukturierten Daten).")}
            </Text>

            <SecondSubHeadline>2.3.</SecondSubHeadline>
            <Text>
              {t('agb9', "Vor Vertragsschluss erstellt die deepsight GmbH in Zusammenarbeit mit dem Auftraggeber eine Leistungsbeschreibung. Diese wird auf Basis der Angaben des Auftraggebers erstellt. Im Falle von unvollständigen bzw. fehlerhaften Vorgaben trägt der Auftraggeber das Risiko des Auftragserfolges.")}
            </Text>

            <SecondSubHeadline>2.4.</SecondSubHeadline>
            <Text>
              {t('agb10', "Zwischen den Beteiligten kommt ein Vertrag durch Annahme eines von der deepsight GmbH gegenüber dem Auftraggeber unterbreiteten Leistungsangebotes, durch die Registrierung auf unserem DataDesk oder durch Übersendung einer Auftragsbestätigung durch die deepsight GmbH zustande. Der Vertrag endet mit Eintritt des geschuldeten Leistungserfolges / Fertigstellung der Leistungserbringung bzw. im Falle der Erbringung von reinen Dienstleistungen durch die deepsight GmbH zum im Vertrag bestimmten Zeitpunkt bzw. nach Kündigung unter Maßgabe der gesetzlichen Bestimmungen.")}
            </Text>


            <SubHeadline>3. {t('agb11', "Pflichten des Auftraggebers (nicht relevant für DataDesk Nutzer)")}</SubHeadline>
            <SecondSubHeadline>3.1.</SecondSubHeadline>
            <Text>
              {t('agb12', "Der Auftraggeber benennt gegenüber der deepsight GmbH einen projektverantwortlichen Mitarbeiter / Ansprechpartner für die Erteilung von verbindlichen Auskünften und die Erbringung von Mitwirkungshandlungen. Der benannte Mitarbeiter ist zudem befugt, im Rahmen der vertraglichen Zusammenarbeit Entscheidungen zu treffen und den Umfang der Beauftragung in Abstimmung mit der deepsight GmbH zu ändern.")}
            </Text>

            <SecondSubHeadline>3.2.</SecondSubHeadline>
            <Text>
              {t('agb14', "Der Auftraggeber unterstützt die deepsight GmbH verantwortlich bei der Erfüllung der vertraglich geschuldeten Leistungen. Hiervon umfasst ist insbesondere die rechtzeitige und vollständige Bereitstellung von Informationen, sowie benötigtem Datenmaterial. Der Auftraggeber wird die deepsight GmbH über alle Umstände, die Einfluss auf die Leistungserstellung und die Vertragserfüllung haben, vollumfänglich und frühzeitig unterrichten.")}
            </Text>

            <SecondSubHeadline>3.3.</SecondSubHeadline>
            <Text>
              {t('agb15', "Um einen reibungslosen Ablauf eines Auftrages zu gewährleisten, müssen durch den Auftraggeber Mitwirkungspflichten erfüllt werden. Diese Verpflichtungen sind Hauptleistungspflichten des Auftraggebers, auf deren Einhaltung Leistungen von deepsight GmbH, insbesondere Terminbestätigungen, beruhen. Diese sind insbesondere, aber nicht abschließend:")}
              <br/>
              <ul>
                <li>
                  {t('agb16', "Der Auftraggeber stellt alle für die Durchführung des Auftrages erforderlichen Unterlagen, Informationen und Materialien vollständig und leserlich zur Verfügung.")}
                </li>
                <li>
                  {t('agb17', "Der Auftraggeber dokumentiert bei Vertragsschluss in schriftlicher Form, wenn die Erbringung der Leistung nur während eines bestimmten Zeitraumes möglich ist und eine verspätete Leistung keine Erfüllung mehr darstellen würde.")}
                </li>
                <li>
                  {t('agb19', "Texte müssen als in einem Textverarbeitungsprogramm gesetzte Datei geliefert werden. Übernimmt deepsight GmbH mit Zustimmung des Auftraggebers die Übertragung aus einem anderen Datenformat, gilt die sich daraus ergebende Datei als Ausgangstext.")}
                </li>
                <li>
                  {t('agb20', "Die Daten müssen virenfrei sein. Eingehende Daten sind auf Viren zu prüfen.")}
                </li>
                <li>
                  {t('agb21', "Der Auftraggeber garantiert, dass er die Nutzungsrechte an den Vorlagen und an den zu bearbeitenden Materialien hat, insbesondere, dass er über das Recht verfügt, anderen die Nutzung, insbesondere die Bearbeitung und Vervielfältigung dieser Vorlagen zu gestatten.")}
                </li>
                <li>
                  {t('agb22', "Der Auftraggeber versichert, dass er alle Regeln des Datenschutzes, insbesondere die DSGVO einhält und berichtigt ist, personenbezogene Daten weiterzuleiten.")}
                </li>
                <li>
                  {t('agb23', "Der Auftraggeber wird deepsight GmbH von sämtlichen Ansprüchen und außergerichtlichen sowie gerichtlichen Kosten und Ersatzansprüchen freihalten, die aus der Verletzung von Immaterialgüterrechten Dritter oder Datenschutzverstoßen hergeleitet werden.")}
                </li>
              </ul>
            </Text>


            <SubHeadline>4. {t('agb24', "Leistungszeit; Verzug")}</SubHeadline>
            <SecondSubHeadline>4.1.</SecondSubHeadline>
            <Text>
              {t('agb25', "Termine zur Leistungserbringung sind unverbindlich. Soweit Termine oder Fristen verbindlich gelten sollen, müssen diese ausdrücklich als verbindlich zwischen den Vertragspartnern vereinbart werden. Die entsprechende Vereinbarung bedarf der Schriftform.")}
            </Text>

            <SecondSubHeadline>4.2.</SecondSubHeadline>
            <Text>
              {t('agb26', "Leistungsverzögerungen aufgrund höherer Gewalt, wie Pandemien, Streik, Naturkatastrophen, Blackouts, …) sowie aufgrund von Umständen im Verantwortungsbereich des Auftraggebers (z. B. verspätete Erbringung von Mitwirkungshandlungen) hat die deepsight GmbH nicht zu vertreten und verlängern die Ausführungszeit entsprechend.")}
            </Text>

            <SecondSubHeadline>4.3. {t("agb260", "(nicht für DataDesk Nutzer relevant)")}</SecondSubHeadline>
            <Text>
              {t('agb27', "Die deepsight GmbH haftet bei Verzögerung der Leistung in Fällen des Vorsatzes oder der groben Fahrlässigkeit der deepsight GmbH oder eines Vertreters bzw. Erfüllungsgehilfen nach den gesetzlichen Bestimmungen. In allen anderen Fällen der schuldhaften Verzögerung der Leistung wird die Haftung der deepsight GmbH für Schadenersatz neben der Leistung auf 5 % des Wertes des von der Verzögerung betroffenen Teils der Leistung und für den Schadenersatz statt der Leistung auf 5 % des Auftragswertes begrenzt. Weitergehende Ansprüche des Auftraggebers sind ausgeschlossen. Diese Haftungsbeschränkung gilt jedoch nicht bei Haftung wegen der Verletzung des Lebens, des Körpers oder der Gesundheit.")}
            </Text>


            <SubHeadline>5. {t('agb28', "Abnahme (nicht für DataDesk Nutzer relevant)")}</SubHeadline>
            <SecondSubHeadline>5.1.</SecondSubHeadline>
            <Text>
              {t('agb29', "Soweit in rechtlicher Hinsicht ein Leistungserfolg geschuldet ist, kann die deepsight GmbH nach jedem Meilenstein dem Auftraggeber die Leistung in geeigneter Form überlassen und eine schriftliche Erklärung verlangen, dass die vorgelegte Leistung bzw. Teilleistung unter dem Vorbehalt der Funktionsfähigkeit im Ganzen abgenommen wird.")}
            </Text>

            <SecondSubHeadline>5.2.</SecondSubHeadline>
            <Text>
              {t('agb30', "Hat sich der Auftraggeber innerhalb einer Frist von 14 Tagen nach Übergabe der Leistung nicht erklärt, gilt die Abnahme im entsprechenden Umfang als erklärt.")}
            </Text>

            <SecondSubHeadline>5.3.</SecondSubHeadline>
            <Text>
              {t('agb31', "Die Abnahme darf durch den Auftraggeber nur bei Vorliegen von wesentlichen Mängeln verweigert werden.")}
            </Text>


            <SubHeadline>6. {t('agb32', "Gewährleistung")}</SubHeadline>
            <SecondSubHeadline>6.1.</SecondSubHeadline>
            <Text>
              {t('agb33', "Der Auftraggeber wird alle Leistungen der deepsight GmbH unverzüglich auf Mangelfreiheit untersuchen.")}
            </Text>

            <SecondSubHeadline>6.2.</SecondSubHeadline>
            <Text>
              {t('agb34', "Mängel sind schriftlich unter genauer Beschreibung der Mängel gegenüber der deepsight GmbH zu rügen.")}
            </Text>

            <SecondSubHeadline>6.3.</SecondSubHeadline>
            <Text>
              {t('agb35', "Der Auftraggeber wird darauf hingewiesen, dass für einen fehlerfreien Einsatz die jeweils definierten System- bzw. Datenvoraussetzungen gelten. Die Einhaltung der System- und Datenvoraussetzungen obliegt ausschließlich dem Auftraggeber und berechtigt im Falle des Verstoßes nicht zur Geltendmachung von Mängelrechten.")}
            </Text>

            <SecondSubHeadline>6.4. {t("agb350", "(nicht für DataDesk Nutzer relevant)")}</SecondSubHeadline>
            <Text>
              {t('agb36', "Bei Mängeln leistet die deepsight GmbH Nacherfüllung nach ihrer Wahl entweder durch Mangelbeseitigung oder Neuerstellung. Schlägt die Nacherfüllung endgültig fehl oder ist sie der deepsight GmbH unzumutbar, so gelten im Übrigen die gesetzlichen Regelungen, soweit im Einzelfall keine abweichende Vereinbarung getroffen wurde.")}
            </Text>

            <SecondSubHeadline>6.5.</SecondSubHeadline>
            <Text>
              {t('agb37', "Soweit der Datensatz durch den Auftraggeber verändert bzw. durch Dritte eine Veränderung veranlasst wurde, bestehen keine Gewährleistungsansprüche gegenüber der deepsight GmbH, es sei denn, der Auftraggeber weist nach, dass durch die Änderung eine Nachbesserung nicht wesentlich erschwert wird und der Mangel unbeschadet der vorgenommenen Änderungen bereits bei Abnahme vorhanden war.")}
            </Text>

            <SecondSubHeadline>6.6.</SecondSubHeadline>
            <Text>
              {t('agb38', "Gewährleistungsansprüche verjähren in 12 Monaten ab Übergabe. Dies gilt nicht bei Vorsatz, grober Fahrlässigkeit, der Verletzung von Leben, Körper und Gesundheit, Arglist, der Übernahme einer Garantie für die Beschaffenheit einer Sache oder einer etwaigen Haftung nach dem Produkthaftungsgesetz.")}
            </Text>


            <SubHeadline>7. {t('agb39', "Zahlungsbedingungen; Fälligkeit; Aufrechnung")}</SubHeadline>
            <SecondSubHeadline>7.1.</SecondSubHeadline>
            <Text>
              {t('agb40', "Die Vergütung von Leistungen der deepsight GmbH erfolgt vorbehaltlich anderer Vereinbarungen nach festen Preisen und nicht nach Aufwand gemäß einer entsprechenden Preisliste.")}
            </Text>

            <SecondSubHeadline>7.2.</SecondSubHeadline>
            <Text>
              {t('agb41', "Soweit keine anderweitige Vergütungsregelung zwischen den Vertragsparteien getroffen wurde, kann die deepsight GmbH bei Vertragsschluss im Hinblick auf einen geschuldeten Leistungserfolg eine Anzahlung i. H. v. 1/3 des vereinbarten Leistungspreises verlangen. Während des weiteren Projektverlaufes ist die deepsight GmbH zudem berechtigt, Abschlagsrechnungen für abgrenzbare Teilleistungen zu legen.")}
            </Text>

            <SecondSubHeadline>7.3.</SecondSubHeadline>
            <Text>
              {t('agb42', "Sind Dienstleistungen Gegenstand der vertraglichen Zusammenarbeit, ist die deepsight GmbH in Ermangelung einer anderweitigen Vereinbarung zwischen den Beteiligten berechtigt, monatlich Rechnung zu legen.")}
            </Text>

            <SecondSubHeadline>7.4.</SecondSubHeadline>
            <Text>
              {t('agb43', "Der Auftraggeber trägt zudem sämtliche Auslagen wie Reise- und Übernachtungskosten, Spesen und im Rahmen der Vertragsdurchführung anfallende Entgeltforderungen Dritter, soweit diese nicht ausdrücklich in einer Preisvereinbarung zwischen den Vertragspartnern inkludiert sind.")}
            </Text>

            <SecondSubHeadline>7.5.</SecondSubHeadline>
            <Text>
              {t('agb44', "Angegebene Preise verstehen sich zzgl. Mehrwertsteuer in der jeweils geltenden gesetzlichen Höhe.")}
            </Text>

            <SecondSubHeadline>7.6.</SecondSubHeadline>
            <Text>
              {t('agb45', "Rechnungen der deepsight GmbH sind vorbehaltlich anderweitiger Vereinbarung nach Erhalt der Rechnung sofort zur Zahlung ohne Abzüge fällig.")}
            </Text>

            <SecondSubHeadline>7.7.</SecondSubHeadline>
            <Text>
              {t('agb46', "Eine Aufrechnung durch den Auftraggeber ist nur mit rechtskräftig festgestellten oder unbestrittenen Forderungen zulässig.")}
            </Text>

            <SubHeadline>8. {t('agb47', "Eigentumsvorbehalt")}</SubHeadline>
            <Text>
              {t('agb48', "Liefert die deepsight GmbH Waren, verbleiben diese bis zur vollständigen Bezahlung sämtlicher Forderungen, die die deepsight GmbH gegen den Auftraggeber aus einer laufenden Geschäftsbeziehung zustehen, im Eigentum von der deepsight GmbH.")}
            </Text>


            <SubHeadline>9. {t('agb49', "Haftung")}</SubHeadline>
            <SecondSubHeadline>9.1.</SecondSubHeadline>
            <Text>
              {t('agb50', "Die deepsight GmbH haftet nicht für einen etwaigen Datenverlust des Auftraggebers. Es obliegt insoweit ausschließlich dem Auftraggeber, vor, während und nach der vertraglichen Zusammenarbeit mit der deepsight GmbH für eine ausreichende Sicherung seiner Daten Sorge zu tragen.")}
            </Text>

            <SecondSubHeadline>9.2.</SecondSubHeadline>
            <Text>
              {t('agb51', "Auch eine Datenwiederherstellung durch die deepsight GmbH ist nicht geschuldet.")}
            </Text>

            <SecondSubHeadline>9.3.</SecondSubHeadline>
            <Text>
              {t('agb52', "Im Falle des Leistungsverzuges wird auf die Bestimmungen unter Ziffer 4 dieser Geschäftsbedingungen verwiesen.")}
            </Text>

            <SecondSubHeadline>9.4.</SecondSubHeadline>
            <Text>
              {t('agb53', "Im Falle etwaiger Mängel gelten die Regelungen unter Ziffer 6 dieser Geschäftsbedingungen.")}
            </Text>

            <SecondSubHeadline>9.5.</SecondSubHeadline>
            <Text>
              {t('agb54', "Für Schäden, die nicht am Liefergegenstand selbst entstanden sind, haftet die deepsight GmbH nur")}
              a) {t('agb55', "bei Vorsatz;")}
              b) {t('agb56', "bei grober Fahrlässigkeit;")}
              c) {t('agb57', "bei schuldhafter Verletzung von Leben, Körper, Gesundheit;")}
              d) {t('agb58', "im Falle von Arglist;")}
              e) {t('agb59', "oder bei Übernahme von Garantien für die Beschaffenheit oder sonstigen Garantien.")}
              {t('agb60', "Bei schuldhafter Verletzung wesentlicher Vertragspflichten haftet die deepsight GmbH auch bei grober Fahrlässigkeit und bei leichter Fahrlässigkeit. Die Haftung im Falle leichter Fahrlässigkeit wird jedoch auf den vertragstypischen, vernünftigerweise vorhersehbaren Schaden begrenzt.")}
            </Text>

            <SecondSubHeadline>9.6.</SecondSubHeadline>
            <Text>
              {t('agb61', "Alle weiteren Ansprüche, insbesondere aus einer verschuldensunabhängigen Haftung, sind ausgeschlossen.")}
            </Text>

            <SecondSubHeadline>9.7.</SecondSubHeadline>
            <Text>
              {t('agb62', "Eine Haftung nach dem Produkthaftungsgesetz bleibt von den vorstehenden Haftungsbeschränkungen unberührt.")}
            </Text>


            <SubHeadline>10. {t('agb63', "Urheberrechte; Rechteeinräumung")}</SubHeadline>
            <SecondSubHeadline>10.1.</SecondSubHeadline>
            <Text>
              {t('agb64', "Die deepsight GmbH stellt dem Auftraggeber die vertraglich geschuldete Leistung nach eigener Wahl entweder durch Überlassung von Datenträgern oder vorzugsweise die Einräumung der Möglichkeit des elektronischen Abrufs zur Verfügung.")}
            </Text>


            <SubHeadline>11. {t('agb65', "Schutzrechtsverletzungen, Verstoß gegen Datenschutz")}</SubHeadline>
            <SecondSubHeadline>11.1.</SecondSubHeadline>
            <Text>
              {t('agb66', "Im Falle von Schutzrechtsverletzungen Dritter oder Verstöße gegen den Datenschutz darf die deepsight GmbH nach vorheriger Absprache mit dem Auftraggeber Änderungen vornehmen, die unter Wahrung der Interessen des Auftraggebers gewährleisten, dass eine Schutzrechts-/ Datenschutzverletzung nicht mehr vorliegt oder aber die für den Auftraggeber erforderlichen Nutzungsrechte erwerben.")}
            </Text>

            <SecondSubHeadline>11.2.</SecondSubHeadline>
            <Text>
              {t('agb67', "Im Falle einer Inanspruchnahme durch Dritte ist der Auftraggeber verpflichtet, die deepsight GmbH unverzüglich unter Vorlage sämtlicher Informationen / Unterlagen zu unterrichten. Schäden, die aus einer verspäteten Meldung an die deepsight GmbH resultieren, trägt ausschließlich der Auftraggeber.")}
            </Text>

            <SecondSubHeadline>11.3.</SecondSubHeadline>
            <Text>
              {t('agb68', "Personenbezogene Daten werden nur erhoben, verarbeitet oder genutzt, sofern der Betroffene eingewilligt hat oder eine Rechtsvorschrift dies anordnet oder erlaubt. Erbringt deepsight GmbH zusammen mit Dritten oder über Dritte eine Leistung, so kann deepsight GmbH diesen Dritten Daten über den Auftraggeber bekannt geben, soweit diese Bekanntgabe für die Erbringung und Abwicklung dieser Leistungen oder für das Inkasso notwendig ist.")}
            </Text>

            <SecondSubHeadline>11.4.</SecondSubHeadline>
            <Text>
              {t('agb69', "Deepsight GmbH ist berechtigt, dem Auftraggeber unter der vom Kunden bei Vertragsschluss mitgeteilten Rufnummer, E-Mail-Adresse oder Postadresse Text- oder Bildmitteilungen zu Zwecken der Beratung und Bewerbung von Produkten in Zusammenhang mit bestehenden und gegebenenfalls noch abzuschließenden Verträgen zukommen zu lassen, sofern der Auftraggeber der Zusendung zugestimmt hat. Der Auftraggeber ist jederzeit berechtigt, eine erteilte Zustimmung schriftlich zu widerrufen.")}
            </Text>

            <SecondSubHeadline>11.5.</SecondSubHeadline>
            <Text>
              {t('agb70', "Deepsight GmbH sichert die Wahrung der Vertraulichkeit über den Inhalt der von ihr zu bearbeitenden Vorlagen zu und verpflichtet sich, im Falle der Beauftragung Dritter, diese zur Verschwiegenheit zu verpflichten. Darüber hinaus ist deepsight GmbH verpflichtet, die zum Zwecke der Leistungserbringung überlassenen Unterlagen sorgfältig zu verwahren und gegen Einsichtnahme Dritter zu schützen. Deepsight GmbH haftet nicht für den unbefugten Zugriff Dritter beim Versand per E-Mail oder per Brief, insbesondere bei der Kommunikation mit dem Kunden in elektronischer Form, da eine vollständige Sicherheit nicht gewährleistet werden kann.")}
            </Text>

            <SubHeadline>12. {t('agb71', "Geheimhaltung")}</SubHeadline>
            <SecondSubHeadline>12.1.</SecondSubHeadline>
            <Text>
              {t('agb72', "Die Vertragspartner verpflichten sich, alle ihnen im Rahmen der Vertragsdurchführung vom jeweils anderen Vertragspartner bekannt gewordenen Informationen und Unterlagen geheim zu halten. Dies gilt nicht für allgemein bekannte oder zugängliche Informationen.")}
            </Text>

            <SecondSubHeadline>12.2.</SecondSubHeadline>
            <Text>
              {t('agb73', "Darüber hinaus gilt auch über die Beendigung des Vertragsverhältnisses hinaus Vertraulichkeit über den Inhalt der vertraglichen Zusammenarbeit.")}
            </Text>

            <SecondSubHeadline>12.3.</SecondSubHeadline>
            <Text>
              {t('agb74', "Der deepsight GmbH ist es unbeschadet hiervon gestattet, den Namen des Auftraggebers als Referenz zu nennen und öffentlich zu kommunizieren. Dies gilt nicht, soweit der Auftraggeber gegenüber der deepsight GmbH schriftlich widerspricht.")}
            </Text>


            <SubHeadline>13. {t('agb75', "Schriftform; Gerichtsstand ")}</SubHeadline>
            <SecondSubHeadline>13.1.</SecondSubHeadline>
            <Text>
              {t('agb76', "Sämtlichen Änderungen und Ergänzungen der getroffenen vertraglichen Vereinbarung bedürfen der schriftlichen Niederlegung. Dies gilt auch für eine etwaige Aufhebung dieses Schriftformerfordernisses. Die Schriftform ist auch durch E-Mail / Telefax gewahrt.")}
            </Text>

            <SecondSubHeadline>13.2.</SecondSubHeadline>
            <Text>
              {t('agb77', "Ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist, soweit der Auftraggeber Kaufmann, juristische Person des öffentlichen Rechts, öffentlich-rechtliches Sondervermögen oder im Inland ohne Gerichtsstand ist, der Geschäftssitz der deepsight GmbH. Die deepsight GmbH ist unbeschadet hiervon berechtigt, den Auftraggeber an seinem allgemeinen Gerichtsstand zu verklagen.")}
            </Text>

            <SecondSubHeadline>13.3.</SecondSubHeadline>
            <Text>
              {t('agb78', "Es gilt ausschließlich das Recht der Bundesrepublik Deutschland.")}
            </Text>


            <SubHeadline>14. {t('agb79', "Salvatorische Klausel")}</SubHeadline>
            <Text>
              {t('agb80', "Sollten einzelne Bestimmungen der vertraglichen Vereinbarung oder dieser allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, wird die Wirksamkeit der übrigen Bestimmungen hierdurch nicht berührt. Vielmehr gilt anstelle der unwirksamen Bestimmung eine von den Parteien zu ersetzende wirksame Bestimmung, die dem wirtschaftlichen Zweck der ungültigen Bestimmung möglichst entspricht. Gleiches gilt für etwaige Vertragslücken.")}
            </Text>
          </Container>
        </PageContent>
      </Page>
    );
  }
}

export default withTranslation("translations")(AGB);
