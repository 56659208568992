import React, {useState, useEffect} from 'react';
import PageContent from '../components/PageContent';
import Headline from "../components/Headline";
import Page from '../components/Page';
import Link from '../components/Link';
import Curtain from '../components/Curtain';
import moment from 'moment';
import {get} from '../security/Api';
import styled from '@emotion/styled';
import {grey5, grey8, orange1} from '../colors';
import {withTranslation} from "react-i18next";
import {CardListContainer, SortHeaderContainer} from '../components/CardList';

const ActivityRow = styled.div`
    font-size: 15px;
    margin-left: 70px;
    padding: 2px;
`;

const Action = styled.span`
    color: ${orange1}
`;

const TipContainer = styled.div`
    padding: 35px;
    background: ${grey8};
    border-radius: 10px 10px 10px 10px;
    width: 100%;
`;

function Activity(props) {
  const {t} = props;
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const type = 'all';

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    const response = await get(`/history/${type}`);

    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.length) {
        setData(responseData);
      }
      await setLoaded(true);
      await setLoadingError(false);
      return;
    }
    await setLoaded(true);
    await setLoadingError(true);
  }

  const renderActivityTable = () => {

    let rows = data.map((activityRow) => {
      let itemTypeArray = activityRow.type.split('_');
      let itemType, action, project = "";
      switch (itemTypeArray[0]) {
        case 'project' :
          itemType = 'a project';

          break;
        case 'file' :
          itemType = 'a file';
          project = itemTypeArray[1] === "delete" ?
            'from the project ' :
            'to the project ';

          break;
        case 'study' :
          itemType = 'a study';
          project = itemTypeArray[1] === "delete" ?
            'from the project ' :
            'for the project ';

          break;
        default: {
          itemType = 'an item';
          project = itemTypeArray[1] === "delete" ?
            'from the project ' :
            'for the project ';
        }
      }
      switch (itemTypeArray[1]) {
        case 'delete' :
          action = "removed";
          break;
        case 'add' :
          action = itemTypeArray[0] === "file" ? "added" : "created";
          break;
        case 'start' :
          action = "started";
          break;
        case 'finish' :
          action = "completed";
          break;
        case 'failed' :
          action = "completed";
          break;
        default:
          action = "operated";
      }

      return <ActivityRow>
        [{moment(activityRow.performedAt.date).format('DD.MM.YYYY HH:mm')}]&nbsp;
        {activityRow.userName}&nbsp;
        <Action>{action}</Action>&nbsp;
        {itemType}&nbsp;
        {itemTypeArray[0] === "project" ? <Link
          to={"./project/" + activityRow.projectId + '/detail'}>{activityRow.projectName}</Link> : '"' + activityRow.itemName + '" '}
        {itemTypeArray[0] === "project" ? "" : project}
        {itemTypeArray[0] === "project" ? "" :
          <Link to={"./project/" + activityRow.projectId + '/detail'}>"{activityRow.projectName}"</Link>}

      </ActivityRow>
    })

    return (
      <div>

        {rows}
      </div>
    )
  }
  if (loaded === false) {
    return (
      <Curtain type="loading"/>
    );
  }
  if (loadingError === true) {
    return (
      <Curtain type="error"/>
    );
  }

  return (
    <Page background={grey5}>
      <PageContent>
        <Headline>{t('activity1', "Die Aktivität Ihrer Organisation ")}</Headline>
        {!data &&
        <CardListContainer>
          <SortHeaderContainer>
            <TipContainer>
              {t("activity13", "Keine Aufzeichnungen ")}
            </TipContainer>
          </SortHeaderContainer>
        </CardListContainer>
        }
        {data && renderActivityTable()}
      </PageContent>
    </Page>
  );
}

export default withTranslation("translations")(Activity);
