import React from 'react';
import { darkblue } from '../colors';
import styled from '@emotion/styled';
import Modal from './Modal';
import { Button } from './Button';
import Input from './Input';

const Headline = styled.div`
  color: ${darkblue};
  font-size: 32px;
  margin-bottom: 28px;
  text-align: left;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-top: 25px;
`;


class CreateManualStudyModal extends React.PureComponent {
  state = {
    notice: null
  };

  render() {
    const { onClick, onClose, headline } = this.props;
    const { notice } = this.state;

    return (
      <Modal onClose={onClose}>
        <Headline>
          {headline}
        </Headline>
        <Input
          id="notice"
          type="textarea"
          label="Notiz"
          required={false}
          value={notice}
          onChange={value => this.setState({ notice: value })}
        />
        <Footer>
          <Button data-cy="submit-modal" icon="main_arrow" onClick={() => onClick(notice)}>Senden</Button>
        </Footer>
      </Modal>
    );
  }
}

export default CreateManualStudyModal;
