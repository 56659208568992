import React from 'react';
import { darkblue } from '../colors';
import styled from '@emotion/styled';
import Modal from './Modal';
import { Button } from './Button';
import Error from './Error';
import { withTranslation } from "react-i18next";

const Headline = styled.div`
  color: ${darkblue};
  font-size: 32px;
  margin-bottom: 28px;
  text-align: left;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-top: 25px;
`;

const MessageContainer = styled.div`
  color: ${darkblue};
  margin-bottom: 28px;
  text-align: left;
`;

class DeleteProjectModal extends React.PureComponent {

  render() {
    const { onClick, onClose, error,t } = this.props;

    return (
      <Modal onClose={onClose}>
        <Headline>
          {t("deleteprojectmodel1", "Projekt löschen")}
        </Headline>
        <MessageContainer>
          {t("deleteprojectmodel2", "Hiermit wird das Projekt, alle Studien und alle Dateien sowie Ergebnisse unwiderruflich gelöscht. Wollen Sie das wirklich?")}
        </MessageContainer>
        {error &&
        <Error>
          {error}
        </Error>
        }
        <Footer>
          <Button data-cy="abort-modal" onClick={() => onClose()}>{t("deleteprojectmodel3", "Abbrechen")}</Button>
          <Button delete={true} data-cy="submit-modal" onClick={() => onClick()}>{t("deleteprojectmodel4", "Löschen")}</Button>
        </Footer>
      </Modal>
    );
  }
}

export default withTranslation("translations")(DeleteProjectModal);
