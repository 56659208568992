import React, {useState, useEffect, useRef} from 'react';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import CardHeaderBackground, {
  CardHeaderBackgroundHeadline,
  HeadlineIcon,
  HeadlineText,
} from '../components/CardHeaderBackground';
import Icon from '../components/Icon/Icon';
import CardHeaderWizard, {Info} from '../components/CardHeaderWizard';
import StudyEdit from '../components/StudyEdit';
import ThematicAnalysisResult from "../components/MethodModals/ThematicAnalysisResult";
import {STATUS_FINISHED, Step, Wizard} from '../components/Wizard';
import CardBody, {CardBodyInner, CardBreakLine} from '../components/CardBody';
import {limegreen, grey70, grey80, darkblue, white} from '../colors';
import SubHeadline from '../components/SubHeadline';
import {withRouter} from 'react-router-dom';
import {Button, ButtonRightContainer, LinkButton, LinkIcon} from '../components/Button';
import styled from '@emotion/styled';
import Page from '../components/Page';
import {studyProgress} from '../studyStatus';
import {withTranslation} from "react-i18next";
import ProgressBar from '../components/ProgressBar';
import Modal from '../components/Modal';

import errorFn from '../error';
import Alert from '../components/Alert';
import Error from "../components/Error";
import {ProjectLink} from "./Study";
import {CardListContainer} from "../components/CardList";
import {Divider} from '../components/Divider';

const DownloadButton = styled(Button)`

    ${props => !props.disabled && `
        background: linear-gradient(90deg, ${grey70}  0%, ${grey80} 50%)
    `};
    ${props => props.disabled && `
        cursor: wait;
    `};

    border-color: ${grey70}};
    min-width: 200px;
    width: 100%;
    height: 64px;
    padding: 0 32px;
    justify-content: space-between;
    margin-top: 28px;
    border: none;

    &: hover {
        border: none;
    }

    &:focus {
        outline: 0;
    }

    > svg {
        margin-right: 0;
        margin-top: -8px;
    }
    
    @media (max-width: 768px) {
      width:100%;
    }
`;

const CardSubHeadline = styled.div`
    font-size: 16px;
    color: ${grey80};
    font-weight: bold;
`;

const ProgressBarContainer = styled.div`
    color: white;
    display:flex;
    align-items: center;
    margin-top: 28px;
`;

const Headline = styled.div`
  color: ${darkblue};
  font-size: 32px;
  margin-bottom: 28px;
  text-align: left;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-top: 25px;
`;

const MessageContainer = styled.div`
  color: ${darkblue};
  margin-bottom: 28px;
  text-align: left;
`;

const ReimburseButtonContainer = styled.div`
    margin-left: 12px;
`;

const ReimburseInfo = styled.div`
    margin-left: 12px;
    color: ${darkblue};
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const TipContainer = styled.div`
    width: 100%;
    margin-top: 56px;
`;

const StatisticsBoxContainer = styled.div`
    background: ${white};
    padding: 20px;
    margin-top: 28px;
    @media (max-width: 768px) {
      width:100%;
    }
`;

const StatisticsBarRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StatisticsBarText = styled.span`
    font-size: ${props => props.size ? props.size + 'px' : "16px"};
    font-weight:  ${props => props.fontStyle ? props.fontStyle : "normal"};
    width: 40%;
    margin-bottom: 4px;
`;

const StudyResultContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
`

const DownloadConatiner = styled.div`
    width: 45%;
    @media (max-width: 768px) {
      width:100%;
    }
`

const StatisticsConatiner = styled.div`
    width: 45%;
    @media (max-width: 768px) {
      width:100%;
    }
`

const StatisticsAIContainer = styled.div`
    margin-top: 30px;
`

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function StudyResults(props) {
  const [formData, setFormData] = useState({name: null});

  const [project, setProject] = useState(null);
  const [study, setStudy] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [barError, setBarError] = useState(false);

  const [info, setInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const [percent, setPercent] = useState(-1);

  const [reimburseModalOpen, setReimburseModalOpen] = useState(false);
  const [reimburseButton, setReimburseButton] = useState(false);
  const [isReimbursed, setIsReimbursed] = useState(false);
  const [contactSupport, setContactSupport] = useState(false);
  const [errorBar, setErrorBar] = useState(false);
  const [errorBarMessage, setErrorBarMessage] = useState(null);

  const [themaCompleted, setThemaCompleted] = useState(false);
  // const [inferenceCompleted, setInferenceCompleted] = useState(false);
  // const [inferenceResultData, setInferenceResultData] = useState(null);

  const {t, match} = props;
  const studyId = useRef(match.params.studyId);
  const projectId = useRef(match.params.projectId);

  const myPercent = useRef(0);
  const secondsElapsed = useRef(0);
  const progressError = useRef(false);

  const secondsTotalRef = useRef(0);

  const large = useRef(false);
  const loadedStudy = useRef(null);
  const loadedProject = useRef(null);


  useEffect(() => {

    async function loadData() {
      let studyResponse = await get(`/study/${studyId.current}/detail`);
      let projectResponse = await get(`/project/${projectId.current}/detail`);

      loadedProject.current = await projectResponse.json();
      loadedStudy.current = await studyResponse.json();

      if (loadedStudy.current.statistics) {
        loadedStudy.current.statistics = JSON.parse(loadedStudy.current.statistics)
      }

      await setProject(loadedProject.current);
      await setStudy(loadedStudy.current);

      if (studyResponse.status === 200 && projectResponse.status === 200) {
        await setIsReimbursed(loadedStudy.current.status === 'reimbursed');
        await setFormData({name: loadedStudy.current.name});

        if (loadedStudy.current.checkResult) {
          let timeNow = new Date().getTime() / 1000;
          secondsElapsed.current = (timeNow - loadedStudy.current.startTimestamp);
          secondsTotalRef.current = loadedStudy.current.checkResult.computationTime * 60;

          console.log("secondsElapsed.current " + secondsElapsed.current);
          console.log("secondsTotalRef.current " + secondsTotalRef.current);

          if (loadedStudy.current.method !== "ThematicAnalysis") {

            myPercent.current = Math.floor((secondsElapsed.current / secondsTotalRef.current) * 100);
            large.current = loadedStudy.current.checkResult.amountCharacters >= 100000; //!!!

            if (large.current && loadedStudy.current.method !== "SanityCheck") {
              if (myPercent.current > 49 && loadedStudy.current.status === "running") {
                myPercent.current = 49;
                await setPercent(49);
              }
              if (myPercent.current > 99 && loadedStudy.current.status === 'halfed') {
                myPercent.current = 99;
                await setPercent(99);
              }

            } else {
              if (myPercent.current > 99) {
                if (loadedStudy.current.status === 'halfed') {
                  myPercent.current = 99;
                  await setPercent(99);
                } else {
                  myPercent.current = 49;
                  await setPercent(49);
                }
              } else {
                myPercent.current = Math.floor((secondsElapsed.current / secondsTotalRef.current) * 100)
                await setPercent(myPercent.current);
              }
            }
          } else {
            myPercent.current = 0;
            await setPercent(0);
          }

          checkStatusAndUpdate();
          progressing();

        } else {
          myPercent.current = -1;
          await setPercent(-1);
          progressError.current = true;
          await setBarError(true);
        }
      } else {
        await setLoadingError(true);
        await setLoaded(true);
      }
      await setLoaded(true);
      return;
    }

    async function checkStatusAndUpdate() {
      if (secondsTotalRef.current > 0) {
        let studyResponse = await get(`/study/${studyId.current}`);

        if (studyResponse.status === 200 && secondsElapsed.current > 0 && secondsTotalRef.current > 0) {
          let myStudy = await studyResponse.json();

          console.log(myStudy.data);

          if (myStudy.statistics) {
            myStudy.statistics = JSON.parse(myStudy.statistics)
          }

          if (myStudy.errorMessage) {
            await setErrorBarMessage(myStudy.errorMessage);
            await setErrorBar(true);
            await setContactSupport(true);
          }

          switch (myStudy.status) {
            case "completed" :
              await setPercent(100);
              myPercent.current = 100;
              setStudy(myStudy);
              break;
            case "thema_completed" :
              await setPercent(100);
              myPercent.current = 100;
              setStudy(myStudy);
              setThemaCompleted(true);
              break;
            case "inference_completed" :
              await setPercent(100);
              myPercent.current = 100;
              setStudy(myStudy);
              setThemaCompleted(true);
              break;
              // await setPercent(100);
              // myPercent.current = 100;
              // console.log(myStudy);
              // setStudy(myStudy);
              // setInferenceCompleted(true);
              // setInferenceResultData(myStudy);
              // break;
            case "failed" :
              await setLoaded(true);
              await setPercent(0);
              await setReimburseButton(true);
              progressError.current = true;
              await setBarError(true);
              myPercent.current = 0;
              break;
            case "reimbursed" :
              await setLoaded(true);
              await setPercent(0);
              myPercent.current = 0;
              await setReimburseButton(false);
              progressError.current = true;
              await setBarError(true);
              break;
            case "halfed" :
              if (myPercent.current < 50) {
                console.log(9);
                await setPercent(50);
                myPercent.current = 50;
                await setReimburseButton(false);
                progressError.current = false;
                await setBarError(false);
                secondsTotalRef.current = secondsElapsed.current * 2;

                await updateTotal(secondsTotalRef.current);//updating total
                await checkStatusAndUpdate(large.current);

              }
              if (myPercent.current >= 99) {
                await setPercent(99);
                await sleep(5000);
                secondsElapsed.current += 5;
                console.log(15);
                await checkStatusAndUpdate(large.current);
              } else {
                await sleep(60000);
                await checkStatusAndUpdate(large.current);
              }
              break;
            default:
              if (loadedStudy.current.method === "ThematicAnalysis") {
                secondsElapsed.current += 5;
                myPercent.current = myStudy.progress;
                await setPercent(myStudy.progress);

                await sleep(5000);
                await checkStatusAndUpdate();
              } else {
                if (myPercent.current === 49) {
                  await sleep(5000);
                  console.log(10);
                  secondsElapsed.current += 5;
                  await checkStatusAndUpdate(large.current);
                }
                if (myPercent.current === 99) {
                  await sleep(5000);
                  secondsElapsed.current += 5;

                  if (await checkIfStudyTakesTooMuchTime(secondsTotalRef.current, secondsElapsed.current)) {
                    console.log("Study takes too much time.");
                    if (await setStudyStatusFailed()) {
                      console.log("Study status set to FAILED!");
                      await setLoaded(true);
                      await setPercent(0);
                      await setReimburseButton(true);
                      progressError.current = true;
                      await setBarError(true);
                      myPercent.current = 0;
                      break;
                    }
                  }
                  await checkStatusAndUpdate(large.current);
                } else {
                  if (large.current) {
                    await sleep(60000);
                  } else {
                    await sleep(30000);
                  }
                  await checkStatusAndUpdate(large.current);
                }
              }
          }
        } else {
          await setPercent(0);
          myPercent.current = 0;
          progressError.current = true;
          await setBarError(true);
          await setReimburseButton(true);
          return;
        }
      } else {
        return;
      }
    }

    async function progressing() {
      let study = loadedStudy.current;

      if (progressError.current) {
        return;
      } else {
        //check after refresh the page
        if (loadedStudy.current.method === "ThematicAnalysis") {
          await setPercent(loadedStudy.current.progress);
          myPercent.current = loadedStudy.current.progress;

          if (loadedStudy.current.progress === 100 && loadedStudy.current.status !== "inference_completed") {
            setThemaCompleted(true);
          }

          if (loadedStudy.current.errorMessage) {
            await setErrorMessage(errorFn(101, t));
            progressError.current = true;
            await setContactSupport(true);

            setErrorBar(true);
            setErrorBarMessage(loadedStudy.current.errorMessage);
          }
        } else if (large.current && loadedStudy.current.method !== "SanityCheck" && loadedStudy.current.method !== "ThematicAnalysis") {
          //more than half time passed but the study is not halfed
          if (secondsElapsed.current > 0 && secondsTotalRef.current > 0 && secondsElapsed.current >= secondsTotalRef.current / 2 && myPercent.current < 50 && !progressError.current) {
            await setPercent(49);
            myPercent.current = 49;
          }
          //time elapsed but the study is not completed (but is halfed for large.current ones)
          if (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && secondsElapsed.current >= secondsTotalRef.current && myPercent.current < 100 && !progressError.current && study.status === "halfed") {
            await setPercent(99);
            myPercent.current = 99;
          }
        } else {
          //time elapsed but the study is not completed (but is halfed for large.current ones)
          if (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && secondsElapsed.current >= secondsTotalRef.current && myPercent.current < 100 && !progressError.current) {
            await setPercent(99);
            myPercent.current = 99;
          }
        }


        //getting into cycle
        while (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && secondsElapsed.current <= secondsTotalRef.current && myPercent.current < 100 && !progressError.current) {
          if (large.current && loadedStudy.current.method !== "SanityCheck" && loadedStudy.current.method !== "ThematicAnalysis") {
            //waiting for halfed if the study is more than 1mln characters
            //progressing till half time
            while (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && myPercent.current < 50 && !progressError.current) {
              let currentPercent = Math.floor((secondsElapsed.current / secondsTotalRef.current) * 100);
              if (currentPercent < 50) {
                console.log(4);
                await setPercent(currentPercent);
                myPercent.current = currentPercent;
              } else {
                console.log(5);
                await setPercent(49);
                myPercent.current = 49;
              }
              await sleep(5000);
              if (myPercent.current !== 49 && myPercent.current !== 99) {
                secondsElapsed.current += 5;
                console.log(7);
              }

            }
          }
          while (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && secondsElapsed.current <= secondsTotalRef.current && myPercent.current < 100 && !progressError.current) {
            if (loadedStudy.current.method !== "ThematicAnalysis") {
              myPercent.current = Math.floor((secondsElapsed.current / secondsTotalRef.current) * 100)
              await setPercent(myPercent.current);
            }

            await sleep(5000);

            if (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && myPercent.current !== 49 && myPercent.current !== 99) {
              secondsElapsed.current += 5;
              console.log(6);
            }

          }
          //cycle has ended, but the study is not yet completed
          if (secondsTotalRef.current > 0 && secondsElapsed.current > 0 && myPercent.current < 100 && !progressError.current && loadedStudy.current.method !== "ThematicAnalysis") {
            console.log(7);
            if (loadedStudy.current.method !== "ThematicAnalysis") {
              await setPercent(99);
              myPercent.current = 99;
            }
          }
        }
      }
    }

    const checkIfStudyTakesTooMuchTime = async (estimatedTime, elapsedTime, howManyTimesIsTooMuch = 60) => {
      console.log("estimated time: " + estimatedTime);
      console.log("elapsed time: " + elapsedTime);
      return false;
      // if (((elapsedTime / estimatedTime) > howManyTimesIsTooMuch) && elapsedTime > 300) {
      //   return true;
      // }
      // return false;
    }

    const setStudyStatusFailed = async () => {
      let studyResponse = await get(`/study/setFailed/${studyId.current}`);
      if (studyResponse.status === 200 && studyResponse.requestId === studyId.current) {
        return true;
      }
      return false;
    }

    async function updateTotal(totalSec) {
      const newTotal = totalSec / 60;
      let onError = async (code) => {
        await setLoaded(true);
        await setError(true);
        await setErrorMessage(errorFn(code, props.t));
        await setPercent(0);
        myPercent.current = 0;
        await setReimburseButton(true);
        progressError.current = true;
        await setBarError(true);
        return;
      }
      let onSuccess = () => {
        return;
      }
      await post(
        `/checkResult/updateTotal`,
        {
          studyId: studyId.current,
          total: newTotal
        },
        onError,
        onSuccess
      )
    }

    loadData();

    return () => {
      secondsTotalRef.current = 0;
    }
  }, [props.t, t])

  useEffect(() => {
    if (secondsTotalRef.current > 0 && secondsElapsed.current > 0) {
      myPercent.current = percent
    }
  }, [percent, barError])

  async function reimburseRequest() {
    const response = await get(
      '/study/reimburse/' + studyId.current
    )
    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.code) {
        await setError(true);
        await setErrorMessage(errorFn(responseData.code, t));
      } else {
        await setError(false);
        await setReimburseButton(false);
        await setIsReimbursed(true);
      }
    } else {
      await setError(true);
      await setErrorMessage(errorFn(0, t));
    }
    await setReimburseModalOpen(false);
    return;
  }

  async function onQuitNameInput() {
    let onError = async (code) => {
      await setLoaded(true);
      await setError(true);
      await setErrorMessage(errorFn(code, t));
    }
    const response = await post(
      '/study/edit',
      {
        ...formData,
        studyId: studyId.current,
      },
      onError
    );
    if (response) {
      await setInfo(true);
      await setInfoMessage(t("studyresult17", 'Der Name wurde erfolgreich geändert.'))
    }
    return false;

  };

  async function onChange(key, value) {
    await setFormData({
      [key]: value
    })
    return false;
  };

  async function downloadFile() {
    const response = await get(`/study/${studyId.current}/file/download`);

    if (response.status === 200) {
      const dispositionHeader = response.headers.get('Content-Disposition');
      const filename = dispositionHeader.split('filename=')[1];
      const file = await response.blob();

      // Download file
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();

      return;
    }

    //If the study status is completed, but the file cant be downloaded
    if (response.status === 500) {
      const responseFailed = await get(`/study/setFailed/${studyId.current}`);
      if (responseFailed.status === 200) {
        await setError(true);
        await setErrorMessage(error(101, t));
        progressError.current = true;
        await setBarError(true);
        myPercent.current = 0;
        await setPercent(0);
        await setReimburseButton(true);
      } else {
        await setError(true);
        await setErrorMessage(errorFn(101, t));
        progressError.current = true;
        await setBarError(true);
        myPercent.current = 0;
        await setPercent(0);
        await setContactSupport(true);
      }
    }
  }

  async function contactSupportHandler() {
    let data = {'type': "study", 'id': studyId.current}
    data.locale = props.i18n.language;

    let onError = async (code) => {
      await setError(true);
      await setErrorMessage(t("studyResult20", 'Bitte kontaktieren Sie uns direkt per E-Mail oder Telefon.'));
      await setContactSupport(false);
    }
    let onSuccess = async () => {
      await setInfo(true);
      await setInfoMessage(t("studyResult19", 'Bitte warten Sie, während unser Spezialist Sie per E-Mail kontaktiert, bei der Sie registriert sind.'));
      await setContactSupport(false);
    }
    await post(
      `/contactSupport`,
      data,
      onError,
      onSuccess
    )
  }

  const capitalizeStatisticsKey = (key) => {
    let words = key.replaceAll("_", " ").split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  };

  const convertTimeIntoMinutes = (n) => {
    const timeInMinutes = n / 60;
    return Math.floor(timeInMinutes);
  };

  const calculateTimeSave = () => {
    return convertTimeIntoMinutes(study.statistics.human_read_time - secondsTotalRef.current);
  }

  const timeConvert = (n) => {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if (rhours < 1) {
      return rminutes + "min"
    }
    return (
      rhours + "h " + rminutes + "min"
    );
  };

  function renderCommonStudy() {
    return (
      <CardBodyInner>
        <SubHeadline>{t("studyresult2", "Ergebnisse")}</SubHeadline>
        <CardBreakLine/>

        <StudyResultContainer>
          <DownloadConatiner>
            <CardSubHeadline>
              {percent < 100 && !reimburseButton && !barError &&
              <>{t("studyresult88", "KI in Arbeit...")}</>
              }
              {(percent >= 100 || reimburseButton) && !barError &&
              <>{t("studyresul89", "Erledigt")}</>
              }
              {barError &&
              <>{t("studyresul90", "Fehler")}</>
              }
              <ProgressBarContainer>
                <ProgressBar
                  error={barError}
                  percent={percent}
                  width={100}
                  height={40}
                  backgroundColor={limegreen}
                />
                {/*{reimburseButton && (*/}
                {/*  <ReimburseButtonContainer>*/}
                {/*    <Button*/}
                {/*      onClick={() => setReimburseModalOpen(true)}*/}
                {/*      icon="credits"*/}
                {/*      tiny={true}*/}
                {/*      delete={true}*/}
                {/*      outline={true}*/}
                {/*      type="button"*/}
                {/*    >*/}
                {/*      {t("studyresult10", "Credits zurückerstatten")}*/}
                {/*    </Button>*/}
                {/*  </ReimburseButtonContainer>*/}
                {/*)}*/}
                {isReimbursed && (
                  <ReimburseInfo>
                    {t("studyResult15", "Die Studienkosten wurden erstattet ")}
                  </ReimburseInfo>
                )}
                {contactSupport && (
                  <ReimburseButtonContainer>
                    <Button
                      onClick={() => contactSupportHandler()}
                      tiny={true}
                      delete={true}
                      outline={true}
                      type="button"
                    >
                      {t("studyResult18", "An Support wenden")}
                    </Button>
                  </ReimburseButtonContainer>
                )}
              </ProgressBarContainer>
            </CardSubHeadline>
            <DownloadButton
              data-cy={"download-results"}
              right={true}
              icon="download"
              onClick={() => downloadFile()}
              disabled={percent < 100}
              type="button"
            >
              {percent < 100 && !reimburseButton && !barError &&
              <>{t("studyresult91", "Vielen Dank, dass sie den deepsight DataDesk nutzen. Ihre Daten werden gerade für den Download vorbereitet.")}</>
              }
              {(percent >= 100 || reimburseButton) && !barError &&
              <>{t("studyresult6", "Download Ergebnisse")}</>
              }
              {barError &&
              <>{t("studyresul90", "Fehler")}</>
              }
            </DownloadButton>

            {!barError && percent === 100 && renderSuggestion()}
          </DownloadConatiner>

          {study.method === "Anonymization" && !barError && percent === 100 && (
            <StatisticsConatiner>
              <CardSubHeadline>
                {t("Statistics1", "Statistiken")}
              </CardSubHeadline>
              <StatisticsBoxContainer>
                <StatisticsBarRow>
                  <StatisticsBarText size={18} fontStyle="bold">
                    {t("Statistics2", "Gesparte Gesamtzeit")}
                  </StatisticsBarText>
                  <StatisticsBarText size={18} fontStyle="bold">
                    {study.statistics.human_read_time
                      ? timeConvert(calculateTimeSave())
                      : "N/A"}
                  </StatisticsBarText>
                </StatisticsBarRow>
                <Divider/>
                <StatisticsBarRow>
                  <StatisticsBarText size={16}>
                    {t("Statistics3", "KI-Analysezeit")}
                  </StatisticsBarText>
                  <StatisticsBarText size={16}>
                    {study.statistics
                      ? timeConvert(
                        convertTimeIntoMinutes(secondsTotalRef.current)
                      )
                      : "N/A"}
                  </StatisticsBarText>
                </StatisticsBarRow>
                <Divider height={1}/>
                <StatisticsBarRow>
                  <StatisticsBarText size={16}>
                    {t("Statistics4", "Geschätzte manuelle Auswertung")}
                  </StatisticsBarText>
                  <StatisticsBarText size={16}>
                    {study.statistics.human_read_time
                      ? timeConvert(
                        convertTimeIntoMinutes(
                          study.statistics.human_read_time
                        )
                      )
                      : "N/A"}
                  </StatisticsBarText>
                </StatisticsBarRow>
                <Divider height={1}/>

                {study.statistics !== "" && <StatisticsAIContainer>
                  <StatisticsBarRow>
                    <StatisticsBarText size={18} fontStyle="bold">
                      {t("Statistics5", "KI hat weitergearbeitet")}
                    </StatisticsBarText>
                  </StatisticsBarRow>
                  <Divider/>
                  {Object.entries(study.statistics).map(([key, value]) => {
                    return <>
                      <StatisticsBarRow>
                        <StatisticsBarText size={16}>{capitalizeStatisticsKey(key)}</StatisticsBarText>
                        <StatisticsBarText size={16}>
                          {value >= 0 ? value + " " : "N/A"}
                        </StatisticsBarText>
                      </StatisticsBarRow>
                      <Divider height={1}/>
                    </>;
                  })}
                </StatisticsAIContainer>}
              </StatisticsBoxContainer>
            </StatisticsConatiner>
          )}
        </StudyResultContainer>

        {errorBar && errorBarMessage && (
          <Error children={"Error message: " + errorBarMessage}/>
        )}
        <br/>

        <ButtonRightContainer>
          <LinkButton
            mainbutton
            data-cy="close-study-result"
            icon="main_arrow"
            type="button"
            to={"/project"}
          >
            {t("studyresult5", "Auswertung Schließen")}
          </LinkButton>
        </ButtonRightContainer>

        <br/>
      </CardBodyInner>
    );
  }

  const renderThemaResult = () => {
    return (
      <ThematicAnalysisResult
        t={t}
        studyId={studyId.current}
        projectId={projectId.current}
        isStudyCustomModel={loadedStudy.current.isCustomModel}
      />
    );
  }

  function renderSuggestion() {
    return (
      <CardListContainer>
        <TipContainer>
          {t(
            "differentRequirements",
            "Sie haben andere Anforderungen für Ihre Daten oder benötigen Anpassungen? Kontaktieren Sie uns über: support@deepsight.de"
          )}
        </TipContainer>
      </CardListContainer>
    )
  }

  if (loaded === false) {
    return (
      <Curtain type="loading"/>
    );
  }
  if (loadingError === true) {
    return (
      <Curtain type="error"/>
    );
  }

  return (
    <React.Fragment>
      <Page>
        <PageContent dark={true}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <CardHeaderBackground>
              <CardHeaderBackgroundHeadline>
                <HeadlineIcon>
                  <Icon name="folder_big_ai"/>
                </HeadlineIcon>
                <HeadlineText>
                  <ProjectLink to={`/project/${project.id}/detail`}>
                    {project.name}
                  </ProjectLink>{" "}
                  / {formData.name}
                </HeadlineText>
              </CardHeaderBackgroundHeadline>
            </CardHeaderBackground>
            <CardHeaderWizard>
              <StudyEdit
                onChangeName={(value) => onChange("name", value)}
                name={formData.name}
                onSubmit={() => onQuitNameInput()}
                status={study.status}
                progress={studyProgress(study)}
              />
              <Wizard>
                <Step icon="data_up" status={STATUS_FINISHED} active={false}>
                  {t("studyResult7", "Daten")}
                </Step>
                <Step
                  icon="analysis_wizard"
                  size={20}
                  status={STATUS_FINISHED}
                  active={false}
                >
                  {t("studyResult8", "Methoden")}
                </Step>
                <Step icon="data_down" status={STATUS_FINISHED} active={true}>
                  {t("studyResult9", "Auswertung")}
                </Step>
              </Wizard>

              <Info>
                <LinkIcon
                  data-cy="close-study-result-x"
                  icon="close"
                  to={`/project/${project.id}/detail`}
                />
              </Info>
            </CardHeaderWizard>
            <CardBody withGradient={true} noPadding={true}>
              {!themaCompleted &&
              renderCommonStudy()
              }
              {themaCompleted &&
              renderThemaResult()
              }
            </CardBody>
          </form>
        </PageContent>
      </Page>
      {error && (
        <Alert
          type="error"
          message={errorMessage ? errorMessage : errorFn(0, t)}
          onClose={() => setError(false)}
        />
      )}
      {info && infoMessage && (
        <Alert
          type="info"
          message={infoMessage}
          onClose={() => setInfo(false)}
        />
      )}

      {reimburseModalOpen && (
        <Modal onClose={() => setReimburseModalOpen(false)}>
          <Headline>{t("studyresult11", "Credits zurückerstatten")}</Headline>
          <MessageContainer>
            {t(
              "studyresult12",
              "Hiermit wird die Studie gelöscht und das Credits zurückerstattet. Wollen Sie das wirklich?"
            )}
          </MessageContainer>
          <Footer>
            <Button
              data-cy="abort-modal"
              type="button"
              onClick={() => setReimburseModalOpen(false)}
            >
              {t("studyresult13", "Abbrechen")}
            </Button>
            <Button
              delete={true}
              type="button"
              data-cy="submit-modal"
              onClick={() => reimburseRequest()}
            >
              {t("studyresult14", "Zurückerstatten")}
            </Button>
          </Footer>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation("translations")(StudyResults));
