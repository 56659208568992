import React from 'react';
import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import Page from '../components/Page';
import { grey5 } from '../colors';
import SubHeadline from '../components/SubHeadline';
import SecondSubHeadline from '../components/SecondSubHeadline';
import { Container, Text } from './AGB';
import styled from '@emotion/styled';
import StyledLink from '../components/StyledLink';
import { withTranslation } from "react-i18next";

export const Liste = styled.ul`
    > li {
      margin-bottom: 10px;
    }
`;

class DataProtection extends React.PureComponent {

  render() {
      const { t } = this.props;
    return (
      <Page background={grey5}>
        <PageContent>
          <Headline>{t('data1',"Datenschutzbestimmungen")}</Headline>
          <Container>
            <Text>
              {t('data2',"Wir erheben personenbezogene Daten auf unserem „deepsight DataDesk“. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.")}
            </Text>

            <SubHeadline>{t('data4',"1. Information über die Erhebung personenbezogener Daten")}</SubHeadline>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
              {t('data5',"Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.")}
            </Text>

            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
              {t('data6',"Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) für die Datenverarbeitung ist")}
              <br />
              <br />
              <b>
                deepsight GmbH<br />
                Lengericher Landstr. 34<br />
                49078 Osnabrück<br />
                Telefon: +49 (0)175 5533500<br />
                E-Mail: hi@deepsight.de<br />
              </b>

              <br />
              <b>
                Externer Datenschutzbeauftrager:<br />
                Boris Nicolaj Willm<br />
                Resilien[i]T GmbH<br />
                Monschauer Straße 12<br />
                40549 Düsseldorf<br />
                <br />
                Telefon: +49 (0) 211 695289 92<br />
                E-Mail: dsb.deepsight@resilienit.de<br />

              </b>

            </Text>
            <SecondSubHeadline>(3)</SecondSubHeadline>
            <Text>
              {t('data7',"Bei Ihrer Kontaktaufnahme mit uns insbesondere per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Vorname, Name und Ihre Telefonnummer freiwillige Angabe) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.")}
            </Text>
            <SecondSubHeadline>(4)</SecondSubHeadline>
            <Text>
               {t('data8',"Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.")}
            </Text>
            <SecondSubHeadline>(5)</SecondSubHeadline>
            <Text>
               {t('data9',"Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.")}
            </Text>
            <SecondSubHeadline>(6)</SecondSubHeadline>
            <Text>
               {t('data10',"Ihre personenbezogenen Daten werden gelöscht, wenn Sie eine erteilte Einwilligung zur Verarbeitung widerrufen haben oder die personenbezogenen Daten zur Erfüllung des mit der Verarbeitung verfolgten Zwecks nicht mehr erforderlich ist. Cookies werden nach 12 Monaten gelöscht. Daten, die im Zusammenhang mit einer Kontaktaufnahme angefallen sind, werden nach 12 Monaten gelöscht.")}
            </Text>
            <SecondSubHeadline>(7)</SecondSubHeadline>
            <Text>
               {t('data11',"Ihre personenbezogenen Daten werden nicht in ein Drittland außerhalb der EU oder dem EWR übermittelt. Eine sonstige Übermittlung erfolgt nur, sofern wir gesetzlich oder behördlich dazu verpflichtet sind bzw. die Weitergabe im Rahmen Ihrer Nutzung der Webseite erforderlich ist.")}
            </Text>
            <SecondSubHeadline>(8)</SecondSubHeadline>
            <Text>
              <b> {t('data12',"Datenübermittlung in die USA:")} </b>
               {t('data13',"Wir weisen darauf hin, dass wir auf unserer Webseite Tools von Anbietern aus den USA nutzen und eingebunden haben. Ihre personenbezogenen Daten können an die US-Unternehmen übermittelt und auf US-Servern gespeichert oder sonst verarbeitet werden. Das Datenschutzniveau der USA entspricht nicht dem Standard der Europäischen Union. Es besteht insbesondere das Risiko, dass US-Behörden etwa zu Prüf- und Überwachungszwecken auf die übermittelten personenbezogenen Daten ggf. zugreifen und diese verarbeiten können. Für Betroffene besteht das Risiko, dass diesbezüglich grundsätzlich keine (gerichtlichen) Rechtsschutzmöglichkeiten zur Verfügung stehen. Rechtsgrundlage für die Datenübermittlung in die USA ist Ihre Einwilligung gem. Art. 49 Abs. 1 Lit. a DS-GVO.")}
            </Text>


            <SubHeadline> {t('data14',"2. Ihre Rechte")}</SubHeadline>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
               {t('data15',"Sie haben das Recht hinsichtlich der Sie betreffenden personenbezogenen Daten")}
              <br />
              <Liste>
                <li>
                   {t('data16',"gemäß Art. 15 DSGVO")} <b> {t('data17',"Auskunft")}</b>  {t('data18',"über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;")}
                </li>
                <li>
                   {t('data19',"gemäß Art. 16 DSGVO unverzüglich die")} <b> {t('data20',"Berichtigung")}</b>  {t('data21',"unrichtiger oder")} <b> {t('data22',"Vervollständigung")}</b>  {t('data23',"Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;")}
                </li>
                <li>
                   {t('data24',"gemäß Art. 17 DSGVO die")} <b> {t('data25',"Löschung")}</b>  {t('data26',"Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;")}
                </li>
                <li>
                   {t('data27',"gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die")} <b> {t('data28',"Richtigkeit")}</b>  {t('data29',"der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur  Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;")}
                </li>
                <li>
                   {t('data30',"gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen")} (<b>{t("data300", "Recht auf Datenübertragbarkeit")}</b>);
                </li>
                <li>
                   {t('data31',"gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte")} <b> {t('data32',"Einwilligung")}</b>  {t('data33',"jederzeit gegenüber uns zu")} <b> {t('data34',"widerrufen")}</b>.  {t('data35',"Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und")} </li>
                <li>
                   {t('data36',"gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren")} (<b> {t('data37',"Beschwerderecht")}</b>).  {t('data38',"In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseren Firmensitz wenden. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:&nbsp;")}
                  <StyledLink href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                  </StyledLink>.
                </li>
              </Liste>
            </Text>

            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
              <b> {t('data39',"Widerspruchsrecht")}</b>  {t('data40',"gegen Verarbeitung nach Art. 6 Abs. 1 Lit. e oder f DS-GVO:")}
              <br />
               {t('data41',"Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten aufgrund Art. 6 Abs. 1 Lit e oder f DS-GVO einzulegen, die auf Grund der nachstehenden Vorschriften erfolgt:")}
              <br />
               {t('data42',"Die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt.")}
              <br />
               {t('data43',"Falls Sie Widerspruch einlegen, verarbeiten wir Ihre personenbezogenen Daten in diesem Fall nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.")}
                <br />
               {t('data44',"Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine Mitteilung an uns z.B. per E-Mail an")} <b>support@deepsight.de</b>.
            </Text>

            <SubHeadline> {t('data45',"3. Erhebung personenbezogener Daten bei Besuch und Nutzung unserer Webseite")}</SubHeadline>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
               {t('data46',"Bei der bloß informatorischen Nutzung der Webseite, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Webseite betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität, Sicherheit und komfortable Nutzung zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f")}
              DS-GVO):
              <br />
              <Liste>
                <li>
                   {t('data47',"IP-Adresse")}
                </li>
                <li>
                   {t('data48',"Datum und Uhrzeit des Zugriffs")}
                </li>
                <li>
                   {t('data49',"Zeitzonendifferenz zur Greenwich Mean Time (GMT)")}
                </li>
                <li>
                   {t('data50',"Inhalt der Anforderung (konkrete Seite)")}
                </li>
                <li>
                   {t('data51',"Zugriffsstatus/HTTP-Statuscode")}
                </li>
                <li>
                   {t('data52',"jeweils übertragene Datenmenge")}
                </li>
                <li>
                   {t('data53',"Webseite, von der aus dem Zugriff erfolgt (Referrer-URL)")}
                </li>
                <li>
                   {t('data54',"Browser, Browsertyp und Browserversion")}
                </li>
                <li>
                   {t('data55',"Sprache und Version der Browsersoftware")}
                </li>
                <li>
                   {t('data56',"verwendetes Betriebssystem.")}
                </li>
              </Liste>
            </Text>
            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
               {t('data57',"Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Webseite Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.")}
            </Text>
            <SecondSubHeadline>(3)</SecondSubHeadline>
            <Text> {t('data58',"Einsatz von Cookies:")}</Text>
            <SecondSubHeadline>a)</SecondSubHeadline>
            <Text>
               {t('data59',"Diese Webseite nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:")}
              <Liste>
                <li>
                   {t('data60',"Transiente Cookies")}
                </li>
                <li>
                   {t('data61',"Persistente Cookies.")}
                </li>
              </Liste>
               {t('data62',"Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Webseite zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.")}
               <br />
              {t('data63',"Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.")}
            </Text>
            <SecondSubHeadline>b)</SecondSubHeadline>
            <Text>
              {t('data64',"Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.")}
            </Text>
            <SecondSubHeadline>c)</SecondSubHeadline>
            <Text>
              {t('data65',"Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Webseite eingeschränkt sein. Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO gespeichert. Wir haben ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung unserer Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.")}
            </Text>
            <SecondSubHeadline>{t('data66',"3.1. Web Analytics")}</SecondSubHeadline>
            <Text>
              {t('data67',"Einsatz von Hotjar")}
            </Text>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
              {t('data68',"Diese Website nutzt Hotjar. Anbieter ist die Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta, Europe.\n" +
                "Nähere Informationen über Hotjar und zu den erfassten Daten entnehmen Sie der Datenschutzerklärung von Hotjar unter dem folgenden Link: https://www.hotjar.com/privacy ")}
            </Text>

            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
              {t('data69',"Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf unserer Website. Mit Hotjar können wir u. a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Hotjar kann dabei auch feststellen, wie lange Sie mit dem Mauszeiger auf einer bestimmten Stelle verblieben sind. Aus diesen Informationen erstellt Hotjar sogenannte Heatmaps, mit denen sich feststellen lässt, welche Websitebereiche vom Websitebesucher bevorzugt angeschaut werden. ")}
            </Text>
            <SecondSubHeadline>(3)</SecondSubHeadline>
            <Text>
              {t('data70',"Des Weiteren können wir feststellen, wie lange Sie auf einer Seite verblieben sind und wann Sie sie verlassen haben. Wir können auch feststellen, an welcher Stelle Sie Ihre Eingaben in ein Kontaktformular abgebrochen haben (sog. Conversion-Funnels). ")}
            </Text>
            <SecondSubHeadline>(4)</SecondSubHeadline>
            <Text>
              {t('data71',"Wir nutzen Hotjar, um die Nutzung unserer Webseite analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Rechtsgrundlage für die Nutzung von Hotjar und die Speicherung von Hotjar-Cookies ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO oder Ihre Einwilligung, sofern Sie diese erteilt haben. Die Einwilligung ist jederzeit widerrufbar.")} </Text>

            <SecondSubHeadline>(5)</SecondSubHeadline>
            <Text>
              {t('data72',"Wenn Sie die Datenerfassung durch Hotjar deaktivieren möchten, klicken Sie auf folgenden Link und folgen Sie den dortigen Anweisungen: https://www.hotjar.com/opt-out Bitte beachten Sie, dass die Deaktivierung von Hotjar für jeden Browser bzw. für jedes Endgerät separat erfolgen muss.")}
            </Text>
            <SecondSubHeadline>(6)</SecondSubHeadline>
            <Text>
              {t('data73',"Wir haben einen Vertrag über Auftragsverarbeitung mit Hotjar geschlossen, um die strengen europäischen Datenschutzvorschriften umzusetzen.")}
            </Text>

            <SecondSubHeadline>3.2. {t('data76',"Social Media Plug-Ins / Buttons")}</SecondSubHeadline>
            <Text>
              {t('data77',"Auf unserer Webseite setzen wir derzeit folgende Social-Media-Plug-ins ein: LinkedIn.")}
            </Text>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
              {t('data78',"Die Plug-ins können Sie in der Regel anhand der jeweiligen Social-Media-Logos erkennen. Wir nutzen dabei die sog. Zwei-Klick-Lösung. Das heißt, wenn Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins erkennen Sie über die Markierung auf dem Kasten über seinen Anfangsbuchstaben oder das Logo. Wir eröffnen Ihnen die Möglichkeit, ggf. über den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Webseite unseres Online-Angebots aufgerufen haben. Zudem werden die unter Ziffer 3 genannten Daten übermittelt.")}
            </Text>

            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
              {t('data79',"Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.")}
            </Text>
            <SecondSubHeadline>(3)</SecondSubHeadline>
            <Text>
              {t('data80',"Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bieten wir Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art. 6 Abs. 1 S. 1 lit. f DS- GVO oder Ihre Einwilligung, sofern Sie diese erteilt haben. Die Einwilligung ist jederzeit widerrufbar.")}
            </Text>
            <SecondSubHeadline>(4)</SecondSubHeadline>
            <Text>
              {t('data81',"Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z. B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.")}
            </Text>

            <SecondSubHeadline>(5)</SecondSubHeadline>
            <Text>
              {t('data182',"Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.")}
            </Text>
            <SecondSubHeadline>(6)</SecondSubHeadline>
            <Text>
              {t('data183',"Der Hoster unserer Webseite ist Webflow, Inc. Unter Webhosting versteht man die Bereitstellung von Webspace sowie die Unterbringung von Webseiten auf dem Webserver eines Internet Service Providers.")}
            </Text>

            <SecondSubHeadline>(7)</SecondSubHeadline>
            <Text>
              {t('data184',"Angaben zu den jeweiligen Anbietern mit deren Datenschutzhinweisen:")}

              <br /><br />
              а) LinkedIn:
                  <br />
                  LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland {t('data85',"Datenschutzbestimmungen")}:
                  <br />
                  <StyledLink href="https://www.linkedin.com/legal/privacy-policy?src=li-other&veh=de.linkedin.com%7Cli-other">
                    https://www.linkedin.com/legal/privacy-policy?src=li-other&veh=de.linkedin.com%7Cli-other
                  </StyledLink>

              <br /><br />
                  b) XING:
                  <br />
                  New Work SE, Dammtorstraße 30, 20354 Hamburg, Deutschland {t('data86',"Datenschutzbestimmungen")}:
                  <br />
                  <StyledLink href="https://privacy.xing.com/de/datenschutzerklaerung">
                    https://privacy.xing.com/de/datenschutzerklaerung
                  </StyledLink>

              <br /><br />
              c) Skype:
                  <br />
                  Skype Communications SARL, 23-29 Rives de Clausen, L-2165 Luxembourg {t('data87',"Datenschutzbestimmungen")}:
                  <br />
                  <StyledLink href="https://privacy.microsoft.com/de-de/privacystatement">
                    https://privacy.microsoft.com/de-de/privacystatement
                  </StyledLink>

              <br /><br />
              d) Webflow:
                  <br />
                  Webflow, Inc. 398 11th Street, San Francisco, CA 94103 {t('data88',"Datenschutzbestimmungen")}:
                  <br />
                  <StyledLink href="https://webflow.com/legal/privacy">
                    https://webflow.com/legal/privacy
                  </StyledLink>

            </Text>

            <SubHeadline>4. {t('data89',"Erhebung personenbezogener Daten im Rahmen unserer Dienste und Geschäftsbeziehung")}</SubHeadline>
            <SecondSubHeadline>(1)</SecondSubHeadline>
            <Text>
              {t('data90',"Im Rahmen unserer Dienste/Beauftragung und Geschäftsbeziehung mit Ihnen erheben wir folgende personenbezogene Daten:")}
              <br />
              <Liste>
                <li>
                  {t('data91',"Vornamen und Nachnamen nebst Anrede (Herr/Frau)")}
                </li>
                <li>
                  {t('data92',"Anschrift")}
                </li>
                <li>
                  {t('data93',"Telefonnummer (Festnetz und/oder Mobilfunk)")}
                </li>
                <li>
                  {t('data94',"E-Mail-Adresse und ggf. weitere Kommunikationsdaten")}
                </li>
                <li>
                  {t('data95',"Vertragsstammdaten (Vertragsbeziehung, produkt- bzw. Vertragsinteresse)")}
                </li>
                <li>
                  {t('data96',"Kundenhistorie")}
                </li>
                <li>
                  {t('data97',"Vertragsabrechnungen- und Zahlungsdaten")}
                </li>
                <li>
                  {t('data98',"Ggf. weitere ergänzende Daten, die Sie mit der Beauftragung bzw. eines Registrierungsvorgangs für unsere Dienstleistung angegeben werden")}
                </li>
                <li>
                  {t('data99',"Alle Informationen, soweit sie für die Vertragsdurchführung, Beratung und unsere Dienstleistung notwendig sind")}
                </li>
                {t('data100',"Die Erhebung dieser Daten erfolgt zu folgenden Zwecken:")}
                <li>
                  {t('data101',"Zur Kommunikation mit Ihnen im Rahmen der Vertragsanbahnung und zur Vertragsdurchführung")}
                </li>
                <li>
                  {t('data102',"Zur Rechnungsstellung")}
                </li>
                <li>
                  {t('data103',"Um eventuelle Haftungsansprüche Ihrerseits gegen uns abwickeln zu können;")}
                </li>
                <li>
                  {t('data104',"Um etwaige (Zahlungs-)Ansprüche unsererseits gegen Sie verfolgen und durchsetzen zu können.")}
                </li>
              </Liste>
              {t('data105',"Die Datenverarbeitung erfolgt aufgrund unserer Beauftragung durch Sie bzw. aufgrund Ihrer Anfrage. Sie ist zu den genannten Zwecken für die angemessene Bearbeitung und für die beiderseitige Erfüllung von Verpflichtungen aus dem Vertrag erforderlich (Art. 6 Abs. 1 S. 1 lit. b DS-GVO).")}
              <br />
              {t('data106',"Ihre Daten werden sowohl digital als ggf. auch in Papierform gespeichert bzw. gesammelt.")}
              <br />
              {t('data107',"Die von uns erhobenen personenbezogenen Daten werden während der Vertragslaufzeit und nach Beendigung unserer vertraglichen Beziehung bis zum Ablauf der gesetzlichen Aufbewahrungspflicht, gespeichert bzw. aufbewahrt und danach gelöscht bzw. vernichtet. Etwas anderes gilt nur, sofern wir aufgrund sonstiger gesetzlicher Bestimmungen (z.B. der Abgabenordnung, des Geldwäschegesetzes etc.) zu einer längeren Speicherung/Aufbewahrung verpflichtet sind (Art. 6 Abs. 1 S. 1 lit. c DS-GVO.")}
              <br />
              {t('data108',"Nach Beendigung der Aufbewahrungsfrist lassen wir Ihre in Papierform vorhandene personenbezogenen Daten unter Wahrung jeglicher Vertraulichkeit vernichten.")}
            </Text>
            <SecondSubHeadline>(2)</SecondSubHeadline>
            <Text>
              {t('data114',"Wir bieten ein webbasiertes Dashboard (grafische Benutzeroberfläche) an, worüber Sie als Auftraggeber Daten eingeben und übertragen können. Das Dashboard wird gehostet durch AWS, eine Cloud-Computing-Plattform von Amazon Web Services Inc., 440 Terry Avenue, North Seattle, WA 98109, USA. Die Daten-Server befinden sich in der Bundesrepublik Deutschland und haben den deutschen und europäischen Datenschutz- und Sicherheitsbestimmungen zu entsprechen.  AWS hat ein sogenanntes Datenschutz-Zentrum, zu dem Sie speziell für Deutschland unter")}&nbsp;<StyledLink href="https://aws.amazon.com/de/compliance/germany-data-protection/">
              https://aws.amazon.com/de/compliance/germany-data-protection/
            </StyledLink> {t('data109',"nähere Informationen finden.")}
            </Text>

            <SecondSubHeadline>(3)</SecondSubHeadline>
            <Text>
              {t('data110',"Im Rahmen der Nutzung des Dashboard und Erbringung des Auftrags/ verwenden wir zur Sprachübersetzungen das Tool DeepL des Anbieters DeepL GmbH, Maarweg 165, 50825 Köln, Deutschland. Personenbezogene Daten innerhalb eines zu übersetzenden Textes werden auf die Server von DeepL übertragen. Die Datenschutzbestimmungen von DeepL können Sie einsehen unter:")}&nbsp;<StyledLink href="https://www.deepl.com/privacy/">
              https://www.deepl.com/privacy/
            </StyledLink>.
            </Text>

            <SecondSubHeadline>(4)</SecondSubHeadline>
            <Text>
              {t('data111',"Für die E-Mails Kommunikation verwenden wir Siteground, des Anbieters SiteGround Spain S.L., C/ Serrano\t1, 5º 28001 Madrid. Die Datenschutzbestimmungen von Siteground können Sie einsehen unter:")}&nbsp;<StyledLink href="https://www.siteground.com/privacy.htm">
              https://www.siteground.com/privacy.htm
            </StyledLink>.
            </Text>

            <SecondSubHeadline>(5)</SecondSubHeadline>
            <Text>
              {t('data112',"Sofern Sie uns beauftragen, personenbezogene Daten Dritter zu verarbeiten, schließen wir mit Ihnen gemäß\t\tArt. 28 DSGVO eine gesonderte Vereinbarung zur Auftragsverarbeitung.")}&nbsp;
            </Text>

          </Container>
        </PageContent>
      </Page>
    );
  }
}

export default withTranslation("translations")(DataProtection);
