import React from 'react';

import { post } from '../security/Api';
import Input from '../components/Input';
import { Button } from '../components/Button';
import PageContent from '../components/PageContent';
import CardBody, { CardBodyInner, CardBodyInnerContainer } from '../components/CardBody';
import { Form } from '../components/Form';
import { AnonymPageHeadline, AnonymPageInfo, LoginButtonContainer } from './Login';
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";
import error from "../error";
import Alert from '../components/Alert';

class PasswordRecovery extends React.PureComponent {
  state = {
    formData: {
      email: null,
    },
    formErrors: {},
    errors: {},
    error: false,
    errorMessage: null
  };

  onChange = (key, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  };

  onSubmit = async () => {
    const { formData } = this.state;
    formData.locale = this.props.i18n.language;
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
    let onSuccess = () => {
      this.props.history.push(`/password-recovery/success/${formData.email}`);
    }
    await post(
      '/password/recovery',
      formData,
      onError,
      onSuccess
    );
  };

  render() {
    const { formData } = this.state;
    const { t } = this.props;

    return (
      <Page background={grey5}>
        <PageContent>

          <CardBody>
            <CardBodyInnerContainer anonym={true}>

              <CardBodyInner anonym={true}>
                <AnonymPageHeadline>{t('passwordrecovery1',"Passwort vergessen")}</AnonymPageHeadline>
                <AnonymPageInfo>{t('passwordrecovery2',"Bitte Geben Sie Ihre E-Mail Adresse an")}</AnonymPageInfo>
              </CardBodyInner>

              <CardBodyInner anonym={true}>
                <Form>
                  <Input
                    id="email"
                    type="text"
                    label="E-Mail"
                    required={true}
                    value={formData.email}
                    onChange={value => this.onChange('email', value)}
                    error={this.state.errorMessage}
                  />
                  <LoginButtonContainer>
                    <Button mainbutton id="submit" icon={'main_arrow'} onClick={this.onSubmit}>{t('passwordrecovery3',"Neues Passwort anfordern")}</Button>
                  </LoginButtonContainer>
                </Form>
              </CardBodyInner>
            </CardBodyInnerContainer>
          </CardBody>
        </PageContent>
        {this.state.error &&
        <Alert
          type="error"
          message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
          onClose={() => this.setState({error: false})}
        />
        }
        {this.state.info && this.state.infoMessage &&
        <Alert
          type="info"
          message={this.state.infoMessage}
          onClose={() => this.setState({info: false})}
        />
        }
      </Page>
    );
  }
}

export default withTranslation("translations")(PasswordRecovery);
