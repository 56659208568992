import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon/Icon';
import { darkblue, red, white } from '../colors';
import ErrorMessage from './ErrorMessage';

const Container = styled.div`
    display: flex;
    width: 100%;
    ${(props => props.flexible || props.tiny) ? `` : 'min-width: 400px'};
    height: ${props => props.tiny ? '20px' : '48px'};
    ${props => props.focus ? `border-bottom: 3px solid ${props.color}` : ''};
    font-size: ${props => props.light ? '18px' : '22px'};
    border-radius: 4px;
    ${props => props.light ? `background: ${white};` : ''};
    ${props => !props.focus && 'cursor: pointer;'};
    ${props => props.error && `border: 1px solid ${red};`};
`;

const InputField = styled.input`
    padding: ${props => props.tiny ? '0' : '8px 0 8px 16px'};
    width: 100%;
    display: flex;
    align-items: center;
    appearance: none;
    border: none;
    display: block;
    outline: 0 none;
    background: transparent;
    font-size: ${props => props.light ? '18px' : '22px'};
    color: ${props => props.color};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }
`;

const InputDummy = styled.span`
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    padding: ${props => props.tiny ? '8px 0 8px 8px' : '8px 0 8px 16px'};
    width: 100%;
`;

const IconContainer = styled.div`
    padding: ${props => props.tiny ? '0' : '8px 16px 8px 0'};
`;

class Input extends React.Component {
    state = {
        focus: false,
        value: null,
        changed: false
    };

    input = React.createRef();

    valueToForm(value) {
        if (value === null) {
            return '';
        }

        return value;
    }

    formToValue(value) {
        const { type } = this.props;

        if (value === '') {
            return null;
        }

        if (type === 'number') {
            return Number(value);
        }

        return value;
    }

    onChange(event) {
        if (this.state.value !== event.target.value){
            const { onChange } = this.props;
            const { value, maxLength } = event.target;

            let formValue = value;

            if (maxLength && maxLength !== -1) {
                formValue = formValue.slice(0, maxLength);
            }
            this.setState({value: value, changed: true});
            onChange(this.formToValue(formValue));
        }
    };

    onBlur(event) {
        const { onBlur } = this.props;
        this.setState({
            focus: false,
        });

        if (onBlur === null) {
            return;
        }
        if (this.state.changed){
            this.setState({changed: false});
            if(!this.props.doNotSubminOnBlur) {
              this.props.onSubmit();
            }
        }
    };

    onKeyUp(event) {
        if (typeof event !== "undefined" ) {
            if (event.key === 'Enter' || event.keyCode === 13) {
                event.preventDefault();
                this.onBlur(event);
            }
        }
    }


    componentDidMount() {
        const input = this.input.current;

        if (input) {
            input.focus();
        }
    }

    componentDidUpdate() {
        const { active } = this.props;

        const input = this.input.current;

        if (input && !active) {
            input.focus();
        }
    }

    render() {
        const {
            id,
            type,
            name,
            value,
            readOnly,
            disabled,
            className,
            error,
            light,
            tiny,
            placeholder = '',
            min = null,
            max = null,
            maxLength = null,
            filename = null,
            color = darkblue,
            active = false,
            flexible = false,
            editable = true,
        } = this.props;

        const { focus } = this.state;

        if (focus || active) {
            return (
                <React.Fragment>
                    <Container focus={active || focus} color={color} error={error} light={light} tiny={tiny}>
                        <InputField
                            ref={this.input}
                            data-cy={id}
                            id={id}
                            name={name}
                            className={className}
                            type={type || 'text'}
                            value={this.valueToForm(value)}
                            filename={filename}
                            onChange={event => this.onChange(event)}
                            readOnly={readOnly}
                            disabled={disabled}
                            onBlur={event => this.onBlur(event)}
                            onFocus={(event) => {
                                this.setState({ focus: true, value: event.target.value });
                            }}
                            onKeyUp={event => this.onKeyUp(event)}
                            placeholder={placeholder}
                            min={min}
                            max={max}
                            maxLength={maxLength}
                            focus={true}
                            color={color}
                            error={error}
                            light={light}
                            tiny={tiny}
                        />
                        {/*<IconContainer>*/}
                        {/*    <Icon name="edit" />*/}
                        {/*</IconContainer>*/}
                    </Container>
                    {error && <ErrorMessage id={id} error={error} special={true} />}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Container
                  onClick={() => this.setState({ focus: (editable ? true : false) })} focus={false} error={error} light={light} flexible={flexible} tiny={tiny}>
                    <InputDummy data-cy={id} tiny={tiny}>
                        {value}
                    </InputDummy>
                  {editable &&
                  <IconContainer tiny={tiny}>
                    <Icon name="edit" size={tiny ? "20" : "32"}/>
                  </IconContainer>
                  }
                </Container>
                {error && <ErrorMessage id={id} error={error} special={true} />}
            </React.Fragment>
        );
    }
}
export default Input;
