import React from 'react';
import Icon from './Icon/Icon';
import Link from './Link';
import { withTranslation } from "react-i18next";
import { Dimmer, Header } from 'semantic-ui-react';
import tokenStorage from "../security/TokenStorage";
import styled from '@emotion/styled';
import {  grey80 } from '../colors';
import 'semantic-ui-css/components/dimmer.min.css';

const MyDimmer = styled(Dimmer)`
    background: ${grey80} !important;
`;

class Curtain extends React.Component {
    type = this.props.type;
    logout = () => {
        tokenStorage.logout();
    }
    render() {

        const { t, type } = this.props;
        if (type==="loading") {
            return (
                <MyDimmer active={true} page>
                    <Header as='h2' icon inverted>
                    <Icon name='settings_filled' />
                        {t('curtain1',"Wird geladen...")}
                    </Header>
                </MyDimmer>
            );
        }
        if (type==="error") {
            return (
                <MyDimmer active={true} page>
                    <Header as='h2' icon inverted>
                    <Icon name='caution_error' />
                        {t('curtain2',"Ladefehler")}&nbsp;<Link to="/">{t('curtain3', 'Klicke hier')}</Link>&nbsp; {t('curtain4', 'um zur Startseite zurückzukehren')}.
                    </Header>
                </MyDimmer>
            );
        }
        if (type==='accountError') {
            this.logout();
            return (
                <MyDimmer active={true} page>
                    <Header as='h2' icon inverted>
                    <Icon name='caution_error' />
                        {t('curtain2',"Ladefehler")}&nbsp;<Link to="/">{t('curtain3', 'Klicke hier')}</Link>&nbsp; {t('curtain4', 'um zur Startseite zurückzukehren')}.
                    </Header>
                </MyDimmer>
            );
        }
    }
}

export default withTranslation("translations")(Curtain);
