import React, {useState, useEffect} from 'react';
import {post} from '../../security/Api';
import {darkblue, grey70, grey80, grey5, tourquoise, blue, white, errorred} from '../../colors';
import styled from '@emotion/styled';
import PageModal from '../PageModal';
import {Button} from '../Button';
import Icon from '../Icon/Icon';
import OptionsBox from '../OptionsBox';
import AnoOptionsBox from '../AnoOptionsBox';
import Select from 'react-select'
import {withTranslation} from "react-i18next";
import Grid from '@mui/material/Grid';
import {InfoPopupGreen} from "../InfoPopup";

const FirstLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-bottom: 28px;
    padding-right: 28px;
`;

const Headline = styled.div`
    color: ${props => props.light ? grey5 : darkblue};
    font-size: 24px;
    text-align: left;
    font-family: AvenirLTPro-Medium, AvenirNextCyrMedium;
    display: flex;
    justify-content: left;
`;

const OptionsRow = styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 34px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const PreviewButtonRow = styled.div`
    display: flex;
    // justify-content: flex-end;
    bottom: 30px;
`;

const MethodHeadline = styled.div`
    display: flex;
    margin-bottom: 14px;
    font-size: 18px;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    justify-content: space-between;
    align-items: center;
`;

const TimerIconContainer = styled.div`
    margin-right: 8px;
    display: flex;
    color: ${grey5};
`;
const SubHeadlineContainer = styled.div`
    font-size: 1em;
    color: ${grey5};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
`;

const SubValue = styled.div`
    font-size: 16px;
    font-weight: normal;
    ${props => props.result && 'font-family: AvenirLTPro-Black, AvenirNextCyrBold;'}
`;

const ModalInnen = styled.div`
    font-size: 1em;
    display: flex;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    color: ${darkblue};
    justify-content: ${props => props.previewOpened ? "left" : "center"};
    height: 100%;
    min-height: 600px;
    width: 100%;
`;

const TransitionHelper = styled.div`
    width: ${props => props.previewOpened ? "75%" : "50%"};
    display: flex;
`;

const MethodOptions = styled.div`
    font-size: .8em;
    width: ${props => props.previewOpened ? "30%" : "100%"};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey80};
    border-radius: 5px;
    margin: 0 15px;
    color: ${grey5};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const MethodPreview = styled.div`
    font-size: 1em;
    width: 70%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey5};
    border-radius: 5px;
    margin: 0;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const MethodPrice = styled.div`
    font-size: .8em;
    width: 25%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${white};
    border-radius: 5px;
    margin: 0 15px;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const PriceTotalLine = styled.p`
    margin-top: -5px;
    font-family: AvenirTPro-Demi, AvenirNextCyrDemi;
    display: flex;
    justify-content: space-between;
`;

const Divider = styled.hr`
    color: ${grey70};
    height: .5px;
`;

const CalcContainer = styled.div`
    margin-top: 30px;
`;

const OverlayContainer = styled.div`
    margin-top: 30px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    &:after {
        content: 'Preview';
        position: relative;
        display: block;
        opacity: 0.9;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        z-index:10;
        box-shadow: 0 0 50px 50px ${grey5}
    }
`;

const BlockContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    position: relative;
`;

const CommentsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const CalcLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PreviewContainer = styled.div`
    font-size: .8em;
`;

const Comment = styled.div`
    background: ${grey5};
    padding: 5px;
    margin: 9px;
    min-height: 24px;
    border-radius: 5px;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
    position: relative;
`;

const Timer = styled.div`
    margin-left: 10px;
    margin-top: 2px;
    color: ${grey5};
`;

const OptionsList = styled.div`
    border: 0;
    margin-top: 10px;
    width:100%;
    display: flex;
    margin-bottom: 20px;
    color: ${grey5};
`;

const CreditsLeft = styled.span`
    color: ${props => props.minus ? errorred : darkblue}
`;

function AnonymizationModal(props) {
  const [preview, setPreview] = useState(true);
  const dbName = "Ano";
  const [totalRows, setTotalRows] = useState(0);
  const [options, setOptions] = useState(
    [
      {
        id: 'replaceEmails',
        label: props.t("StudyDetail16", 'E-Mails'),
        value: false,
        color: {tourquoise},
      },
      {
        id: 'replacePhones',
        label: props.t("StudyDetail17", 'Telefonnummern'),
        value: false,
        color: {tourquoise}
      },
      {
        id: 'replaceCountries',
        label: props.t("StudyDetail27", 'Länder'),
        value: false,
        color: {tourquoise}
      },
      {
        id: 'replaceAddress',
        label: props.t("StudyDetail18", 'Adressen'),
        value: false,
        color: {tourquoise}
      },
      {
        id: 'replaceCities',
        label: props.t("StudyDetail28", 'Städte'),
        value: false,
        color: {tourquoise}
      },
      {
        id: 'replaceDates',
        label: props.t("StudyDetail19", 'Datumsangaben'),
        value: false,
        color: {blue}
      },
    ],
  )
  const [pseudoOptions, setPseudoOptions] = useState([
    {
      id: "pseudoDefault",
      name: props.t("anoModal13", "[Anonymized]"),
      value: true,
    },
    {
      id: "pseudoOption",
      name: props.t("anoModal14", 'Option (z.B. [Name])'),
      value: false,
    },
    {
      id: "pseudoUser",
      name: props.t("anoModal15", "Benutzerdefiniert"),
      value: false,
    }
  ])
  const [pickedPseudonym, setPickedPseudonym] = useState(props.t("anoModal13", "[Anonymized]"));

  const {
    history, onClose, onError, checkResult,
    checkStudy,
    getPreview,
    previewLoaded,
    columnCol,
    checkLoaded, file, t, previewResult
  } = props;

  // const [columnCol, setColumnCol] = useState({});

  useEffect(() => {
    countMethodRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countMethodRows = () => {
    let currentRows = 0;
    props.projectCostNumber.creditHistories.forEach(historyUnit => {
      if (historyUnit.methodName === dbName) {
        currentRows += historyUnit.credit.value;
      }
    })
    setTotalRows(currentRows);
  }

  function renderPreview(comment) {
    return <Comment>{comment}</Comment>;
  }

  function getOptionsForPreview() {
    let result = {};
    result.output = {"ano_generaltag": pickedPseudonym === "Option" ? null : pickedPseudonym,};
    result.preprocess = {
      "replace_emails": options.find((option) => {
        return option.id === "replaceEmails"
      })?.value,
      "replace_phones": options.find((option) => {
        return option.id === "replacePhones"
      })?.value,
    }
    result.anonym = {
      "pseudo": pickedPseudonym === "Option" ? true : false,
      "conservative": false,
      "career_title": false,
      "country": options.find((option) => {
        return option.id === "replaceCountries"
      })?.value,
      "address": options.find((option) => {
        return option.id === "replaceAddress"
      })?.value,
      "city": options.find((option) => {
        return option.id === "replaceCities"
      })?.value,
      "date": options.find((option) => {
        return option.id === "replaceDates"
      })?.value,
      "black": [],
      "black_case_insensitive": [],
      "white": [],
      "white_case_insensitive": []
    }
    return result;
  }

  useEffect(() => {
    getPreview(getOptionsForPreview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, pseudoOptions])


  function onOptionChange(id, value) {
    const newOptions = options.map(option => {
      if (option.id === id) {
        option.value = value;
      }
      return option;
    });

    setOptions([...newOptions]);
  }

  function onPseudoOptionChange(selectedId) {
    const newOptions = pseudoOptions.map((option) => {
      if (option.id === selectedId) {
        option.value = true;
        switch (selectedId) {
          case "pseudoDefault" :
            setPickedPseudonym(props.t("anoModal13", "[Anonymized]"));
            break;
          case "pseudoOption" :
            setPickedPseudonym("Option");
            break;
          case "pseudoUser" :
            setPickedPseudonym("[XXX]");
            break;
          default:
            setPickedPseudonym(props.t("anoModal13", "[Anonymized]"));
        }
      } else {
        option.value = false;
      }
      return option;
    })
    setPseudoOptions([...newOptions]);
  }

  function handlePseudonym(pseudonym) {
    const newOptions = pseudoOptions.map((option) => {
      if (option.id === "pseudoUser") {
        option.value = true;
      } else {
        option.value = false;
      }
      return option;
    })
    setPseudoOptions([...newOptions]);
    setPickedPseudonym(pseudonym);
  }

  let anonymizeStudy = async () => {
    const optionsForApi = {};

    for (const option of options) {
      optionsForApi[option.id] = option.value;
    }
    optionsForApi['anoTag'] = pickedPseudonym === "Option" ? null : pickedPseudonym;
    optionsForApi['pseudo'] = pickedPseudonym === "Option" ? true : false;
    let onSuccess = () => {
      history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
    }

    await post(
      '/study/anonymize',
      {
        studyId: props.studyId,
        columnCol: columnCol.value,
        options: optionsForApi
      },
      onError,
      onSuccess
    );
  };

  let getListOfColumns = () => {
    // if (checkLoaded && checkResult) setColumnCol({ label: checkResult.columnName, value: checkResult.columnName });
    if (checkLoaded && checkResult) {
      let topTenRows = Object.keys(JSON.parse(checkResult.topTenRows));
      return topTenRows.map(function (key) {
        return {
          value: key,
          label: key,
        };
      });
    }
  };

  let changeColumnCol = (e) => {
    // setColumnCol({value: e.value, label: e.value});
    // columnCol = { value: e.value, label: e.value };
    checkStudy(e.value);
    getPreview(getOptionsForPreview(), e.value);
  }

  let creditsLeft = checkLoaded && checkResult ? totalRows - checkResult.calculatedPrice : '...';

  return (
    <PageModal onClose={onClose} color={grey5}>
      <ModalInnen>
        <TransitionHelper previewOpened={preview}>
          <MethodOptions previewOpened={preview}>
            <FirstLine>
              <Headline light>{t("anoModal1", "Anonymisierung")}
                <InfoPopupGreen
                  content={t("tooltipAno")}
                />
              </Headline>
            </FirstLine>
            <div data-tut="step_eleven">
              {checkLoaded && checkResult && file &&
              <MethodHeadline>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <SubHeadlineContainer>
                      <SubValue result={true}>{file.name}</SubValue>
                    </SubHeadlineContainer>
                  </Grid>

                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <TimerIconContainer>
                      <Icon size={24} name="timer"/>
                      <Timer>{checkResult.computationTime < 1 ? 1 : checkResult.computationTime} min</Timer>
                    </TimerIconContainer>
                  </Grid>
                </Grid>
              </MethodHeadline>
              }
              {!checkLoaded && <MethodHeadline>{t("method1", "Wird berechnet...")}</MethodHeadline>}


              <OptionsRow>
                <AnoOptionsBox
                  onChange={(key, value) => onOptionChange(key, !value)}
                  options={options}
                  headline={t("anoModal10", "Ersetze:")}
                  inverted={true}
                />
              </OptionsRow>
            </div>
            <div data-tut="step_twelve">
              <OptionsRow>
                <AnoOptionsBox
                  onChange={(key, value) => onOptionChange(key, value)}
                  options={options}
                  headline={t('anoModal9', "Weitere Optionen")}
                />
              </OptionsRow>
              <OptionsList>
                <OptionsBox
                  headline={props.t("anoModal16", "Ersetzen mit")}
                  options={pseudoOptions}
                  onChange={onPseudoOptionChange}
                  onUserDefined={handlePseudonym}
                  type="radio"
                  vertical={true}
                  color={white}
                />
              </OptionsList>
            </div>

            <PreviewButtonRow>
              <Button
                mainbutton
                type="button"
                // tiny
                onClick={() => setPreview(!preview)}
              >
                {preview ? t("anoModal11", "Vorschau ausblenden") : t("anoModal12", "Vorschau")}
              </Button>
            </PreviewButtonRow>

          </MethodOptions>
          {preview && previewResult &&
          <MethodPreview active={preview} data-tut="step_thirghteen">
            <FirstLine>
              <Headline>{t("anoModal2", "Vorschau")}</Headline>
            </FirstLine>
            {previewLoaded &&
            <PreviewContainer>
              <CalcLine>{t("anoModal8", "Begriffe in den folgenden Beitragen werden ersetzt")}:</CalcLine>
              <OverlayContainer>
                <CommentsContainer>
                  {previewResult?.map(renderPreview)}
                </CommentsContainer>
              </OverlayContainer>

              <BlockContainer>
                <CommentsContainer>
                  {t("anoModal19", "Nicht die richtige Spalte?")}
                </CommentsContainer>
                <CommentsContainer>
                  {t("account127", "Spaltenname auswählen")}
                </CommentsContainer>
                <CommentsContainer>
                  <Select
                    id="columnCol"
                    name="columnCol"
                    options={getListOfColumns()}
                    // value={checkLoaded && checkResult ? { label: checkResult.columnName, value: checkResult.columnName } : ''}
                    value={columnCol}
                    onChange={(value) => changeColumnCol(value)}
                    autoFocus={false}
                    required={true}
                  />
                </CommentsContainer>
              </BlockContainer>
            </PreviewContainer>
            }
            {!previewLoaded &&
            <PreviewContainer>
              <CalcLine>{t("anoModal18", "Vorschau ist vorbereitet...")}:</CalcLine>
              <OverlayContainer>
              </OverlayContainer>
            </PreviewContainer>
            }
          </MethodPreview>
          }
        </TransitionHelper>
        <MethodPrice previewOpened={preview} data-tut="step_fourteen">
          {t("anoModal3", "Durch Anwenden des Data Services werden die folgenden Credits abgebucht")}:
          <CalcContainer>
            <CalcLine>
              <span>{t("projectform5", "Kostennummer")}</span>
              <span>{props.projectCostNumber.name?.length ? props.projectCostNumber.name : '_' + t("projectForm12", "default")}</span>
            </CalcLine>
            <CalcLine>
              <span>{t("anoModal4", "Aktuelle Credits")}</span>
              <span>{totalRows} {t("testCredits5", "Zeilen")}</span>
            </CalcLine>
            <CalcLine>
              <span>{t("anoModal5", "Anonymisierung")}</span>
              <span>{checkLoaded && checkResult ? -checkResult.calculatedPrice : "..."} {t("testCredits5", "Zeilen")}</span>
            </CalcLine>
          </CalcContainer>
          <Divider/>
          <PriceTotalLine>
            <span>{t("anoModal6", "Neue Credits")}</span>
            <CreditsLeft
              minus={creditsLeft < 0}>{checkLoaded && checkResult ? creditsLeft : "..."} {t("testCredits5", "Zeilen")}</CreditsLeft>
          </PriceTotalLine>
          <ButtonRow >
            {creditsLeft >= 0 && creditsLeft !== "..." &&
            <Button type="button" tiny data-cy="send-options" icon="main_arrow" mainbutton
                    onClick={anonymizeStudy}>{t("anoModal7", "Anwenden")}</Button>
            }
            {creditsLeft < 0 && creditsLeft !== "..." &&
            <Button type="button" tiny icon="main_arrow" mainbutton
                    onClick={props.showBuyModal}>{t("anoModal17", "Credits kaufen")}</Button>
            }
          </ButtonRow>
        </MethodPrice>
      </ModalInnen>

    </PageModal>
  );
}

export default withTranslation("translations")(AnonymizationModal);
