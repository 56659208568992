import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from 'react-router-dom';
import {Button} from '../components/ButtonReg';
import {Formik} from 'formik';
import * as Yup from "yup";
import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import CardHeader from '../components/CardHeader';
import CardBody, {CardBodyInner} from '../components/CardBody';
import {Form} from '../components/FormReg';
import CostNumberElement from '../components/CostNumberElement';
import {CreditsInfo, TableHeader} from './OrganisationDetail';
import Page from '../components/Page';
import Curtain from '../components/Curtain';
import errorFn  from "../error";
import Alert from '../components/Alert';
import TextInputLiveFeedback from '../components/TextInputLiveFeedback';
import {LoginButtonContainer} from './Login';

function CostNumberCreate(props) {

  const {t} = props;

  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [info, setInfo] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);

  const [costNumbers, setCostNumbers] = useState([]);
  const [rowsByMethod, setRowsByMethod] = useState(["ssss"]);

  const CostNumberSchema = Yup.object().shape({
    name: Yup
      .string(t("cost9", "Ungültige Angabe Kostnummer"))
      .required(t("cost10", "Dieses Feld muss ausgefüllt sein."))
  })

  useEffect(() => {
    loadCostNumbers();
  }, [])

  useEffect(() => {
    loadCostNumbersRowsByMethod();
  }, [costNumbers])

  let loadCostNumbers = async () => {
    const response = await get('/costnumber/list');

    if (response.status === 200) {
      const responseData = await response.json();
      console.log(responseData);

      setLoaded(true);
      setLoadingError(false);
      setCostNumbers(responseData);
      return
    }
    setLoaded(true);
    setLoadingError(true);
  }

  let loadCostNumbersRowsByMethod = async () => {

    const response = await get('/organisation/rowsleftbymethod');

    if (response.status === 200) {
      const responseData = await response.json();
      console.log(responseData);

      setLoaded(true);
      setLoadingError(false);
      setRowsByMethod(responseData);
      return
    }
    setLoaded(true);
    setLoadingError(true);
  }

  let addCostNumber = async (values, {setFieldError}) => {
    console.log(values.name);
    await post(
      '/costnumber/create',
      values,
      (code) => {
        onError(code, setFieldError);
      },
      () => {
        loadCostNumbers();
        values.name = '';
        setInfo(true);
        setInfoMessage(props.t('cost11', "Die Kostennummer wurde hinzugefügt"));
      }
    );
  }

  let removeCostNumber = async (costNumberId) => {
    let onError = (code) => {
      setError(true);
      setErrorMessage(() => {errorFn(code, props.t)});
    }
    let onSuccess = () => {
      setInfo(true);
      setInfoMessage(props.t('cost14', "Die Kostennummer wurde gelöscht")); // todo
      setError(false);
      setErrorMessage(null);
      loadCostNumbers();
    }
    await post(
      '/costnumber/remove',
      {
        costNumberId: costNumberId
      },
      onError,
      onSuccess
    );
  };

  let onError = async (code, setFieldError) => {
    if (code === 167) {
      await setFieldError('name', errorFn(code, props.t));
    } else {
      setErrorMessage(errorFn(code, props.t));
      setLoadingError(true);
    }
  }

  let renderCostNumber = () => {
    return (
      <>
        {!loaded &&
        <div>{t('cost5', "Loading...")}</div>
        }
        <CardBody>
          {costNumbers.length > 0 && (
            <TableHeader>
              {t('cost6', "Kostennummer")}
              <CreditsInfo>
                <div>{t('todo109', "Rows")}</div>
                <div>&nbsp;</div>
              </CreditsInfo>
            </TableHeader>
          )}

          {costNumbers.length === 0 && <div>{t('cost8', "Keine Kostennummern vorhanden")}</div>}
          {costNumbers.map(renderCostNumberItem)}
        </CardBody>
      </>
    );
  }

  let renderCostNumberItem = (costNumber) => {
    return (
      <React.Fragment key={costNumber.id}>
        <CostNumberElement
          id={costNumber.name}
          costNumberId={costNumber.id}
          name={costNumber.name.length ? costNumber.name : "_" + t("projectForm12", "default")}
          credits={costNumber.credits}
          onRemoveCostNumber={() => removeCostNumber(costNumber.id)}
          rowsByMethod={rowsByMethod}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      {!loaded &&
      <Curtain type="loading"/>
      }
      {loadingError &&
      <Curtain type="error"/>
      }
      {loaded && !loadingError &&

      <Page>
        <PageContent>
          <Headline>{t('cost1', "Kostennummer erstellen")}</Headline>
          <CardHeader>
            {t('cost2', "Bitte fügen Sie eine Kostennummer zu Ihrer Organisation hinzu")}
          </CardHeader>
          <CardBody>
            <CardBodyInner>
              <Formik
                initialValues={{
                  name: ""
                }}
                validationSchema={CostNumberSchema}
                validateOnBlur
                onSubmit={addCostNumber}
              >
                <Form>
                  <TextInputLiveFeedback
                    id="name"
                    name="name"
                    type="text"
                    label={t('cost3', "Kostennummer")}
                    data-cy="name"
                  />
                  <LoginButtonContainer>
                    <Button data-cy="costnumber-create" icon="add" id="submit"
                            type="submit">{t('cost4', "Erstellen")}</Button>
                  </LoginButtonContainer>
                </Form>
              </Formik>
            </CardBodyInner>
          </CardBody>

          {renderCostNumber()}

        </PageContent>
        {error &&
        <Alert
          type="error"
          message={errorMessage ? errorMessage : errorFn(0, t)}
          onClose={() => setError(false)}
        />
        }
        {info && infoMessage &&
        <Alert
          type="info"
          message={infoMessage}
          onClose={() => setInfo(false)}
        />
        }
      </Page>
      }
    </>
  );
}

export default withRouter(withTranslation("translations")(CostNumberCreate));
