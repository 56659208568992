import styled from '@emotion/styled';
import React from 'react';
import { darkblue, tourquoise, grey30, orange1, white, grey70 } from '../colors';
import Icon from './Icon/Icon';

export const Wizard = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StepContainer = styled.div`
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    ${props => props.status === STATUS_NEXT && `background: ${grey30};`}
    ${props => props.status === STATUS_CURRENT && `background: ${orange1};`}
    ${props => props.status === STATUS_FINISHED && `background: ${tourquoise};`}
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    color: ${white};
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-align: center;
    line-height: 32px;
    margin-right: 15px;
    margin-left: 40px;
`;

const Name = styled.div`
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    font-size: 16px;
    ${props => props.status === STATUS_NEXT && `
        color: ${grey70};
        `
    }

    ${props => props.status === STATUS_CURRENT && `color: ${darkblue};`}

    ${props => props.status === STATUS_FINISHED && !props.active &&`color: ${grey70};`}
    ${props => props.status === STATUS_FINISHED && props.active &&`color: ${darkblue};`}

`;

export class Step extends React.PureComponent {
    render() {
        const { children, status, active, size = 16 } = this.props;

        return (
            <StepContainer>
                <IconContainer status={status}>
                    <Icon name={this.props.icon} size={size} />
                </IconContainer>
                <Name status={status} active={active}>{children}</Name>
            </StepContainer>
        );
    }
}

export const STATUS_NEXT = 'next';
export const STATUS_CURRENT = 'current';
export const STATUS_FINISHED = 'finished';
