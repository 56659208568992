import React from 'react';
import { ActionButton } from './Action';
import styled from '@emotion/styled';
import tokenStorage from "../security/TokenStorage";
import axios from "axios";
import {buttonmain} from "../colors";

const UploadInput = styled.input`
  display: none;
`;

const DragAndDrop = styled.div`
  width: 100%;
  button {
    height: 100%;
  }
`;

export default class UploadAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            formErrors: {},
        }
    }

    input = React.createRef();

    onClick = () => {
        this.input.current.click();
    };

    async onChange(event) {
        event.preventDefault();

        let file = "";
        if (event.dataTransfer && event.dataTransfer.items) {
          file = [...event.dataTransfer.items]
            .find((item) => item.kind === "file")
            .getAsFile();
        } else {
          file = event.target.files[0];
        }

        if (this.props.onDrop) {
          this.props.onDrop();
        }

        this.props.onPickFile(file.name);
        await this.uploadFile(file);
    };

    uploadFile = async (file) => {

        const { uploadUrl, language } = this.props;
        const token = tokenStorage.getToken();

        let headers = {};

        if (token !== null) {
            headers['X-AUTH-TOKEN'] = 'Bearer ' + token;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('language', language);

        axios.request({
            method: 'POST',
            headers: headers,
            data: formData,
            url: process.env.REACT_APP_API_URL + uploadUrl,
            onUploadProgress: (p) => {
                var percent = p.loaded/p.total;
                if (percent < 1) {
                    this.props.updateState(percent);
                } else {
                    this.props.updateState(0.99);
                }
            }
        }).then (data => {
            this.props.onChange(data);
        })

    };

    onDragOver = (ev) => ev.preventDefault();

    render() {
        const { children, disabled } = this.props;

        return (
          <React.Fragment>
            <DragAndDrop
              onDrop={(event) => this.onChange(event)}
              onDragOver={(event) => this.onDragOver(event)}
            >
              <ActionButton  disabled={disabled} id="file-upload" big={true} color={buttonmain} onClick={this.onClick}>
                {children}
              </ActionButton>
              <UploadInput
                ref={this.input}
                data-cy="real-file-upload"
                type="file"
                onChange={(event) => this.onChange(event)}
              />
            </DragAndDrop>
          </React.Fragment>
        );
    }
}
