import React, {useState} from "react";
import Tour from "reactour";
import {Button} from "./Button";
// import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

const UserOnboarding = (props) => {
  const {
    steps,
    isOpen,
    // setIsOpen,
    rounded,
    accentColor,
    onAfterOpen,
    onBeforeClose,
    onRequestClose,
    // nextStep,
    setTourPassed,
    currentStep,
    nextStepClicked,
    onSubmit
  } = props;
  // const disableBody = (target) => disableBodyScroll(target);
  // const enableBody = (target) => enableBodyScroll(target);
  const [tmpStep, setTmpStep] = useState(1);
  // const [current, setCurrent] = useState(0);

  return (
    <Tour
      // afterOpen={disableBody}
      // beforeClose={enableBody}
      startAt={currentStep}
      currentStep={currentStep}
      badgeContent={(curr, tot) => `${curr} auf ${tot}`}
      steps={steps}
      isOpen={isOpen}
      rounded={rounded}
      accentColor={accentColor}
      onAfterOpen={onAfterOpen}
      onBeforeClose={onBeforeClose}
      onRequestClose={onRequestClose}
      closeWithMask={false}
      disableWhenSelectorFalsy
      nextButton={<Button
        onClick={() => {
        console.log("CurrentStep: " + currentStep);
        console.log("Tmp: " + tmpStep);
        // console.log("Current: " + current);
        // setCurrent((prev) => {
        //   return prev < steps.length - 1 ? prev + 1 : prev;
        // });
        if ((currentStep === 1 && tmpStep < 4) || (currentStep === 6 && tmpStep < 3) || (currentStep === 9 && tmpStep > 1)) {
          let tmpStep2 = tmpStep + 1;
          setTmpStep(tmpStep2);
          setTimeout(nextStepClicked, 5000000);
        } else if ((currentStep === 1 && tmpStep === 4) || (currentStep === 6 && tmpStep === 3)) {
          setTmpStep(1);
          onSubmit();
          // nextStepClicked();
        } else if (currentStep === 9 && tmpStep === 1) {
          let tmpStep2 = tmpStep + 1;
          setTmpStep(tmpStep2);
          onSubmit();
        } else nextStepClicked();
      }
      } mainbutton type="button" tiny>Next</Button>}
      hideDots
      stepInteraction={false}
      disableInteraction={true}

      showNavigation={false}
      // disableDotsNavigation={true}

      showPrevNextButtons={true}

      prevButton={() => {
        return false;
      }}

      // prevStep={() => {
      //   console.log("CurrentStep: " + currentStep);
      //   console.log("Tmp: " + tmpStep);
      //   console.log("Current: " + current);
      //   if (currentStep === 0 || (currentStep === 1 && tmpStep === 1)) {
      //             console.log("return false");
      //     return false;
      //   }
      //   else {
      //     console.log("setCurrent");
      //     setCurrent((prev) => {
      //       let prevStep = prev > 0 ? prev - 1 : prev;
      //       return prevStep;
      //     });
      //   }
      // }
      // }

      lastStepNextButton={<Button
        mainbutton
        type="button"
        tiny
        onClick={setTourPassed}>Never show again!</Button>}

      updateDelay={30}
    />
  );
}
export default UserOnboarding
