import React from 'react';
import {get, post} from '../security/Api';
import styled from '@emotion/styled';
import {errorred, errorred20, grey30, grey5, white, orange1, grey70, buttonmain} from '../colors';
import Icon from './Icon/Icon';
import RadioButton from './RadioButton';
import UploadAction from './UploadAction';
import ISO6391 from 'iso-639-1'; //language names and codes

import {ActionButton} from './Action';
import ProgressBar from './ProgressBar';
import DeleteFileModal from '../components/DeleteFileModal';
//import { FileType, FileTypeArea, FileTypeText } from './FileTypeArea';
import {withTranslation} from "react-i18next";

// import i18n from 'i18next';


import {Dropdown, Transition} from 'semantic-ui-react';
import 'semantic-ui-css/components/dropdown.css';
import 'semantic-ui-css/components/search.css';
import 'semantic-ui-css/components/divider.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/transition.css';

// var languagesNative = require("@cospired/i18n-iso-languages");

const TransitionWrapper = styled.div`
    > .transition {display: flex !important;}
`;

const LanguagePicker = styled(Dropdown)`
    min-width: 50px !important;
    margin-left: 5px;
    font-size: 18px !important;
    font-weight: normal !important;
    color: ${orange1} !important;
`;

const LanguageText = styled.span`
    margin-left: 40px;
    color: ${grey70} !important;
`;

const Container = styled.div`
    background: ${white};
    border-radius: 10px;
    padding: 0px 30px 10px 30px;
`;

const UploadContainer = styled.div`
    display: flex;
    border: 2px dashed ${grey30};
    border-radius: 10px;
    opacity: 1;
    height: ${props => props.support ? '200px' : '80px'};
    margin-bottom: 5px;
`;

const FileListContainer = styled.div`
    border-top: 2px solid ${grey5};

    // max-height: 320px;
    // overflow-y: scroll;
    // overflow-x: hidden;
    background: ${white};

    >:last-child {
        margin-bottom: 30px;
        // padding-bottom: 35px;
    }
`;

const FileContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
    margin: auto;
    border-bottom: 2px solid ${grey5};
    position: relative;
`;


const NameAndMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NameContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.error ? errorred : grey70}
`;

const Name = styled.div`
    font-size: 18px;
    margin-left: 15px;
    white-space: nowrap;
`;

const ErrorMessage = styled.div`
    color: ${errorred};
    background: ${errorred20};
    font-size: 16px;
    margin-left: 40px;
    padding: 5px 10px;
    border-radius: 5px;
`;

const UploadText = styled.div`
    text-transform: none;
    text-align: left;
    margin-top: 10px;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    font-size: 16px;
    color: ${buttonmain};
`;

const ProgressBarContainer = styled.div`
    z-index: 5;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 9px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 5px 9px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
`;

const IconsAtTheEnd = styled.div`
    display: flex;
    align-items: end;
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    justify-content: start;
    width: 50%;
    padding: 10px 0 10px 0;
`;

class FileUpload extends React.PureComponent {

  state = {
    percent: 0,
    currentFileName: "",
    languageOptions: [],
    files: [],
    deleteFileModalOpen: false,
    deleteFileModalFileId: null,
    deleteFileModalError: null,
    showResultFiles: false,
  }

  handleRadioButtonChange = () => {
    this.setState({showResultFiles: !this.state.showResultFiles});
  }

  renderRadioButtons = () => {
    return (
      <RadioButtonsContainer>
        <RadioButton
          id="0"
          name={this.props.t("FileList01", "Dateien")}
          value={this.state.showResultFiles === false}
          onChange={() => this.handleRadioButtonChange()}
        />
        <RadioButton
          id="1"
          name={this.props.t("FileList02", "Ergebnisse")}
          value={this.state.showResultFiles === true}
          onChange={() => this.handleRadioButtonChange()}
        />
      </RadioButtonsContainer>
    );
  }

  componentDidMount = async () => {
    //const languages = ISO6391.getAllCodes();
    const languages = ["de", "en", "fr"];
    const languageOptions = languages.map((language, index) => {

      let returnObject = {
        key: language,
        value: language,
        // text: languagesNative.getName(language, i18n.language)
        text: ISO6391.getName(language.substring(0, 2).toLowerCase())

      }
      return returnObject;
    });

    console.log(languageOptions);

    this.setState({languageOptions: languageOptions})
    this.props.files.map(async (file) => {
      this.setState({["file" + file.id + "animated"]: false});
      this.setState({["file" + file.id + "visible"]: true});
    })
  }

  componentWillReceiveProps = async (props) => {
    props.files.map(async (file) => {
      this.setState({["file" + file.id + "animated"]: typeof file.justAdded !== "undefined"});
      this.setState({["file" + file.id + "visible"]: typeof file.justAdded === "undefined"});
    })

  }

  componentDidUpdate = async () => {
    this.props.files.map(async (file) => {
      if (typeof file.justAdded !== "undefined") {
        this.setState({["file" + file.id + "visible"]: true});
      }
    })

  }

  openDeleteFileModal = (fileId) => {
    this.setState({
      deleteFileModalOpen: true,
      deleteFileModalFileId: fileId
    });
  };

  downloadFile = async (fileId, isResult = false) => {

    let path = `/file/` + fileId + `/download`;
    if (isResult) {
      path = `/resultfile/` + fileId + `/download`;
    }

    const response = await get(path);

    if (response.status === 200) {
      const dispositionHeader = response.headers.get('Content-Disposition');
      const filename = dispositionHeader.split('filename=')[1];
      const file = await response.blob();

      // Download file
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    }
  };

  closeDeleteFileModal = () => {
    this.setState({
      deleteFileModalOpen: false
    });
  };

  onDeleteFileError = () => {
    this.setState({deleteFileModalError: this.props.t("study15", 'Die Datei kann aktuell nicht gelöscht werden, da eine Studie in diesem Moment verarbeitet wird mit dieser Datei.')});
  }
  onDeleteFileSuccess = async () => {
    await this.closeDeleteFileModal();
    //alert(JSON.stringify(this.state));
    let id = this.state.deleteFileModalFileId;
    await this.setState({["file" + id + "animated"]: true});
    await this.setState({["file" + id + "visible"]: false});

    //alert(JSON.stringify(this.state));
    await this.setState({deleteFileModalError: null, deleteFileModalFileId: null});
    await this.props.delete(id);
    //alert(JSON.stringify(this.state));
  }

  submitDeleteFileModal = async () => {
    const {deleteFileModalFileId} = this.state;

    await post(
      '/file/delete',
      {fileId: deleteFileModalFileId},
      this.onDeleteFileError,
      this.onDeleteFileSuccess
    );
  };

  updateState = async (percent) => {
    this.setState({percent: Math.floor(percent * 100)});
  }

  onPickFile = (fileName) => {
    this.setState({currentFileName: fileName});
  }

  onUpload = async (data) => {
    // alert(JSON.stringify(data));
    // let name = this.state.currentFileName;
    this.setState({currentFileName: ""});
    // data.name = name;
    if (typeof this.myRef !== "undefined") {
      this.myRef.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    }
    await this.props.onUpload(data.data);

  }
  onFileLanguageChange = (e, value, file) => {
    e.preventDefault();
    this.props.editFileLanguage(value.value.value, file);
  }

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Container>
          {this.renderRadioButtons()}
          {this.renderFileList()}
          {this.renderUpload()}
          <UploadText>
            {t("fileUpload2", "* So müssen die Rohdaten aussehen:")}
            <ul>
              <li>{t("fileUpload3", "Im Format .xls, .xlsx, .csv .tsv, .txt, .docx oder .zip")}</li>
              <li>{t("fileUpload4", "Spaltennamen stehen in der ersten Zeile")}</li>
              <li>{t("fileUpload5", "Alle zu bearbeitenden Daten stehen in einer Spalte")}</li>
              <li>{t("fileUpload6", "Bei Excel – alle zu bearbeitenden Daten in Tabelle 1/Sheet 1")}</li>
            </ul>
          </UploadText>
        </Container>
      </React.Fragment>

    );
  };

  renderFileList() {
    const {files} = this.props;
    files.map((file, index) => {
      let animated = this.state.["file" + file.id + "animated"];
      file.animated = animated;
      if (index === 0) {
        file.scrollOnMe = true;
      }
      return null;
    })
    return (
      <FileListContainer>
        {files.map((file, index) => {
          if (typeof file.justAdded !== 'undefined') return this.renderFile(file, index); else return null;
        })}
        {files.map((file, index) => {
          if (typeof file.justAdded === 'undefined' && ((this.state.showResultFiles && file.isResult) || (!this.state.showResultFiles && !file.isResult))) return this.renderFile(file, index); else return null;
        })}
      </FileListContainer>
    );
  };

  renderFile(file, index) {

    const {selected, changeSelected, errors, t, labelOnly, isResult} = this.props;

    const error = errors.find(error => error.id === file.id);
    const hasError = error !== undefined;
    let open = this.state.["file" + file.id + "visible"];

    let highlighted = file.highlighted;

    let checkRef = (ref) => {
      if (file.scrollOnMe === true) {
        this.myRef = ref;
      }
    }

    return (
      <div ref={checkRef} key={file.id} data-tut="step_eight">
        {file.animated &&
        <TransitionWrapper>
          <Transition
            animation="fly down"
            duration={1000}
            unmountOnHide={true}
            visible={open}
          >
            <FileContainer key={index} highlighted={typeof highlighted !== 'undefined'}>
              <NameAndMessageContainer>
                <RadioButton
                  id={`file-${index}`}
                  name={file.name}
                  label={this.renderFileRadioButtonLabel(file.name, hasError)}
                  value={file.id === selected}
                  onChange={() => changeSelected(file.id)}
                />
                <LanguageText>{t("fileUpload7", "Sprache einstellen")}: </LanguageText>
                <LanguagePicker
                  placeholder='Select...'
                  inline
                  search
                  select
                  options={this.state.languageOptions}
                  file={file}
                  value={file.language ? file.language : "fr"}
                  onChange={(e, value) => {
                    this.onFileLanguageChange(e, {value}, file)
                  }}
                />
                {hasError && <ErrorMessage>{error.message}</ErrorMessage>}
              </NameAndMessageContainer>

              <ActionButton color={grey70} onClick={() => this.openDeleteFileModal(file.id)} icon="delete_filled"
                            size={26}/>
            </FileContainer>
          </Transition>
        </TransitionWrapper>
        }
        {!file.animated &&
        <FileContainer key={file.id} highlighted={typeof highlighted !== 'undefined'}>
          <NameAndMessageContainer>
            <RadioButton
              id={`file-${index}`}
              name={file.name}
              label={this.renderFileRadioButtonLabel(file.name, hasError)}
              value={file.id === selected}
              onChange={() => changeSelected(file.id)}
              labelOnly={labelOnly}
            />
            <LanguageText>{t("fileUpload7", "Sprache einstellen")}: </LanguageText>
            <LanguagePicker
              placeholder='Select...'
              inline
              search
              select
              options={this.state.languageOptions}
              file={file}
              value={file.language ? file.language : "de"}
              onChange={(e, value) => {
                this.onFileLanguageChange(e, {value}, file)
              }}
            />
            {hasError && <ErrorMessage>{error.message}</ErrorMessage>}
          </NameAndMessageContainer>
          <IconsAtTheEnd>
            <ActionButton color={grey70} onClick={() => this.downloadFile(file.id, isResult)} icon="download"
                          size={26}/>
            <ActionButton color={grey70} onClick={() => this.openDeleteFileModal(file.id)} icon="delete_filled"
                          size={26}/>
          </IconsAtTheEnd>
        </FileContainer>
        }
      </div>
    );
  };

  renderFileRadioButtonLabel(name, error) {
    return (
      <NameContainer error={error}>
        <Icon name="file_filled"/>
        <Name>
          {name}
        </Name>
      </NameContainer>
    );
  }

  renderUpload() {
    const {uploadUrl, support, t} = this.props;
    let showDeleteModal = typeof this.state.deleteFileModalOpen !== "undefined" && this.state.deleteFileModalOpen === true;
    return (
      <UploadContainer support={support}>
        <UploadAction
          uploadUrl={uploadUrl}
          onChange={this.onUpload}
          updateState={this.updateState}
          onPickFile={this.onPickFile}
          language={this.props.language}
          disabled={this.props.disabled}
        >
          {this.state.currentFileName &&
          <ProgressBarContainer>
            <ProgressBar percent={this.state.percent}/>
          </ProgressBarContainer>
          }
          {!this.state.currentFileName && t("fileupload1", "Daten Hochladen")}
        </UploadAction>
        {showDeleteModal &&
        <DeleteFileModal
          onClose={() => this.closeDeleteFileModal()}
          onClick={() => this.submitDeleteFileModal()}
          error={this.state.deleteFileModalError}
        />
        }
      </UploadContainer>
    );
  };
}

export default withTranslation("translations")(FileUpload);
