import React, {useState, useEffect, useRef} from 'react';

import {
  darkblue,
  grey10,
  white,
  grey30,
} from '../../colors';

import {withTranslation} from "react-i18next";
import {get, post} from "../../security/Api";

import styled from '@emotion/styled';

// import {Button as PlatButton} from '../Button';
import Icon from '../Icon/Icon';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import SearchField from "../SearchField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {InfoPopupGreen} from "../InfoPopup.js";
// import errorFn from "../../error";
import TextField from '@mui/material/TextField';

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    min-height: 50px;
`;

const IconContainer = styled.div`
    position: relative;
    cursor: pointer;
    margin-left: 2px;
    padding-right: 3px;
    padding-left: 3px;
`;

const FilterBox = styled.div`
    display: flex;
    padding-top: 10px;
    margin-left: 10px;
    flex-direction: row-reverse;
`;

const ResultBlock = styled.div`
    width: 100%;
`;

const CommentsContainer = styled.div`
    background: ${grey10};
    padding: 2px;
    margin-top: 12px;
    border-radius: 4px;
    box-shadow: 6px 6px 25px rgb(0,20,50,0.05);
    position: relative;
`;

const CommentText = styled.div`
    background: ${grey30};
    padding: 8px;
    margin: 6px;
    border-radius: 4px;
    box-shadow: 6px 6px 25px rgb(0,20,50,0.05);
    font-size: 14px;
`;

const CommentLabelsContainer = styled.div`
    display: flex;
    // justify-content: space-between;
    align-items: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 85%;
    background: ${white};
    // padding: 4px;
    margin: 6px;
    border-radius: 4px;
    box-shadow: 6px 6px 25px rgb(0,20,50,0.05);
`;

const LabelText = styled.div`
    background: ${darkblue};
    padding: 5px;
    margin: 6px;
    border-radius: 4px;
    color: ${white};
    width: max-content;
    font-size: 12px;
`;

const LabelsContainer = styled.div`
      padding: 8px;
    `;

const LabelsWrapper = styled.div`
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    `;

const Labels = styled.div`
      background: ${white};
      font-size: 16px;
      padding: 4px;
      margin-right: 8px;
      border-radius: 4px;
      box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
      width: max-content;
      margin-bottom: 10px;
      cursor: pointer; 
    `;

const SearchBox = styled.div`
    display: flex;
    justify-content:  space-between;
    padding: 0 10px 0 0;
`;

function ResultTabComments(props) {

  const {studyId, themaCompletedResultData, t} = props;

  const [loading, setLoading] = useState(false);
  const [themaComentsCompletedResultData, setThemaComentsCompletedResultData] = useState([]);

  const [errorBar, setErrorBar] = useState(false);
  const [errorBarMessage, setErrorBarMessage] = useState(null);

  const [expandedComments, setExpandedComments] = useState();
  // const [commentsState, setCommentsState] = useState([]);

  const [filtered, setFiltered] = useState(false);

  const [sortedAlphabetically, setSortedAlphabetically] = useState(false);
  const [sortedByNumberOfComments, setSortedByNumberOfComments] = useState(false);

  const [filterValue, setFilterValue] = useState("");

  const [filteredComments, setFilteredComments] = useState([]);

  const [showUnlabeledOnly, setShowUnlabeledOnly] = useState(false);

  // const [labelText, setLabelText] = useState('');

  const textFieldRef = useRef();

  const ApiResult = useRef(null);

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filtered = themaComentsCompletedResultData.filter(comment =>
      comment.labels.some(label => label.name.toLowerCase().includes(filterValue.toLowerCase()))
    );
    setFilteredComments(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);


  const fetchComments = async () => {

    setLoading(true);

    const themaCommentsResponse = await get(
      `/study/${studyId}/themacomments`,
    );

    console.log(themaCommentsResponse);

    if (themaCommentsResponse.status === 200) {
      ApiResult.current = await themaCommentsResponse.json();
      // console.log("Success" + ApiResult.current);
      await setThemaComentsCompletedResultData(ApiResult.current[0].data.documents);
      setLoading(false);
    } else {
      ApiResult.current = await themaCommentsResponse.json();
      await setErrorBarMessage("status " + themaCommentsResponse.status + " " + themaCommentsResponse.current.message);
      await setErrorBar(true);
      setLoading(false);
    }
  }

  const updateFilterValue = (e) => {
    setFilterValue(e.target.value);
    // setIsFilterApplied(false);
  }

  const deleteFilterValue = () => {
    setFilterValue("");
    // setIsFilterApplied(false);
  }

  const startFiltering = () => {
    // setIsFilterApplied(true);
  }

  const filterUnfilter = () => {
    setFiltered(!filtered);
    setFilteredComments([]);
  }

  const showUnlabeledComments = () => {
    setShowUnlabeledOnly(!showUnlabeledOnly);
  }

  const sortThemaResult = () => {
    const data = [...themaComentsCompletedResultData]
    if (sortedAlphabetically) {
      const sorter = (a, b) => a.text.toLowerCase() < b.text.toLowerCase() ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaComentsCompletedResultData(sortedResult);
      setSortedAlphabetically(false);
    } else {
      const sorter = (a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      const sortedResult = data.sort(sorter);

      setThemaComentsCompletedResultData(sortedResult);
      setSortedAlphabetically(true);
    }
  }

  const sortByNumberOfComments = () => {
    const data = [...themaComentsCompletedResultData]
    if (sortedByNumberOfComments) {
      const sorter = (a, b) => a.labels.length < b.labels.length ? 1 : -1;
      const sortedResult = data.sort(sorter);

      setThemaComentsCompletedResultData(sortedResult);
      setSortedByNumberOfComments(false);
    } else {
      const sorter = (a, b) => a.labels.length > b.labels.length ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaComentsCompletedResultData(sortedResult);
      setSortedByNumberOfComments(true);
    }
  }

  function renderLoader() {
    return (
      <LoaderContainer>
        {!errorBar &&
        <CircularProgress/>
        }
      </LoaderContainer>
    )
  }

  async function addLabelToComment(commentIdsArray, labelId, commentIndex, current_name) {
    let newLabels = themaComentsCompletedResultData[commentIndex].labels.map((label) => {
      return label;
    });

    newLabels.push({
      id: labelId,
      name: current_name
    });

    let onError = async (code) => {
      return null;
    }

    let onSuccess = async (responseData) => {
      setThemaComentsCompletedResultData((prevData) =>
        prevData.map((comment, index) => {
          if (commentIndex === index) {
            return  {
              ...comment,
              labels: newLabels
            };
          }
          return comment;
        })
      );
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "PUT",
        path: '/thematic-analysis/label/updatecomments/' + studyId + '/',
        options: {
          docs_to_update: [commentIdsArray],
          deletelabelids: [],
          addlabelids: [labelId]
        }
      },
      onError,
      onSuccess
    )
  }

  const handleKeyPress = async (event, commentId) => {
    if (event.key === 'Enter') {
      const labelText = textFieldRef.current.value;

      let onError = async (code) => {
        return null;
      }

      let onSuccess = async (responseData) => {
        textFieldRef.current.value = ''
        // setThemaComentsCompletedResultData((prevData) =>
        //   prevData.map((comment, index) => {
        //     if (commentIndex === index) {
        //       return  {
        //         ...comment,
        //         labels: newLabels
        //       };
        //     }
        //     return comment;
        //   })
        // );
      }
      await post(
        '/study/thema/uniapi/',
        {
  
          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/addtopic/' + studyId + '/' + labelText + '/',
          options: {  
            comment_to_add: [[commentId]]
          },
        },
        onError,
        onSuccess
      )
    }
  };

  function renderCommentLabels(commentId, comment) {

    const filteredLabels = themaCompletedResultData.filter(item => !comment.labels.some(label => item.label_id === label.id));
    return (
      <LabelsContainer>
        {t("addFromCodebook001", "Aus Codebuch hinzufügen")}
        <LabelsWrapper>
          {filteredLabels.map((item, ind) => {
            return (
              <Labels
                onClick={() => addLabelToComment(comment.indices, item.label_id, commentId, item.current_name)}
                // onClick={() => addLabel(item.current_name, item.label_id)}
              >
                {item.current_name}
              </Labels>
            );
          })
          }
        </LabelsWrapper>
        <TextField
          label="Label"
          id="outlined-size-small"
          size="small"
          inputRef={textFieldRef}
          onKeyPress={(e) => handleKeyPress(e, commentId)}
          sx={{
            backgroundColor: '#FFFFFF', // Set your desired background color here
            width: 300,
          }}
        />
      </LabelsContainer>
    );
  }

  function renderComment(comment, index) {

    if ((showUnlabeledOnly && comment.labels.length !== 0) && !expandedComments === index) {
      return false;
    }

    const expandCollapseComment = (commentIndex) => {
      if (expandedComments === commentIndex) {
        setExpandedComments(null);
        return;
      }
      setExpandedComments(commentIndex);
    };

    const removeLabelByIndex = async (labelIndex) => {

      let newLabels = comment?.labels.map((label, index) => {
        if(index === labelIndex) return false;
        return label;
      })

      await deleteLabelFromCommentByClick(index, comment.indices, comment.labels[labelIndex].id);

      setThemaComentsCompletedResultData((prevData) =>
        prevData.map((comment, commentIndex) => {
          if (commentIndex === index) {

            return  {
              ...comment,
              labels: newLabels
            };
            // comment.labels.splice( labelIndex, 1 );
            // const updatedLabels = comment.labels.filter(
            //   (label, ind) => ind !== labelIndex
            // );
            // console.log(updatedLabels);
            // comment.labels = updatedLabels;
          }
          return comment;
        })
      );
      // setCommentsState((prevState) => {
      //   // Check if the comment already exists
      //   const existingComment = prevState.find(
      //     (comment) => comment.commentId === index
      //   );
      //
      //   if (existingComment) {
      //     // If the comment exists, update it
      //
      //     let labelIsAlreadyAdded = existingComment.addLabel && existingComment.addLabel.find(
      //       (label) => label === labelIndex
      //     );
      //     if (labelIsAlreadyAdded) {
      //       return prevState.map(
      //         (comment) =>
      //           comment.commentId === index && {
      //             ...comment,
      //             addLabel: comment.addLabel.filter(
      //               (value) => value !== labelIndex
      //             ),
      //           }
      //       );
      //     }
      //     return prevState.map((comment) =>
      //       comment.commentId === index
      //         ? {
      //           ...comment,
      //           deleteLabel: comment.deleteLabel
      //             ? [...comment.deleteLabel, labelIndex]
      //             : [labelIndex],
      //         }
      //         : comment
      //     );
      //   } else {
      //     // If the comment doesn't exist, add a new comment
      //     return [
      //       ...prevState,
      //       { commentId: index, deleteLabel: [labelIndex] },
      //     ];
      //   }
      // });
    };

    return (
      <CommentsContainer>
        <CommentText>{comment.text}</CommentText>
        <CommentLabelsContainer>
          <LabelWrapper>
            {comment.labels?.length > 0 &&
            comment.labels.map((label, labelIndex) => {
              return (
                <>
                  <LabelText>{label.name}
                    <IconButton
                      aria-label="close"
                      color="primary"
                      size="small"
                      onClick={() => {
                        removeLabelByIndex(labelIndex);
                      }}
                    >
                      <Icon size={18} name="close"/>
                    </IconButton>
                  </LabelText>
                </>
              );
            })}
            <IconButton
              aria-label="expand/collapse"
              color="primary"
              size="small"
              onClick={() => expandCollapseComment(index)}
            >
              <Icon size={18} name={expandedComments === index ? "remove" : "add"}/>
            </IconButton>
          </LabelWrapper>
          {/*<PlatButton*/}
          {/*  minWidth*/}
          {/*  width="20%"*/}
          {/*  tiny={true}*/}
          {/*  type="button"*/}
          {/*  mainbutton*/}
          {/*  texttransformnone*/}
          {/*  data-cy="send-options"*/}
          {/*  onClick={() => updateLabelIds(index)}*/}
          {/*>*/}
          {/*  {t("confirm002", "Bestätigen")}*/}
          {/*</PlatButton>*/}
        </CommentLabelsContainer>
       
        {expandedComments === index && renderCommentLabels(index, comment)}
      </CommentsContainer>
    );
  }

  const deleteLabelFromCommentByClick = async (comment_index, comment_indeces, labelPythonId) => {
    let onError = async (code) => {
      // setErrorBar(true);
      // setErrorBarMessage(errorFn(code, props.t));
      // setDeleteCommentWaitingResponse(false);
    }

    let onSuccess = async (responseData) => {
      // await deleteCommentFromMainResults(label_id, comment_id_array);
      // setDeleteCommentWaitingResponse(false);
    }

    await post(
      '/study/thema/uniapi/',
      {
        studyId: studyId,
        method: "PUT",
        path: '/thematic-analysis/label/deletecomment/' + studyId + '/' + labelPythonId + '/',
        options: {
          'docs_to_delete': [comment_indeces],
        },
      },
      onError,
      onSuccess
    )
  }

  // const addLabelFromCommentByClick = async (comment_index, comment_indeces, labelPythonId) => {
  //   let onError = async (code) => {
  //     // setErrorBar(true);
  //     // setErrorBarMessage(errorFn(code, props.t));
  //     // setDeleteCommentWaitingResponse(false);
  //   }
  //
  //   let onSuccess = async (responseData) => {
  //     // await deleteCommentFromMainResults(label_id, comment_id_array);
  //     // setDeleteCommentWaitingResponse(false);
  //   }
  //
  //   await post(
  //     '/study/thema/uniapi/',
  //     {
  //       studyId: studyId,
  //       method: "PUT",
  //       path: '/thematic-analysis/label/deletecomment/' + studyId + '/' + labelPythonId + '/',
  //       options: {
  //         'docs_to_delete': [comment_indeces],
  //       },
  //     },
  //     onError,
  //     onSuccess
  //   )
  // }

  return (
    <>
      <Collapse in={errorBar && errorBarMessage}>
        <Alert
          sx={{background: "pink"}}
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorBar(false);
                setErrorBarMessage(null);
              }}
            >
              <Icon size={18} name="close"/>
            </IconButton>
          }
        >
          Error message: {errorBarMessage}
        </Alert>
      </Collapse>

      <ResultBlock>
        <SearchBox>
          <FilterBox>
            {filtered &&
            <SearchField
              small
              noMagnifier
              placeholder={"filter"}
              searchingValue={filterValue}
              updateSearchingValue={updateFilterValue}
              deleteSearchingValue={deleteFilterValue}
              startSearching={startFiltering}
            />
            }
          </FilterBox>
          <FilterBox>
            <IconContainer>
              <Icon
                name={filtered ? "unfilter" : "filter"}
                size={26}
                onClick={filterUnfilter}
              />
            </IconContainer>
            <IconContainer>
              <Icon
                name={sortedAlphabetically ? "sort_az" : "sort_za"}
                size={24}
                onClick={sortThemaResult}
              />
            </IconContainer>
            <IconContainer>
              <Icon
                name={
                  sortedByNumberOfComments ? "sort_num_down" : "sort_num_up"
                }
                size={24}
                onClick={sortByNumberOfComments}
              />
            </IconContainer>
            <InfoPopupGreen
              content={t('tipuser', "Steuern Sie den Zugriff und Sichtbarkeit Ihrer Daten, Analysen und Ergebnisse für dieses Projekt.")}
            />
          </FilterBox>
        </SearchBox>
        <FilterBox>
          <FormControlLabel control={
            <Switch
              size="small"
              defaultChecked
              checked={showUnlabeledOnly}
              onChange={showUnlabeledComments}
            />} label={t("showUnlabeled", "Nicht eingeordnete Kommentare anzeigen")}/>
        </FilterBox>
        {loading && renderLoader()}
        {/*{filteredComments.length !== 0 && !loading &&*/}
        {/*filteredComments?.map(renderComment)*/}
        {/*}*/}
        {filteredComments.length === 0 && themaComentsCompletedResultData.length !== 0 && !loading &&
        themaComentsCompletedResultData?.map(renderComment)}
      </ResultBlock>
    </>
  );
}

export default withTranslation("translations")(ResultTabComments);
