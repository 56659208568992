import React from 'react';
import { darkblue } from '../colors';
import styled from '@emotion/styled';
import Modal from './Modal';
import { Button } from './Button';
import Error from './Error';
import { withTranslation } from "react-i18next";

const Headline = styled.div`
    color: ${darkblue};
    font-size: 32px;
    margin-bottom: 28px;
    text-align: left;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-item: center;
    margin-top: 25px;
`;

const MessageContainer = styled.div`
    color: ${darkblue};
    margin-bottom: 28px;
    text-align: left;
`;

class DeleteUserModal extends React.PureComponent {

    render() {
        const { onClick, onClose, error,t } = this.props;

        return (
            <Modal onClose={onClose}>
                <Headline>
                    {t("deleteusermodel1", "Account löschen")}
                </Headline>
                <MessageContainer>
                    {t("deleteusermodel2", "Hiermit wird das Account und Team, sowie als alle Projekten, Studien, Dateien und Ergebnisse unwiderruflich gelöscht. Wollen Sie das wirklich?")}
                </MessageContainer>
                {error &&
                    <Error>
                        {error}
                    </Error>
                }
                <Footer>
                    <Button data-cy="abort-modal" onClick={() => onClose()}>{t("deleteusermodel3", "Abbrechen")}</Button>
                    <Button delete={true} data-cy="submit-modal" onClick={() => onClick()}>{t("deleteusermodel4", "Löschen")}</Button>
                </Footer>
            </Modal>
        );
    }
}

export default withTranslation("translations")(DeleteUserModal);
