import React from 'react';

import { post } from '../security/Api';
import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import Error from '../components/Error';
import { Text } from './PasswordRecoverySuccess';
import Page from '../components/Page';
import { grey5 } from '../colors';
import Link from '../components/Link';
import Curtain from '../components/Curtain';
import { withTranslation } from "react-i18next";

class DoubleOptIn extends React.PureComponent {
    state = {
        loaded: false,
        error: false,
        loadingError: false
    };

    async componentDidMount() {
        const token = this.props.match.params.token;
        let onError = (code) => {
            this.setState({
                loadingError: true,
                loaded: true,
            });
        }
        let onSuccess = () => {
            this.setState({
                loadingError: false,
                loaded: true,
            });
        }
        await post(
            '/user/activate',
            { token: token },
            onError,
            onSuccess
        );        
    }

    render() {
        const { t } = this.props;
        const { loaded, loadingError} = this.state;
        if (loaded === false) {
            return (
                <Curtain type="loading"/>
            );
        }
        if (loadingError === true) {
            return (
                <Curtain type="error"/>
            );
        }

        return (
            <Page background={grey5}>
                <PageContent>

                    <Headline>{t('double1',"E-Mail Verifizierung")}</Headline>

                    <Text>
                        {this.renderBody()}
                    </Text>

                </PageContent>
            </Page>
        );
    }

    renderBody() {
        const { loading, error } = this.state;
        const { t } = this.props;

        if (loading) {
            return <div>{t('double2',"Die Aktivierung ist im gange. Einen Moment bitte...")}</div>;
        }

        if (error) {
            return <Error>{t('double3',"Der Aktivierungslink ist leider ungültig.")}</Error>;
        }

        return (
            <React.Fragment>
                <p>{t('double4',"Herzlichen Glückwunsch!")}</p>
                <p>{t('double5',"Ihre E-Mail wurde erfolgreich bestätigt.")}</p>
                <p>{t('double6',"Sie können sich nun ")}<Link to={`/login`}>{t('double7',"einloggen")}</Link>.</p>
            </React.Fragment>
        );
    }
}

export default withTranslation("translations")(DoubleOptIn);
