import { grey10 } from '../colors';
import styled from '@emotion/styled';

export const Divider = styled.hr`
  height: ${props => props.height ? props.height + "px" : "3px"};
  border-width: 0;
  color: ${grey10};
  background-color: ${grey10};
  margin-top: 0;
  margin-bottom: 16px;

`;