import React from 'react';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import {CardContainer} from '../components/CardList';
import CardBody from '../components/CardBody';
import {withRouter} from 'react-router-dom';
import styled from '@emotion/styled';
import CostNumberElement from '../components/CostNumberElement';
import Headline from '../components/Headline';
import {darkblue, grey70, orange1, orange2} from '../colors';
import Select from '../components/Select';
import {Button} from '../components/Button';
import Page from '../components/Page';
import Curtain from '../components/Curtain';
import {withTranslation} from "react-i18next";
import error from "../error";
import Alert from '../components/Alert';
import DeleteOrganisationModal from '../components/DeleteOrganisationModal';
import {isDeepsightAdmin} from '../security/Role';
import Guard from '../components/Guard';
import Icon from '../components/Icon/Icon';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flow-direction: row;
    align-items: center;
    padding: 20px 0;
`;

export const TableHeader = styled.div`
    padding: 25px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    color: ${darkblue};
    line-height: 30px;
`;

export const CreditsInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
`;

const Box = styled.div`
    background: ${grey70};
    padding: 70px;
    margin: 20px 0;
    border-radius: 10px;
`;

const RemoveContainer = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: ${orange1};
    cursor: pointer;
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-transform: uppercase;
    &:hover {
        color: ${orange2};
    }
`;

const RemoveIcon = styled.div`
    margin-top: 6px;
    margin-right: 6px;
`;


export const OrganisationInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

class OrganisationDetail extends React.PureComponent {
  state = {
    costNumberId: null,
    formData: {
      packageId: null,
      methodName: null,
    },
    formErrors: {},
    organisation: null,
    organisationId: null,
    packages: [],
    loaded: false,
    loadingError: false,
    error: false,
    errorMessage: null,
    deleteOrganisationModalOpen: false,
    deleteOrganisationModalError: null,
    selectedOrganisation: null,
    method: null,
    rowsByMethod: [],
    methodList:
      [
        {
          name: 'Anonimization',
          symfonyName: 'Ano',
        },
        {
          name: 'Sanity Check',
          symfonyName: 'Sanity',
        },
        {
          name: 'Thematic Analysis',
          symfonyName: 'Thema',
        }
      ]
  };

  componentDidMount() {
    this.loadOrganisation();
    this.loadPackages();
    this.loadCostNumbersRowsByMethod();
  }

  async loadOrganisation() {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let organisationId = params.get('organisation');
    this.setState({organisationId: organisationId});

    const response = await get(`/organisation/detail?organisationId=${organisationId}`);

    if (response.status === 200) {

      const responseData = await response.json();

      this.setState({
        loaded: true,
        organisation: responseData,
      });

      return;
    }

    this.setState({
      loaded: true,
      loadingError: true,
    });
  }

  async loadPackages() {
    const response = await get(`/package/list`);

    if (response.status === 200) {
      const responseData = await response.json();

      this.setState({
        packages: responseData,
      });
      return;
    }
    this.setState({
      loadingError: true,
    });
  }

  async loadCostNumbersRowsByMethod() {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let organisationId = params.get('organisation');
    const response = await get(`/organisation/rowsleftbymethod?organisationId=${organisationId}`);
    if (response.status === 200) {
      const responseData = await response.json();
      this.setState({
        rowsByMethod: responseData,
      });
    }
  }

  onPackageChange = (value) => {
    const {formData} = this.state;

    this.setState({
      formData: {
        ...formData,
        packageId: value,
      },
    });
  };

  onMethodChange = (value) => {

    const {formData} = this.state;

    if (value === null) {
      this.setState({
        formData: {
          ...formData,
          packageId: null,
          methodName: null,

        },
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          packageId: null,
          methodName: value,
        },
      });
    }
  };

  onCostNumberClick = (value) => {
    const {costNumberId} = this.state;

    if (value === costNumberId) {
      this.setState({
        costNumberId: null,
      });

      return;
    }

    this.setState({
      costNumberId: value,
    });
  };

  removeCredits = async (costNumberId) => {
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
    let onSuccess = () => {
      this.setState({
        error: false,
        errorMessage: null,
        costNumberId: null,
        formData: {
          packageId: null,
          methodName: null,
        },
      });

      this.loadOrganisation();
    }
    await post(
      '/credits/remove',
      {
        costNumberId: costNumberId,
        organisationId: this.state.organisationId
      },
      onError,
      onSuccess
    );
    // if (response.status === 400) {
    //     const responseData = await response.json();
    //     const errors = {};
    //
    //     console.log(responseData);
    //
    //     Object.entries(responseData.data).forEach((entry) => {
    //         const error = entry[1];
    //         errors[error.property] = error.message;
    //     });
    //
    //     this.setState({
    //         errors: errors,
    //     });
    //
    //     return;
    // }
    //
    // if (response.status === 422) {
    //     const responseData = await response.json();
    //     const errors = {};
    //
    //     console.log(responseData);
    //     console.log(formData);
    //
    //     Object.entries(responseData.data).forEach((entry) => {
    //         const error = entry[1];
    //         errors[error.property] = error.message;
    //     });
    //
    //     this.setState({
    //         formErrors: errors,
    //     });
    //
    //     return;
    // }


  };

  removeCostNumber = async (costNumberId) => {
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
    let onSuccess = () => {
      this.setState({
        error: false,
        errorMessage: null,
        costNumberId: null,
        formData: {
          packageId: null,
          methodName: null,
        },
      });

      this.loadOrganisation();
    }
    await post(
      '/costnumber/remove',
      {
        costNumberId: costNumberId,
        organisationId: this.state.organisationId
      },
      onError,
      onSuccess
    );
  };

  onSubmit = async () => {
    const {formData, costNumberId} = this.state;

    //const organisationId = match.params.organisationId;

    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
    let onSuccess = () => {
      this.setState({
        error: false,
        errorMessage: null,
        costNumberId: null,
        formData: {
          packageId: null,
          methodName: null,
        },
      });

      this.loadOrganisation();
    }

    await post(
      '/credits/buy',
      {
        costNumberId: costNumberId,
        organisationId: this.state.organisationId,
        packageId: formData.packageId,
        methodName: formData.methodName,
      },
      onError,
      onSuccess
    );
  };

  getOptionsFromPackages = () => {

    const {packages} = this.state;

    function filterItems(arr, query = "Nothing") {
      return arr.filter((el) => el.methodName.toLowerCase().includes(query.toLowerCase()));
    }

    let filteredArray = (filterItems(packages, this.state.formData.methodName !== null ? this.state.formData.methodName : "nothing"));

    return filteredArray.map((packageObject) => {
      return {
        value: packageObject.id,
        label: `${packageObject.price} Euro Paket (${packageObject.credits} Rows)`,
      };
    });
  };

  getOptionsFromMethods = () => {
    const {methodList} = this.state;

    return methodList.map((methodObject) => {
      return {
        value: methodObject.symfonyName,
        label: `${methodObject.name}`,
      };
    });
  };

  onOrganisationDelete = async () => {
    const {history} = this.props;
    const {t} = this.props;
    // const organisation = this.state.selectedOrganisation;
    let onError = (code) => {
      this.setState({
        deleteOrganisationModalError: t("organisationnodeletealert", 'Die Organisation kann aktuell nicht gelöscht werden.'),
      });
    }
    let onSuccess = async () => {
      await this.setState({
        deleteOrganisationModalError: null,
        deleteOrganisationModalOpen: false,
      });
      await history.push(`/organisation/list`);
    }

    await post(
      '/organisation/remove',
      {
        organisationId: this.state.organisationId, // organisation.id,
      },
      onError,
      onSuccess
    );

  };

  handleOrganisationDelete(organisation) {
    this.setState({deleteOrganisationModalOpen: true, selectedOrganisation: organisation});
  }

  renderCostNumber(organisation) {
    const {t} = this.props;
    return (
      <CardBody>
        {organisation.costNumbers.length > 0 && (
          <TableHeader>
            {t('organisationdetail2', "Kostennummer")}
            <CreditsInfo>
              <div>Rows</div>
              <div>&nbsp;</div>
            </CreditsInfo>
          </TableHeader>
        )}

        {organisation.costNumbers.length === 0 &&
        <div>{t("organisationdetail4", "Keine Kostennummern vorhanden")}</div>}

        {organisation.costNumbers.map(this.renderCostNumberItem)}

      </CardBody>
    );
  }

  renderCostNumberItem = (costNumber) => {
    const {costNumberId} = this.state;
    const {t} = this.props;

    return (
      <React.Fragment key={costNumber.id}>
        <CostNumberElement
          id={costNumber.name}
          costNumberId={costNumber.id}
          name={costNumber.name.length ? costNumber.name : "_" + t("projectForm12", "default")}
          credits={costNumber.credits}
          onClick={() => this.onCostNumberClick(costNumber.id)}
          onRemoveCredits={() => this.removeCredits(costNumber.id)}
          onRemoveCostNumber={() => this.removeCostNumber(costNumber.id)}
          rowsByMethod={this.state.rowsByMethod}
        />

        {costNumberId === costNumber.id && this.renderChargeCredit()}
      </React.Fragment>
    );
  };

  renderChargeCredit() {
    const {formData} = this.state;
    const {t} = this.props;

    return (
      <Box data-cy="select-box">
        <Select
          id="select-method-input"
          placeHolder="Methode auswählen"
          options={this.getOptionsFromMethods()}
          value={formData.methodName}
          onChange={this.onMethodChange}
        />
        <Select
          id="select-package-input"
          placeHolder="Paket auswählen"
          options={this.getOptionsFromPackages()}
          value={formData.packageId}
          onChange={this.onPackageChange}
        />
        <Button disabled={!this.state.formData.packageId} mainbutton id="submit" onClick={this.onSubmit}
                data-cy="submit">{t('organisationdetail3', "Aufladen")}</Button>
      </Box>
    );
  }

  render() {
    const {organisation, loaded, loadingError, deleteOrganisationModalOpen, deleteOrganisationModalError} = this.state;
    const {t} = this.props;

    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="error"/>
      );
    }

    return (
      <Page>

        {deleteOrganisationModalOpen &&
        <DeleteOrganisationModal
          onClose={() => this.setState({deleteOrganisationModalOpen: false, deleteOrganisationModalError: null})}
          onClick={() => this.onOrganisationDelete()}
          error={deleteOrganisationModalError}
        />
        }
        <PageContent>
          <OrganisationInfo>
            <Headline>
              {organisation.name}
            </Headline>

            <Guard secure={isDeepsightAdmin}>
              <RemoveContainer onClick={() => this.handleOrganisationDelete(organisation)}>
                <RemoveIcon>
                  <Icon size={24} name="delete_outline"/>
                </RemoveIcon>
                {t("organisationdelete", "Organisation löschen")}
              </RemoveContainer>
            </Guard>
          </OrganisationInfo>

          <CardContainer>
            {this.renderCostNumber(organisation)}
          </CardContainer>

        </PageContent>
        {this.state.error &&
        <Alert
          type="error"
          message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
          onClose={() => this.setState({error: false})}
        />
        }
        {this.state.info && this.state.infoMessage &&
        <Alert
          type="info"
          message={this.state.infoMessage}
          onClose={() => this.setState({info: false})}
        />
        }
      </Page>

    );
  }

}

export default withRouter(withTranslation("translations")(OrganisationDetail));
