import React from 'react';
import styled from '@emotion/styled';
import { Link as BaseLink } from 'react-router-dom';
import { blue, bluedarker, darkblue, red, grey20 } from '../colors';
import Icon from './Icon/Icon';

const ContainerLink = styled(BaseLink)`
    display: flex;
    color: ${blue};
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    padding: 20px 0;
    font-size: 16px;
    align-items: center;

    &:hover {
        color: ${bluedarker};
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const ActionButtonContainerInProjectList = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContainerButton = styled.button`
    display: flex;
    color: ${props => props.disabled ? grey20  : props.notActive ? darkblue : props.color ? props.color : blue};
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    padding: ${props => props.leftPadding ? '20px 0 20px 25px' : '20px 0'};
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    border: 0;
    background: transparent;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    appearance: none;
    align-items: center;
    ${props => props.big && 'width: 100%; justify-content: center;'}

    &:hover {
        color: ${props => props.danger ? red : props.disabled ? grey20  : bluedarker};
    }

    &:focus {
        outline: 0;
    }
`;

const Text = styled.div`
    margin-left: 10px;
    margin-bottom: -4px;
`;

export class ActionLink extends React.PureComponent {
    render() {
        const { id, children, icon, to } = this.props;

        return (
            <ContainerLink data-cy={id} to={to}>
                <Icon name={icon} size={22} />
                <Text>{children}</Text>
            </ContainerLink>
        );
    }
}

export class ActionButton extends React.PureComponent {
    render() {
        const { id, children, icon, onClick, leftPadding, big , notActive, disabled, danger, color, size = 32} = this.props;

        return (
            <ContainerButton
                data-cy={id}
                leftPadding={leftPadding}
                big={big}
                notActive={notActive}
                disabled={disabled}
                color={color}
                danger={danger}
                onClick={(event) => {
                    if(disabled) return false;
                    event.preventDefault();
                    onClick(event);
                }}
                type="button"
            >
                {icon && <Icon name={icon} size={size} />}
                <Text>{children}</Text>
            </ContainerButton>
        );
    }
}
