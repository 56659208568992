// import React, {Component, useEffect, useState} from 'react';
import React, {useState} from 'react';
import styled from '@emotion/styled';
// import publicIp from "public-ip";

import {post} from '../security/Api';
import {Button} from '../components/ButtonReg';
import CardBody, {CardBodyInner} from '../components/CardBody';
// import {isDeepsightAdmin} from '../security/Role';
import {white} from '../colors';

// import error from "../error";

import {withTranslation} from "react-i18next";
import TextAreaInput from "../components/TextAreaInput";

import {Formik, Field} from 'formik';
import * as Yup from "yup";
import {Form} from '../components/FormReg';
// import ErrorMessage from "../components/Error";
import SuccessMessage from "../components/SuccessMessageNew";
import ErrorMessage from "../components/Error";
import TextInputLiveFeedback from "../components/TextInputLiveFeedback";
// import {withRouter} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export const LoginButtonContainer = styled.div`
    margin-top: 20px;
`;
const CheckboxHider = styled.div`
    visibility: hidden;
`;
const LoaderContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-left: 95px;
`;

function ContactSupportForm(props) {

  const {t, buying} = props;
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState(null);
  // const [phone, setPhone] = useState('');
  // const [subject, setSubject] = useState('');
  // const [message, setMessage] = useState('');
  // const [ip, setIp] = useState('');

  const [sendingInProcess, setSendingInProcess] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);

  const onSignUpSuccess = () => {
    setError(false);
    // setErrorMessage(null);
    setSuccess(true);
    setSendingInProcess(false);
    // this.props.history.push('/contactus');
  }

  const onSignUpError = async (code, setFieldError) => {
    if (code === 492) { //the email is in use
      await setFieldError('email', error(code, props.t));
    } else {
      setError(true);
      // setErrorMessage(error(code, props.t));
      setSuccess(false);
      setSendingInProcess(false);
    }
    setSendingInProcess(false);
  }


  const SignupSchema = Yup.object().shape({
    name: Yup
      .string(t("contactUs10", "Ungültige Angabe Name"))
      .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
    email: Yup
      .string(t("registr34", "Ungültige Angabe E-Mail"))
      .email(t("registr35", "Ungültige Angabe E-Mail"))
      .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
    phone: Yup
      .string(t("contactUs11", "Ungültige Angabe Name")),
    message: Yup
      .string(t("contactUs12", "Ungültige Angabe Nachricht"))
      .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
    buying: Yup
      .boolean(t("contactUs1111", "Ungültige111"))
      .required(t("registr366", "qqqqq")),
  })

  return (
      <CardBody
        marginBottom
        background={white}>

        {success &&
        <SuccessMessage>
          {t('contactUsSuccess', "Ihre Nachricht wurde an den deepsight Admin geschickt.")}
        </SuccessMessage>
        }
        {error &&
        <ErrorMessage>
          {t('contactUsError', "Es ist ein Problem aufgetreten, bitte versuchen Sie es später erneut.")}
        </ErrorMessage>
        }
        <CardBodyInner>
          <h3>{t('contactUs5', "Du brauchst eine API Anbindung oder hast eine individuelle Anfrage? Schreib uns hier über dein Anliegen oder rufe uns direkt an")}</h3>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              message: '',
              buying: buying,
            }}
            validationSchema={SignupSchema}
            validateOnBlur
            onSubmit={async (values, {setFieldError}) => {
              values.locale = props.i18n.language;
              setSendingInProcess(true);
              await post(
                '/contact/support/manually',
                values,
                (code) => onSignUpError(code, setFieldError),
                onSignUpSuccess
              );
            }}

          >
            <Form>
              <TextInputLiveFeedback
                bordered
                id="name"
                name="name"
                type="text"
                label={t('contactUs6', "Name *")}
              />
              <TextInputLiveFeedback
                bordered
                id="email"
                name="email"
                type="text"
                label={t('contactUs7', "E-Mail *")}
              />
              <TextInputLiveFeedback
                bordered
                id="phone"
                name="phone"
                type="text"
                label={t('contactUs8', "Telefon")}
              />

              <TextAreaInput
                bordered
                id="message"
                name="message"
                type="text"
                label={t('contactUs5', "Deine Nachricht *")}
                rows={8}
              />
              <CheckboxHider>
                <Field
                  type="checkbox" id="buying" name="buying" value={buying} />
              </CheckboxHider>

              <LoginButtonContainer>
                {sendingInProcess &&
                <LoaderContainer>
                  <CircularProgress/>
                </LoaderContainer>
                }

                {!sendingInProcess &&
                <Button
                  texttransformnone
                  minwidth
                  tiny
                  rounded
                  data-cy="register-submit"
                  icon={'mail_open'}
                  iconsize={20}
                  id="submit"
                  type="submit">
                  {t('contactUs4', "Nachricht senden")}
                </Button>
                }
              </LoginButtonContainer>
            </Form>
          </Formik>
        </CardBodyInner>
      </CardBody>
  );
}

export default withTranslation("translations")(ContactSupportForm);
