import React from 'react';
import styled from '@emotion/styled';
import { darkblue } from '../colors';
import Modal from './Modal';
import { Button } from './Button';
import { withTranslation } from "react-i18next";

const Headline = styled.div`
  color: ${darkblue};
  font-size: 32px;
  margin-bottom: 28px;
  text-align: left;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const MessageContainer = styled.div`
  color: ${darkblue};
  margin-bottom: 28px;
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`;

class StudyMissingItemsModal extends React.PureComponent {
  render() {
    const { error, onClose, headline, t } = this.props;

    return (
      <Modal onClose={onClose}>
        <Headline>
          {headline}
        </Headline>
        <MessageContainer>
          {error}
        </MessageContainer>
        <Footer>
          <Button data-cy="submit-modal" icon="main_arrow" onClick={() => onClose()}>{t("studymissing1", "Schließen")}</Button>
        </Footer>
      </Modal>
    );
  }
}

export default withTranslation("translations")(StudyMissingItemsModal);
