import React from "react";
import styled from "@emotion/styled";
import { grey10, grey5, white } from '../colors';

const CardBodyContainer = styled.div`

    padding: 35px;

    background: ${props => props.background ? props.background : grey5};

    ${props => props.withGradient ?
        `background: transparent linear-gradient(38deg, ${grey10} 0%, ${white} 100%) 0% 0% no-repeat padding-box;` :
        `background: ${props => props.background ? props.background : grey5 };`};

    border-radius: 0 0 10px 10px;

    ${props => !props.noTopPadding && !props.noBottomPadding && 'padding: 35px 42px;'};
    ${props => props.noTopPadding && !props.noBottomPadding && 'padding: 0px 42px 35px 42px;'};
    ${props => !props.noTopPadding && props.noBottomPadding && 'padding: 35px 42px 35px 42px;'};

    ${props => props.noTopPadding && props.noBottomPadding && 'padding: 0 42px;'};
    ${props => props.addBottomPadding && props.addBottomPadding && 'padding-bottom: 70px;'};
    
    ${props => props.noBottomPadding && !props.noTopPadding &&`
      >:last-child {
          margin-top: -11px;
      }
    `};

    ${props => props.noBottomPadding && props.noTopPadding &&`
      >:last-child {
          margin-top: -11px;
      }
    `}; 
`;

export const CardBodyInner = styled.div`
  padding: 25px;
  padding: ${props => props.noTopPadding ? '0px 25px 25px 25px' : '25px'};
  ${props => props.anonym ? 'width: 50%;' : ''};
  ${props => props.home ? 'width: 30%;' : ''};
`;

const InnerContainer = styled.div`
  display: flex;
  ${props => !props.anonym ? 'justify-content: space-between;' : ''};

`;

const CardBreak = styled.hr`
  height: 3px;
  border-width: 0;
  color: ${grey10};
  background-color: ${grey10};
  margin-top: 0;
  margin-bottom: 28px;
`;

export default class CardBody extends React.PureComponent {
  render() {
    const { children, background, withGradient, noTopPadding, noBottomPadding, addBottomPadding } = this.props;

    return (
      <CardBodyContainer background={background} withGradient={withGradient} noTopPadding={noTopPadding} noBottomPadding={noBottomPadding} addBottomPadding={addBottomPadding}>
        {children}
      </CardBodyContainer>
    );
  }
}

export class CardBodyInnerContainer extends React.PureComponent {
  render() {
    const { children, anonym } = this.props;

    return (
      <InnerContainer anonym={anonym}>
        {children}
      </InnerContainer>
    );
  }
}

export class CardBreakLine extends React.PureComponent {
  render() {
    return (
      <CardBreak />
    );
  }
}
