import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import styled from '@emotion/styled';
// import { hotjar } from 'react-hotjar';

import Header from './components/Header/Header';

// import Home from './pages/Home';
import NotFound from './pages/NotFound';

import Activity from './pages/Activity';
import Documentation from './pages/Documentation';

import Login from './pages/Login';
import ContactUs from './pages/ContactUs';

import Registration from './pages/Registration';
import DoubleOptIn from './pages/DoubleOptIn';
import RegistrationSuccess from './pages/RegistrationSuccess';

import PasswordRecovery from './pages/PasswordRecovery';
import PasswordReset from './pages/PasswordReset';
import PasswordRecoverySuccess from './pages/PasswordRecoverySuccess';

import AdminAcceptInvitation from './pages/AdminAcceptInvitation';

import ProjectList from './pages/ProjectList';
import ProjectDetail from './pages/ProjectDetail';
import ProjectArchivedList from './pages/ProjectArchivedList';

import CostNumberCreate from './pages/CostNumberCreate';
import BuyCredits from './pages/BuyCredits';

import Study from './pages/Study';
import StudyDetail from './pages/StudyDetail';
import withRole from './security/withRole';
import StudyResults from './pages/StudyResults';
import Account from './pages/Account';

import OrganisationList from './pages/OrganisationList';
import OrganisationDetail from './pages/OrganisationDetail';
import OrganisationFiles from './pages/FileList';

import FooterNew from './components/Footer/FooterNew';

import AGB from './pages/AGB';
import Impressum from './pages/Impressum';
import DataProtection from './pages/DataProtection';

import SupportContact from './pages/SupportContact';

import {post} from "./security/Api";
// import {getCurrentHub} from "@sentry/browser";

const ADMIN = 'ROLE_DEEPSIGHT_ADMIN';
const USER = 'ROLE_ADMIN';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

// const WideWizardWindow = styled.div`
//   min-width: 500px;
// `;

const BoldHeader = styled.div`
    font-weight: bolder;
`;

const BlueItalic = styled.div`
    font-style: italic;
    color: blue;
`;

const Text = styled.div`
    margin-top: 5px;
`;

function App() {

  // const [show, setShow] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [light, setLight] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isProjectSubmitted, setIsProjectSubmitted] = useState(false);
  const [userFirstName, setUserFirstName] = useState("Friend");

  const tourConfig = [
    {
      selector: '[data-tut="step_one"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            &#128640; <BoldHeader sx={{fontWeight: "bolder"}}>Step 1: Create Project</BoldHeader>
            <p>
              Great choice, {userFirstName}! Creating a project is the foundation of your
              DataDesk journey. It's like setting up your workspace.
            </p>
            <p>
              Click on "Create Project" to get started. This step is essential for
              organizing and managing your data effectively. Don't worry,
              we're with you every step of the way!
            </p>
            <p>
              Once you've created your project, we'll move on to the next
              exciting step together. Ready for more? Let's go!
            </p>
          </Text>
        </div>
      ),
    },
    {
      selector: '[data-tut="step_one_two"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Project Name</BoldHeader>
            <p>
              Welcome to your first project, {userFirstName}! Give it a unique name or stick with the default
              "My first Data Desk project." Type your preferred name, and click "Got it" when you're ready.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_one_three"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Choose user</BoldHeader>
            <p>
              Now, let's assign the project to a user. For now, it's just you, {userFirstName}!
              Click the dropdown to see how it works. Later, you can add more users.
              The "+" icon will come in handy, but it's not clickable for this tour.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_one_four"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Cost number</BoldHeader>
            <p>
              Select a cost number for accounting purposes. Each project can be linked to different cost numbers. For
              now, there's one default
              number: "my_first_datadesk_project."
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_one_five"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Save and continue</BoldHeader>
            <p>
              Great job! You've taken the first steps in setting up your project.
              Click "Save and Continue" to confirm and move on to the next step, where we'll dive into creating your
              first study.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_six"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; Step 2: Create Study</BoldHeader>
            <p>
              Congratulations, {userFirstName}! You've successfully set up your project. Now, it's time to take the next
              step by
              creating a study.
            </p>
            <p>
              Studies help you organize and analyze data within your projects.
            </p>
            <p>
              <BoldHeader>Why Create a Study?</BoldHeader>
            </p>
            <p>
              Organize Data: Group datasets for specific purposes or time periods.
            </p>
            <p>
              Different Methods: Easily manage various analysis methods for a single project.
            </p>
            <p>
              Click on "Create Study" to kickstart this exciting phase of your data journey!
            </p>
            <p>
              <BlueItalic>Note: You can always revisit and manage your studies from the project dashboard.</BlueItalic>
            </p>
          </Text>
        </div>
      ),
      position: "left",
      styles: {
        width: "500px"
      }
    },
    {
      selector: '[data-tut="step_seven"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Congratulations, {userFirstName}!</BoldHeader>
            <p>
              You've successfully created your first study. This study will be the foundation for your data analysis.
            </p>
            <p>
              <BoldHeader>Study Name</BoldHeader>
            </p>
            <p>
              Your study is named "Study 1" For now, let's keep it as is.
              Click "Got It" to confirm and move on to the next step.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_eight"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Well done, {userFirstName}!</BoldHeader>
            <p>
              For this tour, we've prepared a default dataset for demonstration purposes.
              While you can explore this dataset, keep in mind that in real scenarios, you might want to choose existing
              data from
              previous projects or upload new data.
            </p>
            <p>
              Also the language is set to English. While in real scenarios, you might choose a preferred language,
              here, it's already set for demonstration purposes.
            </p>
            <p>
              Select the available dataset to confirm.
            </p>
          </Text>
        </div>
      ),
      position: "right",
    },
    {
      selector: '[data-tut="step_nine"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Fantastic!</BoldHeader>
            <p>
              Your study is shaping up. Now, let's proceed to the selection of methods.
            </p>
            <p>
              <BoldHeader>Ready to Proceed?</BoldHeader>
            </p>
            <p>
              Click the button and proceed to choose the methods for your study.
            </p>
          </Text>
        </div>
      ),
      position: "top",
    },
    {
      selector: '[data-tut="step_ten"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; Step 4: Choose Analysis Method</BoldHeader>
            <p>
              Great progress, {userFirstName}! You've selected your data and language.
              Now, it's time to choose an analysis method for your study.
            </p>
            <p>
              <BoldHeader>Available Methods:</BoldHeader>
            </p>
            <p>
              Anonymization<br/>
              Sanity Check<br/>
              Thematic Analysis<br/>
            </p>
            <p>
              For this tour, let's focus on anonymization. Click "Got It" to proceed.
            </p>
          </Text>
        </div>
      ),
      position: "top",
    },
    {
      selector: '[data-tut="step_eleven"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; Step 5: Anonymization Options</BoldHeader>
            <p>
              Well done! You've chosen anonymization as your analysis method.
              By default, names will be anonymized in your dataset.
            </p>
            <p>
              <BoldHeader>Default Anonymization:</BoldHeader>
            </p>
            <p>
              Names are replaced with anonymized placeholders.
            </p>
            <p>
              <BoldHeader>Time Estimation:</BoldHeader>
            </p>
            <p>
              Tells the estimated time we need to anonymize your data.
            </p>
            <p>
              Click "Got It" to proceed to additional options.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_twelve"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}>Perfect!</BoldHeader>
            <p>
              You're diving deeper into anonymization now.
              Here are the additional options for refining the process.
            </p>
            <p>
              Click any option to set your preferences and proceed.
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
    },
    {
      selector: '[data-tut="step_thirghteen"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; Explore the Live Preview, {userFirstName}!</BoldHeader>
            <p>
              You've selected your anonymization options.
              Now, let's see how they impact the data in real-time.
            </p>
            <p>
              <BoldHeader>Live Preview:</BoldHeader>
            </p>
            <p>
              Observe changes in the first rows of your dataset as you choose your options.
            </p>
          </Text>
        </div>
      ),
      position: "top",
    },

    {
      selector: '[data-tut="step_fourteen"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; The last step!</BoldHeader>
            <p>
              Check the price, click “Apply” and wait for the results.
            </p>
            <p>
              You've selected your anonymization options.
              Now, let's see how they impact the data in real-time.
            </p>
          </Text>
        </div>
      ),
      position: "bottom"
    },
    {
      selector: '[data-tut="step_fifteen"]',
      content: () => (
        <div>
          <Text color="#e5e5e5">
            <BoldHeader sx={{fontWeight: "bolder"}}> &#128640; Start this tour anytime you want!</BoldHeader>
            <p>
              If you want to start this tour again, just click this button!
            </p>
            <p>
              Good luck, {userFirstName}!
            </p>
          </Text>
        </div>
      ),
      position: "bottom",
      style: {
        backgroundColor: "grey",
        color: "white"
      }
    }
  ];


  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "scroll"
  //   };
  // }, [isTourOpen]);

  let disableScrolling = () => {
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  let enableScrolling = () => {
    window.onscroll = function () {
    };
  }

  async function setTourPassed() {
    let onError = async (code) => {
      console.log(code);
    }
    let onSuccess = () => {
      setIsTourOpen(false);
    }
    await post(
      `/onboarding/passed/`,
      {
        passed: true
      },
      onError,
      onSuccess
    )
  }

  const startTour = () => {
    setCurrentStep(0);
    window.scrollTo(0, 0);
    // disableScrolling();
    disableScrolling();
    setIsTourOpen(true);
  };

  const closeTour = () => {
    enableScrolling();
    // document.body.style.overflow = "scroll";
    setIsTourOpen(false);
    setCurrentStep(0);
  };

  const skipTour = () => {
    setTourPassed();
    closeTour();
  };

  async function nextStepClicked(setStep = null) {
    console.log("current step: " + currentStep);

    if (setStep !== null) {
      console.log("setStep !== null: " + setStep);
      setCurrentStep(setStep);
    } else {
      let tmpStep = currentStep + 1;
      // if(tmpStep === 2) {
      //   alert(2);
      // }
      console.log("setStep !== null: " + tmpStep);
      setCurrentStep(tmpStep);
    }
  }

//   const SampleComponent = () => {
//     useEffect(() => {
//       hotjar.initialize('3263580', x);
//     }, [])
//
// }


  return (
    <Layout>
      <Router>
        {/*{show && !loggedIn &&*/}
        {!loggedIn &&
        <Header
          light={light}
          setLight={setLight}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          setTourPassed={setTourPassed}
          setUserFirstName={setUserFirstName}
          startTour={startTour}
          skipTour={skipTour}
        />
        }
        <Switch>
          <Route exact path="/" component={Login}/>

          <Route exact path="/login" component={Login}/>
          <Route exact path="/contactus" component={ContactUs}/>
          <Route exact path="/history" component={Activity}/>
          <Route exact path="/documentation" component={Documentation}/>

          <Route exact path="/registration" component={Registration}/>
          <Route exact path="/registration/success" component={RegistrationSuccess}/>
          <Route exact path="/registration/:token" component={DoubleOptIn}/>
          <Route exact path="/credits/buy" component={BuyCredits}/>

          <Route exact path="/invite/:token" component={AdminAcceptInvitation}/>

          <Route exact path="/password/forgot" component={PasswordRecovery}/>
          <Route exact path="/password-reset/:token" component={PasswordReset}/>
          <Route exact path="/password-recovery/success/:email" component={PasswordRecoverySuccess}/>

          <Route exact path="/agb" component={AGB}/>
          <Route exact path="/impressum" component={Impressum}/>
          <Route exact path="/data-protection" component={DataProtection}/>

          <Route exact path="/project" component={withRole(ProjectList, USER, currentStep, closeTour, tourConfig,
            isTourOpen, setTourPassed, nextStepClicked,isProjectSubmitted, setIsProjectSubmitted)}/>
          <Route exact path="/project/archived" component={withRole(ProjectArchivedList, USER)}/>
          <Route exact path="/project/:projectId/detail" component={withRole(ProjectDetail, USER, currentStep,
            closeTour, tourConfig, isTourOpen, setTourPassed, nextStepClicked,
            isProjectSubmitted, setIsProjectSubmitted)}/>

          <Route exact path="/project/:projectId/study/:studyId"
                 component={withRole(Study, USER, currentStep, closeTour,
                   tourConfig, isTourOpen, setTourPassed, nextStepClicked, isProjectSubmitted, setIsProjectSubmitted)}/>
          <Route exact path="/project/:projectId/study/:studyId/detail" component={withRole(StudyDetail, USER,
            currentStep, closeTour, tourConfig, isTourOpen, setTourPassed, nextStepClicked,
          )}/>
          <Route exact path="/project/:projectId/study/:studyId/result" component={withRole(StudyResults, USER)}/>

          <Route exact path="/costnumber/create" component={withRole(CostNumberCreate, USER)}/>

          <Route exact path="/account" component={withRole(Account, USER)}/>

          <Route exact path="/organisation/files" component={withRole(OrganisationFiles, USER)}/>

          <Route exact path="/organisation/list" component={withRole(OrganisationList, ADMIN)}/>
          <Route exact path="/organisation/detail" component={withRole(OrganisationDetail, ADMIN)}/>

          <Route exact path="/support/contact" component={SupportContact}/>

          <Route exact path="*" component={NotFound}/>

        </Switch>
        <FooterNew/>
      </Router>
    </Layout>
  );
}

export default App;
