import React, {useState, useEffect} from 'react';
import {post} from '../../security/Api';
import {
  darkblue,
  grey70,
  grey80,
  grey5,
  white,
  errorred, orange2, tourquoise
} from '../../colors';

import styled from '@emotion/styled';
import PageModal from '../../components/PageModal';
import {Button} from '../Button';
import Icon from '../Icon/Icon';
import OptionBoxSanity from '../MethodModals/OptionBoxSanity';
import Select from 'react-select'
import {withTranslation} from "react-i18next";
import Grid from '@mui/material/Grid';
import {InfoPopupGreen} from "../InfoPopup";

const FirstLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-bottom: 28px;
    padding-right: 28px;
`;

const Headline = styled.div`
    color: ${props => props.light ? grey5 : darkblue};
    font-size: 24px;
    text-align: left;
    font-family: AvenirLTPro-Medium, AvenirNextCyrMedium;
    display: flex;
    justify-content: left;
`;

const OptionsRow = styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 34px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const PreviewButtonRow = styled.div`
    display: flex;
    // justify-content: flex-end;
    bottom: 30px;
`;

const MethodHeadline = styled.div`
    display: flex;
    margin-bottom: 14px;
    font-size: 18px;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    justify-content: space-between;
    align-items: center;
`;

const TimerIconContainer = styled.div`
    margin-right: 8px;
    display: flex;
    color: ${grey5};
`;
const SubHeadlineContainer = styled.div`
    font-size: 1em;
    color: ${grey5};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
`;

const SubValue = styled.div`
    font-size: 16px;
    font-weight: normal;
    ${props => props.result && 'font-family: AvenirLTPro-Black, AvenirNextCyrBold;'}
`;

const ModalInnen = styled.div`
    font-size: 1em;
    display: flex;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    color: ${darkblue};
    justify-content: ${props => props.previewOpened ? "left" : "center"};
    height: 100%;
    min-height: 600px;
    width: 100%;
`;

const TransitionHelper = styled.div`
    width: ${props => props.previewOpened ? "75%" : "50%"};
    display: flex;
`;

const MethodOptions = styled.div`
    font-size: .8em;
    width: ${props => props.previewOpened ? "30%" : "100%"};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey80};
    border-radius: 5px;
    margin: 0 15px;
    color: ${grey5};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const MethodPreview = styled.div`
    font-size: 1em;
    width: 70%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey5};
    border-radius: 5px;
    margin: 0;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const MethodPrice = styled.div`
    font-size: .8em;
    width: 25%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${white};
    border-radius: 5px;
    margin: 0 15px;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const PriceTotalLine = styled.p`
    margin-top: -5px;
    font-family: AvenirTPro-Demi, AvenirNextCyrDemi;
    display: flex;
    justify-content: space-between;
`;

const Divider = styled.hr`
    color: ${grey70};
    height: .5px;
`;

const CalcContainer = styled.div`
    margin-top: 30px;
`;

const OverlayContainer = styled.div`
    margin-top: 30px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    &:after {
        content: 'Preview';
        position: relative;
        display: block;
        opacity: 0.9;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        z-index:10;
        box-shadow: 0 0 50px 50px ${grey5}
    }
`;

const BlockContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    position: relative;
`;

const CommentsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const CalcLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PreviewContainer = styled.div`
    font-size: .8em;
`;

const Comment = styled.div`
    padding: 5px;
    margin: 9px;
    min-height: 24px;
    border-radius: 5px;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
    position: relative;
`;

const Timer = styled.div`
    margin-left: 10px;
    margin-top: 2px;
    color: ${grey5};
`;

const CreditsLeft = styled.span`
    color: ${props => props.minus ? errorred : darkblue}
`;

const ResultOptionsRow = styled.div`
    // display: flex;
    // // justify-content: flex-end;
    // justify-content: space-between;
    // align-items: center;
    // bottom: 30px;
`;

const AlleButton = styled.button`
    border: 0;
    border-radius: 20px;
    background-color: ${props => props.color ? props.color : white};
    // text-decoration: underline;
    margin-left: 15px;
    visibility: "visible";
    // color: ${orange2};
    cursor: pointer;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;

    &:hover {filter: brightness(85%);}
`;


function SanityCheckModal(props) {
  const [preview, setPreview] = useState(true);
  const dbName = "Sanity";
  const [totalRows, setTotalRows] = useState(0);
  const [isAplyButtonDisabled, setIsAplyButtonDisabled] = useState(true);
  const [options, setOptions] = useState(
    [
      {
        id: 'deleteComment',
        label: props.t("sanityModal2", 'No comment'),
        value: false,
        color: {tourquoise},
        option_name: "no_comment"
      },
      {
        id: 'deleteIgnorance',
        label: props.t("sanityModal3", 'Ignorance'),
        value: false,
        color: {tourquoise},
        option_name: "ignorance"
      },
      {
        id: 'deleteNoOpinion',
        label: props.t("sanityModal4", 'No opinion'),
        value: false,
        color: {tourquoise},
        option_name: "no_opinion"
      },
      {
        id: 'deleteNoPoint',
        label: props.t("sanityModal5", 'No point'),
        value: false,
        color: {tourquoise},
        option_name: "no_point"
      },
      {
        id: 'deleteNoIssue',
        label: props.t("sanityModal6", 'No issue'),
        value: false,
        color: {tourquoise},
        option_name: "no_issue"
      },
      {
        id: 'deleteNone',
        label: props.t("sanityModal7", 'None'),
        value: false,
        color: {tourquoise},
        option_name: "none"
      },
      {
        id: 'deleteRoomForImprovement',
        label: props.t("sanityModal8", 'Room for improvement'),
        value: false,
        color: {tourquoise},
        option_name: "room_for_improvement"
      },
      {
        id: 'deleteIndifference',
        label: props.t("sanityModal9", 'Indifference'),
        value: false,
        color: {tourquoise},
        option_name: "indifference"
      },
      {
        id: 'deleteNonsense',
        label: props.t("sanityModal10", 'Nonsense'),
        value: false,
        color: {tourquoise},
        option_name: "nonsense"
      },
    ],
  )

  const pickedPseudonym = props.t("anoModal13", "[Anonymized]");

  const {
    history, onClose, onError, checkResult,
    checkStudy,
    getPreview,
    previewLoaded,
    columnCol,
    checkLoaded, file, t, sanityPreviewResult, sanityPreviewOptionsResult
  } = props;

  useEffect(() => {
    countMethodRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countMethodRows = () => {
    let currentRows = 0;
    props.projectCostNumber.creditHistories.forEach(historyUnit => {
      if(historyUnit.methodName === dbName) {
        currentRows += historyUnit.credit.value;
      }
    })
    setTotalRows(currentRows);
  }

  function renderPreview(result, index) {
    let buttonColor;
    let buttonName;

    switch (sanityPreviewOptionsResult[index]) {
      case "no_comment":
        buttonColor = tourquoise;
        buttonName = "No comment";
        break;
      case "ignorance":
        buttonColor = tourquoise;
        buttonName = "Ignorance";
        break;
      case 'no_opinion':
        buttonColor = tourquoise;
        buttonName = "No opinion";
        break;
      case "no_point":
        buttonColor = tourquoise;
        buttonName = "No point";
        break;
      case "no_issue":
        buttonColor = tourquoise;
        buttonName = "No issue";
        break;
      case "none":
        buttonColor = tourquoise;
        buttonName = "None";
        break;
      case "room_for_improvement":
        buttonColor = tourquoise;
        buttonName = "Room for improvement";
        break;
      case "indifference":
        buttonColor = tourquoise;
        buttonName = "Indifference";
        break;
      case "nonsense":
        buttonColor = tourquoise;
        buttonName = "Nonsense";
        break;
      default:
        buttonColor = tourquoise;
        buttonName = "";
    }
    // console.log(buttonName + buttonColor);
    return (
      <ResultOptionsRow>
        <Comment>{result}
          {sanityPreviewOptionsResult[index] &&
          <AlleButton
            color={buttonColor}
          >
            {buttonName}
          </AlleButton>}
        </Comment>

      </ResultOptionsRow>);
  }

  function getOptionsForPreview() {
    let result = {};
    result.sanity = {
      "pseudo": pickedPseudonym === "Option" ? true : false,
      "no_comment": options.find((option) => {
        return option.id === "deleteComment"
      })?.value,
      "ignorance": options.find((option) => {
        return option.id === "deleteIgnorance"
      })?.value,
      "no_opinion": options.find((option) => {
        return option.id === "deleteNoOpinion"
      })?.value,
      "no_point": options.find((option) => {
        return option.id === "deleteNoPoint"
      })?.value,
      "no_issue": options.find((option) => {
        return option.id === "deleteNoIssue"
      })?.value,
      "none": options.find((option) => {
        return option.id === "deleteNone"
      })?.value,
      "room_for_improvement": options.find((option) => {
        return option.id === "deleteRoomForImprovement"
      })?.value,
      "indifference": options.find((option) => {
        return option.id === "deleteIndifference"
      })?.value,
      "nonsense": options.find((option) => {
        return option.id === "deleteNonsense"
      })?.value
    }
    return result;
  }

  useEffect(() => {
    getPreview(getOptionsForPreview());
    isAtLeastOneOptionChecked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])


  function onOptionChange(id, value) {
    const newOptions = options.map(option => {
      if (option.id === id) {
        option.value = value;
      }
      return option;
    });

    setOptions([...newOptions]);
  }

  let sanityStudy = async () => {
    const optionsForApi = {};

    for (const option of options) {
      optionsForApi[option.option_name] = option.value;
    }
    optionsForApi['anoTag'] = pickedPseudonym === "Option" ? null : pickedPseudonym;
    optionsForApi['pseudo'] = pickedPseudonym === "Option" ? true : false;
    let onSuccess = () => {
      history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
    }

    await post(
      '/study/sanity',
      {
        studyId: props.studyId,
        columnCol: columnCol.value,
        options: optionsForApi
      },
      onError,
      onSuccess
    );
  };

  let getListOfColumns = () => {
    // if (checkLoaded && checkResult) setColumnCol({ label: checkResult.columnName, value: checkResult.columnName });
    if (checkLoaded && checkResult) {
      let topTenRows = Object.keys(JSON.parse(checkResult.topTenRows));
      return topTenRows.map(function (key) {
        return {
          value: key,
          label: key,
        };
      });
    }
  };

  let changeColumnCol = (e) => {
    // setColumnCol({value: e.value, label: e.value});
    // columnCol = { value: e.value, label: e.value };
    checkStudy(e.value);
    getPreview(getOptionsForPreview(), e.value);
  }

  let isAtLeastOneOptionChecked = () => {

    let isChecked = true;

    options.map(option => {
      if (option.value === true) {
        isChecked = false;
      }
      return false;
    });
    setIsAplyButtonDisabled(isChecked);
  }

  let creditsLeft = checkLoaded && checkResult ? totalRows - checkResult.calculatedPrice : '...';

  return (
    <PageModal onClose={onClose} color={grey5}>
      <ModalInnen>

        <TransitionHelper previewOpened={preview}>
          <MethodOptions previewOpened={preview}>
            <FirstLine>
              <Headline light>{t("StudyDetail23", 'Sanity Check')}
                <InfoPopupGreen
                  content={t('tooltipSanity', "")}
                />
              </Headline>
            </FirstLine>
            {checkLoaded && checkResult && file &&
            <MethodHeadline>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <SubHeadlineContainer>
                    <SubValue result={true}>{file.name}</SubValue>
                  </SubHeadlineContainer>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <TimerIconContainer>
                    <Icon size={24} name="timer"/>
                    <Timer>{checkResult.computationTime < 1 ? 1 : checkResult.computationTime} min</Timer>
                  </TimerIconContainer>
                </Grid>
              </Grid>
            </MethodHeadline>
            }

            {!checkLoaded && <MethodHeadline>{t("method1", "Wird berechnet...")}</MethodHeadline>}

            <OptionsRow>
              <OptionBoxSanity
                onChange={(key, value) => onOptionChange(key, !value)}
                options={options}
                headline={t("sanityModal1", "Löschen:")}
                inverted={true}
              />
            </OptionsRow>
            <OptionsRow>
              <OptionBoxSanity
                onChange={(key, value) => onOptionChange(key, value)}
                options={options}
                headline={t('anoModal9', "Weitere Optionen")}
              />
            </OptionsRow>
            <PreviewButtonRow>
              <Button
                mainbutton
                type="button"
                // tiny
                onClick={() => setPreview(!preview)}
              >
                {preview ? t("anoModal11", "Vorschau ausblenden") : t("anoModal12", "Vorschau")}
              </Button>
            </PreviewButtonRow>
          </MethodOptions>

          {preview &&
          <MethodPreview active={preview}>
            <FirstLine>
              <Headline>{t("anoModal2", "Vorschau")}</Headline>
            </FirstLine>
            {previewLoaded &&
            <PreviewContainer>
              <CalcLine>{t("anoModal8", "Begriffe in den folgenden Beitragen werden ersetzt")}:</CalcLine>
              <OverlayContainer>
                <CommentsContainer>
                  {sanityPreviewResult?.map(renderPreview)}
                </CommentsContainer>
              </OverlayContainer>

              <BlockContainer>
                <CommentsContainer>
                  {t("anoModal19", "Nicht die richtige Spalte?")}
                </CommentsContainer>
                <CommentsContainer>
                  {t("account127", "Spaltenname auswählen")}
                </CommentsContainer>
                <CommentsContainer>
                  <Select
                    id="columnCol"
                    name="columnCol"
                    options={getListOfColumns()}
                    // value={checkLoaded && checkResult ? { label: checkResult.columnName, value: checkResult.columnName } : ''}
                    value={columnCol}
                    onChange={(value) => changeColumnCol(value)}
                    autoFocus={false}
                    required={true}
                  />
                </CommentsContainer>
              </BlockContainer>
            </PreviewContainer>
            }

            {!previewLoaded &&
            <PreviewContainer>
              <CalcLine>{t("anoModal18", "Vorschau ist vorbereitet...")}:</CalcLine>
              <OverlayContainer>
              </OverlayContainer>
            </PreviewContainer>
            }
          </MethodPreview>
          }
        </TransitionHelper>

        <MethodPrice previewOpened={preview}>
          {t("anoModal3", "Durch Anwenden des Data Services werden die folgenden Credits abgebucht")}:
          <CalcContainer>
            <CalcLine>
              <span>{t("projectform5", "Kostennummer")}</span>
              <span>{props.projectCostNumber.name.length ? props.projectCostNumber.name : '_' + t("projectForm12", "default")}</span>
            </CalcLine>
            <CalcLine>
              <span>{t("anoModal4", "Aktuelle Credits")}</span>
              <span>{totalRows} {t("testCredits5", "Zeilen")}</span>
            </CalcLine>
            <CalcLine>
              <span>{t("sanityModal30", "Sanity check")}</span>
              <span>{checkLoaded && checkResult ? -checkResult.calculatedPrice : "..."} {t("testCredits5", "Zeilen")}</span>
            </CalcLine>
          </CalcContainer>
          <Divider/>
          <PriceTotalLine>
            <span>{t("anoModal6", "Neue Credits")}</span>
            <CreditsLeft
              minus={creditsLeft < 0}>{checkLoaded && checkResult ? creditsLeft : "..."} {t("testCredits5", "Zeilen")}</CreditsLeft>
          </PriceTotalLine>
          <ButtonRow>
            {creditsLeft >= 0 && creditsLeft !== "..." &&
            <Button disabled={isAplyButtonDisabled} type="button" tiny data-cy="send-options" icon="main_arrow" mainbutton
                    onClick={sanityStudy}>{t("anoModal7", "Anwenden")}</Button>
            }
            {creditsLeft < 0 && creditsLeft !== "..." &&
            <Button type="button" tiny icon="main_arrow" mainbutton
                    onClick={props.showBuyModal}>{t("anoModal17", "Credits kaufen")}</Button>
            }
          </ButtonRow>
        </MethodPrice>
      </ModalInnen>

    </PageModal>
  );
}

export default withTranslation("translations")(SanityCheckModal);
