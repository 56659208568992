import React from 'react';
import ErrorMessage from './ErrorMessage';
import styled from '@emotion/styled';
import Label from './Label';

const Container = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    line-height: 25px;
`;

const CheckBoxField = styled.input`
    margin-right: ${props => props.big ? '0' : '16px'};
    margin-left: ${props => props.big ? '10px' : '0'};
    width: ${props => props.big ? '40px' : '25px'};
    height: ${props => props.big ? '40px' : '25px'};
`;

class CheckBox extends React.Component {

    onChange(event) {
        const {onChange} = this.props;

        if(!onChange) {
            return;
        }

        onChange(event.target.checked);
    };

    render() {
        const { id, name, value = false, disabled=false, big = false } = this.props;

        return (
            <Container>
                <CheckBoxField
                    big={big}
                    data-cy={id}
                    id={id}
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={event => this.onChange(event)}
                    disabled={disabled}
                />
                {this.renderLabel()}
            </Container>
        );
    }

    renderLabel() {
        const {label, error, id, big} = this.props;

        if (error) {
            return <ErrorMessage id={id} error={error}/>;
        }

        return (
            <Label htmlFor={id}  big={big}>
                {label}
            </Label>
        );
    }
}

export default CheckBox;
