import React from 'react';
import styled from '@emotion/styled';
import {blackpearl, white} from '../colors';
import {withTranslation} from "react-i18next";

const MultiToggleContainer = styled.fieldset`
  border-radius: 5px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border: 0;
  margin: 0 10px;
  margin-bottom: 20px;
  padding: 3px;
`;

const SlideContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 2em;
  /* don't allow highlighting the text inside the toggle */
  user-select: none; 
`;

const Input = styled.input`
  position: absolute;
  left: -200vw;
`;

const Label = styled.label`
  border-radius: 3px;
  width: 50%;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
  ${props => props.checked && `color: ${white};`}
  ${props => props.checked && `background: ${blackpearl};`}
  ${props => !props.checked && `cursor: pointer;`}
`;


function MultiToggleComponent(props) {

  const {activeMethods, selectedMethod, setSelectedMethod, setSelectedMethodDbName} = props;

  const setChecked = (clickedItem) => {
    setSelectedMethod(clickedItem.method);
    // setSelectedMethodBalance(tarifRowsByMethod[clickedItem.method]);
    setSelectedMethodDbName(clickedItem.db_name);
  }

  return (
    <MultiToggleContainer>
      <SlideContainer>
        {activeMethods.map((method) => {
          return (
            <>
              <Input type="radio" name="account"/>
              <Label
                for="account1"
                checked={selectedMethod === method.method}
                onClick={() => setChecked(method)}
              >
                {method.name}
              </Label>
            </>
          );
        })}

      </SlideContainer>
    </MultiToggleContainer>
  );
}

export default withTranslation("translations")(MultiToggleComponent);

