import styled from '@emotion/styled';
import { white } from '../colors';

const Page = styled.div`
  background: ${props => props.background ? props.background : white};
  display: block;
  flex: 1 0 auto;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  // min-height: 800px;
  box-sizing: border-box;
  overflow-y: auto;

  @media (min-width: 1200px)
    max-width: 1140px;
  }
`;

export default Page;
