import React from 'react';
import ErrorMessage from './ErrorMessage';
import styled from '@emotion/styled';
import Label from './Label';

const Container = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    line-height: 25px;
`;

const RadioButtonField = styled.input`
    margin-right: 16px;
    margin-top: 0;
    width: 25px;
    height: 25px;
`;

class RadioButton extends React.Component {

  onChange(event) {
    const {onChange} = this.props;

    if(!onChange) {
      return;
    }

    onChange(event.target.checked);
  };

  render() {
    const { id, name = null, value = false, labelOnly = false } = this.props;

    return (
      <Container>
        {!labelOnly && <RadioButtonField
          data-cy={id}
          id={id}
          name={name}
          type="radio"
          checked={value}
          onChange={event => this.onChange(event)}
          ref={name}
        />}
        {this.renderLabel()}
      </Container>
    );
  }

  renderLabel() {
    const {label = this.props.name, error, id, color = null} = this.props;
    if (error) {
      return <ErrorMessage id={id} error={error}/>;
    }

    return (
      <Label htmlFor={id} color={color} noMargin>
        {label}
      </Label>
    );
  }
}

export default RadioButton;
