import React from 'react';
import { darkblue, grey5, white } from '../colors';
import styled from '@emotion/styled';
import Icon from './Icon/Icon';

const Background = styled.div`
  position: fixed;
  z-index: 5;
  top 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 20, 50, 0.86);
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  color: ${white};
`;

const ModalContainer = styled.div`
  position: relative;
  background: ${grey5};
  color: ${white};
  ${props => props.narrow && 'width: 50%;'}

  margin: ${props => props.narrow ? '90px 30% 0' : '180px 112px 0'}
`;

const FirstLine = styled.div`
  padding-top: 15px;
  padding-right: 15px;
  align-items: center;
  text-align: right;
`;

const CloseButton = styled.button`
  background: transparent;
  border: 0;
  color: ${darkblue};
  cursor: pointer;
  padding: 0;
`;

const InnerContainer = styled.div`
  padding: 0 60px 50px 50px;
`;

class Modal extends React.PureComponent {
  componentDidMount() {
    document.body.style.overflow = 'hidden auto';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render() {
    const { children, footer, onClose, narrow} = this.props;

    return (
      <Background>
        <ModalContainer narrow={narrow}>
          <Container>
            <FirstLine>
              <CloseButton onClick={onClose}>
                <Icon size={20} name="close_small"/>
              </CloseButton>
            </FirstLine>
            <InnerContainer>
              {children}
            </InnerContainer>
          </Container>
        </ModalContainer>
        {footer}
      </Background>
    );
  }
}

export default Modal;
