import React from 'react';
import styled from '@emotion/styled';


import PageContent from '../components/PageContent';
import CardBody, {CardBodyInner} from '../components/CardBody';
import {HeadlineText} from '../components/CardHeaderBackground';
import Page from '../components/Page';
import ContactSupportForm from '../components/ContactSupportForm';
import {white, mainblue} from '../colors';
import Grid from '@mui/material/Grid';
import {withTranslation} from "react-i18next";
import {lightGreen} from "@mui/material/colors";


export const MiniHeadline = styled.div`
    margin: 0 0 50px 0;
    color: ${white};
    Font-size: 15px;
    letter-spacing: 0.75px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    // text-transform: uppercase;
`;

export const AnonymPageInfo = styled(HeadlineText)`
    color: ${mainblue};
    font-size: 24px;
`;
export const AppVersion = styled(HeadlineText)`
    color: ${lightGreen};
    font-size: 24px;
`;
function SupportContact() {

  const renderLeftHeader = () => {
    // const {t} = props;
    return (
      <Grid item xs={1} md={1} key="2">
        <CardBody  marginBottom background={"#151f29"}>
          <CardBodyInner>
            <MiniHeadline>
              deepsight GmbH
              <br/>
              Lengericher Landstr. 34
              <br/>
              49078 Osnabrück
            </MiniHeadline>
            <AnonymPageInfo>
              +49 (0) 541 9313 2090
              <br/>
              hi@deepsight.de
              <br/>
            </AnonymPageInfo>
            <AppVersion>
              app version - 555
            </AppVersion>
          </CardBodyInner>
        </CardBody>
      </Grid>
    );
  }

  return (
    <Page background={"#151f29"}>
      <PageContent middle>
        <Grid container spacing={{xs: 1, md: 3}} columns={{xs: 1, md: 3}} sx={{backgroundColor: "#151f29"}}>
          <Grid item xs={1} md={2} key="1">
          <ContactSupportForm buying={false}/>
          </Grid>
          {renderLeftHeader()}
        </Grid>
      </PageContent>
    </Page>
  );
}

export default withTranslation("translations")(SupportContact);
