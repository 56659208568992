import React from 'react';
import Curtain from '../components/Curtain';
import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import {ActionButton} from '../components/Action';
import {get, post, post_simple} from '../security/Api';
import InviteAdminForm from '../components/InviteAdminForm';
import DeleteUserModal from '../components/DeleteUserModal';
import ResetPasswordModal from '../components/ResetPasswordModal';
import styled from '@emotion/styled';
import {isSuperAdmin} from '../security/Role';
import tokenStorage from "../security/TokenStorage";
import Guard from '../components/Guard';
import Page from '../components/Page';
import {withTranslation} from "react-i18next";

import CountrySelector from "../components/CountrySelector";
import CountryCodes from 'country-code-info';

import {Button} from '../components/ButtonReg';
import {Formik, Field} from 'formik';
import Label from '../components/LabelReg';
import CardBody, {CardBodyInner} from '../components/CardBody';
import {Form} from '../components/FormReg';
import {darkblue, green1, red, grey5} from '../colors';
import {blue, bluedarker, grey30, grey80, orange1, white} from '../colors';

import {Tab, Menu, Checkbox} from 'semantic-ui-react'
import 'semantic-ui-css/components/tab.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import error from "../error";
import Alert from '../components/Alert';
import {Typography} from "@mui/material";

import moment from 'moment';

export const FormCardBodyInner = styled(CardBodyInner)`
    width: 70%;
    position: relative;
`;

const FormContainer = styled.div`
    // position: absolute;
    // left: 550px;
    // top: 200px;
    // width: 60%;
`;

const StyledButton = styled(Button)`

    background: ${props => {
  if (props.disabled) {
    return grey30;
  }

  if (props.delete && !props.outline) {
    return orange1;
  }

  if (props.danger && !props.outline) {
    return 'transparent';
  }

  return props.outline ? 'transparent' : blue;
}};

    color: ${props => {
  if (props.outline) {
    return darkblue
  }
  if (props.danger) {
    return red
  }
  return white
}};
    border: 3px solid ${props => props.disabled ? grey30 : props.delete ? orange1 : props.danger ? red : blue};


    ${props => !props.disabled && !props.delete && !props.danger && `
        &:hover {
          color: ${white};
          background: ${bluedarker};
          border-color ${bluedarker};
        };
    `};

    ${props => props.danger && `
        &:hover {
          color: ${white};
          background: ${red};
          border-color ${red};
        };
    `};

    > svg {
      ${props => props.text && props.icon && !props.tiny && 'margin-right: 16px;'}
      ${props => props.tiny && props.icon && 'width: 20px; height: 20px; margin-right: 6px;'}
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
`;

export const FormHeadline = styled.div`
   line-height: 35px;
   color: ${darkblue};
   font-size: 16px;
   font-family: AvenirLTPro-Black, AvenirNextCyrBold;
   text-transform: uppercase;
`;

const FormSubHead = styled.h3`
    margin-top: 45px;
`;

const HeaderCheckbox = styled(Checkbox)`
    fontFamily: "AvenirNextLTPro-Medium, AvenirNextCyrMedium";
    margin-left:24px;
    padding-bottom:5px;
    line-height: 24px !important;
    color: ${darkblue} !important;
`;

const InputField = styled(Field)`
    position: relative;
    width: 100%;
    height: ${props => props.narrow ? "40px" : "48px"};
    border-radius: 5px;
    padding: 10px 10px;
    appearance: none;
    border: none;
    display: block;
    outline: 0 none;
    font-family: AvenirNextLTPro-Medium, AvenirNextCyrMedium;
    fontSize: '15px',

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
    &:disabled {
      color: ${grey80};
      border-bottom: 1px solid var(--color-text);
    }
    ${props => props.border && `border: 1px solid ${green1};`}
    ${props => props.error === "true" && `border: 1px solid ${red};`}

`;

const InvoicesListContainer = styled.div``;

const InvoiceElement = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${white};
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 0 25px;
    height: 64px;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;

    text-decoration: none;
    color: ${darkblue};
    cursor: pointer;

    &:hover {
        background: ${grey5};
    }
`;

const InvoiceElementID = styled.div`
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const InvoiceElementAmount = styled.div`
`;

const InvoiceElementStatus = styled.div`
`;

const InvoiceElementDate = styled.div`
    text-align: center;
`;


class Account extends React.PureComponent {
  state = {
    user: null,
    resetPasswordData: {
      old: null,
      new: null
    },

    userFormData: {
      id: null,
      rank: "",
      firstName: "",
      lastName: "",
    },
    organisationFormData: {
      id: null,
      name: "",
      adress: "",
      adress_addon: "",
      zip: "",
      city: "",
      country: "",
      billing_address_diff: "",
      invoice_adress: "",
      invoice_adress_addon: "",
      invoice_zip: "",
      invoice_city: "",
      invoice_country: ""
    },
    formErrors: {},
    errors: {},
    message: '',
    successAdmin: false,
    successProfile: false,
    successOrganisation: false,
    activeTab: 0,
    deleteUserModalOpen: false,
    deleteUserModalError: null,
    resetPasswordModalOpen: false,
    resetPasswordModalError: null,
    loaded: false,
    loadingError: false,
    error: false,
    errorMessage: null,
    info: false,
    infoMessage: null,
    invoices: {}
  };

  t = this.props.t;

  UserEditSuccessMessage = this.t("account10", 'Ihrer Account wurde erfolgreich aktualisiert.');
  OrganisationSuccessMessage = this.t("account13", 'Ihre Organisatie Daten wurde erfolgreich aktualisiert.');
  InviteAdminSuccessMessage = this.t("account39", "Die Einladung wurde erfolgreich versendet");


  // getFormToLoad = async() => {
  //     let search = window.location.search;
  //     let params = new URLSearchParams(search);
  //     let form = params.get('form');
  //
  //     if (form === 'admin') {
  //         await this.setState({activeTab: 2});
  //         await this.activateAdminForm();
  //     }
  //     return;
  // }

  componentDidMount = async () => {
    //await this.getFormToLoad();
    await this.loadUser();
  }

  updateInviteAdminForm = (key, value) => {
    this.setState({
      inviteFormData: {
        ...this.state.inviteFormData,
        [key]: value,
      },
    });
  };

  updateEditUserForm = (key, value) => {
    this.setState({
      userFormData: {
        ...this.state.userFormData,
        [key]: value,
      },
    });
  };

  updateOrganisationForm = async (key, value) => {
    await this.setState({
      organisationFormData: {
        ...this.state.organisationFormData,
        [key]: value,
      },
    });
  };

  loadOrganisation = async () => {
    const response = await get(`/organisation/detail`);
    if (response.status === 200) {
      const responseData = await response.json();
      if (!responseData.id) {
        this.setState({
          loaded: true,
          loadingError: true
        })
        return;
      }
      this.setState({
        organisationFormData: {
          id: responseData.id,
          name: responseData.name ? responseData.name : "",
          adress: responseData.adress ? responseData.adress : "",
          adress_addon: responseData.adress_addon ? responseData.adress_addon : "",
          zip: responseData.zip ? responseData.zip : "",
          city: responseData.city ? responseData.city : "",
          country: responseData.country ? responseData.country : "DE",
          billing_address_diff: responseData.billing_address_diff ? responseData.billing_address_diff : false,
          invoice_adress: responseData.invoice_adress ? responseData.invoice_adress : "",
          invoice_adress_addon: responseData.invoice_adress_addon ? responseData.invoice_adress_addon : "",
          invoice_zip: responseData.invoice_zip ? responseData.invoice_zip : "",
          invoice_city: responseData.invoice_city ? responseData.invoice_city : "",
          invoice_country: responseData.invoice_country ? responseData.invoice_country : "DE",
          removal_request: responseData.removal_request,
          loaded: true,
          loadingError: false
        }
      })
    } else {
      this.setState({
        loaded: true,
        loadingError: true
      })
    }
  }

  loadUser = async () => {
    const response = await get(`/user/detail`);

    if (response.status === 200) {
      const responseData = await response.json();

      this.setState({
        loaded: true,
        user: responseData,
        userFormData: {
          id: responseData.id,
          email: responseData.email,
          firstName: responseData.firstName,
          lastName: responseData.lastName,
          rank: responseData.rank,
        },
        loadingError: false
      });
      if (isSuperAdmin()) {
        await this.loadOrganisation();
      }
    } else {
      this.setState({
        loaded: true,
        loadingError: true
      })
    }
  };

  onEditUserError = (code) => {
    this.setState({
      error: true,
      errorMessage: error(code, this.t),
      successProfile: false,
      successAdmin: false,
      successUser: false
    })
  }

  onEditUserSuccess = () => {
    this.setState({
      info: true,
      infoMessage: this.UserEditSuccessMessage,
      successProfile: true,
      successAdmin: false,
      successOrganisation: false
    });
  }

  onSubmitEditUser = async () => {
    const {userFormData} = this.state;

    await post(
      '/user/edit',
      userFormData,
      this.onEditUserError,
      this.onEditUserSuccess
    );
  };

  openResetPasswordModal = async () => {
    await this.setState({
      resetPasswordModalOpen: true
    });
  };

  closeResetPasswordModal = async () => {
    await this.setState({
      resetPasswordModalOpen: false
    });
  };

  openDeleteUserModal = async () => {
    await this.setState({
      deleteUserModalOpen: true
    });
  };
  closeDeleteUserModal = () => {
    this.setState({
      deleteUserModalOpen: false,
      deleteUserModalError: null
    });
  };

  resetPasswordSuccess = () => {
    this.setState({
      info: true,
      infoMessage: this.t("account36", "Das Passwort wurde geändert")
    })
  }

  submitDeleteUserModal = async () => {
    const response = await get('/organisation/delete/request');
    if (response) {
      const responseData = await response.json();
      console.log(responseData);
      if (response.status === 200 && !response.error) {
        if (responseData.orgName) {
          await get(`/email/${responseData.orgName}`);
        } else await get(`/email/empty_name`);
        // await this.setState({deleteUserModalError: null});
        // await this.closeDeleteUserModal();
        this.setState({deleteUserModalError: error(1004, this.t)});
        if (!isSuperAdmin) {
          await tokenStorage.logout();
        }
      } else {
        const responseData = await response.json();
        if (responseData.code) {
          this.setState({deleteUserModalError: error(responseData.code, this.t)});
        } else {
          this.setState({deleteUserModalError: error(0, this.t)});
        }
      }
      // return;
    }
  }

  toggleAdressAddon = () => {
    this.setState({
      organisationFormData: {
        ...this.state.organisationFormData,
        billing_address_diff: !this.state.organisationFormData.billing_address_diff
      }
    });
  }

  updateSuccessStatus = (statusObject) => {
    this.setState({
      successProfile: statusObject.successProfile,
      successAdmin: statusObject.successAdmin,
      successOrganisation: statusObject.successOrganisation
    })
  }

  activateAdminForm = () => {
    this.setState({
      'activeTab': 2,
      successProfile: false,
      successAdmin: false,
      successOrganisation: false
    });
  }

  getInvoices = async () => {
    this.setState({
      'activeTab': 3
    });

    const response = await get(`/invoice/list`);

    if (response.status === 200) {
      const responseData = await response.json();

      if (!responseData) {
        this.setState({
          loaded: true,
          loadingError: true
        })
        return;
      }

      this.setState({
        invoices: responseData ? responseData : {},
        loaded: true,
        loadingError: false
      })

    } else {
      this.setState({
        loaded: true,
        loadingError: true
      })
    }


  }

  renderInvoiceList = (invoice) => {
    return (
      <InvoiceElement
        key={invoice.id}
        id={invoice.id}
        onClick={() => this.renderInvoice(invoice.id, invoice.voucherNumber)}
      >
        <InvoiceElementID>
          {invoice.voucherNumber}
        </InvoiceElementID>
        <InvoiceElementAmount>
          {invoice.totalAmount} {invoice.currency}
        </InvoiceElementAmount>
        <InvoiceElementStatus>
          {invoice.voucherStatus}
        </InvoiceElementStatus>
        <InvoiceElementDate>
          {moment(invoice.voucherDate).format('DD.MM.YYYY')}
        </InvoiceElementDate>
      </InvoiceElement>
    )
  }


  renderInvoice = async (invoiceId, invoiceName) => {
    let responseFileData = await post_simple(`/invoice/render`, {invoiceId: invoiceId});

    if (responseFileData.status === 200) {
      let responseFile = await responseFileData.json();

      if (responseFile) {
        let pdfData = "data:application/pdf;base64," + responseFile.data;
        const a = document.createElement('a');
        a.href = pdfData;
        a.download = invoiceName + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    } else {
      this.setState({
        loaded: true,
        loadingError: true
      })
    }

  }

  render() {
    const {
      loaded,
      loadingError,
      userFormData,
      organisationFormData,
      deleteUserModalOpen,
      resetPasswordModalOpen,
      deleteUserModalError,
      invoices
    } = this.state;
    const {t} = this.props;
    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="accountError"/>
      );
    }

    const panes = [
      {
        menuItem: (
          <Menu.Item key='profile'
                     style={{
                       'cursor': 'pointer',
                     }}
                     onClick={() => this.setState({'activeTab': 0})}>
            <ActionButton
              id="profile"
              icon="edit_profile"
              onClick={() => this.setState({'activeTab': 0})}
              notActive={this.state.activeTab !== 0}
            >
              {t('acc2', "Profil")}
            </ActionButton>
          </Menu.Item>
        ),
        render: () =>
          <Tab.Pane style={{height: '300px'}} name="profile">
            <FormContainer>
              <CardBody>
                <Formik
                  initialValues={{
                    id: userFormData.id,
                    rank: userFormData.rank,
                    firstName: userFormData.firstName,
                    lastName: userFormData.lastName
                  }}
                  onSubmit={async () => {
                    let values = userFormData;
                    let onSaveUserError = (code) => {
                      this.setState({
                        error: true,
                        errorMessage: error(code, t),
                        successOrganisation: false,
                        successAdmin: false,
                        successProfile: false,
                      });
                    }
                    let onSaveUserSuccess = () => {
                      this.setState({
                        formErrors: {},
                        errors: {},
                        successOrganisation: false,
                        successAdmin: false,
                        successProfile: true,
                        infoMessage: this.UserEditSuccessMessage,
                        info: true
                      });
                    }
                    await post(
                      '/user/edit',
                      values,
                      onSaveUserError,
                      onSaveUserSuccess
                    );
                  }}
                >
                  <Form data-cy="edit-user-form">
                    <Label htmlFor={"email"} focusx={true}>
                      {t("account37", "E-mail")}
                    </Label>{' '}
                    <InputField
                      id="email"
                      name="email"
                      type="text"
                      narrow={true}
                      value={userFormData.email}
                      onChange={() => {
                        return null
                      }}
                      data-cy="email"
                      disabled={true}
                    />
                    <Label htmlFor={"firstName"} focusx={true}>
                      {t("edituserform1", "Vorname")}
                    </Label>{' '}

                    <InputField
                      id="firstName"
                      name="firstName"
                      type="text"
                      narrow={true}
                      value={userFormData.firstName}
                      onChange={(event) => this.updateEditUserForm('firstName', event.target.value)}
                      data-cy="firstName"
                    />
                    <Label htmlFor={"lastName"} focusx={true}>
                      {t("edituserform2", "Nachname")}
                    </Label>{' '}
                    <InputField
                      id="lastName"
                      name="lastName"
                      type="text"
                      narrow={true}
                      value={userFormData.lastName}
                      onChange={(event) => this.updateEditUserForm('lastName', event.target.value)}
                      data-cy="lastName"
                    />
                    <Label htmlFor={"rank"} focusx={true}>
                      {t("edituserform3", "Status innerhalb der Organisation")}
                    </Label>{' '}
                    <InputField
                      id="rank"
                      name="rank"
                      type="text"
                      narrow={true}
                      value={userFormData.rank}
                      onChange={(event) => this.updateEditUserForm('rank', event.target.value)}
                      data-cy="rank"
                    />

                    <ButtonContainer>
                      <Button data-cy="edit-user-submit" type="submit" id="editUserSubmit"
                              icon="main_arrow">{t("edituserform4", "Speichern")}</Button>
                      <Button data-cy="reset-password" type="button" id="resetPassword"
                              onClick={this.openResetPasswordModal}>{t("account38", "Passwort ändern")}</Button>
                      <StyledButton data-cy="delete-user-submit" type="button" id="delete" icon="main_arrow"
                                    onClick={this.openDeleteUserModal}
                                    danger>{t("edituserform5", "Account unwiderruflich löschen")}</StyledButton>
                    </ButtonContainer>
                  </Form>
                </Formik>
              </CardBody>
            </FormContainer>
          </Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='organisation' style={{'cursor': 'pointer'}} onClick={() => this.setState({'activeTab': 1})}>
            <Guard secure={isSuperAdmin}>
              <ActionButton
                id="organisation"
                icon="organization"
                notActive={this.state.activeTab !== 1}
                onClick={() => this.setState({'activeTab': 1})}
              >
                {t('acc6', "Organisation")}
              </ActionButton>
            </Guard>
          </Menu.Item>
        ),
        render: () =>
          <Tab.Pane style={{height: '200px'}} name="organisation">
            <FormContainer>
              <FormHeadline>{t('account35', "Organisation")}
                {organisationFormData.removal_request &&
                <Typography sx={{color: red}}>Removal request has been sent to Deepsight admin.</Typography>
                }
              </FormHeadline>
              <CardBody>
                <Formik
                  initialValues={{
                    id: organisationFormData.id,
                    name: organisationFormData.name,
                    adress: organisationFormData.adress,
                    adress_addon: organisationFormData.adress_addon,
                    zip: organisationFormData.zip,
                    city: organisationFormData.city,
                    country: organisationFormData.country,
                    billing_address_diff: organisationFormData.billing_address_diff,
                    invoice_adress: organisationFormData.invoice_adress,
                    invoice_adress_addon: organisationFormData.invoice_adress_addon,
                    invoice_zip: organisationFormData.invoice_zip,
                    invoice_city: organisationFormData.invoice_city,
                    invoice_country: organisationFormData.invoice_country
                  }}
                  onSubmit={async () => {
                    let values = organisationFormData;
                    let onSaveOrganisationError = (code) => {
                      this.setState({
                        errors: {"error": error(code, t)},
                        successOrganisation: false,
                        successAdmin: false,
                        successProfile: false
                      });
                    }
                    let onSaveOrganisationSuccess = () => {
                      this.setState({
                        formErrors: {},
                        errors: {},
                        successOrganisation: true,
                        successAdmin: false,
                        successProfile: false,
                        info: true,
                        infoMessage: this.OrganisationSuccessMessage
                      });
                    }
                    if (!this.state.organisationFormData.billing_address_diff) {
                      values.invoice_zip = values.zip;
                      values.invoice_adress = values.adress;
                      values.invoice_adress_addon = values.adress_addon;
                      values.invoice_city = values.city;
                      values.invoice_country = values.country;
                    }

                    await post(
                      '/organisation/edit',
                      values,
                      onSaveOrganisationError,
                      onSaveOrganisationSuccess
                    );

                  }}
                >

                  <Form>
                    <FormSubHead>{t("account31", "Adresse")}</FormSubHead>

                    <Label htmlFor={"name"} focusx={true}>
                      {t("account25", "Company Name")}
                    </Label>{' '}
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      narrow={true}
                      value={organisationFormData.name}
                      onChange={(event) => this.updateOrganisationForm('name', event.target.value)}

                    />
                    <Label htmlFor={"adress"} focusx={true}>
                      {t("account22", "Strasse, Hausnummer")}
                    </Label>{' '}
                    <InputField
                      id="adress"
                      name="adress"
                      type="text"
                      narrow={true}
                      value={organisationFormData.adress}
                      onChange={(event) => this.updateOrganisationForm('adress', event.target.value)}
                    />
                    <Label htmlFor={"adress_addon"} focusx={true}>
                      {t("account23", "Adressenzusatz")}
                    </Label>{' '}
                    <InputField
                      id="adress_addon"
                      name="adress_addon"
                      type="text"
                      narrow={true}
                      value={organisationFormData.adress_addon}
                      onChange={(event) => this.updateOrganisationForm('adress_addon', event.target.value)}
                    />
                    <Label htmlFor={"zip"} focusx={true}>
                      {t('account24', "PLZ")}
                    </Label>{' '}
                    <InputField
                      id="zip"
                      name="zip"
                      type="text"
                      narrow={true}
                      value={organisationFormData.zip}
                      onChange={(event) => this.updateOrganisationForm('zip', event.target.value)}
                    />
                    <Label htmlFor={"city"} focusx={true}>
                      {t("account27", "Stadt")}
                    </Label>{' '}
                    <InputField
                      id="city"
                      name="city"
                      type="text"
                      narrow={true}
                      value={organisationFormData.city}
                      onChange={(event) => this.updateOrganisationForm('city', event.target.value)}
                    />
                    <CountrySelector
                      name="country"
                      handleChange={(value) => {
                        this.updateOrganisationForm('country', value.value);
                      }}
                      initialValue={{
                        value: organisationFormData.country,
                        label: CountryCodes.findCountry({'a2': organisationFormData.country}).name
                      }}
                      label={t("account29", "Land")}
                    />
                    <InputField
                      id="country"
                      name="country"
                      type="hidden"
                      narrow={true}
                      value={organisationFormData.country.value}
                    />
                    <FormSubHead>
                      {t("account30", "Rechnungsadresse")}
                      <HeaderCheckbox
                        checked={!this.state.organisationFormData.billing_address_diff}
                        label={t("account34", "Entspricht der Organisationsadresse")}
                        onChange={this.toggleAdressAddon}
                        id="billing_address_diff"
                        name="billing_address_diff"
                      />
                    </FormSubHead>

                    {organisationFormData.billing_address_diff &&
                    <div>
                      <Label htmlFor={"invoice_adress"} focusx={true}>
                        {t("account22", "Strasse, Hausnummer")}
                      </Label>{' '}
                      <InputField
                        id="invoice_adress"
                        name="invoice_adress"
                        type="text"
                        narrow={true}
                        value={organisationFormData.invoice_adress}
                        onChange={(event) => this.updateOrganisationForm('invoice_adress', event.target.value)}
                      />
                      <Label htmlFor={"invoice_adress_addon"} focusx={true}>
                        {t("account23", "Adressenzusatz")}
                      </Label>{' '}
                      <InputField
                        id="invoice_adress_addon"
                        name="invoice_adress_addon"
                        type="text"
                        narrow={true}
                        value={organisationFormData.invoice_adress_addon}
                        onChange={(event) => this.updateOrganisationForm('invoice_adress_addon', event.target.value)}
                      />
                      <Label htmlFor={"invoice_zip"} focusx={true}>
                        {t('account24', "PLZ")}
                      </Label>{' '}
                      <InputField
                        id="invoice_zip"
                        name="invoice_zip"
                        type="text"
                        narrow={true}
                        value={organisationFormData.invoice_zip}
                        onChange={(event) => this.updateOrganisationForm('invoice_zip', event.target.value)}
                      />
                      <Label htmlFor={"invoice_city"} focusx={true}>
                        {t("account27", "Stadt")}
                      </Label>{' '}
                      <InputField
                        id="invoice_city"
                        name="invoice_city"
                        type="text"
                        narrow={true}
                        value={organisationFormData.invoice_city}
                        onChange={(event) => this.updateOrganisationForm('invoice_city', event.target.value)}
                      />

                      <CountrySelector
                        name="invoice_country"
                        handleChange={
                          (value) => this.updateOrganisationForm('invoice_country', value.value)
                        }
                        initialValue={{
                          value: organisationFormData.invoice_country,
                          label: CountryCodes.findCountry({'a2': organisationFormData.invoice_country}).name
                        }}
                        label={t("account29", "Land")}
                      />
                    </div>
                    }
                    <FormSubHead>{t("account32", "Kontaktperson")}</FormSubHead>
                    {(userFormData.firstName ? userFormData.firstName : "") + " " + (userFormData.lastName ? userFormData.lastName : "") + " (" + t("account33", "Dieser Account") + ")"}
                    <InputField
                      id="invoice_country"
                      name="invoice_country"
                      type="hidden"
                      narrow={true}
                      value={organisationFormData.invoice_country.value}
                    />

                    {/*<FormSubHead>{t("account32", "Kontaktperson")}</FormSubHead>*/}
                    {/*{(userFormData.firstName ? userFormData.firstName : "") + " " + (userFormData.lastName ? userFormData.lastName : "") + " (" + t("account33", "Dieser Account") + ")"}*/}
                    <ButtonContainer>
                      <Button data-cy="organisation-submit" icon={'main_arrow'} id="organisationSubmit"
                              type="submit">{t('account21', "Speichern")}</Button>
                    </ButtonContainer>
                  </Form>
                </Formik>

              </CardBody>
            </FormContainer>

          </Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='invoices' style={{'cursor': 'pointer'}} onClick={this.getInvoices}>
            <Guard secure={isSuperAdmin}>
              <ActionButton
                id="invoices"
                icon="invoice"
                onClick={this.getInvoices}
                notActive={this.state.activeTab !== 3}
              >
                {t('acc7', "Rechnungen")}
              </ActionButton>
            </Guard>
          </Menu.Item>
        ),
        render: () =>
          <Tab.Pane style={{height: '300px'}} name="invoices">
            {invoices.length === 0 &&
            <FormHeadline>{t('acc8', "Noch keine Rechnungen verfügbar")}</FormHeadline>
            }
            {invoices.length > 0 &&
            <FormHeadline>{t('acc7', "Rechnungen")}</FormHeadline>
            }

            {invoices.length > 0 &&
            <InvoicesListContainer>
              {invoices.map(this.renderInvoiceList)}
            </InvoicesListContainer>
            }
          </Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item disabled key='team-manage' style={{'cursor': 'not-allowed'}} onClick={() => this.setState({'activeTab': this.state.activeTab})}>
            <Guard secure={isSuperAdmin}>
              <ActionButton
                disabled
                id="team-manage"
                icon="edit_team"
                onClick={this.activateAdminForm}
                notActive={this.state.activeTab !== 2}
              >
                {t('acc4', "Team verwalten")}
              </ActionButton>
            </Guard>
          </Menu.Item>
        ),
        render: () =>
          <Tab.Pane style={{height: '400px'}} name="team-manage">
            <FormContainer>
              <InviteAdminForm updateSuccessStatus={this.updateSuccessStatus}/>
            </FormContainer>
          </Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='logout' onClick={() => tokenStorage.logout()} style={{'cursor': 'pointer'}}>
            <ActionButton
              id="logout" danger icon="logout" notActive={true} onClick={() => tokenStorage.logout()}>
              {t('acc5', "Logout")}
            </ActionButton>
          </Menu.Item>
        ),

      },
    ]
    return (
      <React.Fragment>
        <Page>
          <PageContent full>
            <Headline>{t('acc1', "Mein Account")}</Headline>
            <Tab
              menu={{pointing: true, fluid: true, vertical: true}}
              style={{'padding-left': '67px', 'padding-top': '24px'}}
              panes={panes}
            />
          </PageContent>
          {deleteUserModalOpen &&
          <DeleteUserModal
            onClose={() => this.closeDeleteUserModal()}
            onClick={() => this.submitDeleteUserModal()}
            error={deleteUserModalError}
          />
          }
          {resetPasswordModalOpen &&
          <ResetPasswordModal
            onClose={() => this.closeResetPasswordModal()}
            onSuccess={() => this.resetPasswordSuccess()}
          />
          }
          {this.state.error &&
          <Alert
            type="error"
            message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
            onClose={() => this.setState({error: false})}
          />
          }
          {this.state.info && this.state.infoMessage &&
          <Alert
            type="info"
            message={this.state.infoMessage}
            onClose={() => this.setState({info: false})}
            data-cy="alert-info"
          />
          }
          {
            this.state.successAdmin &&
            <Alert
              type="info"
              message={this.InviteAdminSuccessMessage}
              onClose={() => this.setState({successAdmin: false})}
            />
          }
        </Page>
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(Account);
