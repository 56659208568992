import React from 'react';
import ErrorMessage from './ErrorMessage';
import styled from '@emotion/styled';
import Label from './Label';
import Icon from './Icon/Icon';
import { errorred20, grey80, red, white } from '../colors';

const Container = styled.div`
  width: 100%;
  height: 110px;
  position: relative;
`;

const SelectIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 15px;
  color:  ${props => props.selected ? white : red};
`;

const SelectField = styled.select`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 10px 20px;
  appearance: none;
  border: none;
  border-radius: 0;
  display: block;
  outline: 0 none;
  font-size: 16px;
  ${props => props.selected ? `background: ${grey80}; color: ${white};` : `background: ${errorred20}; color: ${red};`}
  border-radius: 4px;
`;

class Select extends React.Component {
  state = {
    focus: false,
  };

  render() {
    const {
      onChange,
      id,
      name,
      value,
      readOnly,
      disabled,
      className,
      placeHolder,
      options,
    } = this.props;

    const onChangeWrapper = (event) => {
      const value = event.currentTarget.value;

      if (!value) {
        return onChange(null);
      }

      const entry = options.find(choice => {
        return choice.value === value;

      });

      return onChange(entry.value);
    };

    const transformValue = (value) => {
      if (value === null) {
        return '';
      }

      const entry = options.find(choice => {
        return choice.value === value;
      });

      return entry.value;
    };

    return (
      <Container>
        <SelectIcon selected={value}>
          <Icon size={10} name="dropdown" />
        </SelectIcon>
        <SelectField
          data-cy={id}
          name={name}
          value={transformValue(value)}
          onChange={onChangeWrapper}
          className={className}
          readOnly={readOnly}
          disabled={disabled}
          selected={value}
          onBlur={() => this.setState({ focus: false })}
          onFocus={() => this.setState({ focus: true })}
        >
          <option value="">{placeHolder}*</option>
          {options.map(choice => (
            <option data-cy={'option-' + choice.label} key={choice.value} value={choice.value}>{choice.label}</option>
          ))}
        </SelectField>
        {this.renderLabel()}
      </Container>

    );
  }

  renderLabel() {
    const { id, value, label, required, error } = this.props;
    const { focus } = this.state;

    if (error) {
      return <ErrorMessage id={id} error={error} />;
    }

    return (
      <Label focus={true} focusx={focus || value !== null}>
        {label}
        {required && '*'}
      </Label>
    );
  }
}

export default Select;
