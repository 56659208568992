import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../components/PageContent';
import PageModal from '../components/PageModal';
import OrganisationModal from '../components/OrganisationModal';
import Page from '../components/Page';
import Curtain from '../components/Curtain';
import {withTranslation} from "react-i18next";
import styled from '@emotion/styled';
import {buttonmain, grey10} from "../colors";
import 'semantic-ui-css/components/transition.css';
import Alert from '../components/Alert';
import {LinkButton, Button} from "../components/Button";
import {get} from '../security/Api';
import {devices} from "../constants"
import moment from 'moment';

import MultiToggleComponent from "../components/MultiToggle";
import {HeadlineContainer} from "../components/Headline";
import {InfoPopupBlue} from "../components/InfoPopup";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Skeleton} from "@mui/material";
import ContactSupportForm from "../components/ContactSupportForm";

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Icon from "../components/Icon/Icon";
import {useTheme} from '@mui/material/styles';

const Column = styled.div`
    float: left;
    width: 100%;
    padding: 0 10px;
    @media only screen and ${devices.xs} {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.sm} {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.md} {
      width: 50%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.lg} {
      width: 33%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.xl} {
      width: 20%;
      display: block;
      margin-bottom: 20px;
    }    
`;

const ColumnRight = styled.div`
    float: right;
    width: 100%;
    padding: 0 10px;
    @media only screen and ${devices.xs} {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.sm} {
      width: 70%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.md} {
      width: 70%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.lg} {
      width: 80%;
      display: block;
      margin-bottom: 20px;
    }
`;

const ColumnLeft = styled.div`
    float: left;
    width: 100%;
    padding: 0 10px;
    @media only screen and ${devices.xs} {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.sm} {
      width: 30%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.md} {
      width: 30%;
      display: block;
      margin-bottom: 20px;
    }
    @media only screen and ${devices.lg} {
      width: 20%;
      display: block;
      margin-bottom: 20px;
    }
`;

const Row = styled.div`
    margin: 0 -5px;
    // text-align: center;
`;

const BackRow = styled.div`
    background: ${grey10};
    padding: 40px;
    margin: 20px;
    // text-align: center;
`;

const BackRowWhite = styled.div`
    padding: 40px;
    margin: 20px;
    margin-top: 0;
    // text-align: center;
`;

const FlexColumn = styled.div`
    flex-direction: column;
    margin: 0 -5px;
    display: flex;
    // text-align: center;
`;

const Card = styled.div`    
    height: 38vh;
    padding: 16px;
    padding-bottom: 30px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 5px;
    border: 3px solid;
    border-color: #fafafa;
    
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
  &:hover {
    border-color: ${buttonmain};
  };
`;

const NumberOfSigns = styled.div`
    // padding: 10px 10px;
    // margin: 20px;
    // border-radius: 15px;
    // border 3px solid;
    // border-color: blue;
    // color: blue;
    font-weight: bold;
    // font-size: x-large;
    min-height: 50px;
`;
const Price = styled.div`
    // padding: 10px 10px;
    // margin: 20px;
    // border-radius: 15px;
    // border 3px solid;
    // border-color: blue;
    color: blue;
    font-weight: bold;
    font-size: x-large;
`;

const Text = styled.div`
    margin-top: 5px;
    display: flex;
    max-width: 40%;
`;

const TextMiddled = styled.div`
    margin: auto;
    padding 15px 0;
    display: flex;
    text-align: center;
    font-size: x-large;
    font-weight: bold;
`;

const TextMiddledHeader = styled.div`
    margin: auto;
    padding 25px 0;
    display: flex;
    text-align: center;
    font-size: x-large;
    font-weight: bold;
`;

const TextMiddledTwo = styled.div`
    margin: auto;
    padding 15px 25px 15px 0;
    display: flex;
    text-align: left;
    // font-size: x-large;
    // font-weight: bold;
`;

const SuperSpan = styled.span`
    margin: 0 -5px;
    display: flex;
    text-align: center;
    // justify-content: flex-end;
`;
const AreaHeader = styled.h1`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: xx-large;
`;

function BuyCredits(props) {

  const {t} = props;
  const [organisation, setOrganisation] = useState(null);
  const [organisationOpen, setOrganisationOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [pickedPackage, setPickedPackage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [buySuccess, setBuySuccess] = useState(false);

  const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(true);
  const [paymentHistoryLoadingError, setPaymentHistoryLoadingError] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const numberOfHistoryRows = 5;

  const [selectedMethod, setSelectedMethod] = useState("Anonymisierung");
  const [selectedMethodDbName, setSelectedMethodDbName] = useState("Ano");
  // const [selectedMethodBalance, setSelectedMethodBalance] = useState("******");
  const [totalRowsByMethod, setTotalRowsByMethod] = useState(0);
  const [tarifRowsByMethod, setTarifRowsByMethod] = useState(null);
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [costnumbers, setCostnumbers] = useState([]);
  const activeMethods = [
    {
      method: "Anonymisierung",
      name: t('StudyDetail21', 'Anonymisierung'),
      db_name: "Ano"
    },
    {
      method: "Sanity",
      name: t('StudyDetail23', 'Sanity Check'),
      db_name: "Sanity"
    },
    {
      method: "Thema",
      name: t('StudyDetail24', 'Thematische Analyse'),
      db_name: "Thema"
    }
  ];
  useEffect(() => {
    countMethodTotalRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMethod, paymentHistoryLoading])

  useEffect(() => {
    loadOrganisation();
  }, [])

  useEffect(() => {
    loadPackages();
  }, [])

  useEffect(() => {
    loadCostNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadPaymentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfHistoryRows])

  let loadOrganisation = async () => {
    const response = await get(`/organisation/detail`);
    if (response.status === 200) {
      const responseData = await response.json();
      if (!responseData.id) {
        setLoaded(true);
        setLoadingError(true);
        return;
      } else {
        let organisationData = {
          id: responseData.id,
          name: responseData.name ? responseData.name : "",
          credits: responseData.credits ? responseData.credits : 0,
          adress: responseData.adress ? responseData.adress : "",
          adress_addon: responseData.adress_addon ? responseData.adress_addon : "",
          zip: responseData.zip ? responseData.zip : "",
          city: responseData.city ? responseData.city : "",
          country: responseData.country ? responseData.country : "DE",
          billing_address_diff: responseData.billing_address_diff ? responseData.billing_address_diff : false,
          invoice_adress: responseData.invoice_adress ? responseData.invoice_adress : "",
          invoice_adress_addon: responseData.invoice_adress_addon ? responseData.invoice_adress_addon : "",
          invoice_zip: responseData.invoice_zip ? responseData.invoice_zip : "",
          invoice_city: responseData.invoice_city ? responseData.invoice_city : "",
          invoice_country: responseData.invoice_country ? responseData.invoice_country : "DE",
        }
        setOrganisation(organisationData);
        setLoaded(true);
        setLoadingError(false);
        // setTotalRows(organisationData.credits);
      }
    } else {
      setLoaded(true);
      setLoadingError(true);
    }
  }

  let loadPackages = async () => {
    const response = await get(`/package/list`);

    if (response.status === 200) {
      const responseData = await response.json();

      setPackages(responseData);
      return;
    }
    setLoaded(true);
    setLoadingError(true);
  }

  let loadCostNumbers = async () => {
    const response = await get('/costnumber/list');

    if (response.status === 200) {
      const responseData = await response.json();

      let temp = {};
      let tmpCostNumber = [];
      let tmpObject = {};

      await Object.keys(responseData).forEach((key) => {
        let tmpRowsByMethod = {};
        if (Object.keys(responseData[key].creditHistories).length > 0) {
          responseData[key].creditHistories.forEach((value, index) => {
            if (!responseData[key].hasOwnProperty("currentTarifCredits")) {
              responseData[key].currentTarifCredits = {};
            }
            if (!temp.hasOwnProperty(value.methodName)) {
              temp[value.methodName] = 0;
            }
            if (!responseData[key].currentTarifCredits.hasOwnProperty(value.methodName)) {
              responseData[key].currentTarifCredits[value.methodName] = 0;
            }
            if (value.methodName) {
              temp[value.methodName] += parseInt(value.credit);
              responseData[key].currentTarifCredits[value.methodName] += parseInt(value.credit);
            }
            if (!tmpObject.hasOwnProperty(value.methodName)) {
              tmpObject[value.methodName] = 0;
            }
            if (value.methodName) {
              if (!tmpRowsByMethod.hasOwnProperty(value.methodName)) {
                tmpRowsByMethod[value.methodName] = parseInt(value.credit);
              } else {
                tmpRowsByMethod[value.methodName] += parseInt(value.credit);
              }
            }
          })
        } else {
          responseData[key].currentTarifCredits = {};
        }
        tmpCostNumber.push({name: responseData[key].name, rowsByMethod: tmpRowsByMethod});
      });
      console.log(tmpCostNumber);
      setCostnumbers(tmpCostNumber);
      setTarifRowsByMethod(temp);
      // setSelectedMethodBalance(temp[selectedMethod]);
    }
  }

  let loadPaymentHistory = async () => {

    setPaymentHistoryLoadingError(false);
    setPaymentHistoryLoading(true);

    const response = await get(`/payment/history?numberOfHistoryRows=${numberOfHistoryRows}`);

    if (response.status === 200) {
      const responseData = await response.json();

      setTimeout(() => setPaymentHistoryLoading(false), 2000);

      setPaymentHistory(responseData.sort(function (a, b) {
        let keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      }));
      setPaymentHistoryLoadingError(false);
    } else {
      setTimeout(() => setPaymentHistoryLoadingError(true), 5000);
    }
  }

  let handleBuy = (item) => {
    setPickedPackage(item);
    setOrganisationOpen(true);
  }

  let countMethodTotalRows = () => {
    let total = 0;
    costnumbers.forEach((number) => {
      if (number.rowsByMethod[selectedMethodDbName] !== undefined) {
        total += number.rowsByMethod[selectedMethodDbName];
      }
    })
    setTotalRowsByMethod(total);
    return total;
  }

  /*
  let handleBuyTest = () => {
      setPickedPackage({"credits": "Test", "id": "test", "note": "", "price": 0});
      setOrganisationOpen(true);
  }
  */

  let closeOrganisationModal = () => {
    setPickedPackage(null);
    setOrganisationOpen(false);
  }

  let updateOrganisationForm = (values) => {
    let creditsChanged = values.credits !== organisation.credits;
    if (creditsChanged) {
      // setCreditsVisible(false);
      // loadCostNumbers(props);
    }
    setOrganisation(values);
    // setCreditsVisible(true);
    setBuySuccess(true);
  }

  let renderActiveMethodList = () => {
    return (
      <MultiToggleComponent
        activeMethods={activeMethods}
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        // setSelectedMethodBalance={setSelectedMethodBalance}
        // tarifRowsByMethod={tarifRowsByMethod}
        setSelectedMethodDbName={setSelectedMethodDbName}
      />
    );
  }

  let renderPackageTiles = () => {
    return (
      <>
        {packages.map((value) => {
          if (selectedMethod === value.methodName) {
            return (
              <Column>
                <Card>
                  {value.id === "CUSTOM" &&
                  <>
                    <NumberOfSigns>
                    </NumberOfSigns>
                    <Price>
                      <h3>{t("cost510", "Got a bigger project?")}</h3>
                    </Price>
                    <Button
                      texttransformnone
                      mainoutlined
                      // to={'/support/contact'}
                      tiny
                      onClick={() => {
                        setSupportModalOpen(true)
                      }}
                      darkblue
                      rounded
                      fullWidth
                      target="_blank"
                      data-cy="custom-package"
                    >
                      {t("cost700", "Contact Us")}
                    </Button>
                  </>
                  }
                  {value.id !== "CUSTOM" &&
                  <>
                    <NumberOfSigns>
                      <p>{value.rows} <br/>{t("cost300", "Zeilen")}</p>
                    </NumberOfSigns>
                    <Price>
                      <h3>&euro;{value.price}</h3>
                    </Price>

                    <p>{t("cost225", "Entspricht einem Preis von")}&nbsp;  {value.centsPerRow} &nbsp; {t("cost226", "Cent pro Zeile.")}</p>
                    <br/>

                    <Button
                      darkblue
                      tiny
                      rounded
                      fullWidth
                      target="_blank"
                      data-cy="select-package"
                      onClick={() => handleBuy(value)}
                    >
                      {t("cost222", "Wählen")}
                    </Button>
                  </>
                  }
                </Card>
              </Column>
            )
          }
          return false;
        })}
      </>
    );
  }

  let renderTableSkeleton = (input) => {
    if (paymentHistoryLoadingError) return (<text>-</text>);
    if (!paymentHistoryLoading) return input;

    return (
      <Skeleton animation="wave" variant="text"/>
    );
  }

  let changeMethodName = (code) => {
    let nsme = "";

    activeMethods.forEach((method) => {
      if (method.db_name === code) {
        nsme = method.name;
      }
    })
    return nsme;
  }

  let renderHistoryTable = () => {
    return (
      <FlexColumn>
        <BackRowWhite>
          <ColumnLeft>
            <TextMiddled>
              {t("pakete400", "Historie")}
            </TextMiddled>
          </ColumnLeft>
          <ColumnRight>
            <TableContainer>
              <Table sx={{minWidth: 650}} aria-label="history table">
                <TableHead>
                  <TableRow style={{borderBottom: "2px solid", borderColor: "lightgray"}}>
                    <TableCell style={{fontWeight: "bold"}}>{t('Packages500', "Methode")}</TableCell>
                    <TableCell style={{fontWeight: "bold"}} align="right">{t('Packages501', "Zeilen")}</TableCell>
                    <TableCell style={{fontWeight: "bold"}} align="right">{t('Packages502', "Gebucht am")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentHistory.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {renderTableSkeleton(changeMethodName(row.methodName))}
                      </TableCell>
                      <TableCell align="right">{renderTableSkeleton(row.credit)}</TableCell>
                      <TableCell align="right">{renderTableSkeleton(
                        moment(row.createdAt).format('DD.MM.YYYY')
                      )}</TableCell>
                      {/*<TableCell align="right">{renderTableSkeleton(row.credits)}</TableCell>*/}
                    </TableRow>
                  ))}
                  {/*<TablePagination*/}
                  {/*  rowsPerPageOptions={[5, 10, 25]}*/}
                  {/*  component="div"*/}
                  {/*  count={5}*/}
                  {/*  rowsPerPage={5}*/}
                  {/*  showFirstButton={false}*/}
                  {/*  showLastButton={false}*/}
                  {/*  page={1}*/}
                  {/*  onPageChange={() => {alert("changed")}}*/}
                  {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
                  {/*/>*/}
                </TableBody>
              </Table>
            </TableContainer>
          </ColumnRight>
        </BackRowWhite>
      </FlexColumn>
    )
  }

  let renderTotalRowsTable = () => {
    return (
      <FlexColumn>
        <BackRowWhite>
          <TableContainer>
            <Table aria-label="history table">
              <TableHead>
                <TableRow style={{borderBottom: "2px solid", borderColor: "lightgray"}}>
                  <TableCell style={{fontWeight: "bold"}}>{t('cost3', "Kostennummer")}</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{t('buyCredits99', "Anzahl an Zeilen")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costnumbers.map((number, index) => (
                  <TableRow
                    key={number.name}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {number.name !== "" ? number.name : "Test"}
                    </TableCell>
                    <TableCell
                      align="right">{renderTableSkeleton(number.rowsByMethod[selectedMethodDbName] !== undefined ? number.rowsByMethod[selectedMethodDbName] : 0)}</TableCell>
                    {/*<TableCell align="right">{renderTableSkeleton(row.credits)}</TableCell>*/}
                  </TableRow>
                ))}
                <TableRow
                  key={"Total"}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {t('organisationModal17', "Gesamtbetrag")} &nbsp; (
                    {activeMethods.map((method) => {
                      if(selectedMethod === method.method) {
                        return (<>{method.name}</>)
                      }
                      else return false;
                    })}
                    )
                  </TableCell>
                  <TableCell align="right">{renderTableSkeleton(totalRowsByMethod)}</TableCell>
                  {/*<TableCell align="right">{renderTableSkeleton(row.credits)}</TableCell>*/}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </BackRowWhite>
      </FlexColumn>
    )
  }

  let mainContent = (

    <Page>
      <PageContent>
        <HeadlineContainer noFlex>
          <AreaHeader>
            <Text>
              {t('Packages301', "Flexible Preispakete: Wählen Sie eine Methode und Ihren Bedarf aus")}
            </Text>
            <SuperSpan>
              <LinkButton
                texttransformnone
                target="_blank"
                rounded
                mainoutlined
                data-cy="add-user"
                to={'/costnumber/create'}
                tiny={"true"}
              >
                {t("cost12", "Kostennummern verwalten")}
              </LinkButton>
              <InfoPopupBlue
                content={t('packages310', "We need some text here")}
              />
            </SuperSpan>
          </AreaHeader>
        </HeadlineContainer>


        <Row>
          <FlexColumn>
            <BackRow>
              <Row>
                <ColumnLeft>
                  <TextMiddled>
                    {t("pakete304", "Methode")}
                  </TextMiddled>
                </ColumnLeft>
                <ColumnRight>
                  <Row>
                    {renderActiveMethodList()}
                  </Row>
                  {/*<Row>*/}
                  {/*  {renderCurrentRowBalance()}*/}
                  {/*</Row>*/}
                </ColumnRight>
              </Row>
              <Row>
                <ColumnLeft>
                  <TextMiddledHeader>
                    {t("pakete305", "Preis pro Zeile")}
                  </TextMiddledHeader>
                  <TextMiddledTwo>
                    {t("pakete305", "Eine Zeile enthält bis zu 300 Zeichen Text. Falls die länge des Textes, z.B. eine Antwort aus einer " +
                      "Befragung, über 300 Zeichen liegt, werden automatisch 2 Zeilen (oder mehr) berechnet.")}
                  </TextMiddledTwo>
                </ColumnLeft>
                <ColumnRight>
                  <Row>
                    {renderPackageTiles()}
                  </Row>
                </ColumnRight>
              </Row>

              <Row>
                <ColumnLeft>
                </ColumnLeft>
                <ColumnRight>
                  {renderTotalRowsTable()}
                </ColumnRight>
              </Row>
            </BackRow>
          </FlexColumn>
        </Row>

        <Row>
          {renderHistoryTable()}
        </Row>
      </PageContent>

      {organisationOpen &&
      <OrganisationModal
        asModal={true}
        onClose={closeOrganisationModal}
        organisationFormData={organisation}
        updateOrganisationForm={updateOrganisationForm}
        packageObj={pickedPackage}
        projectCostNumber={props.projectCostNumber}
        activeMethods={activeMethods}
        tarifRowsByMethod={tarifRowsByMethod}
      />
      }
      {buySuccess &&
      <Alert
        type="info"
        message={t('organisationModal20', 'Success!')}
        onClose={() => setBuySuccess(false)}
      />
      }
    </Page>
  )

  if (loaded === false) {
    return (
      <Curtain type="loading"/>
    );
  }
  if (loadingError === true) {
    return (
      <Curtain type="error"/>
    );
  }
  return (
    <React.Fragment>
      {!props.asModal &&
      <Page>
        <Dialog
          open={supportModalOpen}
          onClose={() => setSupportModalOpen(false)}
          fullScreen={fullScreen}
          // fullWidth={true}
          maxWidth={false}
          scroll={'body'}
        >
          <IconButton
            aria-label="close"
            onClick={() => setSupportModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon size={10} name="close_small"/>
          </IconButton>
          <ContactSupportForm
            noPadding
            buying={true}
          />
        </Dialog>
        {mainContent}
      </Page>
      }
      {props.asModal &&
      <PageModal dark={true} onClose={() => props.onClose()}>
        {mainContent}
      </PageModal>
      }
    </React.Fragment>

  );
}

export default withRouter(withTranslation("translations")(BuyCredits));
