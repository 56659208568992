import React, {useState} from 'react';
import styled from '@emotion/styled';
import CheckBox from './CheckBox';
import RadioButton from './RadioButton';
import Icon from "./Icon/Icon";

import {withTranslation} from "react-i18next";

import {darkblue} from '../colors';

const Headline = styled.div`
    font-size: 16px;
    margin-bottom: 5px;
`;

const Column = styled.div`
    color: ${props => props.color ? props.color : darkblue}
`;

const Container = styled.div`
    color: ${props => props.color ? props.color : darkblue}
`;

const IconContainer = styled.div`
    width: 100%;
    display: flex;
`;

const EyeIcon = styled(Icon)`
    max-width: 30px;
    height: 32px;
    position: relative;
    top: 2px;
    left: -26px;
    z-index: 1;
    cursor: pointer;
    display: inline-block;
`;

const Inputer = styled.input`
    width: 100%;
    height: 26px;
    margin-top: 5px;
    padding: 10px 10px;
    appearance: none;
    border: none;
    display: inline-block;
    outline: 0 none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

function OptionsBox(props) {
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [userDefined, setUserDefined] = useState("[XXX]");

  const {options, onChange, type = "", headline = "", vertical = false, color = null, onUserDefined} = props;

  function handleChange(optionId) {
    if (optionId !== "pseudoUser") {
      setUserDefined('[XXXz]');
      setShowUserEdit(false);
      onChange(optionId);
    } else {
      onChange(optionId);
      setShowUserEdit(true);
    }
  }

  function checkIfEnterAndSave(e) {
    if (e?.target?.value?.length && e?.keyCode === 13) {
      onUserDefined(e?.target?.value);
    }
  }

  function tickBoxClick() {
    if (userDefined.length) {
      onUserDefined(userDefined);
    }
  }

  function renderOptions() {
    return (
      <React.Fragment>
        {options.map(option => {
          if (type === "radio") {
            return (
              <Container color={color}>
                <RadioButton
                  key={option.id}
                  id={option.id}
                  name={option.name}
                  value={option.value}
                  onChange={() => handleChange(option.id)}
                  color={color}
                />
              </Container>
            );
          } else {
            return (
              <CheckBox
                big
                key={option.id}
                id={option.id}
                label={option.label}
                value={option.value}
                onChange={() => onChange(option.id, option.value)}
              />
            );
          }
        })}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {vertical &&
      <Column color={color}>
        <Headline>
          {headline}
        </Headline>
        {renderOptions()}
        {showUserEdit &&
        <IconContainer>
          <Inputer
            type="text"
            onChange={(e) => setUserDefined(e?.target?.value)}
            onKeyUp={checkIfEnterAndSave}
            value={userDefined}/>

          <EyeIcon
            onClick={tickBoxClick} size={30}
            name={"tickbox30"}/>
        </IconContainer>
        }
      </Column>
      }
      {!vertical &&
      <React.Fragment>
        <Headline>
          {headline}
        </Headline>
        {renderOptions(color)}
      </React.Fragment>
      }

    </React.Fragment>
  );

}

export default withTranslation("translations")(OptionsBox);
