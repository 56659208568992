import React from 'react';

import ProjectForm from './ProjectForm';
import styled from '@emotion/styled';
import { grey80, white } from '../colors';
import { withTranslation } from "react-i18next";

const Background = styled.div`
    position: fixed;
    z-index: 5;
    top 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 20, 50, 0.86);
    overflow-y: auto;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    background: ${grey80};
    color: ${white};
    padding: 150px 48px 50px 48px;
`;

class ProjectCreateModal extends React.PureComponent {
    componentDidMount() {
        document.body.style.overflow = 'hidden auto';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    render() {

        const { userLoaded,
          projectFormData,
          projectFormErrors,
          users,
          onSubmit,
          onClose,
          onChangeName,
          onChangeCostNumber,
          onChangeOwner,
          t } = this.props;

        if (userLoaded === false) {
            return (
                <Background>
                    <Container>
                        <div>{t("createprojectmodal1", "Loading...")}</div>
                    </Container>
                </Background>
            );
        }

        return (
            <React.Fragment>
                <Background>
                    <Container>
                        <ProjectForm
                            onChangeName={onChangeName}
                            onChangeCostNumber={onChangeCostNumber}
                            onChangeOwner={onChangeOwner}
                            name={projectFormData.name}
                            onClose={onClose}
                            onSubmit={onSubmit}
                            onBlur={onSubmit}
                            users={users}
                            owners={projectFormData.owners}
                            costNumberId={projectFormData.costNumberId}
                            formErrors={projectFormErrors}
                            create={true}
                        />
                    </Container>
                </Background>
            </React.Fragment>
        );
    }
}

export default withTranslation("translations")(ProjectCreateModal);
