import React from 'react';

import { post } from '../security/Api';
import tokenStorage from '../security/TokenStorage';

import PageContent from '../components/PageContent';

import CardBody, { CardBodyInner, CardBodyInnerContainer } from '../components/CardBody';
import Error from '../components/Error';

import { AnonymPageHeadline, AnonymPageInfo, LoginButtonContainer } from './Login';
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";
import error from "../error";
import TextInputLiveFeedback from '../components/TextInputLiveFeedback';
import { Button } from '../components/ButtonReg';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Form } from '../components/FormReg';
import Alert from '../components/Alert';


class PasswordReset extends React.PureComponent {

    state = {
        error: false,
        errorToken: null,
        errorMessage: null
    };

    render() {
        const { t } = this.props;
        const PasswordResetSchema = Yup.object().shape({
            password: Yup
                .string(t("registr57", "Ungültige Angabe Passwort"))
                .min(12, t("registr42", "Das Passwort muss mindestens 12 Zeichen beinhalten"))
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{0,}/, {
                    message: t("registr43", "Das Passwort muss Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten"),
                    excludeEmptyString: true
                })
                .required(t("registr47", "Dieses Feld muss ausgefüllt sein.")),
            passwordSecond: Yup
                .string(t("registr45", "Ungültige Angabe Organisation"))
                .required(t("registr47", "Dieses Feld muss ausgefüllt sein."))
                .test('match', t("registr46", "Die Passwörter stimmen nicht überein"), function (passwordSecond){
                    return passwordSecond === this.options.from[0].value.password
                })
         })

        return (

            <Page background={grey5}>
                <PageContent>

                    <CardBody>
                        <CardBodyInnerContainer anonym={true}>

                            <CardBodyInner anonym={true}>
                                <AnonymPageHeadline>{t('passwordreset1',"Neues Passwort")}</AnonymPageHeadline>
                                <AnonymPageInfo>{t('passwordreset2',"Bitte geben Sie ein neues Passwort an")}</AnonymPageInfo>
                            </CardBodyInner>

                            <CardBodyInner anonym={true}>

                                {this.state.errorToken && <Error>{t('passwordreset3',"Der Zurücksetzungslink ist leider ungültig.")}</Error>}
                                {!this.state.errorToken &&
                                    <Formik
                                        initialValues={{
                                              password: '',
                                              passwordSecond: ''
                                        }}
                                        validationSchema={PasswordResetSchema}
                                        validateOnBlur
                                        onSubmit= {async (values, {setFieldError}) => {
                                            const token = this.props.match.params.token;
                                            const { password, passwordSecond } = values;
                                            let onError = (code) => {
                                                if (code === 954){
                                                    this.setState({
                                                        errorToken: error(code, this.props.t)
                                                    })
                                                } else{
                                                    this.setState({
                                                        error: true,
                                                        errorMessage: error(code, this.props.t)
                                                    })
                                                }
                                            }
                                            let onSuccess = (responseData) => {
                                                tokenStorage.setToken(responseData.token);

                                                this.props.history.push('/project');
                                            }
                                            await post(
                                                '/password/reset',
                                                {
                                                    token: token,
                                                    password: password,
                                                    passwordSecond: passwordSecond,
                                                },
                                                onError,
                                                onSuccess
                                            );
                                        }}
                                    >
                                        <Form>
                                            <TextInputLiveFeedback
                                                id="password"
                                                name="password"
                                                type="password"
                                                helpText={t("registr48", "Das Passwort muss aus mindestens 12 Zeichen bestehen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten")}
                                                label={t('resetpassword4',"Neues Passwort")}
                                            />
                                            <TextInputLiveFeedback
                                                id="passwordSecond"
                                                name="passwordSecond"
                                                type="password"
                                                label={t('resetpassword6',"Neues Passwort wiederholen")}
                                            />
                                            <LoginButtonContainer>
                                                <Button id="submit" icon={'main_arrow'} type="submit">{t('passwordreset4',"Login")}</Button>
                                            </LoginButtonContainer>
                                        </Form>
                                    </Formik>
                                }
                            </CardBodyInner>

                        </CardBodyInnerContainer>
                    </CardBody>

                </PageContent>
                {this.state.error &&
                    <Alert
                        type="error"
                        message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
                        onClose={() => this.setState({error: false})}
                    />
                }
                {this.state.info && this.state.infoMessage &&
                    <Alert
                        type="info"
                        message={this.state.infoMessage}
                        onClose={() => this.setState({info: false})}
                    />
                }
            </Page>
        );
    }
}

export default withTranslation("translations")(PasswordReset);
