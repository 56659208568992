import React from 'react';
import {darkblue} from '../colors';
import styled from '@emotion/styled';
import Modal from './Modal';
import {Button} from './Button';
import Error from './Error';
import {withTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const Headline = styled.div`
  color: ${darkblue};
  font-size: 32px;
  margin-bottom: 28px;
  text-align: left;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-top: 25px;
`;

const MessageContainer = styled.div`
  color: ${darkblue};
  margin-bottom: 28px;
  text-align: left;
`;

class DeleteStudyModal extends React.PureComponent {

  render() {
    const {onClick, onClose, error, t, waiting} = this.props;

    return (
      <Modal onClose={onClose}>
        <Headline>
          {t("todo100", "Thema löschen")}
        </Headline>

        {waiting &&
        <CircularProgress/>
        }
        {!waiting &&
        <>
          <MessageContainer>
            {t("todo101", "Dadurch wird das Thema endgültig gelöscht. Möchten Sie wirklich fortfahren?")}
          </MessageContainer>
          {error &&
          <Error>
            {error}
          </Error>
          }
        </>
        }

        <Footer>
          <Button disabled={waiting} data-cy="abort-modal"
                  onClick={() => onClose()}>{t("deletestudymodal3", "Abbrechen")}</Button>
          <Button disabled={waiting} delete={true} data-cy="submit-modal"
                  onClick={() => onClick()}>{t("deletestudymodal4", "Löschen")}</Button>
        </Footer>
      </Modal>
    )
      ;
  }
}

export default withTranslation("translations")(DeleteStudyModal);
