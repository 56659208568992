import React from 'react';

import { post } from '../security/Api';
import publicIp from "public-ip";
import Input from '../components/Input';
import { Button } from '../components/Button';
import PageContent from '../components/PageContent';
import { Form } from '../components/Form';
import CardBody, { CardBodyInner, CardBodyInnerContainer } from '../components/CardBody';
import { AnonymPageHeadline, AnonymPageInfo, LoginButtonContainer } from './Login';
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";
import error from "../error";
import Alert from '../components/Alert';

class AdminAcceptInvitation extends React.PureComponent {
    state = {
        password: null,
        passwordSecond: null,
        error: false,
        errorMessage: null,
        ip: ''
    };

    componentDidMount = async() => {
        let currentIp = await publicIp.v4({});
        await this.setState({ip: currentIp});
    }

    onChange = (key, value) => {
        this.setState({ [key]: value });
    };

    onSubmit = async () => {
        const { match } = this.props;
        const token = match.params.token;

        const { password, passwordSecond } = this.state;
        let onAcceptError = (code) => {
            this.setState({
                error: true,
                errorMessage: error(code, this.props.t)
            })
        }
        let onAcceptSuccess = () => {

            this.props.history.push('/login')
        }
        await post(
            '/invite-admin/accept',
            {
                token: token,
                password: password,
                passwordSecond: passwordSecond,
                ip: this.state.ip,
            },
            onAcceptError,
            onAcceptSuccess
        );


    };

    render() {
        const { password, passwordSecond, error } = this.state;
        const { t } = this.props;

        return (
            <Page background={grey5}>
                <PageContent>
                    <CardBody>
                        <CardBodyInnerContainer anonym={true}>
                            <CardBodyInner anonym={true}>
                                <AnonymPageHeadline>{t('admin1',"Neues Passwort")}</AnonymPageHeadline>
                                <AnonymPageInfo>{t('admin7',"Neues Passwort")}</AnonymPageInfo>
                            </CardBodyInner>

                            <CardBodyInner anonym={true}>
                                {!error &&
                                    <Form>
                                        <Input
                                            id="password"
                                            type="password"
                                            label={t('admin3',"Neues Passwort")}
                                            required={true}
                                            value={password}
                                            onChange={value => this.onChange('password', value)}
                                        />
                                        <Input
                                            id="passwordSecond"
                                            type="password"
                                            label={t('admin4',"Neues Passwort wiederholen")}
                                            required={true}
                                            value={passwordSecond}
                                            onChange={value => this.onChange('passwordSecond', value)}
                                        />
                                        <LoginButtonContainer>
                                            <Button id="submit" icon={'main_arrow'} onClick={this.onSubmit}>{t('admin5',"Akzeptieren")}</Button>
                                        </LoginButtonContainer>
                                    </Form>
                                }
                            </CardBodyInner>
                        </CardBodyInnerContainer>
                    </CardBody>
                </PageContent>
                {error &&
                    <Alert
                        type="error"
                        message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
                        onClose={() => this.setState({error: false})}
                    />
                }
                {this.state.info && this.state.infoMessage &&
                    <Alert
                        type="info"
                        message={this.state.infoMessage}
                        onClose={() => this.setState({info: false})}
                    />
                }
            </Page>
        );
    }
}

export default withTranslation("translations")(AdminAcceptInvitation);
