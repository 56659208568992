import React, { Fragment } from "react";
import styled from "@emotion/styled";
import {blue, buttonmain, darkblue, grey5, white} from '../colors';
import { Link } from "react-router-dom";
import moment from 'moment';
import ProgressRing from './ProgressRing';
import Tooltip from './Tooltip';
import { ActionButton } from './Action';
import DeleteStudyModal from './DeleteStudyModal';
import { post } from '../security/Api';
import { withTranslation } from "react-i18next";

const StudyElementContainer = styled(Link)`
    display: flex;
    align-items: center;
    background: ${white};
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 0 25px;
    height: 64px;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;

    text-decoration: none;
    color: ${darkblue};

    &:hover {
        background: ${grey5};
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
`;

const Name = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Options = styled.div`
    display: flex;
    align-items: center;
    display: flex;
    // margin-right: 50px;
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    color: ${blue};
    margin-right: 10px;
`;

const Date = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
`;

const Text = styled.p`
    display: flex;
    align-items: right;
    // flex-grow: 1;
    justify-content: right;
    padding-right: 20px;
    // text-align: end;
    width: 20%;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

// const Text2 = styled.p`
//     // display: flex;
//     // align-items: center;
//     // flex-grow: 1;
//     padding-right: 55px;
//     font-family: AvenirLTPro-Black, AvenirNextCyrBold;
// `;

class StudyElement extends React.PureComponent {
    state = {
        deleteStudyModalOpen: false,
        deleteStudyModalError: null,
    };

    deleteStudyError = (code) => {
        this.setState({deleteStudyModalError: this.props.t("studyel2", 'Die Studie kann aktuell nicht gelöscht werden, da sie in diesem Moment verarbeitet wird.')});
    }

    methodNameRecognition = (name_from_db) => {
      switch (name_from_db) {
        case 'Anonymization':
          return this.props.t('StudyDetail21', 'Anonymisierung');
        case 'SanityCheck':
          return this.props.t('StudyDetail23', 'Sanity Check');
        case 'ThematicAnalysis':
          return this.props.t('StudyDetail24', 'Thematische Analyse');
        default: return false;
      }
    }

    deleteStudySuccess = (responseData) => {
        const {reload} = this.props;
        this.setState({deleteStudyModalError: null});
        reload();
        this.closeModal();

        return;
    }


    deleteStudy = async () => {
        const { id } = this.props;

        await post(
            '/study/delete',
            { studyId: id },
            this.deleteStudyError,
            this.deleteStudySuccess
        );
    };

    closeModal = () => {
        this.setState({ deleteStudyModalOpen: false, deleteFileModalError: null })
    };

    openModal = () => {
        this.setState({ deleteStudyModalOpen: true })
    };

    render() {
        const { to, name, createdAt, progress, status, studyMethod, t } = this.props;
        const { deleteStudyModalOpen, deleteStudyModalError } = this.state;

        return (
            <Fragment>
                <StudyElementContainer to={to}>
                    <IconContainer>
                        <ProgressRing
                            progress={progress}
                            status={status}
                        />
                    </IconContainer>
                    <Name data-cy={ name === "Second Study" ? "second-study" : ""}>
                        {name}
                    </Name>

                  <Text> {this.methodNameRecognition(studyMethod)}</Text>

                  <Options>
                        <Option>
                            <Tooltip text={t("studyel1", "Studie löschen")}>
                                <ActionButton color={buttonmain} onClick={(event) => this.openModal(event)} icon="delete_outline" size={24} />
                            </Tooltip>
                        </Option>
                    </Options>
                    <Date>{moment(createdAt).format('DD.MM.YYYY')}</Date>
                </StudyElementContainer>

                {deleteStudyModalOpen &&
                    <DeleteStudyModal
                        onClose={() => this.closeModal()}
                        onClick={() => this.deleteStudy()}
                        error={deleteStudyModalError}
                    />
                }
            </Fragment>
        );
    }
}
export default withTranslation("translations")(StudyElement);
