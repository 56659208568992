import React from 'react';
import { withRouter } from 'react-router-dom';

import PageContent from '../components/PageContent';
import { HeadlineContainer, HeadlineText } from '../components/Headline';
import { CardContainer, CardListContainer } from '../components/CardList';
import { get } from '../security/Api';
import CardHeader from '../components/CardHeader';
import moment from 'moment';
import Page from '../components/Page';
import Curtain from '../components/Curtain';
import { withTranslation } from "react-i18next";

class OrganisationList extends React.PureComponent {
    state = {
        errors: {},
        organisations: [],
        loaded: false,
        error: false,
        sortBy: null,
    };

    async componentDidMount() {
        const response = await get('/organisation/list');

        if (response.status === 200) {
            const responseData = await response.json();

            console.log(responseData);

            this.setState({
                loaded: true,
                organisations: responseData,
            });

            return;
        }


        this.setState({
            loadingError: true,
            loaded: true
        });
    }

    renderOrganisation = (organisation) => {
      console.log(organisation);
        //const { t } = this.props;

        return (
            <CardContainer key={organisation.id}>
                <CardHeader
                    id={`detail-${organisation.name}`}
                    icon="folder_outline"
                    info={moment(organisation.createdAt).format('DD.MM.YYYY')}
                    to={`/organisation/detail?organisation=${organisation.id}`}
                    deletionRequested={organisation.removal_request}
                >
                    {organisation.name + " (" + organisation.ownerEmail + " / " + organisation.ownerIp + ")"}
                </CardHeader>
            </CardContainer>
        );
    };

    render() {
        const { loaded, organisations, loadingError } = this.state;
        const { t } = this.props;

        if (loaded === false) {
            return (
                <Curtain type="loading"/>
            );
        }
        if (loadingError === true) {
            return (
                <Curtain type="error"/>
            );
        }

        return (
            <Page>
                <PageContent>

                    <HeadlineContainer>
                        <HeadlineText>{t('organisationlist2',"Alle Organisations")}</HeadlineText>
                    </HeadlineContainer>

                    <CardListContainer>
                        {organisations.map(this.renderOrganisation)}
                    </CardListContainer>

                </PageContent>
            </Page>
        );
    }
}

export default withRouter(withTranslation("translations")(OrganisationList));
