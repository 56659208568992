import React from 'react';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import {CardContainer, SortHeaderContainer} from '../components/CardList';
import CardBody from '../components/CardBody';
import StudyElement from '../components/StudyElement';

import {ActionButton} from '../components/Action';
import {withRouter} from 'react-router-dom';
import ProjectForm from '../components/ProjectForm';
import styled from '@emotion/styled';
import CardHeaderBackground from '../components/CardHeaderBackground';
import CardHeader from '../components/CardHeader';
import {black, buttonmain, grey10, grey70} from '../colors';
import FirstStudyButton from '../components/FirstStudyButton';
import FileUpload from '../components/FileUpload';
import CreateManualStudyModal from '../components/CreateManualStudyModal';
import Page from '../components/Page';
import {studyProgress} from '../studyStatus';
import DeleteProjectModal from '../components/DeleteProjectModal';
import DeleteFileModal from '../components/DeleteFileModal';
import {InfoPopupGreen} from '../components/InfoPopup';
import 'semantic-ui-css/components/popup.min.css';
import {withTranslation} from "react-i18next";
import i18n from 'i18next';
import error from "../error";
import Alert from '../components/Alert';
import UserOnboarding from "../components/UserOnboarding";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flow-direction: row;
    align-items: center;
    padding: 20px 0;
`;

export const DetailSortHeaderContainer = styled(SortHeaderContainer)`
    padding: 0 25px;
`;

const AreaHeader = styled.div`
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    letter-spacing: 0.75px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 0 0 0 42px
`;

const AreaSubHeader = styled.div`
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    margin-top: 30px;
    padding: 0 0 0 42px;
    color: ${grey70};
    font-family: AvenirNextLTPro-Medium, AvenirNextCyrMedium;
`;

const Text = styled.div`
    margin-bottom: -4px;
`;

const NoStudyInformation = styled.div`
    margin-top: 30px;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    color: ${grey70};
`;

const UploadArea = styled.div`
    padding: 0 0 0 42px;
    width: 60%;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StudiesContainer = styled.div`
    height:250px;
    overflow-y: scroll;
`;

class ProjectDetail extends React.PureComponent {
  state = {
    project: null,
    loaded: false,
    error: false,
    errorMessage: null,
    loadingError: false,
    formData: {
      name: '',
      owners: [],
      costNumberId: null,
    },
    formErrors: {},
    errors: {},

    uploadedFile: null,
    selectedFile: null,
    notice: null,
    createManualStudyModalOpen: false,

    deleteFileModalOpen: false,
    deleteFileModalFileId: null,
    deleteFileModalError: null,

    deleteProjectModalOpen: false,
    deleteProjectModalError: null,

    currentStep: this.props.currentStep,
    closeTour: this.props.closeTour,
    tourConfig: this.props.tourConfig,
    isTourOpen: this.props.isTourOpen,
    setTourPassed: this.props.setTourPassed,
    nextStepClicked: this.props.nextStepClicked,

    onRequestClose: this.props.closeTour,
    steps: this.props.tourConfig,
    isOpen: this.props.isTourOpen,
    isProjectSubmitted: this.props.isProjectSubmitted,
    setIsProjectSubmitted: this.props.setIsProjectSubmitted,

  };
  t = this.props.t;

  async componentDidMount() {
    this.loadProject();

  }

  onFileUpload = async () => {
    this.loadProject();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkWizzardStep();
    const {uploadedFile} = this.state;
    if (prevState.uploadedFile === uploadedFile) {
      return;
    }
    this.loadProject();
  }

  checkWizzardStep() {
    if (this.props.currentStep === 6) {
      this.onStudyCreate(this.state.project, true);
    }
  }

  updateFormField = async (key, value) => {
    await this.setState({
      formErrors: {
        ...this.state.formErrors,
        'name': null
      },
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  };

  createManualStudy = async () => {
    const {selectedFile, notice, project} = this.state;
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
    let onSuccess = (responseData) => {
      return responseData;
    }
    await post(
      '/study/create-manual',
      {
        fileId: selectedFile,
        projectId: project.id,
        notice: notice,
      },
      onError,
      onSuccess
    );

  };

  submitManualStudyModal = async (notice) => {
    await this.setState({
      notice: notice,
    });

    await this.createManualStudy();
    this.closeManualStudyModal();
    await this.loadProject();
  };

  openManualStudyModal = () => {
    this.setState({
      createManualStudyModalOpen: true,
    });
  };

  closeManualStudyModal = () => {
    this.setState({
      createManualStudyModalOpen: false,
    });
  };

  submitDeleteFileModal = async () => {
    const {deleteFileModalFileId} = this.state;
    let onError = (code) => {
      this.setState({
        deleteFileModalError: this.t("projectdetail18", 'Die Datei kann aktuell nicht gelöscht werden, da eine Studie in diesem Moment verarbeitet wird mit dieser Datei.')
      })
    }
    let onSuccess = async () => {
      await this.setState({deleteFileModalError: null});
      await this.loadProject();
      await this.closeDeleteFileModal();
    }
    await post(
      '/file/delete',
      {fileId: deleteFileModalFileId},
      onError,
      onSuccess
    );
  };

  openDeleteFileModal = async (fileId) => {
    await this.setState({
      deleteFileModalOpen: true,
      deleteFileModalFileId: fileId,
    });
  };

  closeDeleteFileModal = () => {
    this.setState({
      deleteFileModalOpen: false,
      deleteFileModalFileId: null,
    });
  };

  updateFile = (filename) => {
    this.setState({
      uploadedFile: filename,
    });
  };

  editProject = async () => {

    const {match, t} = this.props;
    const {formData} = this.state;

    const projectId = match.params.projectId;
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, t)
      })
      this.loadProject();
    }
    let onSuccess = () => {
      this.setState({
        info: true,
        infoMessage: t("projectdetail22", "Projektdetails wurden gespeichert")
      })
    }
    await post(
      '/project/edit',
      {
        id: projectId,
        name: formData.name,
        ownerId: formData.owners[0],
        costNumberId: formData.costNumberId,
      },
      onError,
      onSuccess
    );

  };

  closeProject = () => {
    const {history} = this.props;
    history.push(`/project`);
  };

  onStudyCreate = async (project, isWizardStudy) => {
    const {history} = this.props;
    const {formData} = this.state;
    const projectId = project.id;
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.t)
      })
    }
    let onSuccess = (responseData) => {
      this.setState({
        error: false,
        errorMessage: null
      });
      const studyId = responseData.id;

      history.push(`/project/${projectId}/study/${studyId}`);

    }
    await post(
      '/study/create',
      {
        ...formData,
        projectId: projectId,
        defaultName: this.t("ProjectDetail21", "Studie"),
        isWizardStudy: isWizardStudy,
      },
      onError,
      onSuccess
    );

  };

  onProjectDelete = async (project) => {
    const {history, t} = this.props;
    let onError = (code) => {
      this.setState({
        deleteProjectModalError: t("projectdetail11", 'Das Projekt kann aktuell nicht gelöscht werden, da innerhalb des Projektes eine Studie in diesem Moment verarbeitet wird.'),
      });
    }
    let onSuccess = () => {
      this.setState({
        deleteProjectModalError: null,
        deleteProjectModalOpen: false,
      });

      history.push(`/project`);
    }
    await post(
      '/project/delete',
      {
        projectId: project.id,
      },
      onError,
      onSuccess
    );
  };

  editFileLanguage = async (language, file) => {
    const response = await get(`/file/${file.id}/editLanguage/${language}`);
    if (response.status === 200) {
      this.loadProject();
    }
  }

  loadProject = async () => {
    const {match} = this.props;
    const projectId = match.params.projectId;

    const response = await get(`/project/${projectId}/detail`);

    if (response.status === 200) {
      const responseData = await response.json();

      await this.setState({
        loaded: true,
        loadingError: false,
        project: this.sortStudiesInProject(responseData),
        formData: {
          name: responseData.name,
          owners: [responseData.ownerId],
          costNumberId: responseData.costNumberId,
        },
      });
      return;
    }

    if (response.status === 400) {
      const responseData = await response.json();
      if (responseData.code) {
        await this.setState({loaded: true, loadingError: true, errorMessage: error(responseData.code, this.t)});
        return;
      } else {
        await this.setState({loaded: true, loadingError: true, errorMessage: error(0, this.t)});
        return
      }
    }
  };

  sortStudiesInProject = (project) => {

    project.studies.sort((a, b) => {

      let myDateA = new Date(a.createdAt);
      let myDateB = new Date(b.createdAt);
      if (myDateA > myDateB) {
        return -1;
      } else return 0;
    });
    return project;
  }

  render() {
    const {t} = this.props;
    const {
      project,
      loaded,
      formData,
      formErrors,
      error,
      loadingError,
      createManualStudyModalOpen,
      deleteFileModalOpen,
      deleteFileModalError,
      deleteProjectModalOpen,
      deleteProjectModalError,
    } = this.state;
    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="error"/>
      );
    }

    return (
      <React.Fragment>
        <Page>
          <PageContent withGradient={false}>
            <CardHeaderBackground>
              <ProjectForm
                onChangeName={(value) => this.updateFormField('name', value)}
                onChangeOwner={(value) => this.updateFormField('owner', value)}
                onChangeCostNumber={(value) => this.updateFormField('costNumberId', value)}
                name={formData.name}
                costNumberId={formData.costNumberId}
                owners={formData.owners}
                onClose={this.closeProject}
                onSubmit={this.editProject}
                onDelete={() => this.setState({deleteProjectModalOpen: true})}
                error={error}
                formErrors={formErrors}
                doNotSubminOnBlur={false}
              />
              <CardHeader color={black} background={grey10}>
                <AreaHeader>
                  <Text>
                    {t('projectdetail2', "Studien")}
                  </Text>
                  <InfoPopupGreen
                    content={t('projectdetail20', "Hier finden Sie alle angelegten Dokumente, aktiven Services oder finalen Ergebnisse zu diesem Projekt.")}
                  />
                </AreaHeader>
              </CardHeader>
            </CardHeaderBackground>
            <CardContainer>
              {this.renderStudy(project)}
            </CardContainer>

            {//this.renderFiles(project)
            }


          </PageContent>
          {createManualStudyModalOpen &&
          <CreateManualStudyModal
            onClose={() => this.closeManualStudyModal()}
            onClick={(notice) => this.submitManualStudyModal(notice)}
            headline={t('projectdetail10', 'Daten mit Support teilen')}
          />
          }

          {deleteFileModalOpen &&
          <DeleteFileModal
            onClose={() => this.closeDeleteFileModal()}
            onClick={() => this.submitDeleteFileModal()}
            error={deleteFileModalError}
          />
          }
          {this.state.error &&
          <Alert
            type="error"
            message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
            onClose={() => this.setState({error: false})}
          />
          }
          {this.state.info && this.state.infoMessage &&
          <Alert
            type="info"
            message={this.state.infoMessage}
            onClose={() => this.setState({info: false})}
          />
          }


        </Page>

        {deleteProjectModalOpen &&
        <DeleteProjectModal
          onClose={() => this.setState({deleteProjectModalOpen: false})}
          onClick={() => this.onProjectDelete(project)}
          error={deleteProjectModalError}
        />
        }
        <UserOnboarding
          steps={this.state.tourConfig}
          isOpen={this.state.isTourOpen}
          rounded={25}
          accentColor={"#5cb7b7"}
          onRequestClose={this.state.closeTour}
          setTourPassed={this.state.setTourPassed}
          currentStep={this.state.currentStep}
          nextStepClicked={this.state.nextStepClicked}
          onSubmit={() => {return false;}}
        />
      </React.Fragment>

    );
  }

  renderStudy(project) {
    const hasStudies = project.studies.length > 0;
    const {t} = this.props;
    return (
      <CardBody background={grey10} noTopPadding={true} noBottomPadding={hasStudies}>

        {!hasStudies && (
          <div>
            <FirstStudyButton onClick={() => this.onStudyCreate(project)}/>
            <NoStudyInformation>{t('projectdetail3', "Es ist noch keine Studie angelegt oder ausgeführt worden.")}</NoStudyInformation>
          </div>
        )}
        <StudiesContainer>
          {project.studies.map(study => {
            return <StudyElement
              to={`/project/${project.id}/study/${study.id}`}
              key={study.id}
              id={study.id}
              name={study.files[0] ? study.name + ' (' + study.files[0].name + ')' : study.name}
              status={study.status}
              studyMethod={study.method}
              createdAt={study.createdAt}
              progress={studyProgress(study)}
              reload={() => this.loadProject()}
            />;
          })}
        </StudiesContainer>

        {hasStudies && (
          <ActionButtonContainer>
            <ActionButton id="create-study" icon="ai_circle_white" color={buttonmain}
                          onClick={() => this.onStudyCreate(project)}>
              {t('projectdetail4', "Neue Studie hinzufügen")}
            </ActionButton>
          </ActionButtonContainer>
        )}
      </CardBody>
    );
  }

  renderFiles(project) {
    const {selectedFile} = this.state;
    const {t} = this.props;

    return (
      <UploadArea>
        <AreaHeader>
          <Text>
            {t('projectdetail5', "Datenspeicher")}
          </Text>
          <InfoPopupGreen
            content={t('projectdetail6', "Sie können den Datenspeicher auch als Transfermedium benutzen, z.B. um Rohdaten mit dem Support von deepsight zu teilen.")}/>

        </AreaHeader>
        <AreaSubHeader>
          {t('projectdetail13', "Alle für dieses Projekt hochgeladenen Daten werden hier aufgelistet – ganz gleich ob Rohdaten für Data Services oder andere relevante Dokumente für das Projekt.")}
        </AreaSubHeader>
        <FileUpload
          selected={selectedFile}
          changeSelected={(selectedFile) => this.setState({selectedFile: selectedFile})}
          action={() => this.openManualStudyModal()}
          actionName={t('projectdetail7', "Mit Support teilen")}
          deleteAction={(fileId) => this.openDeleteFileModal(fileId)}
          support={true}
          language={i18n.language}
          uploadUrl={`/${project.id}/file/upload`}
          onUpload={(filename) => this.updateFile(filename)}
          files={project.files}
          errors={[]}
          editFileLanguage={async (language, file) => this.editFileLanguage(language, file)}
        />
      </UploadArea>

    );
  }
}

export default withRouter(withTranslation("translations")(ProjectDetail));
