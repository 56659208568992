import React, {Component} from 'react';
import styled from '@emotion/styled';
import publicIp from "public-ip";

import {post} from '../security/Api';
import {Button} from '../components/ButtonReg';
import PageContent from '../components/PageContent';
import CardBody, {CardBodyInner} from '../components/CardBody';
// import {isDeepsightAdmin} from '../security/Role';
import {HeadlineText} from '../components/CardHeaderBackground';
import Page from '../components/Page';
import {white} from '../colors';

import Grid from '@mui/material/Grid';

import error from "../error";

import {withTranslation} from "react-i18next";
import TextAreaInput from "../components/TextAreaInput";

import {Formik} from 'formik';
import * as Yup from "yup";
import {Form} from '../components/FormReg';
// import ErrorMessage from "../components/Error";
import SuccessMessage from "../components/SuccessMessageNew";
import ErrorMessage from "../components/Error";
import TextInputLiveFeedback from "../components/TextInputLiveFeedback";



let controller = new AbortController();
// let signal = controller.signal;

export const MiniHeadline = styled.div`
    margin: 0 0 50px 0;
    color: ${white};
    Font-size: 15px;
    letter-spacing: 0.75px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-transform: uppercase;
`;

export const AnonymPageHeadline = styled(HeadlineText)`
    color: #fafafa;
    font-size: 40px;
    margin-top: -25px;
    padding-bottom: 35px;
    font-weight: bolder;
`;

export const AnonymPageInfo = styled(HeadlineText)`
    color: #fafafa;
    font-size: 24px;
`;

export const LoginButtonContainer = styled.div`
    margin-top: 20px;
`;

class ContactUs extends Component {
  state = {
    name: '',
    email: null,
    phone: '',
    subject: '',
    message: '',
    error: false,
    success: false,
    errorMessage: null,
    ip: ''
  };

  componentDidMount = async () => {
    let currentIp = await publicIp.v4({});
    await this.setState({ip: currentIp});
  }

  componentWillUnmount() {
    controller.abort();
  }

  onChange = (key, value) => {
    this.setState({[key]: value});
  };

  onSignUpSuccess = () => {
    this.setState({
      error: false,
      errorMessage: null,
      success: true
    });
    this.props.history.push('/contactus');
    return;
  }

  onSignUpError = async (code, setFieldError) => {
    if (code === 492) { //the email is in use
      await setFieldError('email', error(code, this.props.t));
    } else {
      await this.setState({
        error: true,
        errorMessage: error(code, this.props.t),
        success: false
      })
    }
  }

  renderLeftHeader() {
    const {t} = this.props;
    return (
      <Grid item xs={1} md={1} key="2">
        <CardBody background={"#151f29"}>
          <CardBodyInner>
            <MiniHeadline>{t('contactus1', "Kostenlos testen")}</MiniHeadline>
            <AnonymPageHeadline>{t('contactus2', "Ein kleiner Schritt bis zum Testen, ein riesiger Schritt fürs Business")}</AnonymPageHeadline>
            <AnonymPageInfo>
              {t('contactus3', "Sie können den DataDesk testen und die Anonymisierung testen. Es stehen 500 Zeichen zum Testen frei. Der Zugang ist 30 Tage zugängilich.")}
            </AnonymPageInfo>
          </CardBodyInner>
        </CardBody>
      </Grid>
    );
  }

  renderForm() {
    // const {email, error, type} = this.state;
    const {t} = this.props;

    const SignupSchema = Yup.object().shape({
      name: Yup
        .string(t("contactUs10", "Ungültige Angabe Name"))
        .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
      email: Yup
        .string(t("registr34", "Ungültige Angabe E-Mail"))
        .email(t("registr35", "Ungültige Angabe E-Mail"))
        .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
      phone: Yup
        .string(t("contactUs11", "Ungültige Angabe Name")),
      subject: Yup
        .string(t("contactUs11", "Ungültige Angabe Name")),
      message: Yup
        .string(t("contactUs12", "Ungültige Angabe Nachricht"))
        .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
    })

    return (
      <Grid item xs={1} md={1} key="3">
        <CardBody
          marginBottom
          background={white}>
          {this.state.success &&
          <SuccessMessage>
            {t('contactUsSuccess', "Ihre Nachricht wurde an den deepsight Admin geschickt.")}
          </SuccessMessage>}
          {this.state.error && <ErrorMessage>
            {t('contactUsError', "Es ist ein Problem aufgetreten, bitte versuchen Sie es später erneut.")}
          </ErrorMessage>}
          <CardBodyInner>
            <h3>{t('contactUs5', "Du brauchst eine API Anbindung oder hast eine individuelle Anfrage? Schreib uns hier über dein Anliegen oder rufe uns direkt an")}</h3>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
              }}
              validationSchema={SignupSchema}
              validateOnBlur
              onSubmit={async (values, {setFieldError}) => {
                await post(
                  '/contactus',
                  values,
                  (code) => this.onSignUpError(code, setFieldError),
                  this.onSignUpSuccess
                );
              }}

            >
              <Form>
                <TextInputLiveFeedback
                  bordered
                  id="name"
                  name="name"
                  type="text"
                  label={t('contactUs6', "Name *")}
                />
                <TextInputLiveFeedback
                  bordered
                  id="email"
                  name="email"
                  type="text"
                  label={t('contactUs7', "E-Mail *")}
                />
                <TextInputLiveFeedback
                  bordered
                  id="phone"
                  name="phone"
                  type="text"
                  label={t('contactUs8', "Telefon")}
                />

                <TextInputLiveFeedback
                  bordered
                  id="subject"
                  name="subject"
                  type="text"
                  label={t('contactUs9', "Betreff")}
                />

                <TextAreaInput
                  bordered
                  id="message"
                  name="message"
                  type="text"
                  label={t('contactUs5', "Deine Nachricht *")}
                  rows={8}
                />

                <LoginButtonContainer>
                  <Button
                    texttransformnone
                    minwidth
                    tiny
                    rounded
                    data-cy="register-submit"
                    // icon={'main_arrow'}
                    id="submit"
                    type="submit">
                    {t('contactUs4', "Nachricht senden")}
                  </Button>
                </LoginButtonContainer>
              </Form>
            </Formik>
          </CardBodyInner>
        </CardBody>
      </Grid>
    );
  }

  render() {
    return (
      <Page background={"#151f29"}>
        <PageContent middle>
          <Grid container spacing={{xs: 1, md: 2}} columns={{xs: 1, md: 2}} sx={{backgroundColor: "#151f29"}}>
            {this.renderLeftHeader()}
            {this.renderForm()}
          </Grid>
        </PageContent>
      </Page>
    );
  }
}

export default withTranslation("translations")(ContactUs);
