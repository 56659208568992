import React from 'react';
import styled from '@emotion/styled';
import {buttonmain, grey5, white} from '../colors';
import Icon from './Icon/Icon';
import { ActionButton } from './Action';
import { withTranslation } from "react-i18next";

const Container = styled.div`
  background: ${white};
  height: 64px;
  padding: 0 20px;
  border-radius: 11px;
  display: flex;
  justify-content: left;
  box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05);

  &:hover {
      background: ${grey5};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
`;

const IconFiller = styled.div`
  width: 32px;
  height: 32px;
`;

class FirstStudyButton extends React.PureComponent {
  render() {
    const { onClick , t} = this.props;

    return (
      <Container data-tut="step_six">
        <IconContainer>
          <Icon size={40} name="ai_circle_white" />
        </IconContainer>
        <ActionButton icon="main_arrow" color={buttonmain} onClick={onClick}>
          {t("firststudybutton", "Erste Studie durchführen")}
        </ActionButton>
        <IconContainer>
          <IconFiller />
        </IconContainer>
      </Container>
    );
  }
}
export default withTranslation("translations")(FirstStudyButton);
