import React from 'react';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import CardHeaderBackground, {
  CardHeaderBackgroundHeadline,
  HeadlineIcon,
  HeadlineText,
} from '../components/CardHeaderBackground';
import Icon from '../components/Icon/Icon';
import CardHeaderWizard, {Info} from '../components/CardHeaderWizard';
import {STATUS_CURRENT, STATUS_FINISHED, STATUS_NEXT, Step, Wizard} from '../components/Wizard';
import CardBody, {CardBodyInner} from '../components/CardBody';
import SubHeadline from '../components/SubHeadline';
import {Link, withRouter} from 'react-router-dom';
import Method from '../components/Method';
import {LinkIcon} from '../components/Button';
import BuyCredits from '../pages/BuyCredits';

import StudyEdit from '../components/StudyEdit';
import styled from '@emotion/styled';
import {isCompleted, isEditAble, isWorking, studyProgress} from '../studyStatus';
import StudyMissingItemsModal from '../components/StudyMissingItemsModal';
import Page from '../components/Page';
import {withTranslation} from "react-i18next";
import error from "../error";
import Alert from '../components/Alert';
import {ProjectLink} from "./Study";
import AnonymizationModal from '../components/MethodModals/AnonymizationModal';
import ThematicAnalysisModal from '../components/MethodModals/ThematicAnalysisModal';
import SanityCheckModal from '../components/MethodModals/SanityCheckModal';
import UserOnboarding from "../components/UserOnboarding";
import Sanity from '../components/Icon/Method/sanity.png'
import ComplianceCheck from '../components/Icon/Method/compliance_check.png'
import LanguageDetection from '../components/Icon/Method/language_detection.png'
import Anonymization from '../components/Icon/Method/anonymization.png'
import ThematicAnalysis from '../components/Icon/Method/thematic_analysis.png'
import Sentiment from '../components/Icon/Method/sentiment.png'


import 'semantic-ui-css/components/grid.min.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const StepLink = styled(Link)`
    text-decoration: none;
`;


class StudyDetail extends React.PureComponent {
  state = {
    formData: {
      name: null,
    },
    formErrors: {},
    study: null,
    project: null,
    studyId: null,
    projectId: null,
    loaded: false,
    loadingError: false,
    error: false,
    showError: false,
    methods: [
      {
        id: 'anonymization',
        name: this.props.t("StudyDetail21", 'Anonymisierung'),
        active: true,
        cypress: "method-ano",
        icon: Anonymization
      },
      {
        id: 'thematic_analysis',
        name: this.props.t("StudyDetail24", 'Thematische Analyse'),
        active: true,
        cypress: "method-thema",
        icon: ThematicAnalysis
      },
      {
        id: 'language_correction',
        name: this.props.t("StudyDetail22", 'Sprachkorrektur'),
        active: false,
        cypress: "method-",
        icon: LanguageDetection
      },
      {
        id: 'sanity_check',
        name: this.props.t("StudyDetail23", 'Sanity Check'),
        active: true,
        cypress: "method-sanity",
        icon: Sanity
      },
      {
        id: 'sentiment_analysis',
        name: this.props.t("StudyDetail25", 'Sentiment Analyse'),
        active: false,
        cypress: "method-",
        icon: Sentiment
      },
      {
        id: 'nps_csat_analysis',
        name: this.props.t("StudyDetail26", 'NPS / CSAT Analyse'),
        active: false,
        cypress: "method-",
        icon: ComplianceCheck
      },
    ],
    selectedMethod: null,
    checkResult: null,
    previewResult: null,
    thematicPreviewResult: null,
    sanityPreviewResult: null,
    sanityPreviewOptionsResult: null,
    checkLoaded: false,
    previewLoaded: false,
    optionModalOpen: true,
    organisationCredits: 0,
    buyModalOpen: false,
    columnCol: {},

    currentStep: this.props.currentStep,
    closeTour: this.props.closeTour,
    tourConfig: this.props.tourConfig,
    isTourOpen: this.props.isTourOpen,
    setTourPassed: this.props.setTourPassed,
    nextStepClicked: this.props.nextStepClicked,

    onRequestClose: this.props.closeTour,
    steps: this.props.tourConfig,
    isOpen: this.props.isTourOpen,
  };
  t = this.props.t;

  async componentDidMount() {
    const {match} = this.props;
    const studyId = match.params.studyId;
    const projectId = match.params.projectId;
    await this.setState({studyId: studyId, projectId: projectId});

    const studyResponse = await get(`/study/${studyId}/detail`);
    const projectResponse = await get(`/project/${projectId}/detail`);

    if (studyResponse?.status === 200 && projectResponse?.status === 200) {

      const study = await studyResponse.json();
      const project = await projectResponse.json();
      if (isEditAble(study) === false) {
        const {history} = this.props;
        await history.push(`/project/${project.id}/study/${study.id}/result`);
        return;
      }

      await this.setState({
        loaded: true,
        loadingError: false,
        study: study,
        formData: {
          name: study.name,
        },
        project: project,
        // projectCredits: newCredits
      });

      let projectCostNumber = await this.reloadCostNumber(this.state.project.costNumberId);
      // let newCredits = projectCostNumber.credits;

      await this.setState({
        projectCostNumber: projectCostNumber,
      });

      // await this.onModalSubmit();
      return;
    }

    await this.setState({
      loadingError: true,
      loaded: true
    });
  }

  onPickMethod = (id) => {
    let selected = null;
    if (!this.state.selectedMethod) {
      selected = this.state.methods.find((method) => method.id === id);
    } else if (this.state.selectedMethod.id !== id) {
      selected = this.state.methods.find((method) => method.id === id);
    } else {
      selected = this.state.selectedMethod;
    }
    if (selected) {
      if (selected.active) {
        this.setState({selectedMethod: selected});
        this.checkStudy();
        // if (!this.state.checkLoaded) {
        //   this.checkStudy();
        // }
        this.setState({optionModalOpen: true});
      }
    }
  }

  checkStudy = async (columnCol = '') => {
    const {study} = this.state;


    await this.setState({
      checkLoaded: false,
    });

    const fileId = study.files[0].id;
    let onError = async (code) => {
      await this.setState({
        error: true,
        errorMessage: error(code, this.t)
      })
    }
    let onSuccess = async (responseData) => {
      // console.log(responseData.topTenRows);
      await this.setState({
        checkResult: responseData,
        checkLoaded: true,
        columnCol: {value: responseData.columnName, label: responseData.columnName},
      });
    }
    await post(
      '/study/check',
      {
        studyId: this.state.studyId,
        fileId: fileId,
        method: this.state.selectedMethod.id,
        columnCol: columnCol
      },
      onError,
      onSuccess
    );
  };

  getPreview = async (options, columnCol = '') => {
    const {study} = this.state;

    await this.setState({
      previewLoaded: false,
    });

    const fileId = study.files[0].id;
    let onError = async (code) => {
      await this.setState({
        error: true,
        errorMessage: error(code, this.t),
        previewLoaded: true,
      })
    }

    let onSuccess = async (responseData) => {
      let result = [];
      let optionResult = [];

      switch (this.state.selectedMethod.id) {
        case "sanity_check":
          let sanityPreviewObject = JSON.parse(responseData[0]?.topRows.topRows);
          // console.log(sanityPreviewObject);
          Object.keys(sanityPreviewObject).forEach((item, index) => {
            result.push(sanityPreviewObject[item]);
            optionResult.push(responseData[0]?.topRows.tags[index])
          });
          await this.setState({
            sanityPreviewResult: result,
            sanityPreviewOptionsResult: optionResult,
            previewLoaded: true,
          });
          break;
        default:
          let previewObject = JSON.parse(responseData[0]?.topRows);
          Object.keys(previewObject).forEach((item, i) => {
            result.push(previewObject[item]);
          });
          await this.setState({
            previewResult: result,
            previewLoaded: true,
          });
      }
    }

    let path;
    switch (this.state.selectedMethod.id) {
      case "sanity_check":
        path = '/study/sanitypreview';
        break;
      default:
        path = '/study/preview';
    }

    await post(
      path,
      {
        studyId: this.state.studyId,
        fileId: fileId,
        method: this.state.selectedMethod.id,
        options: options,
        columnCol: columnCol
      },
      onError,
      onSuccess
    );
  };

  getTemporaryThemaPreview = async (options, columnCol = '') => {
    const {study} = this.state;

    await this.setState({
      previewLoaded: false,
    });

    const fileId = study.files[0].id;
    let onError = async (code) => {
      await this.setState({
        error: true,
        errorMessage: error(code, this.t),
        previewLoaded: true,
      })
    }

    let onSuccess = async (responseData) => {
      let result = [];
      // let optionResult = [];
      // console.log(typeof JSON.parse( responseData[0]?.data));
      // console.log(responseData[0]?.data);

      let previewObject = JSON.parse(responseData[0]?.data);
      console.log(previewObject.topRows);

      let topRows = JSON.parse(previewObject.topRows);
      // console.log(topRows);
      Object.keys(topRows).forEach((item, i) => {
        result.push(topRows[item]);
      });

      console.log(result);

      // await this.setState({
      //   checkResult: responseData,
      //   checkLoaded: true,
      //   columnCol: {value: responseData.columnName, label: responseData.columnName},
      // });

      await this.setState({
        thematicPreviewResult: result,
        previewLoaded: true,
      });
    }

    await post(
      '/study/thematicpreview',
      {
        studyId: this.state.studyId,
        fileId: fileId,
        method: this.state.selectedMethod.id,
        options: options,
        columnCol: columnCol
      },
      onError,
      onSuccess
    );
  };

  getThematicAnalysisPreview = async (options) => {
    const { study } = this.state;

    await this.setState({
      previewLoaded: false,
    });

    const fileId = study.files[0].id;

    let onError = async (code) => {
      await this.setState({
        error: true,
        errorMessage: error(code, this.t),
        previewLoaded: true,
      })
    }

    let onSuccess = async (responseData) => {
      let result = [];
      let previewObject = JSON.parse(responseData[0]?.data);

      previewObject.labels.forEach((item, i) => {
        result.push(item.current_name);
      });

      await this.setState({
        thematicPreviewResult: previewObject,
        previewLoaded: true,
      });
    }

    await post(
      '/study/thematicpreview',
      {
        studyId: this.state.studyId,
        fileId: fileId,
        method: this.state.selectedMethod.id,
        options: options
      },
      onError,
      onSuccess
    );
  };

  anoError = (errorCode) => {
    this.setState({
      error: true,
      errorMessage: error(errorCode, this.t)
    })
  }

  onQuitNameInput = async () => {
    let formData = this.state.formData;
    let onError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.t)
      })
    }
    let onSuccess = () => {
      this.setState({
        error: false,
        errorMessage: null
      })
    }
    await post(
      '/study/edit',
      {
        ...formData,
        studyId: this.state.studyId,
      },
      onError,
      onSuccess
    );
  };

  onChange = (key, value) => {
    this.setState((prevState) => (
      {
        ...prevState,
        formData: {
          ...prevState.formData,
          [key]: value,
        },
      }
    ));
  };

  onOptionChange = (id, value) => {
    const {options} = this.state;

    const newOptions = options.map(option => {
      if (option.id === id) {
        option.value = value;
      }

      return option;
    });

    this.setState(() => (
      {
        options: [...newOptions],
      }
    ));
  }

  reloadCostNumber = async (costNumberId) => {
    let costNumber = {
      id: costNumberId,
      name: 'n/a',
      credits: 0,
    };

    if (costNumberId !== null) {
      const costNumberRespond = await get(`/costnumber/${costNumberId}/detail`);
      if (costNumberRespond.status === 200) {
        costNumber = await costNumberRespond.json();
        return costNumber;
      } else {
        this.setState({error: true, errorMessage: error(0, this.props.t)});
        return costNumber;
      }
    } else {
      // this.setState({ error: true, errorMessage: error(0, this.props.t) });
      return costNumber;
    }

  }

  updateCredits = async () => {
    // this.setState({buyModalOpen: false, projectCredits: projectCostNumber.credits})
    let projectCostNumber = await this.reloadCostNumber(this.state.project.costNumberId);
    this.setState({buyModalOpen: false, projectCostNumber: projectCostNumber})
  }

  render() {
    const {
      loaded,
      loadingError,
      project,
      methods,
      formData,
      study,
      showError,
      formErrors,
      checkResult,
      selectedMethod,
      studyId,
      projectId
    } = this.state;

    const errorMessage = formErrors[''] !== undefined ? formErrors[''] : '';
    const {t} = this.props;
    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="error"/>
      );
    }
    let showAnoModal = (this.state.optionModalOpen && this.state.selectedMethod) ? this.state.selectedMethod.id === "anonymization" ? true : false : false;
    let showThematicAnalysisModal = (this.state.optionModalOpen && this.state.selectedMethod) ? this.state.selectedMethod.id === "thematic_analysis" ? true : false : false;
    let showSanityCheckModal = (this.state.optionModalOpen && this.state.selectedMethod) ? this.state.selectedMethod.id === "sanity_check" ? true : false : false;

    return (
      <React.Fragment>
        <Page>
          <PageContent dark={true}>
            <form onSubmit={e => {
              e.preventDefault();
            }}>
              <CardHeaderBackground>
                <CardHeaderBackgroundHeadline>
                  <HeadlineIcon>
                    <Icon name="folder_big_ai"/>
                  </HeadlineIcon>
                  <HeadlineText>
                    <ProjectLink to={`/project/${project.id}/detail`}>{project.name}</ProjectLink> / {formData.name}
                  </HeadlineText>
                </CardHeaderBackgroundHeadline>


                <CardHeaderWizard>
                  <StudyEdit
                    onChangeName={(value) => this.onChange('name', value)}
                    name={formData.name}
                    onSubmit={() => this.onQuitNameInput()}
                    status={study.status}
                    progress={studyProgress(study)}
                  />

                  <Wizard>
                    <StepLink to={`/project/${project.id}/study/${study.id}`}>
                      <Step icon="data_up"
                            status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_CURRENT}
                            active={false}
                      >{t('studydetail2', "Daten")}</Step>
                    </StepLink>
                    <Step
                      icon="analysis_wizard" size={20}
                      status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_CURRENT}
                      active={true}
                    >
                      {t('studydetail3', "Methoden")}
                    </Step>
                    <Step
                      icon="data_down"
                      status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_NEXT}
                      active={false}
                    >
                      {t('studydetail4', "Auswertung")}
                    </Step>
                  </Wizard>

                  <Info>
                    <LinkIcon data-cy="close-study-detail-x" icon="close" to={`/project/${project.id}/detail`}/>
                  </Info>
                </CardHeaderWizard>
              </CardHeaderBackground>
              {this.state.buyModalOpen &&
              <BuyCredits asModal={true} projectCostNumber={this.state.projectCostNumber}
                          onClose={() => this.updateCredits()}/>
              }
              {showAnoModal &&
              <AnonymizationModal
                onClose={() => this.setState({optionModalOpen: false, buyModalOpen: false})}
                checkStudy={(columnCol) => this.checkStudy(columnCol)}
                getPreview={(options, columnCol) => this.getPreview(options, columnCol)}
                checkResult={this.state.checkResult}
                previewResult={this.state.previewResult}
                checkLoaded={this.state.checkLoaded}
                previewLoaded={this.state.previewLoaded}
                columnCol={this.state.columnCol}
                file={study.files[0]}
                studyId={studyId}
                projectId={projectId}
                onError={this.anoError}
                history={this.props.history}
                // credits={this.state.projectCredits}
                projectCostNumber={this.state.projectCostNumber}
                showBuyModal={() => this.setState({buyModalOpen: true})}
              />
              }
              {showThematicAnalysisModal &&
              <ThematicAnalysisModal
                onClose={() => this.setState({ optionModalOpen: false, buyModalOpen: false })}
                checkStudy={(columnCol) => this.checkStudy(columnCol)}
                // getPreview={(options) => this.getThematicAnalysisPreview(options)}
                getPreview={(options, columnCol) => this.getTemporaryThemaPreview(options, columnCol)}
                checkResult={this.state.checkResult}
                thematicPreviewResult={this.state.thematicPreviewResult}
                checkLoaded={this.state.checkLoaded}
                previewLoaded={this.state.previewLoaded}
                columnCol={this.state.columnCol}
                file={study.files[0]}
                studyId={studyId}
                projectId={projectId}
                onError={this.anoError}
                history={this.props.history}
                // credits={this.state.projectCredits}
                projectCostNumber={this.state.projectCostNumber}
                showBuyModal={() => this.setState({ buyModalOpen: true })}
              />
              }
              {showSanityCheckModal &&
              <SanityCheckModal
                onClose={() => this.setState({optionModalOpen: false, buyModalOpen: false})}
                checkStudy={(columnCol) => this.checkStudy(columnCol)}
                getPreview={(options, columnCol) => this.getPreview(options, columnCol)}
                checkResult={this.state.checkResult}
                sanityPreviewResult={this.state.sanityPreviewResult}
                sanityPreviewOptionsResult={this.state.sanityPreviewOptionsResult}
                checkLoaded={this.state.checkLoaded}
                previewLoaded={this.state.previewLoaded}
                columnCol={this.state.columnCol}
                file={study.files[0]}
                studyId={studyId}
                projectId={projectId}
                onError={this.anoError}
                history={this.props.history}
                // credits={this.state.projectCredits}
                projectCostNumber={this.state.projectCostNumber}
                showBuyModal={() => this.setState({buyModalOpen: true})}
              />
              }

              <CardBody withGradient={true}>
                <CardBodyInner>
                  <SubHeadline>
                    {t('studydetail5', "Methoden auswählen")}
                  </SubHeadline>

                  <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={2}>
                      {methods.map((method) => {
                        if (method.id) {
                          let selected = selectedMethod ? method.id === selectedMethod.id : false
                          return (
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>

                              <Method
                                data-tut="step_ten"
                                cypressClass={method.cypress}
                                key={method.id}
                                id={method.id}
                                headline={method.name}
                                file={study.files[0]}
                                checkResult={checkResult}
                                disabled={true}
                                deleteIcon={false}
                                onPickMethod={this.onPickMethod}
                                active={method.active}
                                selected={selected}
                                icon={method.icon}
                              />
                            </Grid>
                          );
                        } else {
                          return ""
                        }

                      })}
                    </Grid>
                  </Box>

                  {/*<Grid container spacing={2}  xs={2} sm={4} md={6} lg={8} xl={12}>*/}
                  {/*  <Grid>*/}

                  {/*  </Grid>*/}
                  {/*</Grid>*/}

                </CardBodyInner>
              </CardBody>
            </form>
          </PageContent>

        </Page>

        {showError &&
        <StudyMissingItemsModal
          headline={t('studydetail12', "Studie kann nicht beauftragt werden")}
          onClose={() => this.setState({showError: false})}
          error={errorMessage}
        />
        }
        {this.state.error &&
        <Alert
          type="error"
          message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
          onClose={() => this.setState({error: false})}
        />
        }
        {this.state.info && this.state.infoMessage &&
        <Alert
          type="info"
          message={this.state.infoMessage}
          onClose={() => this.setState({info: false})}
        />
        }
        <UserOnboarding
            steps={this.state.tourConfig}
            isOpen={this.state.isTourOpen}
            rounded={25}
            accentColor={"#5cb7b7"}
            onRequestClose={this.state.closeTour}
            setTourPassed={this.state.setTourPassed}
            currentStep={this.state.currentStep}
            nextStepClicked={this.state.nextStepClicked}
            onSubmit={() => this.onPickMethod("anonymization")}
          />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation("translations")(StudyDetail));
