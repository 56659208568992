import React from "react";
import styled from "@emotion/styled";
import { darkblue, grey5 } from '../colors';

const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px 10px 0 0;
  padding-left: 72px;
  height: 90px;
  color: ${darkblue};
  background: ${grey5};
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.div`

`;

export const TextContainer = styled.div`
  margin-right: 15px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

export const IconContainer = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export default class CardHeader extends React.PureComponent {
  render() {
    const {children} = this.props;

    return (
      <CardHeaderContainer>
        {children}
      </CardHeaderContainer>
    );
  }
}
