import React from 'react';
import Icon from './Icon/Icon';
import EditInput from './EditInput';
import {grey70, grey80, orange1, white} from '../colors';
import styled from '@emotion/styled';
import {LinkButton} from './Button';
import {get} from '../security/Api';
import Select from './Select';
import {Transition} from 'semantic-ui-react';
import {withTranslation} from "react-i18next";
import {Button} from "./Button";
import {isSuperAdmin} from "../security/Role";
import {InfoPopupGreen} from "./InfoPopup";


const TransitionWrapper = styled.div`
    > .transition {display: flex !important;}
`;

const Container = styled.div`
    width: 100%;
    color: ${white};
    // padding-bottom: 20px;
    overflow: hidden;
    padding-top: 20px;
`;

const FirstLine = styled.div`
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const EditContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const IconProjectContainer = styled.div`
    margin-right: 20px;
    margin-top: -15px;
    color: ${orange1};
    text-align: right;
    cursor: pointer;
`;

const IconCreateProjectContainer = styled.div`
    margin-right: 20px;
    color: ${orange1};
    text-align: center;
`;

const EditInputContainer = styled.div`
    width: 500px;
    display: flex;
    margin-top: -10px;
`;

const CloseButton = styled.button`
    background: transparent;
    border: 0;
    color: ${white};
    cursor: pointer;
`;


const Box = styled.div`
    padding: 20px 70px 20px 42px;
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    min-height: 0;
`;

const UserBox = styled.div`
    width: 60%;
    background: ${grey70};
    border-radius: 12px;
    padding: 14px 28px 0px 28px;
    box-shadow: 6px 6px 25px rgba(0, 20, 50, 0.05);
    height: 170px;
`;

const UserName = styled.div`
    display: flex;
    align-items: center;

    >svg {
        margin-right: 10px;
    }
`;

const Role = styled.div`
    background: ${grey80};
    padding: 10px 22px;
    border-radius: 4px;
    font-size: 16px;
`;

const CostNumberBox = styled.div`
    width: 35%;
    background: ${grey70};
    border-radius: 12px;
    padding: 14px 28px 0px 28px;
    box-shadow: 6px 6px 25px rgba(0, 20, 50, 0.05);
    height: 170px;
`;

const User = styled.div`
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    border-top: solid 2px ${grey80};
    border-bottom: solid 2px ${grey80};
`;

const BoxName = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled.div`
    margin-bottom: -4px;
    text-transform: uppercase;
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    letter-spacing: 0.75px;
    line-height: 20px;
`;

const SettingIcon = styled.div`
    margin-top: 12px;
    margin-left: -6px;
    text-align: center;
`;
const DeleteIcon = styled.div`
    margin-top: 6px;
    text-align: center;
`;

const DeleteSmallText = styled.div`
    font-size: 12px;
    margin-top: -10px;
    text-align: center;
`;

const SettingSmallText = styled.div`
    font-size: 12px;
    margin-top: -15px;
    text-align: center;
`;

const SubmitRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 70px;
`;

const BoxHeadline = styled.div`
    display: flex;
    align-items: right;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const ProjectButtonsContainer = styled.div`
    display: flex;
`;

class ProjectForm extends React.PureComponent {
  state = {
    open: false,
    loaded: false,
    users: null,
    costNumbers: null,
    _default: null,
    showPlusButton: true,
  };

  async componentDidMount() {

    const usersResponse = await get('/all-users');

    if (usersResponse.status === 200) {
      const usersData = await usersResponse.json();

      this.setState({
        users: usersData,
        loaded: false,
      });

      const costNumbersResponse = await get('/costnumber/list');

      if (costNumbersResponse.status === 200) {

        const costNumbersResponseData = await costNumbersResponse.json();

        this.setState({
          costNumbers: costNumbersResponseData,
          loaded: true,
        });

        if (!this.props.costNumberId) {
          costNumbersResponseData.forEach((costNumber, i) => {
            if (costNumber.name === '') {
              this.props.onChangeCostNumber(costNumber.id);
              return;
            }
            if (i === costNumbersResponseData.length - 1) {
              this.props.onChangeCostNumber(costNumber.id);
            }
          });
          this.advancedToggle();
        }
      }
    }
  }

  componentWillUnmount = () => {
    document.body.style.overflow = 'auto scroll';
  }

  advancedToggle = async () => {
    await this.setState({
      open: !this.state.open
    })
    if (this.state.open) {
      document.body.style.overflow = 'auto scroll';
    } else {
      document.body.style.overflow = 'auto scroll';
    }
  }

  hidePlusButton = () => {
    this.setState({
      showPlusButton: false,
      open: true
    });
  }

  showPlusButton = () => {
    this.setState({
      showPlusButton: true
    });
  }

  reloadCostNumbers = async () => {
    this.showPlusButton();
    const costNumbersResponse = await get('/costnumber/list');
    if (costNumbersResponse.status === 200) {

      const costNumbersResponseData = await costNumbersResponse.json();

      this.setState({
        costNumbers: costNumbersResponseData,
        loaded: true,
      });

      if (!this.props.costNumberId) {
        costNumbersResponseData.forEach((costNumber, i) => {
          if (costNumber.name === '') {
            this.props.onChangeCostNumber(costNumber.id);
            return;
          }
        });
        // this.advancedToggle();
      }
    }
  }

  renderAdvanceSetting() {
    const {loaded} = this.state;
    if (loaded === false) {
      return
    }

    return (
      <TransitionWrapper>
        <Transition
          animation="fade down"
          duration={50}
          unmountOnHide={true}
          visible={this.state.open}
        >
          <Box>
            {this.renderOwners()}
            {this.renderCostNumbers()}
          </Box>
        </Transition>
      </TransitionWrapper>

    );
  }

  renderOwners() {
    const {owners = [], onChangeOwner, formErrors} = this.props;
    const ownerChange = async (ownerId) => {
      owners[0] = ownerId;
      formErrors['owner'] = null;
      await onChangeOwner(ownerId);
    }
    const {t} = this.props;
    const {users} = this.state;

    if (!users) {
      return
    }

    const options = users.map(user => {
      let returnObject;
      if (user.fullName.length) {
        returnObject = {
          value: user.id,
          label: `${user.fullName}  (${user.role})`,
        };
      }
      return returnObject;
    });

    return (
      <UserBox
        data-tut="step_one_three">
        <BoxHeadline>
          <BoxName>
            <Name>
              {t("projectform4", "Nutzer")}
            </Name>
            <InfoPopupGreen
              content={t('tipuser', "Steuern Sie den Zugriff und Sichtbarkeit Ihrer Daten, Analysen und Ergebnisse für dieses Projekt.")}
            />
          </BoxName>
          {
            <LinkButton
              mainbutton
              data-cy="add-user"
              icon="add"
              to={'/account?form=admin'}
              small={"true"}
            />
          }
        </BoxHeadline>
        <Select
          disabled={!isSuperAdmin(owners[0])}
          id="select-project-owner"
          placeHolder="Select project owner"
          red={true}
          options={options}
          value={owners[0]}
          onChange={ownerChange}
          error={formErrors['owner']}
        />
      </UserBox>
    );
  }

  renderOwner(owner) {
    const {users} = this.state;
    const {t} = this.props;
    const user = users.find(user => user.id === owner);
    if (!user) {
      return;
    }

    return (
      <User data-cy="project-owner" key={owner}>
        <UserName data-cy="project-owner-name">
          <Icon size={26} name="person_filled"/>
          {user.fullName || ''}
        </UserName>

        <Role
          data-cy="project-owner-role">{user.role === "Super Admin" ? t("projectForm11", "Projektinhaber") : t("projectForm13", "Nutzer")}</Role>
      </User>
    );
  }

  renderUser(user) {
    const {t, owners = []} = this.props;
    const owner = owners.find(owner => owner === user.id);

    if (owner) {
      return;
    }

    return (
      <User key={user.id}>
        <UserName>
          <Icon size={26} name="person_filled"/>
          {user.fullName || ''}
        </UserName>

        <Role>{user.role === "Super Admin" ? t("projectForm11", "Projektinhaber") : t("projectForm13", "Nutzer")}</Role>
      </User>
    );
  }

  renderCostNumbers() {
    const {costNumberId, onChangeCostNumber, formErrors, t} = this.props;
    const {costNumbers} = this.state;
    const costNumberAutosafe = async (costNumber) => {
      await onChangeCostNumber(costNumber);
    }
    const options = costNumbers.map(costNumber => {
      let returnObject;
      if (costNumber.name.length) {
        returnObject = {
          value: costNumber.id,
          label: `${costNumber.name}  (${costNumber.credits} Credits)`,
        };
      } else {
        returnObject = {
          value: costNumber.id,
          label: `${"_" + t("projectForm12", "default")}  (${costNumber.credits} Credits)`,
        }
        this.setState({"_default": costNumber.id});
      }
      return returnObject;
    });

    return (
      <CostNumberBox data-tut="step_one_four" data-cy="select-box">
        <BoxHeadline>
          <BoxName>
            <Name>
              {t("projectform5", "Kostennummer")}
            </Name>
            <InfoPopupGreen
              content={t('tipcostnumber', "Credits für Datenanalysen werden auf die ausgewählte Kostennummer gerechnet. Sie werden immer gefragt, bevor Credits abgerechnet werden.")}
            />
            {
              // <IconContainer>
              //     <Icon size={26} name="info_outline" />
              // </IconContainer>
            }

          </BoxName>

          {this.state.showPlusButton &&
          <LinkButton
            mainbutton
            onClick={this.hidePlusButton}
            target="_blank"
            data-cy="add-user"
            icon="add"
            to={'/costnumber/create'}
            small={"true"}
          />
          }
          {!this.state.showPlusButton &&
          <Button
            icon="analysis_wizard"
            onClick={this.reloadCostNumbers}>
          </Button>
          }

        </BoxHeadline>
        <Select
          id="select-costNumber-input"
          placeHolder={t("projectform10", "Nummer auswählen")}
          options={options}
          value={costNumberId ? costNumberId : this.state._default}
          onChange={costNumberAutosafe}
          error={formErrors['costNumber']}
        />
      </CostNumberBox>
  );
  }

  render()
    {
      const {onSubmit, name, onChangeName, formErrors, onDelete, onClose, create, t, doNotSubminOnBlur = true} = this.props;
      const {open} = this.state;

      let arrow = open ? ' ▲' : ' ▼';

      return (
        <div>
          <Container>
            <FirstLine>
              <EditContainer>
                <IconCreateProjectContainer>
                  <Icon size={50} name="folder_big_ai"/>
                </IconCreateProjectContainer>

                <EditInputContainer
                  data-tut="step_one_two">
                  <div>
                    <EditInput
                    id="project-name"
                    color={white}
                    value={name}
                    onChange={onChangeName}
                    onSubmit={onSubmit}
                    error={formErrors['name']}
                    create={create}
                    placeholder={t("projectform9", "Unbenanntes Projekt")}
                    active={create ? true : false}
                    doNotSubminOnBlur={doNotSubminOnBlur}
                  />
                  </div>
                </EditInputContainer>
              </EditContainer>

              <ProjectButtonsContainer>
                <IconProjectContainer data-cy="project-setting" onClick={() => this.advancedToggle()}>
                  <SettingIcon><Icon size={48} name="settings_filled"/></SettingIcon>
                  <SettingSmallText>{t("projectform14", "Einstellungen")}{arrow}</SettingSmallText>
                </IconProjectContainer>

                {!create &&
                <IconProjectContainer data-cy="delete-project" onClick={onDelete}>
                  <DeleteIcon><Icon size={48} name="delete_filled"/></DeleteIcon>
                  <DeleteSmallText>{t("projectform7", "Projekt Löschen")}</DeleteSmallText>
                </IconProjectContainer>
                }
                <CloseButton onClick={onClose}>
                  <Icon size={28} name="close_small"/>
                </CloseButton>
              </ProjectButtonsContainer>
              {
              }
            </FirstLine>
            {this.renderAdvanceSetting()
            }

            <TransitionWrapper>
              <Transition
                animation="fade down"
                duration={50}
                unmountOnHide={true}
                visible={this.state.open && !formErrors['name']}
              >
                <SubmitRow data-cy="save-project">
                  <Button data-tut="step_one_five" mainbutton onClick={() => {
                    onSubmit()
                  }} icon="main_arrow">
                    {t('projectlist13', "Erstellen und weiter zur Studie")}
                  </Button>
                </SubmitRow>
              </Transition>
            </TransitionWrapper>
          </Container>

        </div>
      );
    }
  }

  export default withTranslation("translations")(ProjectForm);
