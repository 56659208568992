import styled from '@emotion/styled';
import { darkblue, green3 } from '../colors';
import React from 'react';

const MessageContainer = styled.div`
  top: -90px;
  display: flex;
  height: 90px;
  align-items: center;
  padding: 0 30px;
  background: ${green3};
  border: 3px solid ${green3};
  border-radius: 10px;
  color: ${darkblue};
  text-align: left;
  box-shadow: 6px 6px 25px rgba(54, 125, 206, 0.15);
`;

export default class SuccessMessage extends React.PureComponent {
  render() {
    const {id, children } = this.props;

    return (
      <MessageContainer data-cy={id}>
        {children}
      </MessageContainer>
    );
  }
}
