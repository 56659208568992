import React from "react";
import styled from "@emotion/styled";

export const TooltipContainer = styled.div`
  position: relative;
  top: -20px;
`;

export default class Tooltip extends React.PureComponent {
  state = {
    open: false
  };

  onMouseEnter = () => {
    this.setState({
      open: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const {open} = this.state;
    const {children} = this.props;

    if (open) {
      return null;
    }

    return (
        children
    );
  }
}
