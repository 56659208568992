export const CREATED = 'created';
export const CHECK_PENDING = 'check_pending';
export const CHECKED = 'checked';
export const PENDING = 'pending';
export const RUNNING = 'running';
export const HALFED = 'halfed';
export const COMPLETED = 'completed';
export const FAILED = 'failed';
export const REIMBURSED = 'reimbursed';
export const THEMA_COMPLETED = "thema_completed";
export const INFERENCE_COMPLETED = "inference_completed";

export const ONE_THIRD_PROGRESS = 33;
export const TWO_THIRD_PROGRESS = 66;
export const FULL_PROGRESS = 100;

const editAbleStatus = [
  CREATED,
  CHECK_PENDING,
  CHECKED,
];

const workingStatus = [
  PENDING,
  RUNNING,
  HALFED,
  THEMA_COMPLETED,
];

const completedStatus = [
  COMPLETED,
  INFERENCE_COMPLETED,
  FAILED,
  REIMBURSED
];

export const isEditAble = (study) => {
  return editAbleStatus.find(status => status === study.status) !== undefined;
};

export const isWorking = (study) => {
  return workingStatus.find(status => status === study.status) !== undefined;
};

export const isCompleted = (study) => {
  return completedStatus.find(status => status === study.status) !== undefined;
};

export const isSuccess = (study) => {
  return study.status === COMPLETED;
};

export const isFailed = (study) => {
  return study.status === FAILED;
};

export const studyProgress = (study) => {

  if (study.status === CREATED || study.status === CHECK_PENDING) {

    return ONE_THIRD_PROGRESS;
  }

  if (study.status === CHECKED) {

    return TWO_THIRD_PROGRESS;
  }

  if (study.status === PENDING || study.status === RUNNING || study.status === HALFED || study.status === COMPLETED || study.status === FAILED || study.status === REIMBURSED || study.status === THEMA_COMPLETED || study.status === INFERENCE_COMPLETED) {

    return FULL_PROGRESS;
  }

  return 0;
};
