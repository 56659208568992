import React, {useState} from 'react';
import styled from '@emotion/styled';
import {mainblue, darkblue, grey10, white, orange1, orange2} from '../colors';
import {CreditsInfo} from '../pages/OrganisationDetail';
import Icon from './Icon/Icon';
import {isDeepsightAdmin, isSuperAdmin} from '../security/Role';
import Guard from './Guard';
import {withTranslation} from "react-i18next";

const CostNumberContainer = styled.div`
    display: flex;
    align-items: center;
    background: ${white};
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 20px 25px;
    min-height: 64px;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
    text-decoration: none;
    color: ${darkblue};

    &:hover {
        background: ${grey10};
    }
`;

const Name = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Credits = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
`;

const Bold = styled.div`
    font-weight: bold;
`;

const BoldSpan = styled.span`
    font-weight: bold;
    color: ${mainblue};
`;

const RowsByMethodContainer = styled.div`
  display: block;
  padding-top: 7px;
`;
const RowsByMethodBlock = styled.div`
  display: block;
`;
const ChargeIcon = styled.div`
    margin-top: 6px;
    margin-right: 6px;
`;

const RemoveIcon = styled.div`
    margin-top: 6px;
    margin-right: 6px;
`;

const DropdownIcon = styled.div`
    margin-top: 3px;
    margin-left: 12px;
`;

const ChargeContainer = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: ${darkblue};
    cursor: pointer;
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-transform: uppercase;
`;

const RemoveContainer = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: ${orange1};
    cursor: pointer;
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-transform: uppercase;
    &:hover {
        color: ${orange2};
    }
`;

function CostNumberElement(props) {

  const {name, costNumberId, credits, onClick, onRemoveCredits, onRemoveCostNumber, id, rowsByMethod, t} = props;

  const [methodsCollapsed, setMethodsCollapsed] = useState(true);

  const activeMethods = [
    {
      method: "Anonymisierung",
      name: t('StudyDetail21', 'Anonymisierung'),
      db_name: "Ano"
    },
    {
      method: "Sanity",
      name: t('StudyDetail23', 'Sanity Check'),
      db_name: "Sanity"
    },
    {
      method: "Thema",
      name: t('StudyDetail24', 'Thematische Analyse'),
      db_name: "Thema"
    }
  ];

  const renderRowsByMethod = () => {

    console.log(costNumberId);
    console.log(rowsByMethod[costNumberId][0]);

    return (
      <RowsByMethodBlock>
        {
          Object.entries(rowsByMethod[costNumberId][0]).map(entry => {
            const [key, value] = entry;

            let methodName = "";

            activeMethods.forEach((method) => {
              if(method.db_name === key) {
                methodName = method.name;
              }
            })

            return (
              <p>{methodName}: <BoldSpan>{value}</BoldSpan> {t("todo110","Zeilen")}</p>
            );
          })
        }
      </RowsByMethodBlock>
    )
  }

  const expandCollapseMethods = () => {
    setMethodsCollapsed(!methodsCollapsed);
  }

  return (
    <CostNumberContainer data-cy={'cost-number-' + id}>
      <Name data-cy={'cost-number-' + id + '-name'}>
        {name}
      </Name>
      <CreditsInfo>
        <RowsByMethodContainer>
          <Credits
            onClick={expandCollapseMethods}
            data-cy={'cost-number-' + id + '-credits'}
          >
            <Bold>{credits} {t("totalRows001", "Gesamtzahl der Zeilen")}</Bold>
            &nbsp;
            <Icon
              sx={{cursor: 'pointer'}}
              size={20}
              name={methodsCollapsed ? "expand_all" : "collapse_all"}
            />

          </Credits>
          {!methodsCollapsed && renderRowsByMethod()}
        </RowsByMethodContainer>
        {credits === 0 && isSuperAdmin() &&
        <RemoveContainer onClick={onRemoveCostNumber}>
          <RemoveIcon>
            <Icon size={24} name="delete_outline"/>
          </RemoveIcon>
          {t("cost13", "Kostennummern löschen")}
        </RemoveContainer>
        }

        <Guard secure={isDeepsightAdmin}>
          <RemoveContainer onClick={onRemoveCredits}>
            <RemoveIcon>
              <Icon size={24} name="delete_outline"/>
            </RemoveIcon>
            {t("costnumberel2", "Alle Credits loeschen")}
          </RemoveContainer>

          <ChargeContainer onClick={onClick} data-cy={'cost-number-' + id + '-button'}>
            <ChargeIcon>
              <Icon size={24} name="credits"/>
            </ChargeIcon>
            {t("costnumberel1", "Credits Aufladen")}
            <DropdownIcon>
              <Icon size={15} name="dropdown"/>
            </DropdownIcon>
          </ChargeContainer>
        </Guard>
      </CreditsInfo>
    </CostNumberContainer>
  );
}

export default withTranslation("translations")(CostNumberElement);
