import React from 'react';
import {darkblue, grey10, white, maintext} from '../colors';
import styled from '@emotion/styled';
import Icon from './Icon/Icon';


const ModalContainer = styled.div`
    padding: 0 40px 0 40px;
    display: flex;
    flex-direction: column;
    background: ${props => props.color ? props.color : white};
    // overflow-y: auto;
    background: transparent linear-gradient(38deg, ${grey10} 0%, ${white} 100%) 0% 0% no-repeat padding-box;
`;

const Container = styled.div`
    width: 100%;
    color: ${props => props.dark ? maintext : white};
`;

const FirstLine = styled.div`
    padding-top: 15px;
    padding-right: 15px;
    align-items: center;
    text-align: right;
`;

const CloseButton = styled.button`
    background: transparent;
    border: 0;
    color: ${darkblue};
    cursor: pointer;
    padding: 0;
`;

const InnerContainer = styled.div`
    padding: 0 60px 50px 50px;
`;

function PageModal(props){
    const { children, onClose, color, overlay=false, dark=false } = props;

    return (
            <ModalContainer color={color} overlay={overlay}>
                <Container dark={dark}>
                    <FirstLine>
                        <CloseButton type="button" onClick={onClose}>
                            <Icon size={20} name="close_small"/>
                            </CloseButton>
                    </FirstLine>
                  <InnerContainer>
                    {children}
                  </InnerContainer>
                </Container>
            </ModalContainer>
    );
}

export default PageModal;
