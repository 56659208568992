import React from 'react';
import styled from '@emotion/styled';
import { grey20, darkblue } from '../colors';

const Container = styled.div`
    margin: 4px;
    background-color: ${props => props.checked?  props.color : grey20};
    color: ${darkblue};
    border-radius: 15px;
    overflow: auto;
    float: left;
    cursor: ${props => props.disabled ? "auto;" : "pointer;"}

    box-shadow: inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
    ${props => !props.disabled && `&:hover {filter: brightness(85%); color: ${darkblue};}`}
    display: ${props => props.visible ? 'block' : 'none'}
`;

const CheckBoxField = styled.input`
    display: none;
    cursor: pointer;
`;

const Label = styled.label`
    float: left;
    cursor: pointer;
`;
const LabelSpan = styled.span`
    text-align: center;
    padding: 3px 10px;
    display: block;
    cursor: ${props => props.disabled ? "default;" : "pointer;"}
`;

function CheckBoxButton(props) {
    const {onChange, id, name, label, color, visible, value = false, disabled=false} = props;

    function localOnChange(event) {
        if(onChange) {
            onChange(event.target.checked);
        }
    };
    return (
        <Container disabled={disabled} color={color} checked={value} visible={visible}>
            <Label>
                <CheckBoxField
                    data-cy={id}
                    id={id}
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={event => localOnChange(event)}

                />
                    <LabelSpan checked={value} disabled={disabled}>{label}</LabelSpan>

            </Label>
        </Container>
    );
}

export default CheckBoxButton;
