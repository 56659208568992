import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {withRouter} from 'react-router-dom';

import styled from '@emotion/styled';
import {buttonmain, cardheader, grey5, orange1} from '../colors';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import {HeadlineContainer} from '../components/Headline';
import {Button} from '../components/Button';
import Icon from '../components/Icon/Icon';
import {CardContainer, CardListContainer, SortHeaderContainer, SortStudiesContainer} from '../components/CardList';
import Sort from '../components/Sort';
import CardHeader from '../components/CardHeader';
import CardBody from '../components/CardBodyProject';
import StudyElement from '../components/StudyElement';
import {ActionButton, ActionButtonContainerInProjectList} from '../components/Action';
import FirstStudyButton from '../components/FirstStudyButton';
import Page from '../components/Page';
import {grey10} from '../colors';
import {studyProgress} from '../studyStatus';
import ProjectCreateModal from '../components/CreateProjectModal';
import {withTranslation} from "react-i18next";
import DeleteProjectModal from '../components/DeleteProjectModal';
import error from "../error";
import Alert from '../components/Alert';
import Guard from '../components/Guard';
import {isSuperAdmin} from '../security/Role';
import {InfoPopupGreen} from "../components/InfoPopup";
import UserOnboarding from "../components/UserOnboarding";

const SORT_NAME = 'name';
const SORT_DATE = 'date';
const SORT_METHOD = 'method';

const OptionsIconContainer = styled.div`
  margin-right: 16px;
  color: ${orange1};
`;

const DeleteProject = styled.div`
    cursor: pointer;
    display: flex;
    float: right;
    margin-top: 24px;
`;

const Text = styled.div`
    margin-top: 5px;
`;

const TextProjectDelete = styled.div`
    margin-top: 5px;
    font-size: 15px;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-transform: uppercase;
    color: ${orange1};
`;

const TipContainer = styled.div`
    padding: 35px;
    background: ${grey5};
    border-radius: 10px 10px 10px 10px;
    width: 100%;
`;

const AreaHeader = styled.h1`
    display: flex;
    align-items: center;
    padding: 0 50px 0 42px
`;

const Lefter = styled.h1`
    display: flex;
    align-items: right;
`;

const Righter = styled.span`
    display: flex;
    align-items: right;
`;

function ProjectList(props) {

  const {
    t,
    token,
    isTourOpen,
    tourConfig,
    closeTour,
    setTourPassed,
    currentStep,
    nextStepClicked,
    isProjectSubmitted,
    setIsProjectSubmitted,
    history,
    // setNextStepClicked,
  } = props;

  const [users, setUsers] = useState([]);
  const [userLoaded, setUserLoaded] = useState(false);

  const [projects, setProjects] = useState([]);
  const [projectLoaded, setProjectLoaded] = useState(false);

  const [createProject, setCreateProject] = useState(false);
  const [projectFormData, setProjectFormData] = useState(
    {
      name: null,
      owners: [],
      costNumberId: null,
    }
  );

  const studyFormData = {
      name: t("projectlist8", 'Neue Studie'),
      file: null,
    };

  const [info, setInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorVar, setErrorVar] = useState(false);
  const [projectFormErrors, setProjectFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [deleteProjectModalError, setDeleteProjectModalError] = useState(null);

  const [selectedProjectToDelete, setSelectedProjectToDelete] = useState(null);

  const [projectsSortedBy, setProjectsSortedBy] = useState(SORT_DATE);
  const [sortProjectsAscending, setSortProjectsAscending] = useState(null);

  const [sortStudiesInProjectAscending, setSortStudiesInProjectAscending] = useState(true);

  useEffect( () => {
    loadProjects();
    // eslint-disable-next-line
  }, []);

  useEffect( () => {
    loadAllUsers();
    // eslint-disable-next-line
  }, [projects]);

  useEffect(() => {
    if(currentStep === 2 && !isProjectSubmitted) {
      setIsProjectSubmitted(true);
      onProjectSubmit();
    }
    else checkWizzardStep();
  })

  const checkWizzardStep = () => {
    if (currentStep > 0 && currentStep < 4) {
      console.log(projectFormData);
      setCreateProject(true);
    }
  }

  const loadAllUsers = async () => {
    const userResponse = await get('/all-users',);
    if (userResponse.status === 200) {
      const userResponseData = await userResponse.json();

      setUsers(userResponseData);
      setUserLoaded(true);
      setProjectFormData({
        ...projectFormData,
        owners: [token.id],
        name: isTourOpen ? t("wizardProjectName", 'Mein erstes Projekt') + "_" + projects.length : null,
      })
      return;
    }
    setLoadingError(true);
  }

  const loadProjects = async () => {
    const projectResponse = await get('/project/list',);

    if (projectResponse.status === 200) {
      const projectResponseData = await projectResponse.json();

      setProjectLoaded(true);
      setProjects(projectResponseData);
      setInfoMessage(null);

      return;
    }
    setLoadingError(true);
  }

  const deleteStudyFromList = (projectId, studyId) => {
    console.log(projectId);
    console.log(studyId);

    setProjects((prevState => {
      return prevState.map((project) => {
        if (project.id !== projectId) {
          return project;
        }
        else {
          let tmpArray = [];
          project.studies.forEach(study => {
            if(study.id !== studyId) {
              tmpArray.push(study);
            }
          })
          project.studies = tmpArray;
          return project;
        }
      })
    }));
  }


  const onProjectSubmit = async () => {

    if (isTourOpen) {
      nextStepClicked(5);
    }

    if (!projectFormData.name) {
      console.log("!project name");
      const errors = {};
      errors['name'] = t("projectlist12", "Bitte geben Sie einen Namen an");
      setProjectFormErrors(errors);
      return;
    }

    if (!projectFormData.owners[0]) {
      console.log(projectFormData);
      const errors = {};
      errors['owner'] = t("projectlist14", "Bitte geben Sie den Projektverantwortlichen ein");
      setProjectFormErrors(errors);
      return;
    }

    if (!projectFormData.costNumberId) {
      const errors = {};
      errors['costNumber'] = t("projectlist15", "Bitte geben Sie einen cost nummer");
      setProjectFormErrors(errors);
      return;
    }

    let onError = (code) => {
      const errors = {};
      errors['name'] = error(code, t); //todo
      setProjectFormErrors(errors);
    }
    let onSuccess = (responseData) => {
      history.push(`/project/${responseData.requestId}/detail`);

      setErrorVar(false);
    }
    await post(
      '/project/create',
      {
        name: projectFormData.name,
        ownerId: projectFormData.owners[0],
        costNumberId: projectFormData.costNumberId,
      },
      onError,
      onSuccess
    );
  };

  const handleProjectDelete = (project) => {
    setDeleteProjectModalOpen(true);
    setSelectedProjectToDelete(project);
  }

  const onProjectDelete = async () => {

    let onError = (code) => {
      setDeleteProjectModalError(t("projectlist9", 'Das Projekt kann aktuell nicht gelöscht werden, da innerhalb' +
        ' des Projektes eine Studie in diesem Moment verarbeitet wird.'));
    }
    let onSuccess = async () => {
      setDeleteProjectModalError(null);
      setDeleteProjectModalOpen(false);
      await loadProjects().then(history.push(`/project`));
    }

    await post(
      '/project/delete',
      {
        projectId: selectedProjectToDelete.id,
      },
      onError,
      onSuccess
    );
  };

  const onStudySubmit = async (project) => {

    let onError = (code) => {
      setErrorVar(true);
      setErrorMessage("error(code, t)"); //todo
    }
    let onSuccess = (responseData) => {
      setErrorVar(false);
      setErrorMessage(null);
      const studyId = responseData.id;

      history.push(`/project/${project.id}/study/${studyId}`);
    }
    await post(
      '/study/create',
      {
        ...studyFormData,
        projectId: project.id,
      },
      onError,
      onSuccess
    );

  };

  const onChange = async (key, value) => {

    setProjectFormErrors({
      ...projectFormErrors,
      'name': null,
      'owner': null
    })

    setProjectFormData({
      ...projectFormData,
      [key]: value,
    });
  };

  const sortProjectsBy = (what) => {
    if (projects?.length === 0) return;
    setProjectsSortedBy(what);
    setProjects((prevState => {
      return prevState.sort((a, b) => {
        if (what === SORT_NAME) {
          if (sortProjectsAscending) {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        }

        if (what === SORT_DATE) {
          if (sortProjectsAscending) {
            return moment(b.createdAt).diff(moment(a.createdAt));
          } else {
            return moment(a.createdAt).diff(moment(b.createdAt));
          }
        }
        return 0;
      });
    }));
    setSortProjectsAscending(!sortProjectsAscending);
  }

  const sortStudiesInProjectByFn = (projectId, what) => {
    setProjects((prevState => {
      return prevState.map((project) => {
        if (project.id !== projectId) {
          return project
        }
        else {
          project.studies = project.studies.sort((a, b) => {
            if (what === SORT_NAME) {
              if (sortStudiesInProjectAscending) {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            }

            if (what === SORT_DATE) {
              if (sortStudiesInProjectAscending) {
                return moment(b.createdAt).diff(moment(a.createdAt));
              } else {
                return moment(a.createdAt).diff(moment(b.createdAt));
              }
            }

            if (what === SORT_METHOD) {
              console.log(SORT_METHOD);
              if (sortStudiesInProjectAscending) {
                if (a.method === null) return 1;
                if (b.method === null) return -1;
                return b.method.localeCompare(a.method);
              } else {
                if (a.method === null) return -1;
                if (b.method === null) return 1;
                return a.method.localeCompare(b.method);
              }
            }
            return 0;
          });
          return project;
        }
      })
    }));
    setSortStudiesInProjectAscending(!sortStudiesInProjectAscending);
  }


  const renderProject = (project) => {

    return (
      <CardContainer key={project.id}>
        <CardHeader
          background={cardheader}
          id={`detail-${project.name}`}
          icon="folder_big_ai"
          info={moment(project.createdAt).format('DD.MM.YYYY')}
          to={`/project/${project.id}/detail`}
        >
          {project.name}

          {project.ownerIsSuperAdmin === false &&
          <Guard secure={isSuperAdmin}>
            {` by ${project.owner} / ${project.owner_email}`}
          </Guard>
          }
        </CardHeader>

        {deleteProjectModalOpen &&
        <DeleteProjectModal
          onClose={() => {
            setDeleteProjectModalOpen(false);
            setDeleteProjectModalError(null)
          }}
          onClick={() => onProjectDelete()}
          error={deleteProjectModalError}
        />
        }

        <SortStudiesContainer>
          <Righter>
            <Sort
              active={false}
              onClick={() => {
                sortStudiesInProjectByFn(project.id, SORT_NAME)
              }}
              icon={'dropdown'}
              size={10}
            >
              {t('projectlist5', "A-Z")}
            </Sort>
            <Sort
              active={false}
              onClick={() => {
                sortStudiesInProjectByFn(project.id, SORT_METHOD)
              }}
              icon={'sort_by'}
              size={32}
            >
              {t('projectlist666', "Method")}
            </Sort>
            <Sort
              active={false}
              onClick={() => {
                sortStudiesInProjectByFn(project.id, SORT_DATE)
              }}
              icon={'sort_by'}
              size={32}
            >
              {t('projectlist6', "Erstellt am")}
            </Sort>
          </Righter>
        </SortStudiesContainer>

        {!project.studies.length > 0 &&
        <CardBody noTopPadding addBottomPadding background={grey10}>
          <FirstStudyButton onClick={() => onStudySubmit(project)}/>
          {renderDeleteProject(project)}
        </CardBody>
        }

        {project.studies.length > 0 &&
        <CardBody noTopPadding background={grey10} noBottomPadding={true}>

          {project.studies.map(study => {
            return (
              <StudyElement
                to={`/project/${project.id}/study/${study.id}`}
                key={study.id}
                id={study.id}
                name={study.files[0] ? study.name + ' (' + study.files[0].name + ')' : study.name}
                status={study.status}
                studyMethod={study.method}
                progress={studyProgress(study)}
                createdAt={study.createdAt}
                reload={() => deleteStudyFromList(project.id,study.id)}
              />);
          })}

          <ActionButtonContainerInProjectList float="left">
            <br/>
            <ActionButton id="create-study" icon="add" color={buttonmain} onClick={() => onStudySubmit(project)}>
              {t('projectlist1', "Neue Studie hinzufügen")}
            </ActionButton>

            {renderDeleteProject(project)}
          </ActionButtonContainerInProjectList>

        </CardBody>
        }

      </CardContainer>
    );
  };

  const renderDeleteProject = (project) => {
    return (
      <DeleteProject onClick={() => handleProjectDelete(project)}>
        <OptionsIconContainer>
          <Icon size={26} name="delete_outline"/>
        </OptionsIconContainer>
        <TextProjectDelete>{t("projectlist11", "Projekt Löschen")}</TextProjectDelete>
      </DeleteProject>
    )
  }

  if (projectLoaded === false) {
    return (
      <Curtain type="loading"/>
    );
  }
  if (loadingError === true) {
    return (
      <Curtain type="error"/>
    );
  }

  return (
    <Page>
      {createProject &&
      <ProjectCreateModal
        onChangeName={(value) => onChange('name', value)}
        onChangeOwner={(value) => onChange('owner', value)}
        onChangeCostNumber={(value) => onChange('costNumberId', value)}
        onClose={() => setCreateProject(false)}
        onSubmit={onProjectSubmit}
        userLoaded={userLoaded}
        projectFormData={projectFormData}
        projectFormErrors={projectFormErrors}
        users={users}
      />
      }
      <PageContent>
        <HeadlineContainer>
          <AreaHeader>
            <Text>
              {t('projectlist3', "Alle Projekte")}
            </Text>
            <InfoPopupGreen
              content={t('projecttip', "Projekte können mehrere Studien und Datensätze beinhalten, um Analysen durchzuführen. Sie können den Zugriff, Sichtbarkeit und Kostennummern pro Projekt steuern.")}
            />
          </AreaHeader>

          {/*<HeadlineText>{t('projectlist3', "Alle Projekte")}*/}
          {/*  <InfoPopupGreen*/}
          {/*    content={t('projectdetail20', "Hier finden Sie alle angelegten Dokumente, aktiven Services oder finalen Ergebnisse zu diesem Projekt.")}*/}
          {/*  />*/}
          {/*</HeadlineText>*/}

          {
            /*<SubNavigation>
                          <NavLink icon="folder_outline" data-cy="active-projects" to={'/project'}>Aktive Projekte</NavLink>
                          <NavLink icon="folder_archive_outline" data-cy="archived-projects" to={'/project/archived'}>
                          Archivierte Projekte
                          </NavLink>
                          </SubNavigation>*/
          }

          <Button data-tut="step_one" mainbutton tiny rounded data-cy="project-create" icon="add"
                  onClick={() => setCreateProject(true)}>
            {t('projectlist4', "Neues Projekt erstellen")}
          </Button>
        </HeadlineContainer>

        {projects?.length === 0 &&
        <CardListContainer>
          <SortHeaderContainer>
            <TipContainer>
              {t("firstproject", "Erstellen Sie ein Projekt für Ihre erst Analyse.")}
            </TipContainer>
          </SortHeaderContainer>
        </CardListContainer>
        }

        {projects?.length > 0 &&
        <CardListContainer>
          <SortHeaderContainer>
            <Lefter>
              <Sort
                active={projectsSortedBy === SORT_NAME}
                onClick={() => {
                  sortProjectsBy(SORT_NAME)
                }}
                icon={(projectsSortedBy === SORT_NAME) && !sortProjectsAscending ? 'expand_all' : 'collapse_all'}
                size={10}
              >
                {t('projectlist5', "A-Z")}
              </Sort>
              <Sort
                active={projectsSortedBy === SORT_DATE}
                onClick={() => {
                  sortProjectsBy(SORT_DATE)
                }}
                icon={(projectsSortedBy === SORT_DATE) && !sortProjectsAscending ? 'collapse_all' : 'expand_all'}
                size={10}
              >
                {t('projectlist6', "Erstellt am")}
              </Sort>
            </Lefter>
          </SortHeaderContainer>

          {projects?.map(renderProject)}

        </CardListContainer>
        }

      </PageContent>
      {errorVar &&
      <Alert
        type="error"
        message={errorMessage ? errorMessage : error(0, t)}
        onClose={() => setErrorVar(false)}
      />
      }
      {info && infoMessage &&
      <Alert
        type="info"
        message={infoMessage}
        onClose={() => setInfo(false)}
      />
      }

      <UserOnboarding
        steps={tourConfig}
        isOpen={isTourOpen}
        rounded={25}
        accentColor={"#5cb7b7"}
        onRequestClose={closeTour}
        setTourPassed={setTourPassed}
        currentStep={currentStep}
        nextStepClicked={nextStepClicked}
        onSubmit={onProjectSubmit}
      />
    </Page>
  );
}

export default withRouter(withTranslation("translations")(ProjectList));
