import React, {useState, useEffect} from 'react';
import {get, post} from '../../security/Api';
// import {darkblue, grey70, grey80, grey5, tourquoise, blue, white, errorred} from '../../colors';
import {darkblue, grey70, grey80, grey10, grey5, white, errorred, grey90, grey30} from '../../colors';
import styled from '@emotion/styled';
import PageModal from '../PageModal';
import {Button} from '../Button';
// import Icon from '../Icon/Icon';
import OptionsBox from '../OptionsBox';
// import AnoOptionsBox from '../AnoOptionsBox';
// import Select from 'react-select'
import {withTranslation} from "react-i18next";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Select from "react-select";
// import EditInput from "../EditInput";
// import Error from "../Error";

const FirstLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-bottom: 8px;
    padding-right: 28px;
`;

const Headline = styled.div`
    color: ${props => props.light ? grey5 : props.disabled ? grey30 : grey80};
    font-size: 24px;
    text-align: left;
    font-family: AvenirLTPro-Medium, AvenirNextCyrMedium;

`;

const LoaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: auto;
`;

const LoaderContainer2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
        margin-top: 47%;
`;

const LoaderContainer3 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
`;

/*
const OptionsRow = styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 34px;
`;
*/

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const PreviewButtonRow = styled.div`
    display: flex;
    // justify-content: flex-end;
    bottom: 30px;
`;

const MethodHeadline = styled.div`
    display: flex;
    margin-bottom: 14px;
    font-size: 18px;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    justify-content: space-between;
    align-items: center;
`;

// const TimerIconContainer = styled.div`
//     margin-right: 8px;
//     display: flex;
//     color: ${grey5};
// `;

const SubHeadlineContainer = styled.div`
    font-size: 1em;
    color: ${grey5};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
`;

const SubValue = styled.div`
    font-size: 16px;
    font-weight: normal;
    ${props => props.result && 'font-family: AvenirLTPro-Black, AvenirNextCyrBold;'}
`;

const ModalInnen = styled.div`
    font-size: 1em;
    display: flex;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    color: ${darkblue};
    justify-content: ${props => props.previewOpened ? "left" : "center"};
    height: 100%;
    min-height: 600px;
    width: 100%;
`;

const TransitionHelper = styled.div`
    width: ${props => props.previewOpened ? "75%" : "50%"};
    display: flex;
`;

const MethodOptions = styled.div`
    font-size: .8em;
    width: ${props => props.previewOpened ? "30%" : "100%"};
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey80};
    border-radius: 5px;
    margin: 0 15px;
    color: ${grey5};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const MethodPreview = styled.div`
    font-size: 1em;
    width: 70%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 30px;
    background: ${grey5};
    border-radius: 5px;
    margin: 0;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const RightColumn = styled.div`
    width: "20%";
    max-width: 20%;
    // width: fit-content;
`;

const RightColumnArea = styled.div`
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 15px 10px;
    background: ${white};
    border-radius: 5px;
    margin: 0 15px 10px;
    color: ${props => props.disabled ? grey30 : grey90};
    transition: 0.15s;
    min-width: 200px;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const PriceTotalLine = styled.p`
    margin-top: -5px;
    font-family: AvenirTPro-Demi, AvenirNextCyrDemi;
    display: flex;
    justify-content: space-between;
`;

const Divider = styled.hr`
    color: ${grey70};
    height: .5px;
`;

const CalcContainer = styled.div`
    margin-top: 30px;
`;

const OverlayContainer = styled.div`
    margin-top: 30px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    &:after {
        content: 'Preview';
        position: relative;
        display: block;
        opacity: 0.9;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        z-index:10;
        box-shadow: 0 0 50px 50px ${grey5}
    }
`;

const CommentsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const CalcLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PreviewContainer = styled.div`
    font-size: .8em;
`;

// const CommentsBox = styled.div`
//     background: ${grey5};
//     padding: 5px;
//     margin: 9px;
//     min-height: 24px;
//     border-radius: 5px;
//     box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
//     position: relative;
// `;

// const CommentGroup = styled.div`
//     font-weight: bold;
//     font-size: 1.5em;
//     padding: 10px 0;
//     overflow: hidden;
// `;

const Comment = styled.div`
    background-color: ${grey10};
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    overflow: hidden;
`;

// const Timer = styled.div`
//     margin-left: 10px;
//     margin-top: 2px;
//     color: ${grey5};
// `;

const OptionsList = styled.div`
    border: 0;
    margin-top: 10px;
    width:100%;
    display: flex;
    margin-bottom: 20px;
    color: ${grey5};
`;

const BlockContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    position: relative;
`;

const CreditsLeft = styled.span`
    color: ${props => props.minus ? errorred : darkblue}
`;

const MethodPrice = styled.div`
    font-size: .8em;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 0 10px;
    transition: 0.15s;
`;

const ModelOptionsContainer = styled.div`
    color: black;
    margin-bottom: 20px;
`;

// const ModalVersionContainer = styled.div`
//     margin-top: 20px;
// `;

const ModelHeading = styled.div`
    color: white;
    font-size: 16px;
    margin-bottom: 6px;
`;

function ThematicAnalysisModal(props) {

  const [preview, setPreview] = useState(true);
  const [pseudoOptions, setPseudoOptions] = useState([
    {
      id: "pseudoAuto",
      name: props.t("themaModal13", "Themen automatisch suchen"),
      value: true,
    },
    {
      id: "pseudoOwn",
      name: props.t("themaModal12", "Wenden Sie Ihr eigenes Modell an"),
      value: false,
    },
    // {
    //   id: "pseudoHybrid",
    //   name: props.t("themaModal15", "Hybrid"),
    //   value: false,
    // },
  ])

  const [modelCol, setModelValue] = useState(undefined);
  const [codebook, setCodebook] = useState(undefined);
  // const [versionCol, setVersionValue] = useState({value: "", label: ""});

// eslint-disable-next-line no-unused-vars
  const [modelOptions, setModelOptions] = useState([])

  const [showModels, setShowModels] = useState(false);

  const [pickedPseudonym, setPickedPseudonym] = useState(props.t("themaModal13", "Themen automatisch suchen"));
  // const checkColumnShow = false;
  const [themaStarted, setThemaStarted] = useState(false);
  // const [themaErrorCode, setThemaErrorCode] = useState(undefined);
  // const [themaErrorMessage, setThemaErrorMessage] = useState(undefined);

  const {
    history, onClose, checkResult,
    checkStudy,
    getPreview,
    previewLoaded,
    checkLoaded,
    file,
    t,
    columnCol,
    thematicPreviewResult
  } = props;

  // const renderPreview = (item) => {
  //     return (
  //       <CommentsBox>
  //         <CommentGroup>{item.current_name}</CommentGroup>
  //         {item.prototypes.map((subitem, t) => {
  //           return (
  //             <Comment>{subitem.text}</Comment>
  //           );
  //         })}
  //       </CommentsBox>
  //     )
  // }

  function getOptionsForPreview() {
    let result = {};

    result.output = {
      "option": pickedPseudonym === "Option" ? null : pickedPseudonym,
      // "option": pickedPseudonym,
    };

    /*
    result.preprocess = {
      "replace_emails": options.find((option) => {
        return option.id === "replaceEmails"
      })?.value,
      "replace_phones": options.find((option) => {
        return option.id === "replacePhones"
      })?.value,
    }
    */
    /*
    result.anonym = {
      "pseudo": pickedPseudonym === "Option" ? true : false,
      "conservative": false,
      "career_title": false,
      "country": options.find((option) => {
        return option.id === "replaceCountries"
      })?.value,
      "address": options.find((option) => {
        return option.id === "replaceAddress"
      })?.value,
      "city": options.find((option) => {
        return option.id === "replaceCities"
      })?.value,
      "date": options.find((option) => {
        return option.id === "replaceDates"
      })?.value,
      "black": [],
      "black_case_insensitive": [],
      "white": [],
      "white_case_insensitive": []
    }
    */
    return result;
  }


  useEffect(() => {
    getPreview(getOptionsForPreview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedPseudonym])

  useEffect(() => {
    showModels && getCustomModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModels])

  /*
    function onOptionChange(id, value) {
      const newOptions = options.map(option => {
        if (option.id === id) {
          option.value = value;
        }
        return option;
      });

      setOptions([...newOptions]);
    }
  */

  function onPseudoOptionChange(selectedId) {
    setModelValue(undefined);
    const newOptions = pseudoOptions.map((option) => {
      if (option.id === selectedId) {
        option.value = true;
        switch (selectedId) {
          case "pseudoAuto" :
            setPickedPseudonym(props.t("themaModal13", "Themen automatisch suchen"));
            setShowModels(false);
            break;
          case "pseudoOwn" :
            setPickedPseudonym(props.t("themaModal14", 'Eigene Themen vorgeben'));
            setShowModels(true);
            break;
          case "pseudoHybrid" :
            setPickedPseudonym(props.t("themaModal15", "Hybrid"));
            break;
          default:
            setPickedPseudonym(props.t("themaModal13", "Themen automatisch suchen"));
        }
      } else {
        option.value = false;
      }
      return option;
    })
    setPseudoOptions([...newOptions]);
  }

  function handlePseudonym(pseudonym) {
    const newOptions = pseudoOptions.map((option) => {
      if (option.id === "pseudoUser") {
        option.value = true;
      } else {
        option.value = false;
      }
      return option;
    })

    setPseudoOptions([...newOptions]);
    setPickedPseudonym(pseudonym);
  }


  let analysisStudy = async () => {
    setThemaStarted(true);
    // setThemaErrorCode(undefined);
    // setThemaErrorMessage(undefined);

    if (showModels && modelCol !== undefined) {
      let onError = async (code) => {
        setThemaStarted(false);
        // setThemaErrorCode(code);
        console.log(code);
        // setThemaErrorMessage(undefined);
      }

      let onSuccess = () => {
        setThemaStarted(false);
        history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
      }
      await post(
        '/study/themacustommodelstart',
        {
          studyId: props.studyId,
          fileId: props.file.id,
          modelId: modelCol.id.toString(),
          columnCol: checkLoaded && checkResult ? checkResult.columnName : '',
          // numberOfRows: checkResult.amountComments.toString(),
          // options: optionsForApi
        },
        onError,
        onSuccess
      )
    }
    else {
      const optionsForApi = {};
      optionsForApi['option'] = pickedPseudonym === "Option" ? null : pickedPseudonym;
      optionsForApi['output'] = ["temporary"];

      let onError = async (code) => {
        setThemaStarted(false);
      }

      let onSuccess = () => {
        setThemaStarted(false);
        history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
      }

      await post(
        '/study/thematicstart',
        {
          studyId: props.studyId,
          fileId: props.file.id,
          columnCol: checkLoaded && checkResult ? checkResult.columnName : '',
          numberOfRows: checkResult.amountComments.toString(),
          options: optionsForApi,
          codebook: codebook === undefined ? false : true,
        },
        onError,
        onSuccess
      )
    }
  }

  let getCustomModels = async () => {

    const response = await get(`/study/custommodel`);

    if (response.status === 200 && response !== false) {
      const responseData = await response.json();

      console.log(responseData[0].data);
      setModelOptions(responseData[0].data.map((model) => {
        return {
          id: model.id,
          name: model.name + " - v" + model.version
        }
      }))
    }
  }

  let inferenceGenerateEmbeddings = async () => {

    setThemaStarted(true);

      let onError = async (code) => {
        setThemaStarted(false);
        history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
        // setThemaStarted(false);
        // setThemaErrorCode(code);
        // console.log(code);
        // setThemaErrorMessage(undefined);
      }

      let onSuccess = () => {
        setThemaStarted(false);
        history.push(`/project/${props.projectId}/study/${props.studyId}/result`);
      }
      await post(
        '/study/inference/createembeddings',

        {
          studyId: props.studyId,
          fileId: props.file.id,
          modelId: modelCol.id.toString(),
          columnCol: checkLoaded && checkResult ? checkResult.columnName : '',
          // numberOfRows: checkResult.amountComments.toString(),
          // options: optionsForApi
        },
        onError,
        onSuccess
      )
  }

  let getListOfColumns = () => {
    // if (checkLoaded && checkResult) setColumnCol({ label: checkResult.columnName, value: checkResult.columnName });
    if (checkLoaded && checkResult) {
      let topTenRows = Object.keys(JSON.parse(checkResult.topTenRows));
      return topTenRows.map(function (key) {
        return {
          value: key,
          label: key,
        };
      });
    }
  };

  let getListOfModels = () => {
    if (modelOptions.length === 0) return;
    return modelOptions.map((model) => {
      return {
        id: model.id,
        value: model.name,
        label: model.name,
      };
    })
  }

  let getListOfCodebook = () => {
    return [{
        id: 1,
        value: "NPS_v1",
        label: "NPS_v1",
      }];
  }

  let changeModelOption = (e) => {
    setModelValue({value: e.value, label: e.value, id: e.id});
  }

  let changeCodebookOption = (e) => {
    setCodebook({value: e.value, label: e.value, id: e.id});
  }

  let changeColumnCol = (e) => {
    // setColumnCol({value: e.value, label: e.value});
    // columnCol = { value: e.value, label: e.value };
    checkStudy(e.value);
    getPreview(getOptionsForPreview(), e.value);
  }

  function renderAnoPreview(comment) {
    return <Comment>{comment}</Comment>;
  }

  let renderModelOptions = () => {
    if (showModels) {
      return (
        <ModelOptionsContainer>
          <ModelHeading>
            <span>{props.t('ModelOptions1', "Model")}</span>
          </ModelHeading>
          <Select
            id="modelCol"
            name="modelCol"
            options={getListOfModels()}
            value={modelCol}
            onChange={(value) => changeModelOption(value)}
            autoFocus={false}
            required={true}
          />

          {/*<ModalVersionContainer>*/}
          {/*<ModelHeading>*/}
          {/*   <span>{props.t('ModelOptions2', "Version")}</span>*/}
          {/* </ModelHeading>*/}
          {/* <Select*/}
          {/*   id="versionCol"*/}
          {/*   name="versionCol"*/}
          {/*   options={getListOfModelVersions()}*/}
          {/*   value={versionCol}*/}
          {/*   onChange={(value) => changeModelVersionOption(value)}*/}
          {/*   autoFocus={false}*/}
          {/*   required={true}*/}
          {/* />*/}
          {/*</ModalVersionContainer>*/}
        </ModelOptionsContainer>
      )
    }
  }

  let renderCodebookOptions = () => {
      return (
        <ModelOptionsContainer>
          <ModelHeading>
            <span>{props.t('CodebookOptions1', "Codebook")}</span>
          </ModelHeading>
          <Select
            id="modelCol"
            name="modelCol"
            options={getListOfCodebook()}
            value={codebook}
            onChange={(value) => changeCodebookOption(value)}
            autoFocus={false}
            required={true}
          />
        </ModelOptionsContainer>
      )
  }

  let renderLeftColumn = () => {
    return (
      <MethodOptions previewOpened={preview}>
        <FirstLine>
          <Headline light>{t("StudyDetail24", 'Thematische Analyse')}</Headline>
        </FirstLine>

        {checkLoaded && checkResult && file &&
        <MethodHeadline>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <SubHeadlineContainer>
                <SubValue result={true}>{file.name}</SubValue>
              </SubHeadlineContainer>
            </Grid>
            {/*<Grid item sm={12} xs={12} md={12} lg={12} xl={12}>*/}
            {/*  <TimerIconContainer>*/}
            {/*    <Icon size={24} name="timer"/>*/}
            {/*    <Timer>{checkResult.computationTime < 1 ? 1 : checkResult.computationTime} min</Timer>*/}
            {/*  </TimerIconContainer>*/}
            {/*</Grid>*/}
          </Grid>
        </MethodHeadline>
        }
        {/*{!checkLoaded && <MethodHeadline>{t("method1", "Wird berechnet...")}</MethodHeadline>}*/}

        <OptionsList>
          <OptionsBox
            headline={props.t('anoModal9', "Weitere Optionen")}
            options={pseudoOptions}
            onChange={onPseudoOptionChange}
            onUserDefined={handlePseudonym}
            type="radio"
            vertical={true}
            color={white}
          />
        </OptionsList>
        {!showModels && renderCodebookOptions()}
        {renderModelOptions()}
        <PreviewButtonRow>
          <Button
            mainbutton
            type="button"
            tiny
            // icon={"eye"}
            onClick={() => setPreview(!preview)}
          >
            {preview ? t("anoModal11", "Vorschau ausblenden") : t("anoModal12", "Vorschau")}
          </Button>
        </PreviewButtonRow>
      </MethodOptions>
    )
  }

  let renderPreviewResult = () => {
    return (
      <MethodPreview active={preview}>
        <FirstLine>
          <Headline>{t("anoModal2", "Vorschau")}</Headline>
        </FirstLine>

        {previewLoaded &&
        <PreviewContainer>
          {/*<CalcLine>{t("anoModal8", "Begriffe in den folgenden Beitragen werden ersetzt")}:</CalcLine>*/}
          <OverlayContainer>
            <CommentsContainer>
              {thematicPreviewResult?.map(renderAnoPreview)}
            </CommentsContainer>
          </OverlayContainer>

          <BlockContainer>
            <CommentsContainer>
              {t("anoModal19", "Nicht die richtige Spalte?")}
            </CommentsContainer>
            <CommentsContainer>
              {t("account127", "Spaltenname auswählen")}
            </CommentsContainer>
            {/*<CommentsContainer>*/}
            {/*  <Select*/}
            {/*    id="columnCol"*/}
            {/*    name="columnCol"*/}
            {/*    options={getListOfColumns()}*/}
            {/*    // value={checkLoaded && checkResult ? { label: checkResult.columnName, value: checkResult.columnName } : ''}*/}
            {/*    value={columnCol}*/}
            {/*    onChange={(value) => changeColumnCol(value)}*/}
            {/*    autoFocus={false}*/}
            {/*    required={true}*/}
            {/*  />*/}
            {/*</CommentsContainer>*/}
          </BlockContainer>
          <CommentsContainer>
            <Select
              id="columnCol"
              name="columnCol"
              options={getListOfColumns()}
              // value={checkLoaded && checkResult ? { label: checkResult.columnName, value: checkResult.columnName } : ''}
              value={columnCol}
              onChange={(value) => changeColumnCol(value)}
              autoFocus={false}
              required={true}
            />
          </CommentsContainer>
        </PreviewContainer>
        }

        {!previewLoaded &&
        <LoaderContainer2>
          <CircularProgress/>
        </LoaderContainer2>
        }
      </MethodPreview>
    )
  }

  function renderLoader() {
    return (
      <LoaderContainer3>
        <CircularProgress/>
      </LoaderContainer3>
    )
  }

  let renderRightColumn = () => {

    // console.log(props.projectCostNumber?.rowsByMethod['Thema']);
    // console.log(checkLoaded);
    // console.log(checkResult);
    // console.log(checkResult?.calculatedPrice);

    let currentRows = props.projectCostNumber?.rowsByMethod['Thema'] ? props.projectCostNumber.rowsByMethod['Thema'] : 0;
    let creditsLeft = checkLoaded && checkResult && props.projectCostNumber?.rowsByMethod['Thema'] !== undefined ? props.projectCostNumber.rowsByMethod['Thema'] - checkResult.calculatedPrice : '...';

    console.log(currentRows);
    console.log(creditsLeft);

    return (
      <RightColumn>
        <RightColumnArea>
          <MethodPrice>
            <FirstLine>
              <Headline>1. {t("ThemaResult4", 'Vorsortierung')}</Headline>
            </FirstLine>
            {t("ThemaResult41", 'Automatische Sortierung und erste Vorschläge für Codes.')}
            <br/>
            <br/>
            {t("anoModal3", "Durch Anwenden des Data Services werden die folgenden Zeilen abgebucht")}:
            <CalcContainer>
              <CalcLine>
                <span>{t("projectform5", "Kostennummer")}</span>
                <span>{props.projectCostNumber.name.length ? props.projectCostNumber.name : '_' + t("projectForm12", "default")}</span>
              </CalcLine>
              <CalcLine>
                <span>{t("anoModal4", "Aktuelle Zeilen")}</span>
                <span>{currentRows}  {t("organisationModal9", "Zeilen")}</span>
              </CalcLine>
              <CalcLine>
                <span>{t("StudyDetail24", 'Thematische Analyse')}</span>
                <span>{checkLoaded && checkResult ? -checkResult.calculatedPrice : "..."} {t("organisationModal9", "Zeilen")}</span>
              </CalcLine>
            </CalcContainer>
            <Divider/>
            <PriceTotalLine>
              <span>{t("anoModal6", "Neue Zeilen")}</span>
              <CreditsLeft
                minus={creditsLeft < 0}>
                {checkLoaded && checkResult ? creditsLeft : "..."} {t("organisationModal9", "Zeilen")}
              </CreditsLeft>
            </PriceTotalLine>
            <ButtonRow>
              {themaStarted &&
              renderLoader()
              }
              {!themaStarted && creditsLeft >= 0 && creditsLeft !== "..." && !showModels &&
              <Button
                mainbutton
                // disabled={!previewLoaded || (showModels && modelCol === undefined)}
                icon="click"
                type="button"
                color={grey70}
                fullWidth
                texttransformnone
                data-cy="send-options"
                onClick={analysisStudy}
              >
                {showModels ? t("ThemaResult7", "das Modell anwenden") : t("ThemaResult6", "Daten   vorsortieren")}
              </Button>
              }
              {showModels && !themaStarted &&
              <Button
                mainbutton
                disabled={(showModels && modelCol === undefined) || creditsLeft <= 0 || creditsLeft === "..."}
                icon="click"
                type="button"
                color={grey70}
                fullWidth
                texttransformnone
                data-cy="send-options"
                onClick={inferenceGenerateEmbeddings}
              >
                {t("ThemaResult77", "Start")}
              </Button>
              }


              {creditsLeft < 0 && creditsLeft !== "..." &&
              <Button type="button" tiny icon="main_arrow" mainbutton
                      onClick={props.showBuyModal}>{t("anoModal17", "Zeilen kaufen")}</Button>
              }
            </ButtonRow>
          </MethodPrice>
        </RightColumnArea>

        {/*<RightColumnArea disabled>*/}
        {/*  <FirstLine>*/}
        {/*    <Headline disabled>2. {t("ThemaResult5", 'Korrektur')}</Headline>*/}
        {/*  </FirstLine>*/}
        {/*  Manuelle Korrektur der Gruppierung und Codes*/}
        {/*</RightColumnArea>*/}

        {/*<RightColumnArea disabled>*/}
        {/*  <FirstLine>*/}
        {/*    <Headline disabled>3. {t("ThemaResult6", 'Nachsortierung')}</Headline>*/}
        {/*  </FirstLine>*/}
        {/*  Anwendung des Codebooks auf die restlichen Daten*/}
        {/*  <ProgressBarContainer>*/}
        {/*    <ProgressBar progress={0}>*/}
        {/*    </ProgressBar>*/}
        {/*  </ProgressBarContainer>*/}
        {/*  <Button*/}
        {/*    type="button"*/}
        {/*    disabled*/}
        {/*    fullWidth*/}
        {/*    texttransformnone*/}
        {/*    data-cy="send-options"*/}
        {/*    onClick={() => alert("clicked")}*/}
        {/*  >*/}
        {/*    {t("ThemaResult7", "Daten   nachsortieren")}*/}
        {/*  </Button>*/}

        {/*</RightColumnArea>*/}
      </RightColumn>
    )
  }

  return (
    <PageModal onClose={onClose} color={grey5}>
      <ModalInnen>
        <TransitionHelper previewOpened={preview}>
          {renderLeftColumn()}
          {!thematicPreviewResult &&
          <LoaderContainer>
            <CircularProgress/>
          </LoaderContainer>
          }
          {preview && thematicPreviewResult &&
          renderPreviewResult()
          }
        </TransitionHelper>

        {renderRightColumn()}

        {/*{checkColumnShow &&*/}
        {/*renderCheckColumn()*/}
        {/*}*/}
      </ModalInnen>
    </PageModal>
  );
}

export default withTranslation("translations")(ThematicAnalysisModal);
