import React from 'react';
import moment from 'moment';

import {get} from '../security/Api';
import PageContent from '../components/PageContent';
import Headline, {HeadlineContainer, HeadlineText} from '../components/Headline';
import {NavLink, SubNavigation} from '../components/SubNavigation';
import {CardListContainer, SortHeaderContainer} from '../components/CardList';
import Sort from '../components/Sort';
import CardHeader from '../components/CardHeader';
import styled from '@emotion/styled';
import Page from '../components/Page';
import Curtain from '../components/Curtain';
import {withTranslation} from "react-i18next";

const SORT_NAME = 'name';
const SORT_DATE = 'date';

const CardContainer = styled.div`
                            margin-bottom: 10px;
                        `;

class ProjectArchivedList extends React.PureComponent {
  state = {
    projects: [],
    loaded: false,
    error: false,
    loadingError: false,
    sortBy: null,
  };

  async componentDidMount() {
    const response = await get('/project/list');

    if (response.status === 200) {
      const responseData = await response.json();

      console.log(responseData);

      this.setState({
        loaded: true,
        projects: responseData,
      });

      return;
    }

    this.setState({
      loadingError: true,
      loaded: true
    });

  }

  renderProject(project) {
    return (
      <CardContainer key={project.id}>
        <CardHeader
          icon="folder_outline"
          info={moment(project.createdAt).format('DD.MM.YYYY')}
          to={`/project/${project.id}/detail`}
        >
          {project.name}
        </CardHeader>
      </CardContainer>
    );
  }

  render() {
    const {loaded, projects, sortBy, loadingError} = this.state;
    const {t} = this.props;

    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="error"/>
      );
    }

    const sortedProjects = [...projects].sort((a, b) => {
      if (sortBy === SORT_NAME) {
        return a.name.localeCompare(b.name);
      }

      if (sortBy === SORT_DATE) {
        return moment(b.createdAt).diff(moment(a.createdAt));
      }

      return 0;
    });

    return (
      <Page>
        <PageContent>

          <HeadlineContainer>
            <HeadlineText>{t('projectarchive2', "Alle Projekte")}</HeadlineText>
            <SubNavigation>
              <NavLink icon="folder_outline" data-cy="login"
                       to={'/project'}>{t('projectarchive3', "Aktive Projekte")}</NavLink>
              <NavLink icon="folder_archive_outline" data-cy="registration"
                       to={'/project/archived'}>{t('projectarchive4', "Archivierte Projekte")}</NavLink>
            </SubNavigation>
            <div/>
          </HeadlineContainer>

          <CardListContainer>
            <Headline>2020</Headline>
            <SortHeaderContainer>
              <Sort onClick={() => this.setState({sortBy: SORT_NAME})}>{t('projectarchive5', "A-Z")}</Sort>
              <Sort onClick={() => this.setState({sortBy: SORT_DATE})}>{t('projectarchive6', "Zuletzt geöffnet")}</Sort>
            </SortHeaderContainer>

            {sortedProjects.map(this.renderProject)}

          </CardListContainer>

        </PageContent>
      </Page>
    );
  }
}

export default withTranslation("translations")(ProjectArchivedList);
