import React from 'react';
import styled from '@emotion/styled';
import { grey30, grey80, white,orange1 } from '../colors';
import { Link } from 'react-router-dom';
import Icon from './Icon/Icon';

const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px 10px 0 0;
  padding: 0 42px;
  height: 80px;

  color: ${props => props.color ? props.color : white};
  background: ${props => props.background ? props.background : grey80};
`;

const CardHeaderLinkContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px 10px 0 0;
  padding: 0 72px;
  height: 80px;

  color: ${white};
  background: ${props => props.background ? props.background : grey80};

  text-decoration: none;

  .name {
    display: flex;
  }

  &:hover {
    .name {
      color:  ${grey30};

      > svg {
        margin-left: 4px;
      }
    }
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;

  > svg {
      margin-bottom: -2px;
      transition: margin-left 0.5s;
  }
`;

const Info = styled.div`
  font-size: 16px;
`;

const TextContainer = styled.div`
  margin-right: 15px;
  font-size: 22px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const IconContainer = styled.div`
  margin-right: 15px;
`;

export default class CardHeader extends React.PureComponent {
  render() {
    const { to, id, background, color, deletionRequested} = this.props;

    if (to) {
      return (
        <CardHeaderLinkContainer data-cy={id} to={to} background={deletionRequested ? orange1 : background} color={color}>
          {this.renderInner()}
          {deletionRequested ? "Requested deletion" : ""}
            </CardHeaderLinkContainer>
      );
    }

    return (
      <CardHeaderContainer data-cy={id} background={deletionRequested ? orange1 : background} color={color}>
        {this.renderInner()}
        {deletionRequested ? "Requested deletion" : ""}
      </CardHeaderContainer>
    );

  }

  renderInner() {
    const { info, children, to, icon } = this.props;

    return (
      <React.Fragment>
        <Name>
          {icon && <IconContainer><Icon name={icon} size={48} /></IconContainer>}
          <div className="name">
            <TextContainer>
              {children}
            </TextContainer>
            {to && <Icon name="main_arrow" />}
          </div>
        </Name>

        <Info>
          {info}
        </Info>
      </React.Fragment>
    );
  }
}
