import React, {useState, useEffect, useRef} from 'react';

import {
  darkblue,
  grey5,
  grey10,
  grey70,
  grey80,
  grey90,
  white,
  black,
  grey7,
  grey30,
  cancel,
  grey20,
  buttonmain
} from '../../colors';

import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {get, post, post_simple} from "../../security/Api";

import styled from '@emotion/styled';

import errorFn from "../../error";

import {Button, Button as PlatButton} from '../Button';
import Input from '../EditInput.js';
import EditInput from "../EditInput";
import Icon from '../Icon/Icon';
import DeleteLabelModal from "../DeleteLabelModal";
import CheckBox from "../CheckBox";
import SearchField from "../SearchField";
import ResultTabComments from "../Thema/ResultTabComments";
import Tab from "../Tab";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {InfoPopupGreen} from "../InfoPopup.js";

// import {fakeSimilarities} from "../../FakeData";

const MainHeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;

    padding 0 0 5px 5px;
`;

const FirstLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-bottom: 12px;
    padding-right: 28px;
`;

const Headline = styled.div`
    color: ${props => props.light ? grey5 : props.disabled ? grey30 : grey80};
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    font-family: AvenirLTPro-Medium, AvenirNextCyrMedium;
`;

const SubHeader = styled.div`
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 5px;
    padding-right: 5px;
`;
const MiddleHeader = styled.div`
    font-size: 18px;
    font-weight: bold;          
    font-weight: normal;
    margin-bottom: 5px;
    padding-right: 5px;
`;
const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    min-height: 50px;
`;

const ModalInnen = styled.div`
    font-size: 1em;
    display: flex;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    color: ${darkblue};
    justify-content: ${props => props.previewOpened ? "left" : "center"};
    height: 100%;
    min-height: 600px;
    width: 100%;
    
`;

const TransitionHelper = styled.div`
    width: ${props => props.previewOpened ? "80%" : "40%"};
    display: flex;
`;

const LeftColumn = styled.div`
    width: "100%";
    max-width: 40%;
    width: fit-content;
    min-width: 260px;
    // height: 70vh;
    // overflow:scroll; 
`;

const ThemaNameArea = styled.div`
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 15px 15px;
    background: ${grey80};
    border-radius: 5px;
    margin: 0 5px 5px;
    color: ${grey5};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const MergeButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MethodOptions = styled.div`

  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
    overflow-y: scroll;
    height: 60vh;
    scrollbar-width: thin;
    font-size: .8em;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding-left: 12px;
    padding-top: 15px;
    padding-right: 12px;
    background: ${grey80};
    border-radius: 5px;
    margin: 0 5px;
    color: ${grey5};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05);
`;

const OptionsRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const OptionUnitRow = styled.div`
    display: flex;
    justify-content:  space-between;
`;

const OptionUnit = styled.div`
    background-color: ${white};
    color: ${black};
    padding: 5px;
    margin: 10px 0;
    border-radius: 7px;
    overflow: hidden;
    width: fit-content;
    display: flex;
    margin-right: 5px;
`;

const SearchBox = styled.div`
    display: flex;
    justify-content:  space-between;
`;

const RightColumn = styled.div`
    width: 20%;
    padding-left: 5px;
    // max-width: 20%;
    // width: fit-content;
`;

const RightColumnArea = styled.div`
    float:right;
    width: 100%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 15px 15px;
    background: ${white};
    border-radius: 5px;
    margin: 0 0 10px 10px;
    color: ${props => props.disabled ? grey30 : grey90};
    transition: 0.15s;
    min-width: 200px;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05);
`;

const IconContainer = styled.div`
    position: relative;
    cursor: pointer;
    margin-left: 2px;
    padding-right: 3px;
    padding-left: 3px;
`;

const LabelBox = styled.div`
    display: flex;
    padding-top: 4px;
    margin-left: 2px;
`;

const FilterBox = styled.div`
    display: flex;
    padding-top: 10px;
    margin-left: 10px;
`;

const MethodPreview = styled.div`
    // scrollbar-color: #6969dd;
    // scrollbar-width: thin;
    overflow-y: scroll;
    height: 74vh;
    scrollbar-width: thin;

    font-size: 1em;
    width: 100%;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    padding: 0 10px;
    background: ${grey5};
    border-radius: 5px;
    margin: 0;
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05) ;
`;

const ResultContainer = styled.div`
    font-size: .8em;
`;

const OneThema = styled.div`

`;

const CommentsBox = styled.div`
    background: ${grey5};
    padding: 5px;
    margin: 5px 3px 0 3px;
    min-height: 24px;
    border-radius: 5px;
    // box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
    box-shadow: 0px 7px 20px -3px rgba(0, 0, 0, 0.28);
    position: relative;
    z-index: 1;
`;

const Comment = styled.div`
    background-color: ${props => props.addnew ? white : grey10};
    color: ${props => props.addnew ? grey30 : grey90};
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: ${props => props.addnew ? 'dashed' : ""};
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    cursor: ${props => props.addnew ? 'crosshair' : 'move'};
    transition: all .2s ease-in-out;
    &:hover {
      scale: 1.01;
    }
`;

const SuggestedCommentsBox = styled.div`
    background: ${grey20};
    padding: 5px;
    margin: 0 10px 25px 10px;
    min-height: 24px;
    border-radius: 0 0 7px 7px;
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
    position: relative;
`;

const SuggestedComment = styled.div`
    background-color: ${grey7};
    color: ${props => props.addnew ? grey30 : grey90};
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px dashed;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    cursor: ${props => props.addnew ? 'crosshair' : 'move'};
    transition: all .2s ease-in-out;
    &:hover {
      scale: 1.01;
    }
`;

const NavbarLinkContainer = styled.div`

`;

const NavbarLink = styled(Link)`
    color: ${buttonmain};
    font-family: Arial, Helvetica, sans-serif;
    margin: auto;
    text-decoration: none;
    // text-transform: uppercase;
`;

const EditInputContainer = styled.div`
    display: flex;
    margin-top: -10px;
`;

const MergedTopicInputContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const TabContainer = styled.div`
    display: flex;
    position: relative;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: end;
`;

const ProgressBarContainer = styled.div`
  height: 30px;
  margin: 0 0 20px 0;
  overflow: hidden;
  position: relative;
  background-color: ${grey10};
`;

const ProgressBar = styled.span`
  height: 100%;
  display: block;
  width: ${props => props.progress ? props.progress + '%' : 0};
  line-height: 30px;
  position: absolute;
  text-align: end;
  background-color: ${grey70};
`;

const ProgressBarPercentage = styled.span`
  color: ${white};
  font-size: 16px;
  margin-right: 4px;
`;

const DottedHr = styled.hr`
  margin: 0 10px 21px 10px;
	padding: 0;
	height: 0;
	border: none;
	border-top: 2px dashed lightgrey;
`;

function ThematicAnalysisResult(props) {

  // const dragCommentIndexInArray = useRef(null);
  // const dragCommentId = useRef(null);

  const dragFromLabelId = useRef(null);
  const dragToLabelId = useRef(null);
  const dragCommentPythonId = useRef(null);

  const dragFromArea = useRef(null);
  const dragedCommentText = useRef(null);

  const dragComment = useRef(null);
  const dragOverComment = useRef(null);
  const sourceIndex = useRef(null);
  const destinationIndex = useRef(null);

  const [errorBar, setErrorBar] = useState(false);
  const [errorBarMessage, setErrorBarMessage] = useState(null);

  const [themaCompletedResultData, setThemaCompletedResultData] = useState(null);

  const [similarCommentsWaitingResponse, setSimilarCommentsWaitingResponse] = useState(true);
  const [similarCommentsForNewLabel, setSimilarCommmentsForNewLabel] = useState(undefined);

  const [moveSuggestionWaitingResponse, setMoveSuggestionWaitingResponse] = useState(false);

  const [sortedAlphabetically, setSortedAlphabetically] = useState(false);
  const [sortedByNumberOfComments, setSortedByNumberOfComments] = useState(false);

  const [filtered, setFiltered] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const [searchingLabelIndex, setSearchingLabelIndex] = useState(undefined);
  const [searchingValue, setSearchingValue] = useState("");
  const [startSearching, setStartSearching] = useState(false);
  const [searchingResultArray, setSearchingResultArray] = useState([]);

  const [deleteLabelModalOpen, setDeleteLabelModalOpen] = useState(false);
  const [deleteLabelModalError, setDeleteLabelModalError] = useState(null);
  const [labelIdToDelete, setLabelIdToDelete] = useState(null);
  const [deleteLabelWaitingResponse, setDeleteLabelWaitingResponse] = useState(false);

  const [deleteCommentWaitingResponse, setDeleteCommentWaitingResponse] = useState(false);

  const [labelNameFocused, setLabelNameFocused] = useState(null);
  const [labelNameBeforeEdit, setLabelNameBeforeEdit] = useState(undefined);

  const [newLabelModalOpen, setNewLabelModalOpen] = useState(false);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelWaitingResponse, setNewLabelWaitingResponse] = useState(false);

  const [mergeActivated, setMergeActivated] = useState(false);
  const [mergeLabels, setMergeLabels] = useState([]);
  const [mergedLabelName, setMergedLabelName] = useState("");
  const [mergingLabelsInProgress, setMergingLabelsInProgress] = useState(false);

  const [preparingFileToDownload, setPreparingFileToDownload] = useState(false);
  const [downloadLink, setDownloadLink] = useState(undefined);

  const [tabIndex, setTabIndex] = useState(1);
  const [labelProgress, setLabelProgress] = useState(0);


  const ApiResult = useRef(null);

  const {
    t,
    studyId,
    projectId,
    isStudyCustomModel,
  } = props;

  useEffect(() => {
    fetchThemaData();
    fetchLabelCommentsProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, mergingLabelsInProgress])

  const fetchThemaData = async () => {

    const themaResponse = await get(
      `/study/${studyId}/themaresult`,
    )

    if (themaResponse.status === 200) {
      ApiResult.current = await themaResponse.json();
      await setThemaCompletedResultData(ApiResult.current[0].data.labels);

      await fetchSimilarComments(ApiResult.current[0].data.labels);
    } else {
      // setThemaCompletedResultData(fakeData.labels);
      // setThemaSimilarComentsCompletedResultData(fakeLabelsData.labels);
      setSimilarCommentsWaitingResponse(false);
      ApiResult.current = await themaResponse.json();
      await setErrorBarMessage("status " + themaResponse.status + " " + ApiResult.current.message);
      await setErrorBar(true);
    }
  }

  const fetchLabelCommentsProgress = async () => {

    let onError = async (code) => {
      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);
    }

    let onSuccess = async (responseData) => {
      setLabelProgress(responseData[0].data)
    }
    await post(
      '/study/thema/uniapi/',
      {
        studyId: studyId,
        method: "PUT",
        path: '/thematic-analysis/label/progress/' + studyId + "/",
        options: {'needContent': true},
      },
      onError,
      onSuccess
    )
  }

  const fetchSimilarComments = async (themaResultFromApi) => {

    setSimilarCommentsWaitingResponse(true);

    const themaSimilarCommentsResponse = await get(
      `/study/${studyId}/themasimilarcomments`,
    );

    if (themaSimilarCommentsResponse.status === 200) {
      let similarities = await themaSimilarCommentsResponse.json();

      // console.log("Thema array length " + themaResultFromApi?.length);
      // console.log("Similarities array length " + similarities[0].data.labels.length);

      if (!themaResultFromApi) return;

      let counter = 0;
      let max = similarities[0].data.labels.length;

      await setThemaCompletedResultData(themaResultFromApi.map((label, label_index) => {
        if (counter < max) {
          similarities[0].data.labels.forEach((similar_label) => {
            if (label.label_id === similar_label.label_id) {
              label.similarities = similar_label.similarities;
            }
          })
        }
        counter++;
        return label;
      }))
      setSimilarCommentsWaitingResponse(false);
    } else {
      console.log(themaSimilarCommentsResponse.status);
      // ApiResult.current = await themaSimilarCommentsResponse.json();
      // setThemaSimilarComentsCompletedResultData(fakeSimilarities.labels);
      // setThemaSimilarComentsCompletedResultData([]);
      // setSimilarCommentsError(true);
      // setSimilarCommentsErrorCode(ApiResult.current.code);
      // setSimilarCommentsErrorText(ApiResult.current.message)
    }
    setSimilarCommentsWaitingResponse(false);
    // setThemaSimilarComentsCompletedResultData([]);
  }

  const onChangeNewLabelName = async (value) => {
    setNewLabelName(value);
  }

  const onAddNewLabelSubmit = async () => {

    setErrorBar(false);
    setErrorBarMessage(null);

    if (newLabelModalOpen && (newLabelName == null || newLabelName === "")) {
      addNewTopicModalClose();
      return false;
    }

    setNewLabelWaitingResponse(true);
    addNewTopicModalClose();

    let onError = async (code) => {

      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);

      setDeleteLabelWaitingResponse(false);
      setNewLabelWaitingResponse(false);
    }

    let onSuccess = async (responseData) => {

      await setThemaCompletedResultData(prevState => {
        let obj = {
          current_name: newLabelName,
          detail_edit_url: "/thematic-analysis/label/" + studyId + "/" + responseData[0].data + "/",
          is_true_label: false,
          label_id: parseInt(responseData[0].data),
          original_name: newLabelName,
          prototypes: [],
          similarities: [],
          searchResults: [],
          revert_url: "/thematic-analysis/label/revert/" + studyId + "/" + responseData[0].data + "/",
        }

        return [obj, ...prevState];
      });
      setSearchingLabelIndex(undefined);
      setNewLabelWaitingResponse(false);
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "PUT",
        path: '/thematic-analysis/label/addtopic/' + studyId + '/' + newLabelName + "/",
        options: {'newLabelAdd': true, 'needContent': true},
      },
      onError,
      onSuccess
    )
  };

  const onLabelEditSubmit = async (index) => {
    setErrorBar(false);
    setErrorBarMessage(null);
    setLabelNameBeforeEdit(undefined);

    await setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
      if (index === label_index) {
        label.name_changed = true;
      }
      return label;
    }))

    let onError = async (code) => {

      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);

      await setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (index === label_index) {
          label.name_changed = false;
        }
        return label;
      }))
    }

    let onSuccess = () => {
      console.log("Renamed");
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "PUT",
        path: themaCompletedResultData[index].detail_edit_url,
        options: {'newLabelName': themaCompletedResultData[index].current_name},
      },
      onError,
      onSuccess
    )
  };

  const returnOriginalLabelName = async (index) => {
    setErrorBar(false);
    setErrorBarMessage(null);

    let onError = async (code) => {
      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);
    }

    let onSuccess = () => {
      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (index === label_index) {
          label.current_name = label.original_name;
        }
        return label;
      }))
    }

    await post(
      '/study/thema/uniapi/',
      {
        studyId: studyId,
        method: "PUT",
        path: themaCompletedResultData[index].revert_url,
        options: {'revertLabelName1': true},
      },
      onError,
      onSuccess
    )
  }

  const deleteLabel = async () => {
    setErrorBar(false);
    setErrorBarMessage(null);
    setDeleteLabelWaitingResponse(true);

    let onError = async (code) => {
      // await setError(true);

      // setErrorBar(true);
      setDeleteLabelModalError(errorFn(code, props.t));
      setDeleteLabelWaitingResponse(false);
    }

    let onSuccess = async (responseData) => {

      await setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (labelIdToDelete === label.label_id) {
          console.log("label - " + label);
          label.current_name = "deleted_label";
          label.prototypes = [];

          return label;
        }
        return label;
      }))

      setDeleteLabelModalError(undefined);
      setDeleteLabelModalOpen(false);
      setDeleteLabelWaitingResponse(false);
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "PUT",
        path: '/deletetopic/' + studyId + '/' + labelIdToDelete + '/',
        options: {'deleteLabel': true},
      },
      onError,
      onSuccess
    )
  }

  const onChangeMergedLabelName = async (value) => {
    setMergedLabelName(value);
  }

  async function activateMerging() {
    setErrorBar(false);
    setErrorBarMessage(null);

    if (mergeActivated && mergeLabels.length > 1) {

      if (mergedLabelName.length === 0) {
        setErrorBar(true);
        setErrorBarMessage("name!!!");
        return;
      }

      setMergeActivated(false);
      setMergingLabelsInProgress(true);

      let onError = async (code) => {
        setMergingLabelsInProgress(false);

        await setErrorBarMessage(errorFn(code, props.t));
        await setErrorBar(true);

      }

      let onSuccess = async (responseData) => {
        setThemaCompletedResultData(null);
        setMergingLabelsInProgress(false);
        // setMergeError(null);
        setMergeLabels([]);
      }

      await post(
        '/study/thema/uniapi/',
        {

          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/merge/' + studyId + '/' + mergedLabelName + '/',
          options: {labels_to_merge: mergeLabels},
        },
        onError,
        onSuccess
      )
    } else {
      setMergeActivated(!mergeActivated);
    }
    // setMergeError(null);
    setMergeLabels([]);
  }

  let deactivateMerging = () => {
    setMergeActivated(false);
    setMergeLabels([]);
    setMergedLabelName("");

    setErrorBar(false);
    setErrorBarMessage(null);
  }

  function addNewTopicModalClose() {
    setNewLabelModalOpen(false);
    // setNewLabelModalError(null);
  }

  function addNewTopicModalOpen() {
    setErrorBar(false);
    setErrorBarMessage(null);

    setNewLabelModalOpen(true);
    // setNewLabelModalError(null);
  }

  function closeDeleteTopicModal() {
    setErrorBar(false);
    setErrorBarMessage(null);

    setDeleteLabelModalOpen(false);
    setDeleteLabelModalError(null);
    setLabelIdToDelete(null);
  }

  async function downloadFilePrepare() {
    setErrorBar(false);
    setErrorBarMessage(null);
    setPreparingFileToDownload(true);

    let onError = async (code) => {
      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);
      setPreparingFileToDownload(false);
    }

    let onSuccess = (responseData) => {
      setPreparingFileToDownload(false);
      setDownloadLink(responseData[0].data);
      console.log("Success" + responseData[0].data);
      // downloadFile();
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "GET",
        path: "/thematic-analysis/study/" + studyId + "/download/",
        options: {
          'prepareFileForDownload': true,
          'needContent': true,
          'projectId': projectId,
        },
      },
      onError,
      onSuccess
    )
  }

  async function downloadFile() {
    setErrorBar(false);
    setErrorBarMessage(null);

    const response = await get(`/study/` + studyId + `/file/download`);

    if (response.status === 200) {
      const dispositionHeader = response.headers.get('Content-Disposition');
      const filename = dispositionHeader.split('filename=')[1];
      const file = await response.blob();

      // Download file
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();

      return;
    }
  }

  const sortThemaResult = () => {
    setSearchingLabelIndex(undefined);
    const data = [...themaCompletedResultData]
    if (sortedAlphabetically) {
      const sorter = (a, b) => a.current_name.toLowerCase() < b.current_name.toLowerCase() ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaCompletedResultData(sortedResult);
      setSortedAlphabetically(false);
    } else {
      const sorter = (a, b) => a.current_name.toLowerCase() > b.current_name.toLowerCase() ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaCompletedResultData(sortedResult);
      setSortedAlphabetically(true);
    }
  }

  const sortByNumberOfComments = () => {
    setSearchingLabelIndex(undefined);
    const data = [...themaCompletedResultData]
    if (sortedByNumberOfComments) {
      const sorter = (a, b) => a.prototypes.length < b.prototypes.length ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaCompletedResultData(sortedResult);
      setSortedByNumberOfComments(false);
    } else {
      const sorter = (a, b) => a.prototypes.length > b.prototypes.length ? 1 : -1;
      const sortedResult = data.sort(sorter);
      setThemaCompletedResultData(sortedResult);
      setSortedByNumberOfComments(true);
    }
  }

  const filterUnfilter = () => {
    setFiltered(!filtered);
    setFilterValue("");
    setSearchingLabelIndex(undefined);
  }

  const nameIsNotUnique = (name) => {
    let result = false;
    themaCompletedResultData.forEach((label) => {
      if (label.current_name === name) {
        result = true;
      }
    })
    return result;
  }

  function handleTabChange(index) {
    setTabIndex(index)
  }

  const updateFilterValue = (e) => {
    setFilterValue(e.target.value);
    // setIsFilterApplied(false);
  }

  const deleteFilterValue = () => {
    setFilterValue("");
    // setIsFilterApplied(false);
  }

  const startFiltering = () => {
    // setIsFilterApplied(true);
    setSearchingLabelIndex(undefined);
  }

  async function startActiveLearning() {
    let onError = async (code) => {
      await setErrorBarMessage(errorFn(code, props.t));
      await setErrorBar(true);
    }

    let onSuccess = () => {
      alert("success");
    }

    await post(
      '/study/thema/uniapi/',
      {

        studyId: studyId,
        method: "GET",
        path: "/thematic-analysis/study/active-learning/" + studyId,
        options: {'activeLearning': true, 'needContent': true},
      },
      onError,
      onSuccess
    )
  }


  function renderLoader() {
    return (
      <LoaderContainer>
        {!errorBar &&
        <CircularProgress/>
        }
      </LoaderContainer>
    )
  }

  function renderTabButtons() {
    return (
      <TabContainer>
        <Tab
          onClick={() => handleTabChange(1)}
          active={tabIndex}
          text={t("ThemaResult44", "Themen")}
          // icon={"header"}
        />
        <Tab
          onClick={() => handleTabChange(0)}
          active={!tabIndex}
          text={t("ThemaResult43", "Kommentare")}
        />
      </TabContainer>

      // <TabButtons>
      //   <PlatButton
      //     minWidth
      //     width="30%"
      //     tiny={true}
      //     type="button"
      //     color={tabIndex === 0 ? grey70 : grey30}
      //     style={{marginRight: "20px"}}
      //     texttransformnone
      //     onClick={() => handleTabChange(0)}
      //   >
      //     {t("themaResult001", "Kommentare (12%)")}
      //   </PlatButton>
      //
      //   <PlatButton
      //     minWidth
      //     width="25%"
      //     tiny={true}
      //     type="button"
      //     color={tabIndex === 1 ? grey70 : grey30}
      //     texttransformnone
      //     onClick={() => handleTabChange(1)}
      //   >
      //     {t("themaResult002", "Themen (8%)")}
      //   </PlatButton>
      // </TabButtons>
    );
  }

  function renderLeftColumn() {

    return (
      <LeftColumn>
        <ThemaNameArea previewOpened={true}>
          <FirstLine>
            <Headline light>{t("StudyDetail24", 'Thematische Analyse')}</Headline>
          </FirstLine>

          {mergingLabelsInProgress &&
          renderLoader()
          }
          {mergeActivated && !mergingLabelsInProgress &&
          <MergedTopicInputContainer>
            <EditInput
              light
              id="merged-label-name"
              color={cancel}
              value={mergedLabelName}
              onChange={onChangeMergedLabelName}
              onSubmit={onAddNewLabelSubmit}
              // error={"error"}
              placeholder={t("todo666", "Merged topic name")}
              active={true}
              doNotSubminOnBlur={true}
            />
          </MergedTopicInputContainer>
          }

          <MergeButtonContainer>
            {themaCompletedResultData && themaCompletedResultData.length !== 0 && mergeActivated && !mergingLabelsInProgress &&
            <PlatButton
              icon={"close"}
              tiny={true}
              type="button"
              fullWidth
              cancel
              // texttransformnone
              data-cy="send-options"
              onClick={deactivateMerging}
            >
              cancel
            </PlatButton>
            }
            &nbsp;
            {themaCompletedResultData && themaCompletedResultData.length !== 0 && !mergingLabelsInProgress &&
            <PlatButton
              disabled={themaCompletedResultData.length < 2 || (mergeActivated && (mergedLabelName === "" || mergedLabelName == null || nameIsNotUnique(mergedLabelName)))}
              icon={mergeActivated ? "check" : "merge"}
              tiny={true}
              type="button"
              color={grey70}
              mainbutton
              fullWidth
              texttransformnone
              data-cy="send-options"
              // onClick={openMergeModal}
              onClick={activateMerging}
            >
              {!mergeActivated &&
              t("mergeTopics2", "Themen zusammenführen")
              }
              {mergeActivated &&
              t("Ok", "Ok")
              }
            </PlatButton>
            }
          </MergeButtonContainer>
        </ThemaNameArea>

        <MethodOptions previewOpened={true}>
          <FirstLine>
            <Headline light>{t("ThemaResult1", 'Codebuch')}</Headline>
          </FirstLine>

          {themaCompletedResultData && themaCompletedResultData.length !== 0 && themaCompletedResultData.map(renderLabels)}
        </MethodOptions>
      </LeftColumn>
    )
  }

  function renderRightColumn() {
    return (
      <RightColumn>
        <RightColumnArea>
          <FirstLine>
            <Headline>1. {t("ThemaResult4", 'Vorsortierung')}</Headline>
          </FirstLine>
          <SubHeader>
            {t("ThemaResult41", 'Automatische Sortierung und erste Vorschläge für Codes.')}
          </SubHeader>
          <br/>


          {/*{t("ThemaResult42", 'Abdeckung in %: ')}*/}
          {/*<ProgressBarContainer>*/}
          {/*  <ProgressBar progress={95}>*/}
          {/*  </ProgressBar>*/}
          {/*</ProgressBarContainer>*/}
          <MiddleHeader>{t("labelProgress", 'Fortschritt in %:')}</MiddleHeader>
          <ProgressBarContainer>
            <ProgressBar progress={labelProgress}>
              <ProgressBarPercentage progress={labelProgress}>
                {Math.round(labelProgress)}%
              </ProgressBarPercentage>
            </ProgressBar>
          </ProgressBarContainer>

          {themaCompletedResultData && themaCompletedResultData.length !== 0 && !preparingFileToDownload && !downloadLink &&

          <PlatButton
            disabled={preparingFileToDownload}
            onClick={() => downloadFilePrepare()}
            type="button"
            color={grey70}
            mainbutton
            fullWidth
            texttransformnone
            data-cy="send-options"
            icon={"download"}
          >
            {t("Todo200", "Prepare downloading")}
          </PlatButton>
          }
          {preparingFileToDownload &&
          renderLoader()
          }


          {themaCompletedResultData && themaCompletedResultData.length !== 0 && downloadLink &&

          <PlatButton
            // disabled={!downloadLink}
            // disabled={true}
            onClick={() => downloadFile()}
            type="button"
            color={grey70}
            fullWidth
            mainbutton
            texttransformnone
            data-cy="send-options"
            icon={"download"}
          >
            {t("Todo7", "Download results")}
          </PlatButton>

          }
        </RightColumnArea>

        {!isStudyCustomModel &&
        <RightColumnArea>
          <FirstLine>
            <Headline>2. {t("ThemaResult5", 'Korrektur')}</Headline>
          </FirstLine>
          <SubHeader>
            {t("CorrectManually", "Manuelle Korrektur der Gruppierung und Codes")}
          </SubHeader>

          <br/>

          <PlatButton
            // disabled={!themaCompletedResultData}
            disabled
            onClick={() => startActiveLearning()}
            type="button"
            color={grey70}
            mainbutton
            fullWidth
            texttransformnone
            data-cy="send-options"
            icon={"go"}
          >
            {t("Todo300", "Daten nachsortieren")}
          </PlatButton>
        </RightColumnArea>
        }
        {/*<RightColumnArea>*/}
        {/*  <FirstLine>*/}
        {/*    <Headline disabled>3. {t("ThemaResult6", 'Nachsortierung')}</Headline>*/}
        {/*  </FirstLine>*/}
        {/*  Anwendung des Codebooks auf die restlichen Daten*/}
        {/*  <ProgressBarContainer>*/}
        {/*    <ProgressBar progress={0}>*/}
        {/*    </ProgressBar>*/}
        {/*  </ProgressBarContainer>*/}
        {/*  <PlatButton*/}
        {/*    type="button"*/}
        {/*    disabled*/}
        {/*    fullWidth*/}
        {/*    texttransformnone*/}
        {/*    data-cy="send-options"*/}
        {/*    onClick={() => alert("clicked")}*/}
        {/*  >*/}
        {/*    {t("ThemaResult7", "Daten   nachsortieren")}*/}
        {/*  </PlatButton>*/}
        {/*</RightColumnArea>*/}
      </RightColumn>
    )
  }

  function renderResult() {
    return (
      <MethodPreview active={true}>
        {renderTabButtons()}
        {tabIndex === 1 ? (
          <>
            <br/>
            <MainHeaderRow>
              <Headline>{t("ThemaResult16", "Geclusterte Daten")}</Headline>

              <IconContainer>
                {themaCompletedResultData && themaCompletedResultData.length !== 0 && newLabelModalOpen && (
                  <EditInputContainer>
                    <div>
                      <EditInput
                        id="new-label-name"
                        // color={white}
                        value={newLabelName}
                        onChange={onChangeNewLabelName}
                        onSubmit={onAddNewLabelSubmit}
                        // error={"error"}
                        placeholder={t("todo310", "Neuer Themenname")}
                        active={true}
                        // doNotSubminOnBlur={false}
                      />
                    </div>
                  </EditInputContainer>
                )}
                {themaCompletedResultData && themaCompletedResultData.length !== 0 &&
                !newLabelModalOpen &&
                !newLabelWaitingResponse && (
                  <PlatButton
                    icon="add"
                    tiny={true}
                    type="button"
                    // color={grey70}
                    mainbutton
                    fullWwidth
                    texttransformnone
                    data-cy="send-options"
                    onClick={addNewTopicModalOpen}
                  >
                    {t("todo001", "Neues Thema hinzufügen")}
                  </PlatButton>
                )}
                {newLabelWaitingResponse && renderLoader()}
              </IconContainer>
            </MainHeaderRow>

            <Collapse in={errorBar && errorBarMessage}>
              <Alert
                sx={{background: "pink"}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorBar(false);
                      setErrorBarMessage(null);
                    }}
                  >
                    <Icon size={18} name="close"/>
                  </IconButton>
                }
              >
                Error message: {errorBarMessage}
              </Alert>
            </Collapse>

            {/*// <ErrorContainer>*/}
            {/*//   <Error*/}
            {/*//     children={'Error message: ' + errorBarMessage}*/}
            {/*//   />*/}
            {/*// </ErrorContainer>*/}

            <SearchBox>
              <FilterBox>
                {filtered &&
                <SearchField
                  small
                  noMagnifier
                  placeholder={"filter"}
                  searchingValue={filterValue}
                  updateSearchingValue={updateFilterValue}
                  deleteSearchingValue={deleteFilterValue}
                  startSearching={startFiltering}
                />
                }
              </FilterBox>
              <FilterBox>
                <IconContainer>
                  <Icon
                    name={filtered ? "unfilter" : "filter"}
                    size={26}
                    onClick={filterUnfilter}
                  />
                </IconContainer>
                <IconContainer>
                  <Icon
                    name={sortedAlphabetically ? "sort_az" : "sort_za"}
                    size={24}
                    onClick={sortThemaResult}
                  />
                </IconContainer>
                <IconContainer>
                  <InfoPopupGreen
                    content={t('sortComments', "Sortieren nach der Anzahl eingeordneter Kommentare")}
                    name={
                      sortedByNumberOfComments ? "sort_num_down" : "sort_num_up"
                    }
                    size={24}
                    onClick={sortByNumberOfComments}
                  />
                </IconContainer>

              </FilterBox>
            </SearchBox>
            <ResultContainer>
              {!themaCompletedResultData && renderLoader()}
              {themaCompletedResultData && themaCompletedResultData.length === 0 && renderNoResult()}
              {themaCompletedResultData && themaCompletedResultData.length !== 0 &&
              themaCompletedResultData?.map(renderOneThema)}
            </ResultContainer>
          </>
        ) : (
          <>
            <ResultTabComments
              studyId={studyId}
              themaCompletedResultData={themaCompletedResultData}
              fetchThemaData={() => fetchThemaData()}
              fetchLabelCommentsProgress={() => fetchLabelCommentsProgress()}
            />
            {/*{themaComentsCompletedResultData.length === 0 && renderLoader()}*/}
            {/*{themaComentsCompletedResultData.length !== 0 &&*/}
            {/*themaComentsCompletedResultData?.map(renderComments)}*/}
          </>
        )}
      </MethodPreview>
    );
  }

  function renderNoResult() {
    return (
      <>
        {!errorBar &&
        <Headline>{t("NoResult", "Keine Einträge gefunden")}</Headline>
        }
      </>
    )
  }


  const renderLabels = (label, index) => {

    if (label.current_name === "deleted_label") {
      return false;
    }

    const editLeftColumnLabelName = async (value) => {
      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (index === label_index) {
          label.current_name = value;
        }
        return label;
      }))
    }

    const onCheckboxClick = (event) => {

      console.log(mergeLabels.length);
      console.log(mergeLabels);

      setMergeLabels(prevState => {

        if (mergeLabels.includes(label.label_id)) {
          return prevState.filter((value) => {
            if (value !== label.label_id) return value;
            return false;
          });
        } else {
          return [label.label_id, ...prevState];
        }
      });

      const {onChange} = props;

      if (!onChange) {
        return;
      }
      onChange(event.target.checked);
    }

    return (
      <OptionsRow>
        <OptionUnit>
          <Input
            tiny={true}
            flexible={true}
            light={true}
            id="study-name"
            value={label.current_name}
            onChange={editLeftColumnLabelName}
            onSubmit={() => onLabelEditSubmit(index)}
          />
          ({label.document_count})

          {(label.current_name !== label.original_name) &&
          <IconContainer>
            <Icon
              name={'reset'}
              size={16}
              onClick={() => returnOriginalLabelName(index)}
            />
          </IconContainer>
          }
        </OptionUnit>
        {mergeActivated &&
        <CheckBox
          big
          key={index}
          id={label.label_id}
          // label={option.label}
          value={mergeLabels.includes(label.label_id)}
          onChange={onCheckboxClick}

        />
        }
      </OptionsRow>
    );
  }

  const generateSimilaritiesForNewLabel = async (label_id) => {

    setSimilarCommmentsForNewLabel(label_id);

    const newLabelSimilaritiesResponse = await get(
      `/study/${studyId}/label/${label_id}/themanewlabelsimilarcomments`,
    );

    if (newLabelSimilaritiesResponse.status === 200) {
      let newLabelSimilarities = await newLabelSimilaritiesResponse.json();

      console.log(themaCompletedResultData[label_id]);
      console.log(newLabelSimilarities[0].data);

      setThemaCompletedResultData(prevState => {
        return prevState.map((label_value) => {
          if (label_id === label_value.label_id) {
            // console.log(label_value);
            console.log(label_value.similarities);
            console.log(newLabelSimilarities[0].data);
            label_value.similarities = newLabelSimilarities[0].data;
          }
          return label_value;
        });
      });

    } else {
      console.log(newLabelSimilaritiesResponse.status);
    }
    setSimilarCommmentsForNewLabel(undefined);
  }

  const renderOneThema = (thema, thema_index) => {

    if (filtered && !thema.current_name.includes(filterValue)) return false;

    let numberOfShownComments = 3;
    let currentComment = 1;

    let numberOfShownSuggestedComments = 3;
    let currentSuggestedComment = 1;

    if (mergeLabels.length > 0 && !mergeLabels.includes(thema.label_id)) {
      return false;
    }

    if (thema.current_name === "deleted_label") {
      return false;
    }

    const updateSearchingValue = (e) => {
      setSearchingLabelIndex(thema_index);
      setSearchingValue(e.target.value);
      setStartSearching(false);
      setSearchingResultArray([]);
    }

    const deleteSearchingValue = () => {
      setSearchingLabelIndex(undefined);
      setSearchingValue("");
      setStartSearching(false);
      setSearchingResultArray([]);
    }

    const startSearchingFn = async (labelId) => {
      setStartSearching(true);
      // setSearchingError(false);
      // setSearchingErrorCode(undefined);
      // setSearchingErrorText("");

      await post_simple(
        `/study/themasearching`,
        {
          studyId: studyId,
          labelId: labelId,
          searchingValue: searchingValue
        },
      )
        .then(function (data) {
          if (data.status === 200) {
            return data.json();
          } else {
            // console.log(data.status);
            // console.log(data.statusText);
            // setSearchingError(true);
            // setSearchingErrorCode(data.status);
            // setSearchingErrorText(data.statusText);
            return false;
          }
        })
        .then(function (json) {
          if (json) {
            // console.log(json.data.data);
            setSearchingResultArray(json.data.data);
          }
        })
      setStartSearching(false);
    }

    const deleteLabeledItem = async (value) => {
      setDeleteLabelModalOpen(true);
      setLabelIdToDelete(value.label_id);
    }

    const deleteComment = async (value, comment_index, label_id, comment_id_array) => {

      console.log(comment_id_array);

      await setErrorBarMessage(null);
      await setErrorBar(false);

      setDeleteCommentWaitingResponse(true);

      let onError = async (code) => {
        setErrorBar(true);
        setErrorBarMessage(errorFn(code, props.t));

        setDeleteCommentWaitingResponse(false);
      }

      let onSuccess = async (responseData) => {
        await deleteCommentFromMainResults(label_id, comment_id_array);
        setDeleteCommentWaitingResponse(false);
      }

      await post(
        '/study/thema/uniapi/',
        {
          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/deletecomment/' + studyId + '/' + label_id + '/',
          options: {
            'docs_to_delete': comment_id_array,
          },
        },
        onError,
        onSuccess
      )
    }

    const deleteCommentFromMainResults = async (label_id, comment_id) => {
      let deletionGranted = true; // This var is here to avoid double deletion
      setThemaCompletedResultData(prevState => {
        return prevState.map((value, array) => {
          if (label_id === value.label_id && deletionGranted) {
            value.prototypes.forEach((comment_to_delete, comment_to_delete_index) => {
              if (comment_to_delete.index === comment_id) {
                deletionGranted = false;
                value.prototypes.splice(comment_to_delete_index, 1);
              }
            });
          }
          return value;
        });
      });
    }

    const addCommentToMainResults = async (label_id, similar_comment) => {
      let additionGranted = true; // This var is here to avoid double deletion
      setThemaCompletedResultData(prevState => {
        return prevState.map((value) => {
          if (label_id === value.label_id && additionGranted) {
            additionGranted = false;
            value.prototypes.push({
              index: similar_comment.id,
              text: similar_comment.name ? similar_comment.name : similar_comment.text
            });
          }
          return value;
        })
      });
    }

    const deleteCommentFromSimilarities = async (label_id, similar_comment, similar_comment_ind_in_array) => {

      let deletionGranted = true; // This var is here to avoid double deletion

      setThemaCompletedResultData(prevState => {
        return prevState.map((value, index) => {
          if (label_id === value.label_id && deletionGranted) {
            deletionGranted = false;
            value.similarities.splice(similar_comment_ind_in_array, 1);
          }
          return value;
        })
      });
    }

    const moveSuggestionToLabelByClick = async (similar_comment, label_index_in_array, thema_label_id, similar_comment_ind_in_array) => {

      setMoveSuggestionWaitingResponse(true);

      let onError = async (code) => {
        setErrorBar(true);
        setErrorBarMessage(errorFn(code, props.t));
        setMoveSuggestionWaitingResponse(false);
      }

      let onSuccess = async (responseData) => {
        console.log("Success" + responseData[0].data);
        await addCommentToMainResults(thema_label_id, similar_comment, similar_comment_ind_in_array);
        await deleteCommentFromSimilarities(thema_label_id, similar_comment, similar_comment_ind_in_array);
        setMoveSuggestionWaitingResponse(false);
        // fetchLabelCommentsProgress();
      }

      await post(
        '/study/thema/uniapi/',
        {
          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/updatecomments/' + studyId + '/',
          options: {
            'docs_to_update': [[similar_comment.id]],
            'deletelabelids': [],
            'addlabelids': [thema_label_id]
          },
        },
        onError,
        onSuccess
      )

      // sourceIndex.current = null;
      // destinationIndex.current = null;
      // dragComment.current = null;
      // dragOverComment.current = null;
    };

    const moveSearchedCommentToLabelByClick = async (searched_comment, label_id_in_array, label_index, searched_comment_ind_in_array) => {

      // searched_comment, thema_index, thema.label_id, searched_comment_ind_in_array

      // console.log("searched_comment: " + searched_comment);
      // console.log("label_id_in_array: " + label_id_in_array);
      // console.log("searched_comment_ind_in_array:" + searched_comment_ind_in_array);

      setMoveSuggestionWaitingResponse(true);

      let onError = async (code) => {
        setErrorBar(true);
        setErrorBarMessage(errorFn(code, props.t));
        setMoveSuggestionWaitingResponse(false);
      }

      let onSuccess = (responseData) => {
        console.log("Success" + responseData[0].data);

        addCommentToMainResults(label_index, searched_comment);

        setSearchingResultArray(prevState => {
          return prevState.filter(value => value.id !== searched_comment.id)
        });
        setMoveSuggestionWaitingResponse(false);
      }


      await post(
        '/study/thema/uniapi/',
        {
          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/updatecomments/' + studyId + '/',
          options: {
            'docs_to_update': [[searched_comment.id]],
            'deletelabelids': [],
            'addlabelids': [label_index]
          },
        },
        // '/study/thema/uniapi/',
        // {
        //   studyId: studyId,
        //   method: "PUT",
        //   path: '/thematic-analysis/label/addcomment/' + studyId + '/' + label_index + '/' + searched_comment.id + '/',
        //   options: {
        //     'commentToLabel': true
        //   },
        // },
        onError,
        onSuccess
      )
      //
      // sourceIndex.current = null;
      // destinationIndex.current = null;
      // dragComment.current = null;
      // dragOverComment.current = null;
    };

    const editLabelName = async (e, index) => {
      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (index === label_index) {
          label.current_name = e.target.value;
        }
        return label;
      }))
    }

    // const confirmLabelAsTrue = async (index) => {

    // let onError = async (code) => {
    //   setErrorBar(true);
    //   setErrorBarMessage(errorFn(code, props.t));
    // }
    //
    // let onSuccess = (responseData) => {
    //   console.log("Success" + responseData[0].data);
    //   setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
    //     if (index === label_index) {
    //       label.is_true_label = !label.is_true_label;
    //     }
    //     return label;
    //   }))
    // }

    //   await post(
    //     '/study/thema/uniapi/',
    //     {
    //       studyId: studyId,
    //       method: "PUT",
    //       path: '/thematic-analysis/label/istruelabel/' + studyId + '/' + index,
    //       options: {'isTrueLabel': true, 'needContent': true},
    //     },
    //     onError,
    //     onSuccess
    //   )
    // }

    // const confirmLabelAsFalse = async (index) => {
    //
    //   let onError = async (code) => {
    //     setErrorBar(true);
    //     setErrorBarMessage(errorFn(code, props.t));
    //   }
    //
    //   let onSuccess = (responseData) => {
    //     console.log("Success" + responseData[0].data);
    //     setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
    //       if (index === label_index) {
    //         label.is_true_label = !label.is_true_label;
    //       }
    //       return label;
    //     }))
    //   }
    //
    //
    //   await post(
    //     '/study/thema/uniapi/',
    //     {
    //       studyId: studyId,
    //       method: "PUT",
    //       path: '/thematic-analysis/label/isfalselabel/' + studyId + '/' + index,
    //       options: {'isTrueLabel': true, 'needContent': true},
    //     },
    //     onError,
    //     onSuccess
    //   )
    // }

    const showMoreOrLess = () => {

      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (thema_index === label_index) {
          label.showMoreEnabled = !label.showMoreEnabled;
        }
        return label;
      }))
    }

    const showMoreSimilaritiesOrLess = () => {

      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (thema_index === label_index) {
          label.showMoreSimilaritiesEnabled = !label.showMoreSimilaritiesEnabled;
        }
        return label;
      }))
    }

    const expandCollapseComments = () => {
      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (thema_index === label_index) {
          // label.collapsed = !label.collapsed;
          label.expanded = !label.expanded;
        }
        return label;
      }))
    }

    const expandCollapseSimilarities = () => {
      setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
        if (thema_index === label_index) {
          label.similaritiesExpanded = !label.similaritiesExpanded;
        }
        return label;
      }))
    }

    // const reorder = (list, startIndex, endIndex) => {
    //   const result = {...list};
    //   const [removed] = result.prototypes.splice(startIndex, 1);
    //   result.prototypes.splice(endIndex, 0, removed);
    //   return result;
    // };

    let labelNameOnHover = () => {
      setLabelNameFocused(thema_index);
    }

    let labelNameOnFocus = () => {
      setLabelNameFocused(thema_index);
      setLabelNameBeforeEdit(themaCompletedResultData[thema_index].current_name);
    }

    let labelNameOnFocusOut = (e, index) => {

      setLabelNameFocused(null);

      if (e.target.value === undefined) {
        return false;
      }

      if (labelNameFocused !== null && e.target.value !== labelNameBeforeEdit && labelNameBeforeEdit !== undefined) {
        onLabelEditSubmit(index);
        setThemaCompletedResultData(themaCompletedResultData.map((label, label_index) => {
          if (index === label_index) {
            label.current_name = e.target.value;
          }
          return label;
        }))
      }
      setLabelNameBeforeEdit(undefined);
    }

    function currentLabelFocused() {
      return thema_index === labelNameFocused;
    }

    const onDragEnd = async () => {

      console.log("From " + dragFromLabelId.current);
      console.log("To " + dragToLabelId.current);
      console.log("dragCommentPythonId " + dragCommentPythonId.current);
      console.log("Drag from: " + dragFromArea.current);
      console.log('Dragged text: ' + dragedCommentText.current);

      // const sInd = +sourceIndex.current;
      // const dInd = +destinationIndex.current;

      // const sourceCommentIndex = dragComment.current;
      // const destinationCommentIndex = dragOverComment.current;

      // const newArray = [...themaCompletedResultData];
      // const sourceObj = newArray[sInd];
      // const targetObj = newArray[dInd];
      // const sourcePrototypes = [...sourceObj.prototypes];
      // const targetPrototypes = [...targetObj.prototypes];

      // const docsToMoveObj = sourcePrototypes[sourceCommentIndex];

      let onError = async (code) => {
        setErrorBar(true);
        setErrorBarMessage(errorFn(code, props.t));
      }

      let onSuccess = (responseData) => {
        console.log("Success" + responseData[0].data);
        switch (dragFromArea.current) {
          case "similarities":
            deleteCommentFromSimilarities(dragFromLabelId.current, "", dragComment.current);
            break;
          case "search":
            setSearchingResultArray(prevState => {
              return prevState.filter(value => value.id !== dragCommentPythonId.current[0]);
            });
            break;
          default:
            deleteCommentFromMainResults(dragFromLabelId.current, dragCommentPythonId.current);
            break;

        }
        addCommentToMainResults(dragToLabelId.current, {id: dragCommentPythonId.current, text: dragedCommentText.current});

        // if (sInd === dInd) {
        //   const items = reorder(
        //     themaCompletedResultData[sInd],
        //     sourceCommentIndex,
        //     destinationCommentIndex
        //   );
        //   const newState = [...themaCompletedResultData];
        //   newState[sInd] = items;
        //   console.log(
        //     `Comment ${sourceCommentIndex} moved from topic ${sInd} to ${dInd}`
        //   );
        //   setThemaCompletedResultData(newState);
        // } else {
        //   if (sourceObj && targetObj) {
        //     const [removed] = sourcePrototypes.splice(sourceCommentIndex, 1);
        //     targetPrototypes.splice(destinationCommentIndex, 0, removed);
        //
        //     sourceObj.prototypes = sourcePrototypes;
        //     targetObj.prototypes = targetPrototypes;
        //     console.log("new State", newArray);
        //     console.log(
        //       `Comment ${sourceCommentIndex} moved from topic ${sInd} to ${dInd}`
        //     );
        //     setThemaCompletedResultData(newArray);
        //   }
        // }
      }

      await post(
        '/study/thema/uniapi/',
        {
          studyId: studyId,
          method: "PUT",
          path: '/thematic-analysis/label/movecomment/' + studyId + '/' + dragFromLabelId.current + '/' + dragToLabelId.current + '/',
          options: {"docs_to_move": [dragCommentPythonId.current]},
        },
        onError,
        onSuccess
      )

      dragFromLabelId.current = null;
      dragToLabelId.current = null;
      dragCommentPythonId.current = null;
      dragFromArea.current = null;
      dragedCommentText.current = null;

      sourceIndex.current = null;
      destinationIndex.current = null;
      dragComment.current = null;
      dragOverComment.current = null;
    };

    let renderSimilarComments = (label_id) => {
      return (
        <>
          <OptionUnitRow>
            <LabelBox>{thema.similarities?.length !== 0 ? thema.similarities?.length : "0"} similar
              comments &nbsp;
              (<span style={{'cursor': 'pointer', 'color': buttonmain}}
                     onClick={expandCollapseSimilarities}>
                {!thema.similaritiesExpanded ? "show" : "hide"}
              </span>)
              {/*<IconContainer>*/}
              {/*  <Icon*/}
              {/*    name={item.collapsed ? "expand_all" : "collapse_all"}*/}
              {/*    size={20}*/}
              {/*    onClick={expandCollapseSimilarities}*/}
              {/*  />*/}
              {/*</IconContainer>*/}
            </LabelBox>
          </OptionUnitRow>

          {thema.similarities?.length > 0 && thema.similaritiesExpanded && thema.similarities.map((similar_comment, similar_comment_ind_in_array) => {
            if (!thema.showMoreSimilaritiesEnabled && currentSuggestedComment > numberOfShownSuggestedComments) return false;
            currentSuggestedComment++;

            return (
              // <SuggestedComment draggable
              //                   id={similar_comment_ind_in_array}
              //                   onDragStart={(e) => [dragComment.current = similar_comment_ind_in_array, sourceIndex.current = thema_index]}
              //                   onDragEnter={(e) => [dragOverComment.current = similar_comment_ind_in_array, destinationIndex.current = thema_index]}
              //                   onDragEnd={onDragEnd}
              //                   onDragOver={(e) => e.preventDefault()}>

              <SuggestedComment draggable
                                id={similar_comment_ind_in_array}
                                onDragStart={(e) => {
                                  dragFromLabelId.current = thema.label_id;
                                  dragCommentPythonId.current = [similar_comment.id];
                                  dragedCommentText.current = similar_comment.name;

                                  dragComment.current = similar_comment_ind_in_array;
                                  sourceIndex.current = thema_index;
                                  dragFromArea.current = "similarities";
                                }}
                                onDragEnter={(e) => {
                                  dragToLabelId.current = thema.label_id;
                                  dragOverComment.current = similar_comment_ind_in_array;
                                  destinationIndex.current = thema_index;
                                }}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => e.preventDefault()}>
                {similar_comment.name}
                <ButtonRow>
                  <Button
                    disabled={moveSuggestionWaitingResponse}
                    marginLeft
                    mainbutton
                    skinny
                    onClick={() => moveSuggestionToLabelByClick(similar_comment, thema_index, thema.label_id, similar_comment_ind_in_array)}
                  >
                    {moveSuggestionWaitingResponse &&
                    <Icon
                      name={"timer"}
                      size={14}
                    />
                    }
                    {!moveSuggestionWaitingResponse ? t("tag", "Tag") : ""}
                  </Button>
                </ButtonRow>
              </SuggestedComment>
            );
          })}

          {/*{!similarCommentsWaitingResponse && !similarCommentsError &&*/}

          {!similarCommentsWaitingResponse &&
          <NavbarLinkContainer>
            {thema.similarities?.length !== 0 &&
            !thema.showMoreSimilaritiesEnabled &&
            thema.similaritiesExpanded &&
            numberOfShownComments < thema.similarities?.length &&
            <NavbarLink
              onClick={showMoreSimilaritiesOrLess}
            >
              {t("ShowMore", 'Zeig mehr')}
            </NavbarLink>
            }
            {thema.showMoreSimilaritiesEnabled &&
            thema.similaritiesExpanded &&
            thema.similarities?.length !== 0 &&
            <NavbarLink
              onClick={showMoreSimilaritiesOrLess}
            >
              {t("ShowLess", 'Zeige weniger')}
            </NavbarLink>
            }
          </NavbarLinkContainer>
          }
          {!similarCommentsWaitingResponse && thema.similarities?.length === 0 &&
          <ButtonRow>
            <Button
              disabled={thema.prototypes.length < 10}
              marginLeft
              mainbutton
              skinny
              onClick={() => generateSimilaritiesForNewLabel(thema.label_id)}
            >
              {moveSuggestionWaitingResponse &&
              <Icon
                name={"timer"}
                size={14}
              />
              }
              {t("generateSimilarities", "Generieren Sie ähnliche Kommentare")}
            </Button>
          </ButtonRow>
          }
        </>
      )
    }

    let renderSearchedComments = (props) => {

      return (
        <SuggestedCommentsBox>
          <OptionUnitRow>
            <LabelBox>
              Found ({searchingResultArray.length}) comments: &nbsp;
            </LabelBox>
          </OptionUnitRow>

          {searchingResultArray.map((searched_comment, searched_comment_ind_in_array) => {

            return (
              <SuggestedComment draggable
                                id={searched_comment_ind_in_array}
                                onDragStart={(e) => {
                                  dragFromLabelId.current = thema.label_id;
                                  dragCommentPythonId.current = [searched_comment.id];
                                  dragedCommentText.current = searched_comment.text;

                                  dragComment.current = searched_comment_ind_in_array;
                                  sourceIndex.current = thema_index;
                                  dragFromArea.current = "search";
                                }}
                                onDragEnter={(e) => {
                                  dragToLabelId.current = thema.label_id;
                                  dragOverComment.current = searched_comment_ind_in_array;
                                  destinationIndex.current = thema_index;
                                }}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => e.preventDefault()}>
                <>
                  {renderWithHighlights(searched_comment.text)}
                </>

                <ButtonRow>
                  <Button
                    disabled={moveSuggestionWaitingResponse}
                    marginLeft
                    mainbutton
                    skinny
                    onClick={() => moveSearchedCommentToLabelByClick(searched_comment, thema_index, thema.label_id, searched_comment_ind_in_array)}
                  >
                    {moveSuggestionWaitingResponse &&
                    <Icon
                      name={"timer"}
                      size={14}
                    />
                    }
                    {!moveSuggestionWaitingResponse ? t("tag", "Tag") : ""}
                  </Button>
                </ButtonRow>
              </SuggestedComment>
            );
          })}
        </SuggestedCommentsBox>
      )
    }

    return (
      <OneThema>
        <CommentsBox>
          <OptionUnitRow>
            <TextField
              inputProps={{style: {fontWeight: 'bolder'}}} // font size of input text
              multiline={currentLabelFocused()}
              onFocus={labelNameOnFocus}
              onMouseEnter={labelNameOnHover}
              onChange={(value) => editLabelName(value, thema_index)}
              onMouseOut={(value) => labelNameOnFocusOut(value, thema_index)}
              fullWidth
              label={t("themaComments001", 'Thema')}
              id="outlined-size-small"
              value={thema.current_name}
              size="small"
            />

            {!currentLabelFocused() &&
            <>
              {thema.current_name !== thema.original_name && (
                <LabelBox>
                  <IconContainer>
                    <Icon
                      name={"reset"}
                      size={24}
                      onClick={() => returnOriginalLabelName(thema_index)}
                    />
                  </IconContainer>
                </LabelBox>
              )}

              {/*<PlatButton*/}
              {/*  green={item.is_true_label}*/}
              {/*  marginLeft*/}
              {/*  tiny={true}*/}
              {/*  minWidth*/}
              {/*  mainbutton*/}
              {/*  texttransformnone*/}
              {/*  data-cy="send-options"*/}
              {/*  onClick={!item.is_true_label ? () => confirmLabelAsTrue(index) : () => confirmLabelAsFalse(index)}*/}
              {/*>*/}
              {/*  {!item.is_true_label ? t("themaComments003", 'Bestätigen') : "Unselect"}*/}
              {/*</PlatButton>*/}
            </>
            }

            <LabelBox>
              <IconContainer>
                <Icon
                  name={"delete_outline"}
                  size={30}
                  onClick={() => deleteLabeledItem(thema)}
                />
              </IconContainer>
            </LabelBox>
          </OptionUnitRow>

          <LabelBox>
            {t('themaSearching001', 'Beschreiben Sie das Thema mit ein paar Worten')}:
          </LabelBox>
          {searchingLabelIndex === thema_index && startSearching && renderLoader()}

          {(searchingLabelIndex !== thema_index || !startSearching) &&
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <SearchField
              placeholder={t("search", "Suchen")}
              searchingValue={searchingLabelIndex === thema_index ? searchingValue : ""}
              updateSearchingValue={updateSearchingValue}
              deleteSearchingValue={deleteSearchingValue}
              startSearching={() => startSearchingFn(thema.label_id)}
            />
          </Box>
            // <Comment addnew>
            //   Drag and drop a comment here
            // </Comment>
          }

          <OptionUnitRow>
            {thema.prototypes.length > 0 &&
            <LabelBox>{thema.prototypes.length} linked comments &nbsp;
              (<span style={{'cursor': 'pointer', 'color': buttonmain}}
                     onClick={expandCollapseComments}>
                  {!thema.expanded ? "show" : "hide"}
                </span>)
              {/*  <IconContainer>*/}
              {/*  <Icon*/}
              {/*    name={item.collapsed ? "expand_all" : "collapse_all"}*/}
              {/*    size={20}*/}
              {/*    onClick={expandCollapseComments}*/}
              {/*  />*/}
              {/*</IconContainer>*/}
            </LabelBox>
            }
          </OptionUnitRow>

          {/*{item.prototypes.length !== 0 && !item.collapsed && item.prototypes.map((subitem, ind) => {*/}
          {thema.prototypes.length !== 0 && thema.expanded && thema.prototypes.map((comment, comment_index) => {
            if (!thema.showMoreEnabled && currentComment > numberOfShownComments) return false;
            currentComment++;

            return (
              <Comment draggable
                       id={comment_index}
                       onDragStart={(e) => {
                         dragFromLabelId.current = thema.label_id;
                         dragCommentPythonId.current = comment.index;
                         dragedCommentText.current = comment.text;
                         dragComment.current = comment_index;
                         sourceIndex.current = thema_index;
                         dragFromArea.current = "prototypes";
                       }}
                       onDragEnter={(e) => {
                         dragToLabelId.current = thema.label_id;
                         dragOverComment.current = comment_index;
                         destinationIndex.current = thema_index;
                       }}
                       onDragEnd={onDragEnd}
                       onDragOver={(e) => e.preventDefault()}>
                {comment.text} {comment.index.length !==1 && <>({comment.index.length})</>}

                <ButtonRow>
                  <IconContainer>
                    <Button
                      disabled={deleteCommentWaitingResponse}
                      mainbutton
                      skinny
                      onClick={() => deleteComment(comment, thema_index, thema.label_id, comment.index)}
                    >
                      {deleteCommentWaitingResponse &&
                      <Icon
                        name={"timer"}
                        size={14}
                      />
                      }
                      {!deleteCommentWaitingResponse ? t("untag", "Untag") : ""}
                    </Button>
                  </IconContainer>
                </ButtonRow>
              </Comment>
            );
          })}

          <NavbarLinkContainer>
            {/*{item.prototypes.length !== 0 && !item.showMoreEnabled && numberOfShownComments < item.prototypes.length && !item.collapsed &&*/}
            {thema.prototypes.length !== 0 && !thema.showMoreEnabled && numberOfShownComments < thema.prototypes.length && thema.expanded &&

            <NavbarLink
              onClick={showMoreOrLess}
            >
              {t("ShowMore", 'Zeig mehr')}
            </NavbarLink>
            }
            {/*{item.prototypes.length !== 0 && item.showMoreEnabled && !item.collapsed &&*/}
            {thema.prototypes.length !== 0 && thema.showMoreEnabled && thema.expanded &&
            <NavbarLink
              onClick={showMoreOrLess}
            >
              {t("ShowLess", 'Zeige weniger')}
            </NavbarLink>
            }
          </NavbarLinkContainer>
        </CommentsBox>


        {searchingLabelIndex === thema_index && searchingResultArray.length !== 0 && renderSearchedComments(thema.label_id)}

        <SuggestedCommentsBox>
          {((similarCommentsWaitingResponse && thema.prototypes.length !== 0) || similarCommentsForNewLabel === thema.label_id) ? renderLoader() : renderSimilarComments(thema.label_id)}
        </SuggestedCommentsBox>
        <DottedHr/>
      </OneThema>
    )
  }

  const renderWithHighlights = (text) => {
    let newText = text.replaceAll(searchingValue, `<strong style="background-color: #fcfc88;">${searchingValue}</strong>`);
    return (
      <div className="content" dangerouslySetInnerHTML={{__html: newText}}></div>
    )
  }

  return (
    <ModalInnen>
      <TransitionHelper previewOpened={true}>
        {renderLeftColumn()}
        {renderResult()}
      </TransitionHelper>

      {renderRightColumn()}

      {deleteLabelModalOpen &&
      <DeleteLabelModal
        waiting={deleteLabelWaitingResponse}
        onClose={() => closeDeleteTopicModal()}
        onClick={() => deleteLabel()}
        error={deleteLabelModalError}
      />
      }

    </ModalInnen>
  );
}

export default withTranslation("translations")(ThematicAnalysisResult);
