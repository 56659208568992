import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { get } from '../security/Api';
import Page from '../components/Page';
import { withTranslation } from "react-i18next";
import Alert from '../components/Alert';
import CardBody, { CardBodyInner } from '../components/CardBody';
import SubHeadline from '../components/SubHeadline';
import FileUpload from '../components/FileUpload';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import RadioButton from '../components/RadioButton';
import {InfoPopupGreen} from "../components/InfoPopup";

import { Dropdown } from 'semantic-ui-react';
import { orange1 } from '../colors';
import styled from '@emotion/styled';

const ProjectPicker = styled(Dropdown)`
    min-width: 100px !important;
    margin-left: 16px;
    font-size: 22px !important;
    font-weight: normal !important;
    color: ${orange1} !important;
`;

const ProjectContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    justify-content: start;
    width: 50%;
    margin-bottom: 20px;
`;

function OrganisationFiles(props) {
  const {t} = props;

  const [files, setFiles] = useState([]);
  const [resultFiles, setResultFiles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingError, setLoadingError] = useState(false);
  const [radioButtonIndex, setRadioButtonIndex] = useState(0);

   useEffect(() => {
    const fetchData = async () => {
      try {
        await loadFiles();
        await loadProjects();
        await loadResultFiles();
      } catch (error) {
        setLoadingError(true);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProjects = async () => {
    try {
      const projectResponse = await get('/project/list');

      if (projectResponse.status === 200) {
        const projectResponseData = await projectResponse.json();

        const allProjectsObj = { id: "1", name: "All Projects" };
        projectResponseData.splice(0, 0, allProjectsObj);
        setProjects(projectResponseData);
      } else {
        setLoadingError(true);
      }
    } catch (error) {
      setLoadingError(true);
    }
  }

  const loadResultFiles = async () => {
    try {
      const resultFilesResponse = await get('/organisation/resultfiles');
      if (resultFilesResponse.status === 200) {
        const resultFilesResponseData = await resultFilesResponse.json();
        setResultFiles(resultFilesResponseData);
      } else {
        setLoadingError(true);
      }
    } catch (error) {
      setLoadingError(true);
    }
  }

  const loadFiles = async () => {
    try {
      const response = await get(`/organisation/files`);
      if (response.status === 200) {
        const responseData = await response.json();
        setLoaded(true);
        setLoadingError(false);
        setFiles(responseData);
      } else if (response.status === 400) {
        const responseData = await response.json();
        if (responseData.code) {
          setLoaded(true);
          setLoadingError(true);
          setErrorMessage(error(responseData.code, t));
        } else {
          setLoaded(true);
          setLoadingError(true);
          setErrorMessage(error(0, t));
        }
      }
    } catch (error) {
      setLoadingError(true);
    }
  }

  const onUpload = async (file) => {
    await loadFiles();
  }

  const deleteById  =(id) => {
    const filterFiles = files.filter(file => file.id !== id);
    setFiles(filterFiles);
  }

  const handleProjectChange = (id) => {
    setSelectedProjectId(id);
  }

  const handleRadioButtonChange = (index) => {
    setRadioButtonIndex(index);
  }

  const renderRadioButtons = () => {
    return (
      <RadioButtonsContainer>
        <RadioButton
          id="0"
          name={t("FileList01", "Dateien")}
          value={radioButtonIndex === 0}
          onChange={() => handleRadioButtonChange(0)}
        />
        <RadioButton
          id="1"
          name={t("FileList02", "Ergebnisse")}
          value={radioButtonIndex === 1}
          onChange={() => handleRadioButtonChange(1)}
        />
      </RadioButtonsContainer>
    );
  }

  const filteredFiles = selectedProjectId && selectedProjectId !== "1"
    ? files.filter(file => file.projectId === selectedProjectId)
    : files;

  const filteredResultFiles = selectedProjectId && selectedProjectId !== "1"
    ? resultFiles.filter(resultFile => resultFile.projectId === selectedProjectId)
    : resultFiles;

  if (!loaded) {
    return <Curtain type="loading" />;
  }
  if (loadingError) {
    return <Curtain type="error" />;
  }



  return (
    <Page>
       <PageContent dark={true}>
          {error &&
            <Alert
              type="error"
              message={errorMessage ? errorMessage : error(0, t)}
              onClose={() => setError({ error: false })}
            />
          }
          {/* {info && infoMessage &&
            <Alert
              type="info"
              message={infoMessage}
              onClose={() => setError({ info: false })}
            />
          } */}

          <CardBody withGradient={true}>
            <CardBodyInner noTopPadding={true}>
              <ProjectContainer>
                <SubHeadline>
                  {t("fileUpload8", "Wählen Sie Projekt aus")}
                </SubHeadline>

                {projects.length > 0 &&
                  <ProjectPicker
                    select
                    options={projects.map((project) => ({
                      key: project.id,
                      text: project.name,
                      value: project.id
                    }))}
                    defaultValue={projects[0].id}
                    onChange={(e, { value }) => {
                      handleProjectChange(value)
                    }}
                  />}
                <InfoPopupGreen
                  content={t('FileList03', "Wählen Sie ein Projekt aus, um eine Datei hochzuladen.")}
                />
              </ProjectContainer>
              {renderRadioButtons()}
              <FileUpload
                isResult={radioButtonIndex !== 0}
                uploadUrl={`/${selectedProjectId}/file/upload`}
                language={i18n.language}
                labelOnly={true}
                onUpload={(file) => onUpload(file)}
                files={radioButtonIndex === 0 ? filteredFiles : filteredResultFiles}
                errors={[]}
                delete={async (id) => deleteById(id)}
                disabled={selectedProjectId === "1" || selectedProjectId === null}
              />
            </CardBodyInner>
          </CardBody>
        </PageContent>
    </Page>
  );
}

export default withTranslation("translations")(OrganisationFiles);
