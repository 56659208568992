import React, { Component } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import styled from '@emotion/styled';
import Label from './LabelReg';
import { blue, darkblue } from '../colors';

const CountryLabel = styled(Label)`
    float: none;

`;

export default class CountrySelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: countryList().getData(),
            value: null,
            name: "countryList"
        };
    }
    componentDidMount() {
        this.setState({value: this.props.initialValue});
        this.setState({name: this.props.name});
    }

    changeHandler = value => {
        this.setState({ value });
        this.props.handleChange(value);
    };

    render() {
        const countryStyles = {
            control: styles => ({
                ...styles,
                background: 'white',
                color: darkblue,
                fontFamily: 'AvenirNextLTPro-Medium, AvenirNextCyrMedium',
                fontSize: '15px',
                border: 0,
                borderRadius: '5px',
                margin: 'auto',
                cursor: 'pointer',
            }),
            option: (styles, {isFocused, isSelected }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? blue : 'white',
                    color: isFocused ? 'white' : darkblue,
                    fontFamily: 'AvenirNextLTPro-Medium, AvenirNextCyrMedium',
                    fontSize: '15px',
                    padding: '10px',
                    cursor: 'pointer',
                };
            },
            input: styles => ({
                ...styles,
                zIndex: '1',
                fontFamily: 'AvenirNextLTPro-Medium, AvenirNextCyrMedium',
                fontSize: '15px',
                color: 'black',
                height: this.props.narrow ? "24px" : '40px',
            }),
            placeholder: styles => ({
                ...styles,
                fontFamily: 'AvenirNextLTPro-Medium, AvenirNextCyrMedium',
                fontSize: '15px',
                color: 'black'
            }),
            singleValue: (styles) => ({
                ...styles,
                background: 'white',
                color: 'black',
                fontFamily: 'AvenirNextLTPro-Medium, AvenirNextCyrMedium',
                fontSize: '15px',
            }),
        };
        return (
            <div>
                <CountryLabel htmlFor={this.state.name} focusx={true} narrow={this.props.narrow}>
                    {this.props.label}
                </CountryLabel>{' '}
                <Select
                    styles={countryStyles}
                    name={this.state.name}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={this.changeHandler}
                    autoFocus={false}
                    required={true}
                />
            </div>
        );
    }
}
