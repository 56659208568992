import React from 'react';
import styled from '@emotion/styled';
import CheckBoxButton from '../CheckBoxButton';
import { withTranslation } from "react-i18next";
import { orange2 } from '../../colors';


const AlleButton = styled.button`
    border: 0;
    text-decoration: underline;
    margin-left: 15px;
    visibility: ${props => props.visible ? "visible" : "hidden"};
    background: none;
    color: ${orange2};
    cursor: pointer;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;

    &:hover {filter: brightness(85%);}
`;

const Headline = styled.div`
    font-size: 16px;
    margin-bottom: 5px;
`;

function OptionBoxSanity(props) {
  const { options, onChange, t, inverted = false, headline = "" } = props;

  function getColor(optionColor){
    if (optionColor){
      return Object.values(optionColor);
    }else {
      return null;
    }
  }

  function renderAnonymizationOptions() {
    return (
      <div>
        {options.map(option => {
          let visible = inverted && option.value ? true : inverted && !option.value ? false : !inverted && option.value ? false : true;
          return (
            <CheckBoxButton
              key={option.id}
              id={option.id}
              label={option.label}
              value={option.value}
              onChange={() => onChange(option.id, inverted? option.value : !option.value)}
              color={() => getColor(option.color)}
              visible={visible}
            />
          );
        })}
      </div>
    );
  }

  function toggleCheckedAll() {
    const value = allOptionsChecked();

    for (const option of options) {
      onChange(option.id, !value);
    }
  }

  function allOptionsChecked() {
    for (const option of options) {
      if (option.value === false) {
        return false;
      }
    }
    return true;
  }

  return (
    <React.Fragment>
      {inverted &&
      <div>
        <Headline>
          {headline}
        </Headline>
        <CheckBoxButton
          key={"replaceNames"}
          id={"replaceNames"}
          label={t("optionmodal3", "Namen")}
          value={true}
          onChange={() => {return}}
          disabled={true}
          color={orange2}
        />
        {renderAnonymizationOptions()}
      </div>
      }
      {!inverted && !allOptionsChecked() &&
      <div>
        <Headline>
          {headline}
          <AlleButton
            id="remove_all"
            data-cy={"add_all_options"}
            onClick={() => toggleCheckedAll()}
            visible={!allOptionsChecked()}
            type="button"
          >{t("optionmodal1", "Alle hinzufügen")}</AlleButton>
        </Headline>
        {renderAnonymizationOptions()}
      </div>
      }
    </React.Fragment>
  );
}

export default withTranslation("translations")(OptionBoxSanity);
