import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { blue, grey70, grey30, errorred } from '../colors';

const Container = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : grey70};
    height: ${props => props.height ? props.height + 'px' : "25px"};
    padding: 3px;
    width: ${props => props.width ? props.width + '%' : "49%"};
    min-width: 200px;
    border-radius: 5px;
    display:flex;
    align-content: center;
    @media (max-width: 768px) {
      width:100%;
    }
`;


const animateShine = keyframes`
    0% {
      opacity: 0;
      width: 0;
    }
    50% {
      opacity: .3;
    }
    100% {
      opacity: 0;
      width: 95%;
    }
`;

const Bar = styled.span`
    min-width: ${props => props.percent + "%"};
    width: ${props => props.percent + "%"};
    display: inline-block;
    height: 100%;
    border-radius: 3px;
    transition: width .4s ease-in-out;
    background-color: ${grey30};
    text-align: center;
    font-size: 15px;
    position: relative;

    &:after {
        content: '';
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${blue};
        border-radius: 3px;
        animation: ${animateShine} 2s ease-out infinite;
    }
`;

const BarErrored = styled.span`
    min-width: 100%;
    width: 100%;
    display: inline-block;
    height: 100%;
    border-radius: 3px;
    transition: width .4s ease-in-out;
    background-color: ${errorred};
    text-align: center;
    font-size: 15px;
    position: relative;

    &:after {
        content: '';
        opacity: 0.3;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${errorred};
        border-radius: 3px;
    }
`;

const BarNoAnimation = styled.span`
    min-width: 100%;
    width: 100%;
    display: flex;
    height: 100%;
    border-radius: 3px;
    transition: width .4s ease-in-out;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : blue};
    text-align: center;
    font-size: 15px;
    color: black;
    position: relative;
    align-items:center;
    justify-content:center;

`;

export default class ProgressBar extends React.PureComponent {

    render() {
        const { percent, error, width, height, backgroundColor } = this.props;
        return (
            <Container width={width} height={height} backgroundColor={backgroundColor} >
                {percent < 100 &&
                    <Bar percent={percent}>{percent}%</Bar>
                }
                {percent === 100 &&
                    <BarNoAnimation backgroundColor={backgroundColor}>100%</BarNoAnimation>
                }
                {error === true &&
                    <BarErrored/>
                }
            </Container>
        );
    }
}
