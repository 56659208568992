import React from 'react';
import {useField} from 'formik';
import {red, green1, darkblue, grey60, grey10} from '../colors';
import styled from '@emotion/styled';
import Label from './LabelReg';
import Icon from "./Icon/Icon";
import {TextArea} from "semantic-ui-react";

const InputField = styled(TextArea)`
    position: relative;
    z-index: ${props => props.noIndex ? 0 : 1};
    width: 100%;
    border-radius: 5px;
    padding: 10px 10px;
    appearance: none;
    border: none;
    display: block;
    outline: 0 none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
    &:disabled {
      background: none;
      border-bottom: 1px solid var(--color-text);
    }
    ${props => props.bordered && `border: 1px solid ${grey10};`}
    ${props => props.border && `border: 1px solid ${green1};`}
    ${props => props.error === "true" && `border: 1px solid ${red};`}

`;

const Hint = styled.div`
    margin-top: 0;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 10px;
    display: block;
    color: ${darkblue};
`;

const Feedback = styled.div`
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 10px;
    float: right;
    ${props => props.valid === "valid" && `color: ${green1};`}
    ${props => props.valid === "invalid" && `color: ${red};`}
`;

const SuccessMark = styled.span`
    margin-right:5px;
`;

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content:  flex-end;
`;

const EyeIcon = styled(Icon)`
    max-width: 26px;
    position: relative;
    top: -38px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
    color: #f7f7f7;
    flex: 1;
    fill: ${grey60};
`;

const TextAreaInput = ({label, helpText, icon,iconClick, ...props}) => {

  const [field, meta, rows] = useField(props);
  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the field has been visited (touched === true)
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const renderSuccessMark = () => {
    return <SuccessMark>✓</SuccessMark>
  }

  const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched || props.checkAlways;
  return (
    <div className={`form-control ${
      showFeedback ? (meta.error ? 'invalid' : 'valid') : ''
    }`}
    >
      <Label htmlFor={props.id} focusx={true} narrow={props.narrow}>
        {label}
      </Label>{' '}
      {showFeedback ? (
        <Feedback data-cy={`${meta.error ? 'invalid' : 'valid'}-${props['data-cy']}`}
                  valid={meta.error ? 'invalid' : 'valid'}>{meta.error ? meta.error : renderSuccessMark()}</Feedback>
      ) : null
      }
      <InputField
        {...props}
        {...field}
        {...rows}
        onFocus={handleFocus}
        border={showFeedback}
        error={`${(meta.error && ((!!didFocus && field.value.trim().length > 2) || meta.touched || props.checkAlways))}`}
      />
      {icon &&
      <IconContainer>
        <EyeIcon
          onClick={iconClick} size={26}
          name={icon}/>
      </IconContainer>
      }
      {helpText ? (<Hint>{helpText}</Hint>) : null}
    </div>
  )
};

export default TextAreaInput
