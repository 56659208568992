import React, {useState, useEffect, useRef} from 'react';
import {darkblue, grey5, grey7, grey10, grey30, grey70, grey90} from '../colors';
import styled from '@emotion/styled';

import {InfoPopupGreen} from './InfoPopup';
import TextInputLiveFeedback from './TextInputLiveFeedback';
import Modal from './Modal';
import {Form} from './FormReg';
import CheckBox from "./CheckBox";
import {Button, LinkButton} from './Button';
import {Button as ButtonReg}   from './ButtonReg';
import Alert from '../components/Alert';
import CountrySelector from "../components/CountrySelector";
import Curtain from '../components/Curtain';
import Label from '../components/LabelReg';

import {post, get, post_simple} from '../security/Api';
import * as Yup from "yup";
import {withTranslation} from "react-i18next";
import error from "../error";
import CountryCodes from 'country-code-info';
import {Grid} from 'semantic-ui-react';
// import { Form as PureForm } from 'semantic-ui-react';

import 'semantic-ui-css/components/grid.css';
import 'semantic-ui-css/components/form.css';

import {Formik, Field} from 'formik';
import {Link} from "react-router-dom";


const DarkHeadline = styled.h1`
    color: ${darkblue} !important;
    margin-bottom: 70px;
`;

const Divider = styled.hr`
    color: ${grey70};
    height: .5px;
    width: 100%;
    margin-top: -3px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
`;

export const FormHeadline = styled.div`
   line-height: 35px;
   color: ${darkblue};
   font-size: 16px;
   font-family: AvenirLTPro-Black, AvenirNextCyrBold;
   text-transform: uppercase;
`;

const FormSubHead = styled.h3`
    margin-top: 45px;
    color: ${darkblue};
`;

const RechnungContainer = styled.div`
    display: flex;
`;

const FlexLabel = styled(Label)`
    margin-top:34px;
    margin-left: 25px;
    font-size: 15px;
`;

const HeaderCheckbox = styled(Field)`
    fontFamily: "AvenirNextLTPro-Medium, AvenirNextCyrMedium";
    margin-left:10px;
    color: ${darkblue} !important;
`;
const DarkGrid = styled(Grid)`
    font-family: AvenirNextLTPro-Medium, AvenirNextCyrMedium;
    color: ${darkblue};
    background: ${props => props.white ? "white" : "transparent"};
    box-shadow: 6px 6px 25px rgb(0, 20, 50, 0.05);
    margin-bottom: 5px !important;
    border-radius: 5px;
`;

const SubHeadline = styled.h3`
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    margin-top: 10px;
`;

const SubSubHeadline = styled.h4`
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    margin: 0;
`;

const Adress = styled.div`
    width: 50%;
`;
const Name = styled.div`
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
`;
const AdressLine = styled.div`
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
    display: flex;
`;

const SmallRow = styled(Grid.Row)`
    margin: 0;
    padding: 0 !important;
`;

const CreditBuyRow = styled.div`
    background: transparent;
    padding: 5px;
    border: 1px solid;
    border-color: ${grey10};
    border-radius: 5px;
    display: flex;
`;

const Credits = styled.div`
    width: 60%
`;
const Price = styled.div`
    width: 40%;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
`;


const CostNumberBox = styled.select`
    padding: 10px 20px;
    // margin-bottom: 30px;
    display: block;
    width: 100%;
    margin-right: 10px;
`;

const RightColumnArea = styled.div`
    padding: 10px 10px;
    background: ${grey7};
    border-radius: 5px;
    color: ${props => props.disabled ? grey30 : grey90};
    transition: 0.15s;
    box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
`;

const ButtonBox = styled.div`
    // padding-top: 4px;
    display: flex;
    justify-content: space-between;
`;

// const FirstGridRow = styled.div`
//     padding: 10px 10px;
//     background: ${grey7};
//     border-radius: 5px;
//     color: ${props => props.disabled ? grey30 : grey90};
//     transition: 0.15s;
//     box-shadow: 6px 6px 25px rgb(54, 125, 206, 0.05) ;
// `;

const Hl = styled.hr`
    width: 100%;
    height: 10px;
    border: 0;
    background-color: ${grey5};
`;

const DatenContainer = styled.div`
    display: flex;
`;

const DatenschutzLink = styled(Link)`
    margin-left: 10px;
    margin-top: 11px;
    color: #7c7c8c;
    text-decoration: none;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    display: flex;
    &:hover {
      color: #7e8c96;
    }
`;

function OrganisationModal(props) {
  const {
    onClose,
    t,
    organisationFormData,
    updateOrganisationForm,
    packageObj,
    activeMethods,
    tarifRowsByMethod
  } = props;

  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAdressAddon, setShowAdressAddon] = useState(organisationFormData.billing_address_diff);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(t("account13", 'Ihre Organisatie Daten wurde erfolgreich aktualisiert.'))
  const [kasse, setKasse] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [costNumbers, setCostNumbers] = useState(false);
  const [costNumberSelected, setCostNumberSelected] = useState('');
  // const [methodSelected, setMethodSelected] = useState('');
  const [currentCostNumberBalance, setCurrentCostNumberBalance] = useState({});
  const [datePrivacySelected, setDatePrivacySelected] = useState(false);
  const [agbSelected, setAgbSelected] = useState(false);

  useEffect(() => {
    loadCostNumbers(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    updateSelectedRateBalance(costNumberSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costNumberSelected])


  let loadCostNumbers = async (props) => {
    const response = await get('/costnumber/list');

    if (response.status === 200) {
      const responseData = await response.json();
      // setTarifRowsByMethod();

      let temp = {};

      await Object.keys(responseData).forEach((key) => {

        if (Object.keys(responseData[key].creditHistories).length > 0) {
          responseData[key].creditHistories.forEach((value, index) => {
            if (!responseData[key].hasOwnProperty("currentTarifCredits")) {
              responseData[key].currentTarifCredits = {};
            }
            if (!temp.hasOwnProperty(value.methodName)) {
              temp[value.methodName] = 0;
            }
            if (!responseData[key].currentTarifCredits.hasOwnProperty(value.methodName)) {
              responseData[key].currentTarifCredits[value.methodName] = 0;
            }
            if (value.methodName) {
              temp[value.methodName] += parseInt(value.credit);
              responseData[key].currentTarifCredits[value.methodName] += parseInt(value.credit);
            }
          })
        } else {
          responseData[key].currentTarifCredits = {};
        }
      });

      setCostNumbers(responseData);
      // setTarifRowsByMethod(temp);

      if (props.asModal && props.projectCostNumber && props.projectCostNumber.id) {
        await setCostNumberSelected(props.projectCostNumber.id);
        updateSelectedRateBalance(props.projectCostNumber.id);
      } else if (responseData[0]) {
        await setCostNumberSelected(responseData[0].id);
        updateSelectedRateBalance(responseData[0].id);
      }

      setLoaded(true);
      // setLoadingError(false);

      console.log(tarifRowsByMethod);

      return;
    }
  }

  // const [comment, setComment] = useState("");

  let updateSelectedRateBalance = (id) => {

    console.log(costNumbers);

    Object.keys(costNumbers).forEach(function (key) {
      if (id === costNumbers[key].id) {
        // alert(id);
        if (Object.keys(costNumbers[key].currentTarifCredits) && Object.keys(costNumbers[key].currentTarifCredits).length > 0) {
          setCurrentCostNumberBalance(costNumbers[key].currentTarifCredits);
          console.log(costNumbers[key].currentTarifCredits);
          // alert(costNumbers[key].currentTarifCredits);
        } else setCurrentCostNumberBalance({});
      }
    })
  }

  let financify = (price) => { //convert any number to a financing format
    let string_large = price.toString();
    let array = string_large.split(".");
    return array.length > 1 ? array[0] + "." + (array[1] + "00").substring(0, 2) : array + '.00';
  }


  let tax = 0, tax_large = 0;
  if (packageObj) {
    if (packageObj.price) {
      tax_large = (packageObj.price * 0.19).toString();
      tax = financify(tax_large);
    }
  }

  let checkOrganisationFull = (o) => {
    return (o.name.length > 0 && o.adress.length > 0 && o.zip.length > 0 && o.city.length > 0 && o.country.length > 0 && o.invoice_adress.length > 0 && o.invoice_zip.length > 0 && o.invoice_city.length > 0 && o.invoice_country.length > 0);
  }
  const organisationFull = useRef(checkOrganisationFull(organisationFormData));
  const [formFull, setFormFull] = useState(organisationFull.current);

  let toggleAdressAddon = (e, setFieldValue) => {
    setFieldValue('showAdressAddon', !showAdressAddon);
    setShowAdressAddon(!showAdressAddon);
  }

  let buyCredits = async () => {
    setLoaded(false);
    let response = await post_simple(
      '/credits/buyself',
      {
        organisationId: organisationFormData.id,
        packageId: packageObj.id,
        costNumberId: costNumberSelected,
        methodName: packageObj.methodName,
      }
    );
    if (response.status === 200) {
      let credits = organisationFormData.credits + packageObj.credits;
      let newOrganisation = {
        ...organisationFormData,
        credits: credits,
      }
      updateOrganisationForm(newOrganisation);
      setLoaded(true);
      let responseData = await response.json();
      if (responseData) { //change the url in the controller for the open invoice in order to be able to get a PDF
        let pdfData = "data:application/pdf;base64," + responseData.data;
        const a = document.createElement('a');
        a.href = pdfData;
        a.download = "deepsight_invoice.pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      }

      onClose();

      return;
    } else {
      setErrorState(true);
      setErrorMessage(error(0, t));
      setLoaded(true);
      return;
    }
  }

  let buyCreditsTest = async () => {
    setLoaded(false);
    let response = await post_simple(
      '/credits/buytest',
      {
        organisationId: organisationFormData.id,
        comment: "",
      }
    );
    if (response.status === 200) {
      setLoaded(true);
      await response.json();
      setSuccess(true);
      setSuccessMessage(t("organisationModal23", "Ihre Anfrage wird innerhalb von 24 Stunden bearbeitet"));
      return;
    } else {
      setErrorState(true);
      setErrorMessage(error(0, t));
      setLoaded(true);
      return;
    }
  }

  const OrganisationSchema = Yup.object().shape({
    showAdressAddon: Yup.boolean(),
    name: Yup
      .string(t("account18", "Ungültige Angabe Name"))
      .required(t("account15", "Dieses Feld muss ausgefüllt sein."))
      .nullable(),
    adress: Yup
      .string(t("account19", "Ungültige Angabe Adress"))
      .nullable()
      .required(t("account15", "Dieses Feld muss ausgefüllt sein.")),
    invoice_adress: Yup
      .string(t("account19", "Ungültige Angabe Adress"))
      .nullable()
      .when("showAdressAddon", {
        is: true,
        then: Yup.string().required(t("account15", "Dieses Feld muss ausgefüllt sein."))
      }),
    zip: Yup
      .string(t("account20", "Ungültige Angabe PLZ"))
      .nullable()
      .required(t("account15", "Dieses Feld muss ausgefüllt sein.")),
    invoice_zip: Yup
      .string(t("account20", "Ungültige Angabe PLZ"))
      .nullable()
      .when("showAdressAddon", {
        is: true,
        then: Yup.string().required(t("account15", "Dieses Feld muss ausgefüllt sein."))
      }),
    city: Yup
      .string(t("account40", "Ungültige Angabe Stadt"))
      .nullable()
      .required(t("account15", "Dieses Feld muss ausgefüllt sein.")),
    invoice_city: Yup
      .string(t("account40", "Ungültige Angabe Stadt"))
      .nullable()
      .when("showAdressAddon", {
        is: true,
        then: Yup.string().required(t("account15", "Dieses Feld muss ausgefüllt sein."))
      }),
  })

  if (loaded === false) {
    return (
      <Curtain type="loading"/>
    );
  }


  let costNumbersOptions = () => {

    if (loaded && costNumbers) {

      return costNumbers.map(function (costNumber) {
        if (costNumber.name.length) {
          return (<option value={costNumber.id}>{costNumber.name} ({costNumber.credits} Credits)</option>);
        } else {
          return (<option
            value={costNumber.id}>{'_' + t("projectForm12", "default")} ({costNumber.credits} Credits)</option>);
          // this.setState({ "_default": costNumber.id });
        }
      });

    }
  }

  // let methodOptions = () => {
  //   return methodList.map(function (method) {
  //     if (method.name.length) {
  //       return (<option value={method.symfonyName}>{method.name}</option>);
  //     } else return false;
  //   });
  // }


  let costNumberSelect = (e) => {
    setCostNumberSelected(e.target.value);
    updateSelectedRateBalance(e.target.value);
  }

  let togglePrivacyCheckbox = () => {
    setDatePrivacySelected(!datePrivacySelected);
  }

  let toggleAgbCheckbox = () => {
    setAgbSelected(!agbSelected);
  }


  // let methodSelect = (e) => {
  //   setMethodSelected(e.target.value);
  // }

  return (
    <Modal onClose={onClose} narrow={true}>
      <DarkHeadline>
        {t("todo107", "Top up rows")}
      </DarkHeadline>
      <DarkGrid white>
        {/*<Grid.Row columns={2} verticalAlign='top'>*/}
        {/*  <Grid.Column width={6}>*/}
        {/*    <RightColumnArea>*/}
        {/*      <SubSubHeadline>{t("todo106", "Current rows")}*/}
        {/*      </SubSubHeadline>*/}
        {/*    </RightColumnArea>*/}
        {/*  </Grid.Column>*/}
        {/*  <Grid.Column width={10}>*/}
        {/*    <RightColumnArea>*/}
        {/*      {organisationFormData.credits}*/}
        {/*      /!*{t("organisationModal9", "Credits")}*!/*/}
        {/*    </RightColumnArea>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}

        {/*{Object.keys(organisationFormData).length > 0 &&*/}
        {/*Object.keys(tarifRowsByMethod).map(function (key) {*/}
        {/*  if(packageObj.methodName !== key) return false;*/}

        {/*  return (*/}
        {/*    <Grid.Row columns={2} verticalAlign='top'>*/}
        {/*      <Grid.Column width={6}>*/}
        {/*        <RightColumnArea><SubSubHeadline>{key}</SubSubHeadline></RightColumnArea>*/}
        {/*      </Grid.Column>*/}
        {/*      <Grid.Column width={10}>*/}
        {/*        <RightColumnArea>*/}
        {/*          {tarifRowsByMethod[key]}*/}
        {/*        </RightColumnArea>*/}
        {/*      </Grid.Column>*/}
        {/*    </Grid.Row>*/}
        {/*  )*/}
        {/*})*/}
        {/*}*/}

        {/*<Grid.Row columns={1} verticalAlign='top'>*/}
        {/*  <Grid.Column width={16}>*/}
        {/*    <FirstGridRow>*/}
        {/*      <SubSubHeadline>{t("todo10", "Current rows by method:")}*/}
        {/*      </SubSubHeadline>*/}
        {/*    </FirstGridRow>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}

        <Grid.Row columns={2} verticalAlign='top'>
          <Grid.Column width={6}>
            <RightColumnArea>
              <SubSubHeadline>
                {activeMethods.map(method => {
                  if (method.method === packageObj.methodName) return method.name + " " + t("organisationModal31", "Gesamtmenge")
                  return false;
                })}
              </SubSubHeadline>
            </RightColumnArea>
          </Grid.Column>
          <Grid.Column width={10}>
            <RightColumnArea>
              {tarifRowsByMethod[packageObj.methodName] > 0 ? tarifRowsByMethod[packageObj.methodName] : "0"}
            </RightColumnArea>
          </Grid.Column>
        </Grid.Row>


        <Grid.Row columns={3} verticalAlign='top'>
          <Grid.Column width={6}>
            <RightColumnArea>
              <SubSubHeadline>{t("projectform10", "Nummer auswählen")}
              </SubSubHeadline>
            </RightColumnArea>
          </Grid.Column>
          <Grid.Column width={10}>
            <ButtonBox>
              <CostNumberBox
                id="costNumberSelect"
                name="costNumberSelect"
                value={costNumberSelected}
                onChange={costNumberSelect}
              >
                {costNumbersOptions()}
              </CostNumberBox>
              <LinkButton
                title={t("cost12", "Kostennummern verwalten")}
                mainbutton
                icon={"edit"}
                target="_blank"
                data-cy="manage-costnumbers"
                to={'/costnumber/create'}
                middle={"true"}
                minWidth
              >
              </LinkButton>
            </ButtonBox>
          </Grid.Column>
          <Grid.Column width={2}>

          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2} verticalAlign='top'>
          <Grid.Column width={6}>
            <RightColumnArea>
              <SubSubHeadline>
                {activeMethods.map(method => {
                  if (method.method === packageObj.methodName) return method.name + " " + t("organisationModal32", "ausgewählte Methode")
                  return false;
                })}
              </SubSubHeadline>
            </RightColumnArea>
          </Grid.Column>
          <Grid.Column width={10}>
            <RightColumnArea>
              {currentCostNumberBalance[packageObj.methodName] > 0 ? currentCostNumberBalance[packageObj.methodName] : "0"}
            </RightColumnArea>
          </Grid.Column>
        </Grid.Row>

        <Hl/>

        {/*<Grid.Row columns={1} verticalAlign='top'>*/}
        {/*  <Grid.Column width={16}>*/}
        {/*    <FirstGridRow>*/}
        {/*      <SubSubHeadline>{t("todo11", "Select method to up rows:")}*/}
        {/*      </SubSubHeadline>*/}
        {/*    </FirstGridRow>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}

        {/*<Grid.Row columns={4} verticalAlign='top'>*/}
        {/*  <Grid.Column width={6}>*/}
        {/*    <RightColumnArea>*/}
        {/*      <SubSubHeadline>*/}
        {/*        {t("updateit111", "Select method")}*/}
        {/*      </SubSubHeadline>*/}
        {/*    </RightColumnArea>*/}
        {/*  </Grid.Column>*/}

        {/*  <Grid.Column width={10}>*/}
        {/*    <CostNumberBox*/}
        {/*      id="methodSelect"*/}
        {/*      name="methodSelect"*/}
        {/*      value={methodSelected}*/}
        {/*      onChange={methodSelect}*/}
        {/*    >*/}
        {/*      {methodOptions()}*/}
        {/*    </CostNumberBox>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}

        <Grid.Row columns={3} verticalAlign='top'>
          <Grid.Column
            width={6}>
            <RightColumnArea>
              <SubSubHeadline>{t("organisationModal2", "Rows hinzufügen")}</SubSubHeadline>
            </RightColumnArea>
          </Grid.Column>
          <Grid.Column width={7}>
            <CreditBuyRow>
              <Credits>{packageObj && packageObj.credits} {t("organisationModal3", "Zeile")}</Credits>
              <Price>{packageObj && packageObj.price}&euro;</Price>
            </CreditBuyRow>
          </Grid.Column>

          <Grid.Column width={3}>
          <ButtonBox>
            <Button
              fullWidth
              narrow={true}
              title={t("organisationModal4", "Ändern")}
              mainbutton
              icon={"edit"}
              onClick={onClose}>
              {t("organisationModal4", "Ändern")}
            </Button>
          </ButtonBox>
          </Grid.Column>
        </Grid.Row>
        {/*<Grid.Row columns={4} verticalAlign='top'>*/}
        {/*    <Grid.Column width={5}><SubSubHeadline>{t("organisationModal21", "Use Case")}</SubSubHeadline></Grid.Column>*/}
        {/*    <Grid.Column width={7}>*/}
        {/*        <PureForm>*/}
        {/*            <TextAreaTiny rows={2} value={comment} onChange={(e) => setComment(e.target?.value)} placeholder={t("organisationModal22", "Bitte, beschreiben Sie kurz den Use case")}/>*/}
        {/*        </PureForm>*/}
        {/*    </Grid.Column>*/}
        {/*</Grid.Row>*/}
      </DarkGrid>
      {formFull &&
      <React.Fragment>
        <DarkGrid white>
          <Grid.Row columns={3} verticalAlign='top'>
            <Grid.Column width={6}>
              <SubSubHeadline>{t("organisationModal6", "Rechnungsadresse")}</SubSubHeadline>
            </Grid.Column>
            <Grid.Column width={7}>
              <Adress>
                <Name>{organisationFormData.name}</Name>
                <AdressLine>{organisationFormData.invoice_adress}</AdressLine>
                <AdressLine>{organisationFormData.invoice_zip}{" "}{organisationFormData.invoice_city}</AdressLine>
                <AdressLine>{CountryCodes.findCountry({'a2': organisationFormData.invoice_country}).name}</AdressLine>
              </Adress>
            </Grid.Column>
            <Grid.Column width={3}>
                <ButtonBox>
                  <Button
                    fullWidth
                    narrow={true}
                    title={t("organisationModal4", "Ändern")}
                    mainbutton
                    icon={"edit"}
                    onClick={() => {
                      setFormFull(false);
                    }}
                  >
                    {t("organisationModal4", "Ändern")}
                  </Button>
                </ButtonBox>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} verticalAlign='top'>
            <Grid.Column width={5}>
              <SubSubHeadline>{t("organisationModal10", "Bezahlmethode")}</SubSubHeadline>
            </Grid.Column>
            <Grid.Column width={6}>
              <AdressLine>
                <span>{t("organisationModal11", "Rechnung")}</span>
                <InfoPopupGreen
                  content={t('organisationModal12', "Aktuell koennen Credits nur auf Rechnung bestellt werden. Wir arbeiten an weiteren Bezahlmethoden.")}/>
              </AdressLine>
            </Grid.Column>
          </Grid.Row>
        </DarkGrid>
        {!kasse &&
        <DarkGrid>
          <Grid.Row columns={1} verticalAlign='top' align="center">
            <Grid.Column width={16}>
              <Button
                mainbutton
                tiny={true}
                onClick={() => setKasse(true)}
                type="button">
                {t("organisationModal14", "Zur Kasse gehen")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </DarkGrid>
        }
      </React.Fragment>
      }
      {kasse && formFull &&
      <DarkGrid white>
        <SubHeadline>{t("organisationModal13", "Bestaetigen")}</SubHeadline>
        <SmallRow columns={2} verticalAlign='top' width={12}>
          <Grid.Column width={6} floated="left">
            {packageObj && packageObj.credits} {t('todo108', 'Rows')}
          </Grid.Column>
          <Grid.Column width={3} align="right" floated="right">
            {packageObj && financify(packageObj.price)}&euro;
          </Grid.Column>
        </SmallRow>
        <SmallRow columns={1}>
          <Grid.Column width={16}>
            <Divider/>
          </Grid.Column>
        </SmallRow>
        <SmallRow columns={2} verticalAlign='top'>
          <Grid.Column width={6} floated="left">
            {t("organisationModal15", "Zwischensumme (netto)")}
          </Grid.Column>
          <Grid.Column width={3} align="right" floated="right">
            {packageObj && financify(packageObj.price)}&euro;
          </Grid.Column>
        </SmallRow>
        <SmallRow columns={2} verticalAlign='top'>
          <Grid.Column width={6} floated="left">
            {t("organisationModal16", "Umsatzsteuer 19%")}
          </Grid.Column>
          <Grid.Column width={3} align="right" floated="right">
            {tax}&euro;
          </Grid.Column>
        </SmallRow>
        <Grid.Row columns={2} verticalAlign='top'>
          <Grid.Column width={6} floated="left">
            <SubSubHeadline>{t("organisationModal17", "Gesamtbetrag")}</SubSubHeadline>
          </Grid.Column>
          <Grid.Column width={3} align="right" floated="right">
            <SubSubHeadline>{packageObj && tax && financify(tax * 1 + packageObj.price)}&euro;</SubSubHeadline>
          </Grid.Column>
        </Grid.Row>
        <SmallRow columns={1} verticalAlign='top'>
          <Grid.Column width={16}>
            {t("organisationModal18", "Zalungsziel sind 60 Tage nach Rechnungseingang")}
          </Grid.Column>
        </SmallRow>

        <Grid.Row columns={1} verticalAlign='top' align="left">
          <Grid.Column width={16}>
            <DatenContainer>
              <CheckBox
                key={"index"}
                label={t("dataprivacy2", "Ich bestätige hiermit die ")}
                value={datePrivacySelected}
                onChange={togglePrivacyCheckbox}
                id="acceptTerms"
                name="acceptTerms"
                type="checkbox"
              />

              <DatenschutzLink to={'/data-protection'}
                               target="_blank">{t("privacypolicy", "Datenschutzbestimmungen. ")}</DatenschutzLink>
            </DatenContainer>
          </Grid.Column>
        </Grid.Row>


        <DatenContainer>
          <CheckBox
            key={"index"}
            label={t("dataprivacy2", "Ich bestätige hiermit die ")}
            value={agbSelected}
            onChange={toggleAgbCheckbox}
            id="acceptAgb"
            name="acceptAgb"
            type="checkbox"
          />

          <DatenschutzLink
            target="_blank"
            to={'/agb'}>
            {t("agbs", "AGB")}
          </DatenschutzLink>
        </DatenContainer>


        <Grid.Row columns={1} verticalAlign='top' align="center">
          <Grid.Column width={16}>
            {packageObj?.price > 0 &&
            <Button
              mainbutton
              icon={'main_arrow'}
              tiny={true}
              onClick={() => buyCredits()}
              disabled={!datePrivacySelected || !agbSelected}
              type="button">
              {t("organisationModal19", "Kostenpflichtig im Auftrag geben ")}
            </Button>
            }
            {packageObj?.price === 0 &&
            <Button
              mainbutton
              tiny={true}
              danger
              icon={'main_arrow'}
              onClick={() => buyCreditsTest()}
              type="button">
              {t("organisationModal24", "Test Credits anfordern")}
            </Button>
            }
          </Grid.Column>
        </Grid.Row>
      </DarkGrid>
      }

      {!formFull &&
      <Formik
        validateOnMount
        initialValues={{
          id: organisationFormData.id,
          credits: organisationFormData.credits,
          name: organisationFormData.name,
          adress: organisationFormData.adress,
          adress_addon: organisationFormData.adress_addon,
          zip: organisationFormData.zip,
          city: organisationFormData.city,
          country: organisationFormData.country,
          billing_address_diff: organisationFormData.billing_address_diff,
          invoice_adress: organisationFormData.invoice_adress,
          invoice_adress_addon: organisationFormData.invoice_adress_addon,
          invoice_zip: organisationFormData.invoice_zip,
          invoice_city: organisationFormData.invoice_city,
          invoice_country: organisationFormData.invoice_country,
          // showAdressAddon: organisationFormData.billing_address_diff,
        }}
        enableReinitialize
        validationSchema={OrganisationSchema}
        onSubmit={async (values, formikHelpers) => {
          let onSaveOrganisationError = (code) => {
            setErrorState(true);
            setErrorMessage(error(code, t));
            setSuccess(false);
          }
          let onSaveOrganisationSuccess = async () => {
            updateOrganisationForm(values);
            setFormFull(true);
            setSuccess(true);
          }
          if (!showAdressAddon) {
            values.invoice_zip = values.zip;
            values.invoice_adress = values.adress;
            values.invoice_adress_addon = values.adress_addon;
            values.invoice_city = values.city;
            values.invoice_country = values.country;
          }

          values.billing_address_diff = showAdressAddon;

          await post(
            '/organisation/edit',
            values,
            onSaveOrganisationError,
            onSaveOrganisationSuccess
          );
        }}
      >
        {({values, setFieldValue}) => (

          <Form>
            <FormSubHead>{t("account31", "Adresse")}</FormSubHead>
            <TextInputLiveFeedback
              id="name"
              name="name"
              type="text"
              label={t("account25", "Company Name")}
              narrow={true}
              checkAlways={true}
              noIndex={true}
            />
            <TextInputLiveFeedback
              id="adress"
              name="adress"
              type="text"
              label={t("account22", "Strasse, Hausnummer")}
              narrow={true}
              checkAlways={true}
              noIndex={true}
            />
            <TextInputLiveFeedback
              id="adress_addon"
              name="adress_addon"
              type="text"
              label={t("account23", "Adressenzusatz")}
              narrow={true}
              checkAlways={true}
              noIndex={true}
            />
            <TextInputLiveFeedback
              id="zip"
              name="zip"
              type="text"
              label={t('account24', "PLZ")}
              narrow={true}
              checkAlways={true}
              noIndex={true}
            />
            <TextInputLiveFeedback
              id="city"
              name="city"
              type="text"
              label={t("account27", "Stadt")}
              narrow={true}
              checkAlways={true}
              noIndex={true}
            />
            <CountrySelector
              name="country"
              narrow={true}
              initialValue={{
                value: organisationFormData.country,
                label: CountryCodes.findCountry({'a2': organisationFormData.country}).name
              }}
              handleChange={
                (value) => setFieldValue("country", value.value)
              }
              label={t("account29", "Land")}
            />
            <RechnungContainer>
              <FormSubHead>
                {t("account30", "Rechnungsadresse")}
              </FormSubHead>
              <FlexLabel htmlFor={"showAdressAddon"} focusx={false}>
                {t("account34", "Entspricht der Organisationsadresse")}
                <HeaderCheckbox
                  // checked={!organisationFormData.billing_address_diff}
                  checked={!showAdressAddon}
                  onChange={(e) => toggleAdressAddon(e, setFieldValue)}
                  type="checkbox"
                  id="billing_address_diff"
                  name="billing_address_diff"
                />
              </FlexLabel>{' '}

            </RechnungContainer>
            <Field id="showAdressAddon" type="hidden" name="showAdressAddon"/>
            {showAdressAddon &&
            <div>
              <TextInputLiveFeedback
                id="invoice_adress"
                name="invoice_adress"
                type="text"
                label={t("account22", "Strasse, Hausnummer")}
                narrow={true}
                checkAlways={true}
                noIndex={true}
              />
              <TextInputLiveFeedback
                id="invoice_adress_addon"
                name="invoice_adress_addon"
                type="text"
                label={t("account23", "Adressenzusatz")}
                narrow={true}
                checkAlways={true}
                noIndex={true}
              />
              <TextInputLiveFeedback
                id="invoice_zip"
                name="invoice_zip"
                type="text"
                label={t('account24', "PLZ")}
                narrow={true}
                checkAlways={true}
                noIndex={true}
              />
              <TextInputLiveFeedback
                id="invoice_city"
                name="invoice_city"
                type="text"
                label={t("account27", "Stadt")}
                narrow={true}
                checkAlways={true}
                noIndex={true}
              />
              <CountrySelector
                name="invoice_country"
                narrow={true}
                initialValue={{
                  value: organisationFormData.invoice_country,
                  label: CountryCodes.findCountry({'a2': organisationFormData.invoice_country}).name
                }}
                handleChange={
                  (value) => setFieldValue("invoice_country", value.value)
                }
                label={t("account29", "Land")}
              />
            </div>
            }
            <ButtonContainer>
              <ButtonReg
                tiny
                mainbutton
                data-cy="organisation-submit"
                icon={'main_arrow'}
                id="organisationSubmit"
                type="submit">
                {t('organisationModal5', "Speichern")}
              </ButtonReg>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      }
      {errorState &&
      <Alert
        type="error"
        message={errorMessage ? errorMessage : error(0, t)}
        onClose={() => setErrorState(false)}
      />
      }
      {success && successMessage &&
      <Alert
        type="info"
        message={successMessage}
        onClose={() => setSuccess(false)}
      />
      }
    </Modal>
  );

}

export default withTranslation("translations")(OrganisationModal);
