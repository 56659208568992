import React from "react";
import Icon from "./Icon/Icon";
import styled from "@emotion/styled";
import { darkblue, grey60 } from '../colors';

const SortContainer = styled.button`
  display: flex;
  align-items: center;
  
  color: ${props => props.active ? `${darkblue}` : `${grey60}`};
  
  font-size: 16px;
  
  border: 0;
  background: none;
  cursor: pointer;
  appearance: none;
  
  &:focus {
     outline: 0;
  } 
`;

const SortIconContainer = styled.div`
  margin-right: 5px;
`;

export default class Sort extends React.PureComponent {
  render() {
    const {children, onClick, active, icon, size} = this.props;

    return (
      <SortContainer onClick={onClick} active={active}>
        <SortIconContainer>
          <Icon name={icon} size={size} />
        </SortIconContainer>
        {children}
      </SortContainer>
    );
  }
}
