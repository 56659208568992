import React from 'react';
import {Divider, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

// import styled from '@emotion/styled';
// import {darkblue, white, blue} from '../../colors';
import Icon from '../Icon/Icon';
// import logo from '../Header/logo.svg';
// import {NavLink} from '../NavLink';
// import logo from '../Footer/logo_deepsight_short_black.svg';
// import ButtonLink from '../Buttons/ButtonLink';
import {withTranslation} from "react-i18next";

import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LanguageCodePicker from "../LanguageCodePicker";

const LogoContainer = styled('div')({
  padding: "0"
});

// const TypographyBold = styled('Typography')({
//   fontWeight: "bolder"
// });

const Cntr = styled('div')({
  marginLeft: "10%",
  marginRight: "10%",
});
// const FooterLogo = styled('img')({
//   marginLeft: "30px",
//   marginTop: "10px",
//   maxWidth: "100%"
// });

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: "#efefef",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 0,
  boxShadow: "none"
}));

const DatenschutzLink = styled(Link)`

  color: #7c7c8c;
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: inline;

  &:hover {
    color: #7e8c96;
  }
`;

function FooterNew(props) {
  const {t} = props;
  return (

    <Box sx={{flexGrow: 1, backgroundColor: "#efefef"}}>
      <Grid container spacing={{xs: 2}} columns={{xs: 1}} sx={{backgroundColor: "#efefef", marginTop: "20px"}}>
        <Grid item xs={1} key="1">
          <Item
            sx={{
              height: 120,
              backgroundColor: "#efefef",
              textAlign: "left"
            }}
          >
            {/*<LogoContainer>*/}
            {/*  <FooterLogo src={logo} alt="Logo"/>*/}
            {/*</LogoContainer>*/}

            <Divider
              sx={{
                marginTop: "95px",
                borderBottomWidth: 2,
                marginLeft: "10%",
                marginRight: "10%",
                borderColor: "#fbfafb"
              }}
              variant="middle"/>
          </Item>
        </Grid>
      </Grid>

      <Cntr>

        <Grid container spacing={3} sx={{backgroundColor: "#efefef", marginBottom: "10px"}}>
          <Grid item xs={12} sm={6} md={3}>
            <Item
              sx={{
                textAlign: "left",
                fontWeight: "bold"
              }}>
              <Typography type="H5" sx={{fontWeight: "bold"}}>deepsight GmbH</Typography>
              <Typography type="H5" sx={{fontWeight: "bold"}}>Lengericher Landstr. 34</Typography>
              <Typography type="H5" sx={{fontWeight: "bold"}}>49078 Osnabrück</Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item
              sx={{
                textAlign: "left",
              }}>
              <Typography type="H5" sx={{fontWeight: "bold"}}>hi@deepsight.de</Typography>
              <Typography type="H2" sx={{fontWeight: "bold"}}>
                <LogoContainer>
                  <br/>
                  <DatenschutzLink
                    to={{ pathname: "https://de.linkedin.com/company/deepsight-gmbh" }}
                    target={"_blank"}>
                    <Icon name="linkedn" size={35}/>
                  </DatenschutzLink>
                  {/*<Icon name="xing" size={35}/>*/}
                </LogoContainer>
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item
              sx={{
                textAlign: "left",
              }}>
              <DatenschutzLink
                to={{pathname: "https://www.deepsight.de/company/about" }}
                target={"_blank"}>
              <Typography type="H2" sx={{fontWeight: "bold"}}>
                {t("companies", "Unternehmen")}</Typography>
              </DatenschutzLink>
              <DatenschutzLink
                to={{ pathname: "https://www.deepsight.de/company/jobs" }}
                target={"_blank"}>
              <Typography type="H2" sx={{fontWeight: "bold"}}>
                {t("jobs", "Jobs")}
              </Typography>
              </DatenschutzLink>
              <DatenschutzLink
                to={{ pathname: "/contactus" }}
                target={"_blank"}>
              <Typography type="H2" sx={{fontWeight: "bold"}}>
                {t("contact1", "Kontakt")}
                </Typography>
            </DatenschutzLink>
          </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item
              sx={{
                textAlign: "left",
              }}>

              <DatenschutzLink
                to={'/data-protection'}
                target={"_blank"}><Typography
                type="H2" sx={{fontWeight: "bold"}}
              >
                {t("privacypolicy", "Datenschutzbestimmungen ")}
              </Typography>
              </DatenschutzLink>
              <DatenschutzLink
                to={'/data-protection'}
                target={"_blank"}
              >
                <Typography type="H2"
                            sx={{fontWeight: "bold"}}
                >Impressum
                </Typography>
              </DatenschutzLink>
              <DatenschutzLink
                to={'/agb'}
                target={"_blank"}
              >
                <Typography
                  type="H2"
                  sx={{fontWeight: "bold"}}>
                  {t("agbs", "AGB")}
                </Typography>
              </DatenschutzLink>

            </Item>
          </Grid>
        </Grid>

      </Cntr>

      <Grid container spacing={{xs: 2}} columns={{xs: 1}}
            sx={{marginTop: "20px", backgroundColor: "#efefef", minHeight: "200px"}}>
        <Grid item xs={1} key="3">
          <Item
            sx={{
              height: 50,
              backgroundColor: "#efefef",
              textAlign: "right"
            }}
          >
            <Divider
              sx={{
                borderBottomWidth: 2,
                marginLeft: "10%",
                marginRight: "10%",
                borderColor: "#fbfafb"
              }}
              variant="middle"/>
            <Cntr
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row-reverse"
              }}>
              <LanguageCodePicker/>
            </Cntr>
          </Item>

        </Grid>
      </Grid>
    </Box>
  )
}

export default withTranslation("translations")(FooterNew);
