import React from 'react';
import EditInput from './EditInput';
import styled from '@emotion/styled';
import { IconContainer, Name } from './CardHeaderWizard';
import ProgressRing from './ProgressRing';

const EditInputContainer = styled.div`
    width: 500px;
`;

class StudyEdit extends React.PureComponent {

    render() {
        const { name, onChangeName, status, progress, onSubmit } = this.props;

        return (
            <Name>
                <IconContainer>
                    <ProgressRing
                        progress={progress}
                        status={status}
                    />
                </IconContainer>
                <EditInputContainer>
                    <EditInput
                        light={true}
                        id="study-name"
                        value={name}
                        onChange={onChangeName}
                        onSubmit={onSubmit}
                    />
                </EditInputContainer>

            </Name>
        );
    }
}

export default StudyEdit;
