import React from 'react';
import { darkblue } from '../colors';
import styled from '@emotion/styled';
import Modal from './Modal';
import Error from './Error';
import { post } from '../security/Api';
import TextInputLiveFeedback from './TextInputLiveFeedback';
import { Button } from './ButtonReg';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Form } from './FormReg';
import { withTranslation } from "react-i18next";
import error from "../error";

const Headline = styled.div`
    color: ${darkblue};
    font-size: 32px;
    margin-bottom: 28px;
    text-align: left;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-item: center;
    margin-top: 25px;
`;

class ResetPasswordModal extends React.PureComponent {
    state= {
        error: false,
        errorMessage: null
    }
    render() {
        const { onClose, t } = this.props;

        const PasswordChangeSchema = Yup.object().shape({
            oldPassword: Yup
                .string(t("registr56", "Ungültige Angabe Passwort"))
                .required(t("registr44", "Dieses Feld muss ausgefüllt sein.")),
            newPassword: Yup
                .string(t("registr57", "Ungültige Angabe Passwort"))
                .min(12, t("registr42", "Das Passwort muss mindestens 12 Zeichen beinhalten"))
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{0,}/, {
                    message: t("registr43", "Das Passwort muss Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten"),
                    excludeEmptyString: true
                })
                .required(t("registr47", "Dieses Feld muss ausgefüllt sein.")),
            newPasswordAgain: Yup
                .string(t("registr45", "Ungültige Angabe Organisation"))
                .required(t("registr47", "Dieses Feld muss ausgefüllt sein."))
                .test('match', t("registr46", "Die Passwörter stimmen nicht überein"), function (passwordSecond){
                    return passwordSecond === this.options.from[0].value.newPassword
                })
         })

        return (
            <Modal onClose={onClose}>
                <Headline>
                    {t("resetpassword1", "Passwort ändern")}
                </Headline>
                    <Formik
                        initialValues={{
                              oldPassword: '',
                              newPassword: '',
                              newPasswordAgain: ''
                        }}
                        validationSchema={PasswordChangeSchema}
                        validateOnBlur
                        onSubmit={async (values, {setFieldError}) => {
                                let onError = async (code) => {
                                    if (code === 490){
                                        await setFieldError("oldPassword", this.props.t("resetpassword3", "Das aktuelles Passwort ist nicht korrekt"));
                                    }else{
                                        this.setState({
                                            error: true,
                                            errorMessage: error(code, this.props.t)
                                        })
                                    }
                                }
                                let onSuccess = () => {
                                    this.props.onSuccess()
                                }
                                await post(
                                    '/password/update',
                                    values,
                                    onError,
                                    onSuccess
                                )
                            }
                        }
                    >
                        <Form>
                            <TextInputLiveFeedback
                                id="oldPassword"
                                name="oldPassword"
                                type="password"
                                label={t('resetpassword5',"Aktuelles Passwort")}
                            />
                            <TextInputLiveFeedback
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                helpText={t("registr48", "Das Passwort muss aus mindestens 12 Zeichen bestehen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten")}
                                label={t('resetpassword4',"Neues Passwort")}
                            />
                            <TextInputLiveFeedback
                                id="newPasswordAgain"
                                name="newPasswordAgain"
                                type="password"
                                label={t('resetpassword6',"Neues Passwort wiederholen")}
                            />
                            {this.state.error &&
                                <Error>
                                    {this.state.errorMessage}
                                </Error>
                            }
                            <Footer>
                                <Button data-cy="abort-modal" onClick={() => onClose()}>{t("deleteusermodel3", "Abbrechen")}</Button>
                                <Button data-cy="submit-modal" type='submit'>{t("resetpassword2", "Ändern")}</Button>
                            </Footer>
                        </Form>
                    </Formik>


            </Modal>
        );
    }
}

export default withTranslation("translations")(ResetPasswordModal);
