import React from 'react';
import styled from "@emotion/styled";
import {
  buttonmain
} from '../colors';
import Icon from './Icon/Icon';

const TabUnit = styled.div`
    ${props => !props.active && 'cursor: pointer;'};
    // ${props => !props.active && 'background: darkgrey;'};
    color: ${props => props.active ? buttonmain : 'lightgrey'};
    border: 1px solid transparent;
    // border-bottom: 1px solid darkgrey;
    padding: 15px;
    
    ${props => props.active && `
      // border: 1px solid darkgrey;
      // border-radius: 5px 5px 0 0;
      border-bottom: 1px solid ${buttonmain};
      z-index:2;
      transition: border 0.9s ease 0s;
  ` };
  
    &::after {
      content: "";
      width: 100%;
      // border-bottom: 1px solid darkgrey;
      position: absolute;
      bottom: 0;
    }
`;

export const TabText = styled.div`
    display: inline;
    padding-left: 5px;

`;

const Tab = (props) => {

  const {onClick, text, active = false, icon} = props;

  return (
    <TabUnit active={active} onClick={onClick}>
      {icon && <Icon size={12} name={icon}/>}
      <TabText>
        {text}
      </TabText>
    </TabUnit>
  )
};

export default Tab
