import React from 'react';

import styled from '@emotion/styled';
import Icon from './Icon/Icon';

const StudyIcon = styled(Icon)`
  position: relative;
`;

const Svg = styled.svg`
  position: relative;
  margin-left: -55px;
  height: 70px;
  width: 70px;
`;

const Circle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

const colorMap = {
  'created': '#ED7F43',
  'check_pending': '#ED7F43',
  'checked': '#ED7F43',
  'pending': '#ED7F43',
  'running': '#ED7F43',
  'halfed': '#ED7F43',
  'completed': '#6EC4C3',
  'thema_completed': '#6EC4C3',
  'inference_completed': '#6EC4C3',
  'failed': '#E3453D',
  'reimbursed': '#999999'
};

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { status } = this.props;

    this.color = colorMap[status];
  }

  state = {
    radius: 35,
    stroke: 6,
  };

  render() {
    const { progress } = this.props;
    const { radius, stroke } = this.state;

    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
      <React.Fragment>
        <StudyIcon size={40} name="ai_circle_white" />
        <Svg>
          <Circle
            stroke={this.color}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </Svg>
      </React.Fragment>
    );
  }
}

export default ProgressRing;
