import styled from '@emotion/styled';
import { bluedarker, darkblue } from '../colors';

const StyledLink = styled.a`
  color: ${darkblue};

  &:hover {
      color: ${bluedarker};
  }
`;

export default StyledLink;
