import React from "react";
import {Link, withRouter} from "react-router-dom";
import styled from "@emotion/styled";

import {bluedarker, darkblue} from "../colors";
import Icon from "./Icon/Icon";

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const NavLinkItem = styled(Link)`
  color: ${darkblue};
  text-decoration: none;
  display: block;
  border-bottom: 3px solid ${props => props.selected ? darkblue : 'transparent'};
  margin-right: 76px;
  margin-top: 5px;

  line-height: 32px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;

  &:hover {
    color: ${bluedarker};
    border-bottom: 3px solid transparent;
  }

  > svg {
    margin: 0 5px -8px 0px;
  }
`;

export class SubNavigation extends React.PureComponent {
  render() {
    return (
      <NavigationContainer>
        {this.props.children}
      </NavigationContainer>
    );
  }
}

class NavLinkComponent extends React.PureComponent {
  render() {
    const {to, location, icon, children} = this.props;

    return (
      <NavLinkItem
        data-cy={this.props['data-cy']}
        to={to}
        selected={location.pathname === to}
      >
        {icon && <Icon name={icon} />}
        {children}
      </NavLinkItem>
    );
  }
}

export const NavLink = withRouter(NavLinkComponent);
