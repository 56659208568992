import React from "react";

export default class Guard extends React.PureComponent {
  render() {
    const { children, secure } = this.props;

    if (secure() === false) {
      return null;
    }

    return children;
  }
}
