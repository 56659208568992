import React from "react";
import styled from "@emotion/styled";

export const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flow-direction: row;
  align-items: center;
`;

export const HeadlineText = styled.h2`

`;

export default class SubHeadline extends React.PureComponent {
  render() {
    return (
      <HeadlineContainer>
        <HeadlineText>
          {this.props.children}
        </HeadlineText>
      </HeadlineContainer>
    );
  }
}
