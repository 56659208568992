import React from 'react';
// import ErrorMessage from './ErrorMessage';
// import styled from '@emotion/styled';
// import Label from './Label';
import Icon from './Icon/Icon';
// import { errorred20, grey80, red, white } from '../colors';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import {withTranslation} from "react-i18next";

function SearchField(props) {

  const {
    placeholder,
    searchingValue,
    updateSearchingValue,
    deleteSearchingValue,
    startSearching,
    small,
    noMagnifier,
  } = props;

  let onKeyPressed = (e) => {
    if(e.keyCode === 13){
      console.log('value', e.target.value);
      // put the login here
      startSearching();
    }
  }

  return (
    <Paper
      variant="outlined"
      component="form"
      sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', height: small ? '32px' : ''}}
    >

      <InputBase
        size="small"
        value={searchingValue}
        sx={{ml: 1, flex: 1}}
        placeholder={placeholder}
        onChange={updateSearchingValue}
        onKeyDown={onKeyPressed}
      />
      <IconButton type="button" sx={{p: '10px'}} aria-label="search">
        <Icon
          name={"close"}
          size={24}
          onClick={deleteSearchingValue}
        />
      </IconButton>
      {!noMagnifier &&
      <>
        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
        <IconButton type="button" sx={{p: '10px'}} aria-label="search">
          <Icon
            name={"search"}
            size={24}
            onClick={startSearching}
          />
        </IconButton>
      </>
      }
    </Paper>
  );

}

export default withTranslation("translations")(SearchField);

