import React from 'react'
import { Popup } from 'semantic-ui-react'
import styled from '@emotion/styled';

import Icon from './Icon/Icon';
import { green3, darkblue, blue, buttonmain } from '../colors';


const NotEmptyPopup = styled(Popup)`
    visibility: ${props=> props.content.length > 0 ? "visible" : "hidden"}
`;

const Green3 = {
    background: green3,
    borderRadius: '10px',
    color: darkblue,
    font: 'normal normal medium 16px/24px Avenir',
}

const Text = styled.div`
    margin-bottom: -4px;
`;

const IconContainer = styled.div`
    color: ${blue};
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const IconContainerBlue = styled.div`
    color: ${buttonmain};
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const InfoPopupGreen = (props) => (
    <Container>
        <Text>
            {props.children}
        </Text>
        <IconContainer>
            <NotEmptyPopup
                basic
                wide={props.wide}
                content={props.content}
                style={Green3}
                trigger={<Icon size={26} name="info_outline" />}
            />
        </IconContainer>
    </Container>
)

export const InfoPopupBlue = (props) => (
  <Container>
    <Text>
      {props.children}
    </Text>
    <IconContainerBlue>
      <NotEmptyPopup
        basic
        wide={props.wide}
        content={props.content}
        style={Green3}
        trigger={<Icon size={26} name="info_outline" />}
      />
    </IconContainerBlue>
  </Container>
)
