import React from 'react';
import {useTranslation} from 'react-i18next'
import PageContent from '../components/PageContent';
import Headline from "../components/Headline";
import Page from '../components/Page';
import {grey5} from '../colors';
import {withTranslation} from "react-i18next";
import {Typography} from "@mui/material";


function Documentation() {

  const {t} = useTranslation();

  return (
    <Page background={grey5}>
      <PageContent>
        <Headline>{t('documentationPageHeader', "Kopfzeile der Dokumentationsseite ")}</Headline>
        <Typography>Version {process.env.REACT_APP_CURRENT_VERSION}</Typography>
      </PageContent>
    </Page>
  );
}

export default withTranslation("translations")(Documentation);
