import React from 'react';
import styled from '@emotion/styled';

import { post } from '../security/Api';
import TextInputLiveFeedback from './TextInputLiveFeedback';
import { Button } from './ButtonReg';
import { Formik } from 'formik';
import * as Yup from "yup";
import CardBody from './CardBody';
import { Form } from './FormReg';
import { withTranslation } from "react-i18next";
import error from "../error";
import Alert from '../components/Alert';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top:30px;
`;

const FormSubHead = styled.h3`
    margin-top: 45px;
`;

class InviteAdminForm extends React.PureComponent {
    state = {
        formData: {
            email: null,
            firstName: null,
            lastName: null,
        },
        formErrors: {},
        errors: {},
    };
    pageReload = () => {
        this.setState({
            error: false,
            errorMessage: null,
        })
        window.location.reload();
    }
    render() {


    const { t, updateSuccessStatus } = this.props;
    const InviteAdminSchema = Yup.object().shape({
        email: Yup
            .string(t("registr34", "Ungültige Angabe E-Mail"))
            .email(t("registr35", "Ungültige Angabe E-Mail"))
            .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
        firstName: Yup
            .string(t("registr41", "Ungültige Angabe Vorname")),
        lastName: Yup
            .string(t("registr55", "Ungültige Angabe Nachname"))
     })

    return (
            <CardBody>
                <Formik
                  initialValues={{
                      email: '',
                      firstName: "",
                      lastName: ""
                  }}
                  validationSchema={InviteAdminSchema}
                  validateOnBlur
                  onSubmit={async (values, {setFieldError}) => {
                      let locale = this.props.i18n.language;
                      values.locale = locale;
                      let inviteAdminError = (code) => {
                        if (code === 492) {
                            setFieldError('email', error(code, t));
                        } else{
                            this.setState({
                                error: true,
                                errorMessage: error(code,t)
                            })
                        }
                          updateSuccessStatus({
                              successProfile: false,
                              successAdmin: false,
                              successOrganisation: false
                          })
                      }
                      let inviteAdminSuccess = async (responseData) => {
                          await this.setState({
                              formErrors: {},
                              errors: {},
                          });
                          updateSuccessStatus({
                              successProfile: false,
                              successAdmin: true,
                              successOrganisation: false
                          })
                      }
                      await post(
                          '/invite-admin',
                          values,
                          inviteAdminError,
                          inviteAdminSuccess
                      );

              }}
            >
                <Form data-cy='invite-admin-form'>
                    <FormSubHead>{t("inviteadminform1", "Admin Einladen")}</FormSubHead>
                    <TextInputLiveFeedback
                        id="email"
                        name="email"
                        type="text"
                        label="E-Mail"
                        data-cy='email'
                    />
                    <TextInputLiveFeedback
                        id="firstName"
                        name="firstName"
                        type="text"
                        label={t("inviteadminform8","Vorname (optional)")}
                        data-cy="firstName"
                    />
                    <TextInputLiveFeedback
                        id="lastName"
                        name="lastName"
                        type="text"
                        label={t("inviteadminform7","Nachname (optional)")}
                        data-cy='lastName'
                    />
                    <ButtonContainer>
                        <Button data-cy="invite-admin-submit" type="submit" id="inviteAdminSubmit" icon="main_arrow">{t("inviteadminform3", "Einladen")}</Button>
                    </ButtonContainer>
                </Form>
        </Formik>
        {this.state.error &&
            <Alert
                type="error"
                message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
                onClose={this.pageReload}
            />
        }
        </CardBody>


    );
  }
}

export default withTranslation("translations")(InviteAdminForm);
