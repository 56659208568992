import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import {get, post} from '../security/Api';
import PageContent from '../components/PageContent';
import Curtain from '../components/Curtain';
import {Button} from '../components/Button';
import CardHeaderBackground, {
  CardHeaderBackgroundHeadline,
  HeadlineIcon,
  HeadlineText,
} from '../components/CardHeaderBackground';
import Icon from '../components/Icon/Icon';
import CardHeaderWizard, {Info} from '../components/CardHeaderWizard';
import {STATUS_CURRENT, STATUS_FINISHED, STATUS_NEXT, Step, Wizard} from '../components/Wizard';
import CardBody, {CardBodyInner} from '../components/CardBody';
import SubHeadline from '../components/SubHeadline';
import styled from '@emotion/styled';
import StudyEdit from '../components/StudyEdit';
import FileUpload from '../components/FileUpload';
import {isCompleted, isEditAble, isWorking, studyProgress} from '../studyStatus';
import Page from '../components/Page';
import {white, blue} from '../colors';
import error from '../error';
import {LinkIcon} from '../components/Button';

import {InfoPopupGreen} from '../components/InfoPopup';
import {withTranslation} from "react-i18next";
import i18n from 'i18next';
import Alert from '../components/Alert';
import UserOnboarding from "../components/UserOnboarding";

export const ProjectLink = styled(Link)`
    color: ${white};
    text-decoration: none;
    &:hover {
        color: ${blue};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
    // padding: 0 42px 0 0;
`;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


class Study extends React.PureComponent {
  state = {
    formData: {
      name: '',
      file: null,
    },

    formErrors: {},
    errors: {},
    project: null,
    study: null,
    loaded: false,
    error: false,
    errorMessage: "",
    info: false,
    infoMessage: "",
    loadingError: false,

    selectedFile: null,
    fileErrors: [],

    currentStep: this.props.currentStep,
    closeTour: this.props.closeTour,
    tourConfig: this.props.tourConfig,
    isTourOpen: this.props.isTourOpen,
    setTourPassed: this.props.setTourPassed,
    nextStepClicked: this.props.nextStepClicked,

    onRequestClose: this.props.closeTour,
    steps: this.props.tourConfig,
    isOpen: this.props.isTourOpen,
  };

  t = this.props.t;
  onError = (code) => {
    this.setState({loaded: true, error: true, errorMessage: error(code, this.t)});
  }

  async componentDidMount() {
    await this.loadProject();
    await this.loadStudy();

    const {study, project, error} = this.state;
    if (!error) {
      if (study) {
        if (isEditAble(study) === false) {
          const {history} = this.props;

          await history.push(`/project/${project.id}/study/${study.id}/result`);
          return;
        }
        ;
      }
    }
    return;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.error) {
      const {formData} = this.state;

      if (prevState.formData.file === formData.file) {
        return;
      }

      await this.loadProject();
      return;
    }
  }


  loadProject = async (file) => {
    const {match} = this.props;
    const projectId = match.params.projectId;

    const response = await get(`/project/${projectId}/detail`);

    if (response.status === 200) {
      const responseData = await response.json();
      let updatedFiles = false;
      if (typeof file !== "undefined") {
        await responseData.files.forEach((element, index) => {
          if (element.id === file.id) {
            responseData.files[index].justAdded = true
            updatedFiles = true;
          }
        });
      }
      await this.setState({
        project: responseData,
      });
      if (updatedFiles) {
        let file = this.state.project.files.find(file => file.justAdded === true);
        let index = this.state.project.files.indexOf(file);
        let project = this.state.project;
        await sleep(1000);
        project.files[index].justAdded = undefined;
        project.files[index].highlighted = true;
        await this.setState({project: project});
        this.forceUpdate();
      }
    } else {
      await this.setState({loadingError: true});
      return;
    }
  };

  loadStudy = async () => {
    const {match} = this.props;
    const studyId = match.params.studyId;

    const studyResponse = await get(`/study/${studyId}`);

    if (studyResponse.status === 200) {
      const responseData = await studyResponse.json();

      await this.setState({
        study: responseData,
        formData: {
          name: responseData.name,
        },
        loaded: true,
      });

      await this.updateSelectedFile();
      return;
    }
    if (studyResponse.status === 400) {
      const responseData = await studyResponse.json();
      if (responseData.code) {
        this.setState({loaded: true, loadingError: true, errorMessage: error(responseData.code, this.t)});
        return;
      } else {
        this.setState({loaded: true, loadingError: true, errorMessage: error(0, this.t)});
        return
      }
    }
    this.setState({
      loadingError: true,
      loaded: true
    });
    return;
  };

  updateSelectedFile = () => {
    const {study} = this.state;

    if (study === null) {
      return;
    }

    if (study.files.length === 0) {
      return;
    }

    this.setState({
      selectedFile: study.files[0].id,
    });
  };

  onChange = (key, value) => {
    this.setState((prevState) => (
      {
        ...prevState,
        formData: {
          ...prevState.formData,
          [key]: value,
        },
      }
    ));
  };

  onClose = () => {
    const {history, match} = this.props;
    const projectId = match.params.projectId;

    history.push(`/project/${projectId}/detail`);
  };

  editFileLanguage = async (language, file) => {
    const response = await get(`/file/${file.id}/editLanguage/${language}`);
    if (response.status === 200) {
      this.loadProject();
    }
  }

  onStudyEditError = (code) => {
    this.setState({
      error: true,
      errorMessage: error(code, this.t)
    })
  }
  onStudyEditSuccess = () => {
    this.setState({
      error: false,
      errorMessage: null,
      info: true,
      infoMessage: this.t("studyresult17", 'Der Name wurde erfolgreich geändert.')
    })
  }


  onQuitNameInput = async (event) => {
    const {match} = this.props;
    const {formData} = this.state;

    const studyId = match.params.studyId;

    await post(
      '/study/edit',
      {
        ...formData,
        studyId: studyId,
      },
      this.onStudyEditError,
      this.onStudyEditSuccess
    );
  };

  onSubmit = async () => {
    const {history, match} = this.props;
    const {selectedFile} = this.state;

    const projectId = match.params.projectId;
    const studyId = match.params.studyId;
    let onAddFileError = (code) => {
      this.setState({
        error: true,
        errorMessage: error(code, this.t),
        finishedStep: true,
      });
    }
    let onAddFileSuccess = () => {
      this.setState({
        error: false,
        errorMessage: null,
        finishedStep: true,
      });
      this.state.nextStepClicked(9);

      history.push(`/project/${projectId}/study/${studyId}/detail`);
    }

    await post(
      `/${studyId}/file/${selectedFile}/add`,
      {
        studyId: studyId,
        fileId: selectedFile,
      },
      onAddFileError,
      onAddFileSuccess
    );
  };

  onUpload = async (file) => {
    await this.loadProject(file);
  }
  deleteById = async (id) => {
    let files = this.state.project.files.filter(file => file.id !== id);
    let project = this.state.project;
    project.files = files;
    this.setState({project: project});
  }

  render() {
    const {formData, fileErrors, loaded, project, study, error, loadingError, selectedFile} = this.state;

    const {t} = this.props;


    if (loaded === false) {
      return (
        <Curtain type="loading"/>
      );
    }
    if (loadingError === true) {
      return (
        <Curtain type="error"/>
      );
    }

    return (
      <Page>
        <PageContent dark={true}>
          <UserOnboarding
            steps={this.state.tourConfig}
            isOpen={this.state.isTourOpen}
            rounded={25}
            accentColor={"#5cb7b7"}
            onRequestClose={this.state.closeTour}
            setTourPassed={this.state.setTourPassed}
            currentStep={this.state.currentStep}
            nextStepClicked={this.state.nextStepClicked}
            onSubmit={this.onSubmit}
          />
          <form>
            <CardHeaderBackground>
              <CardHeaderBackgroundHeadline>
                <HeadlineIcon>
                  <Icon name="folder_big_ai"/>
                </HeadlineIcon>
                <HeadlineText>
                  <ProjectLink to={`/project/${project.id}/detail`}>{project.name}</ProjectLink> / {formData.name}
                </HeadlineText>
              </CardHeaderBackgroundHeadline>

              <CardHeaderWizard>
                <div data-tut="step_seven">
                <StudyEdit
                  onChangeName={(value) => this.onChange('name', value)}
                  name={formData.name}
                  onSubmit={() => this.onQuitNameInput()}
                  status={study.status}
                  progress={studyProgress(study)}
                />
                </div>
                <Wizard>
                  <Step icon="data_up"
                        status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_CURRENT}
                        active={true}
                  >
                    {t('study1', "Daten")}
                  </Step>
                  <Step icon="analysis_wizard" size={20}
                        status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_NEXT}
                        active={false}
                  >
                    {t('study2', "Method")}
                  </Step>
                  <Step icon="data_down"
                        status={isWorking(study) || isCompleted(study) ? STATUS_FINISHED : STATUS_NEXT}
                        active={false}
                  >
                    {t('study3', "Auswertung")}
                  </Step>
                </Wizard>
                <Info>
                  <LinkIcon data-cy="close-study-x" icon="close" to={`/project/${project.id}/detail`}/>
                </Info>
              </CardHeaderWizard>
            </CardHeaderBackground>
            <CardBody withGradient={true}>
              <CardBodyInner noTopPadding={true}>
                <InfoPopupGreen wide
                                content={t('study8', "Um Daten erfolgreich zu bearbeiten oder zu analysieren, braucht unsere KI entsprechend vorbereiteten Input. So müssen zum Beispiel alle zu bearbeitenden Daten in einer Spalte im Dokument stehen. Wenn Sie Unterstützung bei der Vorbereitung Ihrer Dokumente brauchen, kontaktieren Sie gerne unseren Support.")}>
                  <SubHeadline>
                    {t("study66", "Rohdaten wählen")}
                  </SubHeadline>
                </InfoPopupGreen>
                <FileUpload
                  changeSelected={(selectedFile) => this.setState({selectedFile: selectedFile})}
                  selected={selectedFile}
                  uploadUrl={`/${project.id}/file/upload`}
                  language={i18n.language}
                  onUpload={(file) => this.onUpload(file)}
                  files={project.files}
                  errors={fileErrors}
                  editFileLanguage={async (language, file) => this.editFileLanguage(language, file)}
                  delete={async (id) => this.deleteById(id)}
                />

                <ButtonContainer>
                  <Button
                    data-tut="step_nine"
                    mainbutton
                    fullwidth
                    data-cy="submit-file"
                    icon="main_arrow"
                    disabled={selectedFile === null}
                    onClick={() => this.onSubmit()}
                    type="button"
                  >
                    {t('study6', "Weiter zur Auswahl der Methoden")}
                  </Button>
                </ButtonContainer>
              </CardBodyInner>
            </CardBody>
          </form>
        </PageContent>
        {this.state.error &&
        <Alert
          type="error"
          message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
          onClose={() => this.setState({error: false})}
        />
        }
        {this.state.info && this.state.infoMessage &&
        <Alert
          type="info"
          message={this.state.infoMessage}
          onClose={() => this.setState({info: false})}
        />
        }
      </Page>
    );
  }

  hasFileAlreadyUploaded() {
    const {selectedFile} = this.state;

    return selectedFile !== null;
  }
}

export default withTranslation("translations")(withRouter(Study));
