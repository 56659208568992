import React from 'react';
import styled from '@emotion/styled';
import { grey30, grey70, grey80, grey5, white, darkblue } from '../colors';
import { withTranslation } from "react-i18next";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

let dark_blue_arr = hexToRgb(darkblue);
let dark_blue_shadow = `3px 5px 6px rgba(${dark_blue_arr.r}, ${dark_blue_arr.g}, ${dark_blue_arr.b}, 0.2)`;
let darker_blue_shadow = `5px 8px 10px rgba(${dark_blue_arr.r}, ${dark_blue_arr.g}, ${dark_blue_arr.b}, 0.8)`;

const MethodContainer = styled.div`
    position: relative;
    height: 230px;
    cursor: ${props => props.active ? 'pointer' : 'auto'};

overflow-y: hidden;
overflow-x: hidden;

    margin-bottom: 18px;

    ${props => props.selected && `background: linear-gradient(90deg, ${grey70}  0%, ${grey80} 50%);`}
    ${props => (!props.selected && props.active) && `background: ${white};`}
    ${props => (!props.selected && !props.active) && `background: ${grey5};`}

    color: ${props => props.selected ? white : props.active ? grey80 : grey70};
    border: ${props => props.active ? `1px solid ${grey70}` : `1px solid ${grey30}`};
    border-radius: 4px;
    appearance: none;

    font-size: 1rem;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    text-decoration: none;
    text-align: left;
    box-shadow: ${props => props.active ? dark_blue_shadow : 'none'};
    ${props => props.active &&
        `&:hover {
            background: linear-gradient(90deg, ${grey70}  0%, ${grey80} 50%);
            color: white;
            box-shadow: ${darker_blue_shadow};
        }`
    }
`;

const MethodInnerRow = styled.div`
    display: flex;
    justify-content: left;
    height: 190px;
    padding: 38px;
    // overflow-y: scroll;

    > svg {
        ${props => props.text && props.icon && 'margin-right: 16px;'}
        width: 96px;
        height: 72px;
    }
`;

const IconContainer = styled.div`
    margin-right: 20px;
    width: 90px;
`;

const MethodDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const HeadlineContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const MethodHeadline = styled.div`
    display: flex;
    margin-bottom: 14px;
    font-size: 22px;
    font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
    justify-content: space-between;
    align-items: center;
`;

const SubHeadline = styled.div`
    font-size: 1em;

    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
`;

const ButtonRow = styled.div`
    position: absolute;
    right: -7px;
    padding: 7px;
    bottom: -3px;
    float: right;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: ${grey30};
    color: white;
    font-family: AvenirNextLTPro, AvenirNextCyrMedium;
`;


class Method extends React.PureComponent {
    render() {
        const { headline, t, active, selected, onPickMethod, id, cypressClass, icon } = this.props;

        return (
            <MethodContainer data-tut={id === "anonymization" ? "step_ten" : ""} active={active} selected={selected} onClick={() => onPickMethod(id)} data-cy={cypressClass}>
                <MethodInnerRow>
                    <IconContainer>
                        <img
                            src={icon}
                            alt='Method'
                            width={60}
                            height={60}
                            style={{
                                'position': 'relative',
                                'marginRight': '10px',
                            }}
                        />
                    </IconContainer>
                    <MethodDetail>
                        <HeadlineContainer>
                            <MethodHeadline>{headline}</MethodHeadline>
                        </HeadlineContainer>
                        <SubHeadline>
                        { id === "anonymization" &&
                            t("method7", "Ersetze alle gewuenschten Begriffe (z.B. Namen, Kontaktdaten oder Addressen) mit einem Pseudonym.")
                        }
                        { id === "thematic_analysis" &&
                            t("method10", "Identifiziere Themen automatisch oder gruppiere  Kommentare nach vordefinierten Themen")
                        }
                        { id === "sanity_check" &&
                            t("method8", "Entferne unsinnige oder bedeutungslose Kommentare (z.B. “ok”, “n/a”)")
                        }
                        { id === "language_correction" &&
                            t("method9", "Korrigiere Tippfehler, schludrige Beitrage oder Grammatikfehler")
                        }
                        { id === 'sentiment_analysis' &&
                            t("method11", "Analysiere Stimmungsbild, in positiven und negativen Kommentaren")
                        }
                        { id === 'nps_csat_analysis' &&
                            t("method12", "Analysiere Themen und Sentiment in Kommentaren zu Net Promoter Score (NPS) oder Customer Satisfaction (CSAT)")
                        }

                        </SubHeadline>
                    </MethodDetail>

                </MethodInnerRow>
                {!active &&
                    <ButtonRow>

                      {t("accessible", "Verfügbar ab")}&nbsp;
                        {/*{ id === "anonymization" &&*/}
                        {/*    t("method13", "Ersetze alle gewuenschten Begriffe (z.B. Namen, Kontaktdaten oder Addressen) mit einem Pseudonym.")*/}
                        {/*}*/}
                        {/*{ id === "thematic_analysis" &&*/}
                        {/*    t("method14", "Ende 2021")*/}
                        {/*}*/}
                        {/*{ id === "sanity_check" &&*/}
                        {/*    t("method15", "2022")*/}
                        {/*}*/}
                        {/*{ id === "language_correction" &&*/}
                        {/*    t("method16", "2022")*/}
                        {/*}*/}
                        {/*{ id === 'sentiment_analysis' &&*/}
                        {/*    t("method17", "2022")*/}
                        {/*}*/}
                        {/*{ id === 'nps_csat_analysis' &&*/}
                        {/*    t("method18", "2022")*/}
                        {/*}*/}
                    </ButtonRow>
                }
            </MethodContainer>
        );
    }
}

export default withTranslation("translations")(Method);
