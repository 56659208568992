import styled from "@emotion/styled";
import Link from "./Link";
import {grey10} from "../colors";

export const CardListContainer = styled.div`
  margin-top: 5px;
`;

export const CardContainer = styled.div`
  margin-bottom: 54px;
`;

export const SortHeaderContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 0;
  padding: 0 72px;
`;

export const SortStudiesContainer = styled.div`
  display: flex;
  justify-content: right;
  margin: 0;
  padding: 10px 42px;
  background: ${grey10};
`;

export const More = styled(Link)`
  display: block;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
`;
